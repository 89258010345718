import { TextField } from '@material-ui/core'
import React from 'react'
import InputMask from 'react-input-mask'

export default function PhoneInput({disabled,value,onChange,onBlur,isphone,...props}){

  return <InputMask
    mask="(999) 999-9999"
    value={value}
    onChange = {onChange}
    onBlur = {onBlur}
    maskChar=""
    disabled={disabled}
  >
    {() => <TextField  {...props} disabled={disabled}/>}
  </InputMask>
    

}