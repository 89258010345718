
import LeftIcon from '@material-ui/icons/ChevronLeft'
import RightIcon from '@material-ui/icons/ChevronRight'
import PopupMenu from '../../components/common/PopupMenu'
import React, { useEffect, useRef, useState } from 'react'

const weekdays = 'Sun,Mon,Tues,Wednes,Thurs,Fri,Satur'.split(',')

export const usaDate = dat => {
    const [y,m,d] = dat.split('-')
    return [m,d,y].join('/')
}

export const getDay = dat => {
    const dy = new Date(dat).getDay()
    return weekdays[dy]+'day'
}
export default function DatePicker({futureOnly=true,value,notToday,onChange,...props}){
    const cdate = new Date()

    const cyear = cdate.getFullYear()
    const cmonth =  cdate.getMonth()
    const todate =  cdate.getDate()

    const [month,setMonth] = useState(cmonth)
    const [year,setYear] = useState(cyear)

    const [date,setDate] = useState([cyear,cmonth,todate])

    const months= ["January","February","March","April","May","June","July",
            "August","September","October","November","December"]


    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);

    const lastDate = lastDay.getDate()
    const firstWeekday = firstDay.getDay()

    const days = []
    const [hide,setHide] = useState(false)

    for(let i=0;i<firstWeekday;i++){
        days.push(0)
    }

    for(let i=0;i<lastDate;i++){
        days.push(i+1)
    }
    const incMonth = (val) => {
        let newMonth = month+val;
        if(newMonth == -1){
            setYear(year-1)
            newMonth = 11
        }else if(newMonth == 12){
            setYear(year+1)
            newMonth = 0
        }

        setMonth(newMonth)
    }
    let isInPast = d =>{
        if(!futureOnly){return false}

        const diff= ((year*365+month*30+d)-(cyear*365+cmonth*30+todate))
        return (diff - (notToday?1:0)) < 0
    }
    const dateString = (date) => date.map((d,i) => `0${i==1?d+1:d}`.slice(i?-2:-4)).join('-')
    function pickDate(d){
        if(!isInPast(d)){
            setDate([year,month,d])
            console.log([year,month,d])
            setHide(true)
        }
    }
    const firstTime = useRef(true);
    useEffect(()=>{
    
        if(!firstTime.current)
        {
            console.log(dateString(date))
            if(onChange){
                onChange(dateString(date))
            }
        }
        firstTime.current = false

    },[date])
    
    useEffect(()=>{
        if(value){

            const[y,m,d] = (value||'').split('-').map(d => Number(d))
            
            const tyear =  y 
            const tmonth = (m&&m-1) 

            setMonth(tmonth)
            setYear(tyear)

            setDate([tyear,tmonth,d])

        }
    },[value])

    const month_class = 'bg-white'
    const picker =  <div className='d-flex flex-column p-2'
        style={{minHeight:'250px',userSelect:'none'}}
    >
        <div className='d-flex justify-content-between align-items-center pb-2'>
            <button onClick={()=>incMonth(-1)}
                className={month_class}
            >
                <LeftIcon/>
            </button>
            <p className='m-0 font-weight-500'>
                {months[month]}
                <span className='mx-1'>
                {year}
                </span>
            </p>
            <button onClick={()=>incMonth(1)} className={month_class}>
                <RightIcon/>
            </button>
        </div>
        <div className=' ' 
        style={{display:'grid',gridTemplateColumns:'repeat(7,1fr)'}}
        >
            {weekdays.map(w=>
            <p key={w} 
                className='font-weight-500 text-secondary
                text-capitalize text-small py-1 px-2'
            >
                {w.slice(0,3)}
            </p>
            )}
            {days.map((d,i)=>
                <div key={d+i}
                className={` p-2 px-2 cursor-pointer hover-scale text-small
                    d-flex align-items-center justify-content-center
                    ${isInPast(d)?'bg-secondary2 opacity-20':''}
                    ${(date[0]==year&&date[1]==month&&date[2]==d)?'bg-primary text-white rounded':''}
                `}
                onClick={()=>pickDate(d)}
                >
                    {d||''}
                </div>
             )}
     
        </div>

    </div>

    return <div className={`rounded-8 overflow-hidden border-thick ${props.className} `}>
        <PopupMenu label={
            <p className='p-1 px-sm-2 py-0 m-0 rounded '>
                {!value ?
                <span>
                    <span className='d-sm-inline d-none'>Select a date</span>
                    <span className='d-inline d-sm-none'>Date</span>
                </span>
                :usaDate(dateString(date))
                }
            </p>
        }
        className='bg-white px-sm-3 px-2 p-1 w-100 d-block'
        hide={hide}
        unHide={()=>setHide(false)}
        align_x='center'
        >
            {picker}
        </PopupMenu>
    </div>
}
