import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFeatureSchema, getHouse, selectFeatures, selectFeatureSchema, selectServices } from '../../app/user/userSlice'
import PopupMenu from '../../components/common/PopupMenu'
import DownIcon from '@material-ui/icons/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, CircularProgress,
     ClickAwayListener, Divider, Input, MenuItem, Select, Switch, TextField, Tooltip, useMediaQuery } from '@material-ui/core'
import MDialog from '../../components/common/MDialog'
import AddIcon from '@material-ui/icons/Add'
import { $alert, $baseUrl, $confirm, $error, $success } from '../../utils/http'
import MoreIcon from '@material-ui/icons/MoreHoriz'
import { $prettify, padZero } from '../../utils'
import SubmitButton from '../../components/common/SubmitButton'
import Axios from 'axios'
import SimpleMenu from '../../components/common/SimpleMenu'
import { useTheme, withStyles } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack'
import DownladIcon from '@material-ui/icons/GetAppRounded'
import CloseIcon from '@material-ui/icons/Close'
import UploadIcon from  '@material-ui/icons/CloudUpload'
import {allowed_icons} from '../../utils/constants'
import { Add, ArrowDropDown, Check, Close, Delete, ExpandMore, Info, OpenInNew } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import  StatusText  from '../../components/special/StatusText'
import { usaDate } from '../../components/input/DatePicker'
import { DocumentCount, DocumentViewer, Renamer } from '../../components/common/DocumentViewer'
import { DocumentUploader } from '../../components/common/DocumentUploader'
import { getDeepValue, getFeatureIcon, sortAscending, sortDescending } from '../../utils/helpers'
import {DateTimePicker} from '../../components/input/TimePicker'
import { prettyTime, safe_parse } from '../../components/special/NotificationCard'
import MExpand from '../../components/common/MExpand'
import { getUser } from '../../app/auth/authSlice'
import AutoComplete from '../../components/common/AutoCompleteField'
import AddressField from '../../components/input/AddressField'
import { Form, Formik } from 'formik'
import LinkIcon from '@material-ui/icons/Link'
import {Link} from 'react-router-dom'

export default function FeatureEditor({edited,onEditChange,onClose,...props}){
    const [LOADING,SAVING] = '123456789'.split('')

    const house = useSelector(getHouse)
    const features = useSelector(selectFeatures)
    const featureSchema = useSelector(selectFeatureSchema)
    const services = useSelector(selectServices)

    const category_list = (featureSchema?.category_list) || []
    const categories = (featureSchema?.categories) || []
    const icons = (featureSchema?.icons) || []
    
    const templateList =  [...category_list,...categories]
    const babyTemplateList = categories || []
    const [errors,setErrors] = useState({})

    const isEditing = edited && edited !=true
    const isAdding = edited ==true

    const history = useHistory()

    const default_icon = icons.find(i => i.name == 'feature')?.id 
    const defaultFeatureInfo = {name:'',icon:default_icon,has_subfeatures:false,baby_name:''}
    const [template,setTemplate] = useState('')
    const [featureInfo,setFeatureInfo] = useState(defaultFeatureInfo) 
    const [childInfo,setChildInfo] = useState({child:null,id:'',name:''}) 
    
    const [babyFeature,setBabyFeature] = useState()
    const [showDocuments,setShowDocuments] = useState(false)

    const [loading,setLoading] = useState(false)
    const [popup,setPopup] = useState(false)

    const [serviceHistory,setServiceHistory] = useState([])
    const [showingHistory,setShowingHistory] = useState(false)

    const dispatch = useDispatch()
    const feature = (isAdding? babyFeature:edited)

    const addingSimpleFeature = edited == true
    const addingMultiFeature = edited == true && babyFeature && (babyFeature.has_subfeatures)

    let multiTemplate = template?.sub_features?.length

    const editingBabyFeature = isEditing && babyFeature && !babyFeature?.children?.length
    const editingMultiFeature = isEditing && edited.children?.length 

    const addingFeature = edited == true 
    const editingFeature = edited && edited != true && babyFeature
    
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

    function notYet() {
        $alert('This feature has not been implemented yet!')
    }
    function getOriginal(){
        const allFeatures = [...features]
        features.forEach(f=>{allFeatures.push(...f.children)})
        return allFeatures.find(f=> f.id == babyFeature.id && f.category == babyFeature.category)
    }
    async function fetchSchema(feature){
        try {
            setLoading(LOADING)
            await dispatch(fetchFeatureSchema(feature))
        } catch (err) {
            $error(err)            
        }finally{
            setLoading(0)
        }
    }

    useEffect(()=>{
        if(edited && edited != true && !babyFeature){
            setBabyFeature(edited.children[0] || edited)
        }
        setFeatureInfo(defaultFeatureInfo)

    },[edited])

    useEffect(()=>{
        if(edited){
            console.log('feature-list updated!')
            let updatedEdited = features.find(f => f.category == edited.category && f.id == edited.id)
            const multiEdited = edited.has_subfeatures || (edited.children&& edited.children.length)
            let newBaby = null
            if(multiEdited){
                if(babyFeature){
                    const babies = []
                    features.forEach(f=>{babies.push(...f.children)})
                    newBaby = babies.find(f=> f.id == babyFeature.id && f.category == babyFeature.category)
                    newBaby = {...babyFeature,documents:newBaby?.documents}
                }
                setBabyFeature(newBaby)
            }else{
                newBaby = updatedEdited
                if(newBaby){
                    newBaby = {...babyFeature,documents:newBaby?.documents}
                }
                setBabyFeature(newBaby)
            }
            onEditChange(updatedEdited)
        }
    },[features])


    function handleBack(){
        setTemplate('')
        if(isAdding && babyFeature){
            setBabyFeature(null)
        }else if(isEditing && babyFeature){
            setBabyFeature(getOriginal())
        }
        else{
            closeDialog()
        }
    }
    async function updateFeature(feature,create){
        try {
            const category = featureSchema.categories.find(c => c.id == feature.category)
            setLoading(SAVING)
            const fdata = {...feature}

            if(category){
                // const extra_fields = category.extra_fields
                const extra_keys = Object.keys(category.extra_fields)
                let extra_data = {}
    
                for(let key of extra_keys){
                    extra_data[key] = feature[key]
                }
                const extra_data_string = JSON.stringify(extra_data)
                fdata.extra_details = extra_data_string
            }

        
            const method = create?'post':'patch'
            const id = create?'':`/${feature.id}`
            const type = feature.hasOwnProperty('category_group')?'compound_feature':'simple_feature'
            fdata.house = house.id
            const res = await Axios[method]($baseUrl+`/houses/${house.id}/features/${type}${id}`,fdata)
            $success(res)
            return res.data.data
        } catch (err) {
            $error(err)
        } finally{
            setLoading(0)
        }
    }
    const showThisForNow = ({obj,category,parent}) => {
        obj.parent = parent.id
        obj.category = obj.category || category || parent.category
        
        onEditChange(parent)
        setBabyFeature(obj)
    }

    async function handleButtonClick(button){
        const feature = isAdding ? babyFeature : edited
        if(button=='edit_name_/_icon'){
            const name = feature.name 
            setFeatureInfo({...featureInfo,...(feature||featureInfo),name})
            setPopup({type:'name'})
        }else if(button =='create_sub_feature'){
            const name = feature.name //+' subfeature'
            const pname = feature.name

            const icon = feature.icon || default_icon
            let parent = {name:pname,icon,has_subfeatures:true}

            const featureData = {
                ...babyFeature,parent,
                name:(name)
            }
            const obj = await updateFeature(featureData)
            showThisForNow({
                obj,category:feature.category,
                parent:{...obj.parent,children:[obj],category: null}
            })
            
        }else if(button == 'delete'){
            const deleted = await deleteFeature(feature)
            if(deleted && feature.children && feature.children.length){
                closeDialog()
            }
        }
    }
    
    async function handleNext(){
        let newBorn = null
        try{
        if(!isEditing){
            // create Feature
            if(babyFeature){
                if(addingMultiFeature){
                    // addding a simple_feature multi-feature
                    // const is_multifeature_template = !!babyFeature.sub_features 
                    
                    const name = featureInfo.baby_name //+' subfeature'
                    const icon = babyFeature.icon || default_icon
                    const pname = babyFeature.name
                    const category = template.id

                    let parent = {name:pname,icon} 
                    const featureData = {parent,category,name:name}

                    // if(is_multifeature_template){
                    //     parent.children = babyFeature.sub_features
                    // }

                    const obj = await updateFeature(featureData,true)
                    showThisForNow({obj,parent:obj.parent})
                    console.log('multi-feature created  !')
                }else{
                    //add simple feature
                    const featureData = {...babyFeature,category:template.id}
                    await updateFeature(featureData,true)
                    closeDialog()
                }
            }else{
                if(!template && !featureInfo.name.trim()){
                    return setErrors({name:'Please enter the feature name'})
                }
                // Show feature creation screen
                const multiFeature = multiTemplate
                const babies = template && template.sub_features
                if(babies){
                    // Creating a compound feature
                    const {icon,name} = multiFeature
                    let data = {parent:{category_group:template.id},children:babies.map(b => b.id)} 
                    const obj = await updateFeature(data,true)
                    console.log('multi-feature template created  !')

                }
                else if(template){
                    // Showing simple feature creation form

                    const f_type = 'simple_feature'
                    const templateDefaults = featureSchema[f_type].defaults
                    setBabyFeature({
                        ...featureInfo,
                        ...templateDefaults,
                        category: template?.id,
                        name: template.name,
                        icon: template.icon,
                    })
                }else{
                    setBabyFeature({...featureInfo})
                }
            }
        }else{
            if(babyFeature){
                // patch babyFeature
                const featureData = {...babyFeature}
                
                let schema = featureSchema['simple_feature'||'feature']
                const fields = Object.keys(schema.fields)

                // Nullify values in the form data if it is out of range.
                for(let f of fields){
                    const field = schema.fields[f]
                    if(fields && field.choices){
                        const active_choice =  field.choices.find(c=>c.value == featureData[f])
                        if(!active_choice){
                            console.log('not a valid choice ==')
                            console.log(f)
                            console.log(featureData[f])
                            featureData[f] = undefined
                        }
                    }
                }

                const obj = await updateFeature(featureData)
                // closeDialog()
            }else{
                // convert new baby feature to existing feature
                console.log('creating new baby feat')
                if(!template && !featureInfo.baby_name.trim()){
                    return setErrors({name:'Please enter the name of the feature'})
                }
                let featureData = {parent:edited.id,has_subfeatures:false}
                if(template){
                    featureData.name = $prettify(template?.name)//+' subfeature'
                    featureData.category= template?.id
                }
                else{
                    featureData= {...featureData,...featureInfo,name:featureInfo.baby_name}
                }
                const obj = await updateFeature(featureData,true)
                showThisForNow({obj,parent:edited})
            }
        }
        }catch(err){
            $error(err)
        }
    }
    function closeDialog(){
        setBabyFeature(null)
        setTemplate(null)
        setShowDocuments(false)
        setErrors({})
        setShowingHistory(false)
        if(onClose){onClose()}
    }
    async function updateNameIcon(){
        const feature = isAdding ? babyFeature : edited
        const updateData = {id:feature.id,...featureInfo}
   
        await updateFeature(updateData)

        if(feature == babyFeature){
            setBabyFeature({...babyFeature,...updateData})
        }else if(isEditing){
            onEditChange({...feature,...updateData})
        }

        setPopup(false)
    }
    
    const iconEditor = <div className='mr-2 d-flex flex-column'>
        <p className='text-muted text-small m-0 mb-1'>Icon</p>
        <SimpleMenu
            align_x='left'
            label={
                <div className='p-1' onClick={()=>setTemplate('')}>
                    <img src={getFeatureIcon(featureInfo,featureSchema)} height='32px'></img>
                    <DownIcon color='primary'/>
                </div>
            }
            className='flex-grow-1 bg-white px-2'
            style={{borderRadius:'8px',border:"2px solid var(--light-gray)"}}
            options={icons}
            isIconGrid
            onChange={i=>setFeatureInfo({...featureInfo,icon:i.id})}
        >
            <div className='p-1' onClick={()=>setTemplate('')}>
                <img src={getFeatureIcon(featureInfo,featureSchema)} height='32px'></img>
                <DownIcon color='primary'/>
            </div>
   

        </SimpleMenu>
    
    </div>
    

    const nameEditor = (baby,noFlex) => <div className={` ${noFlex?'':'flex-grow-1'}  `}>
        <p className='text-muted text-small m-0 mb-1'>Name</p>
        <TextField
            variant='outlined'
            placeholder='Name your feature'
            className='w-100 '
            focused={!template}
            onFocus={()=>{setTemplate('');setErrors({...errors,name:null})}}
            value={featureInfo[baby?'baby_name':'name']}
            onChange={e=>{
                const featInfo = {...featureInfo}
                featInfo[baby?'baby_name':'name'] = e.target.value
                setFeatureInfo(featInfo)
            }}
            inputProps={{maxLength:(64),style:{fontWeight:'500'},}}
        >

        </TextField>
        {errors.name && !template?
            <p className=' text-danger text-small ml-1 my-1'>
                {errors.name}
            </p>
        :null}
    </div>

    const addNewFeature = <div className='p-3 mt-1 mb-4 overflow-hidden'>
        <p className='lead font-weight-bold m-0 mb-1'>
            New feature
        </p>
        <p className='text-muted text-medium m-0
        font-weight-500 mb-2'>
            Choose a feature
        </p>

        <div className='container-fluid overflow-auto rounded-8 ' 
            style={{height:'240px',backgroundColor:'#f0f0f0'}}
        >
            <div className='row row-cols-2 row-cols-sm-3 '>
                {templateList.
                sort(sortAscending('name')).
                map(t =>
                    <div className='col p-1' key={t.id+String(t.parent)}>
                        <TemplateTile 
                        key={t.id}
                        feature={t} 
                        active={template==t}
                        onClick={()=>setTemplate(t)}
                        />
                    </div>
                )}
            </div>
        </div>
   
        <div className='d-flex align-items-center mt-2'>
            <Divider className='flex-grow-1' />
            <p className=' font-weight-500 text-medium
            text-muted m-0 mx-2'>
                or create your own
            </p>
            <Divider className='flex-grow-1' />
        </div>

        <div  className={`d-flex align-items-center 
            ${template?'filter-grayscale':''} `}
        >
            {iconEditor}
            {nameEditor()}
        </div>

        <div className='d-flex align-items-center'>
            <Checkbox
            color='primary'
             checked={!!featureInfo.has_subfeatures}
             onClick={()=>setFeatureInfo({...featureInfo,has_subfeatures:!featureInfo.has_subfeatures})}
            ></Checkbox>
            <p className='font-weight-500 text-small m-0 mr-auto'>
                Has sub features
            </p>
            
            <ActionButtons 
                onNext={handleNext} 
                onBack={handleBack}
                loading={loading==SAVING}
                nextText={multiTemplate?'Create':'Next'}
            />
        </div>

    </div>

    const editSingleFeature = <div>
        <h1>Editing single feature !!!</h1>
    </div>

    let currentView = addNewFeature

    let babyEditor = ''

    const addingBabyFeature = isAdding && babyFeature && !babyFeature.has_subfeatures 
    
    function requestService(){
        history.push(`/services?add_new=${babyFeature.category}|${babyFeature.id}&step=1`)
    }
    async function showFeatureHistory(){
        try {
            if(!babyFeature){return}
            setLoading(LOADING)
            setShowingHistory(true)
            const res = await Axios.get(`${$baseUrl}/features/simple_feature/${babyFeature.id}/services`)

            const pastServices = res.data
            setServiceHistory(pastServices)

        } catch (err) {
            setShowingHistory(false)
        }
        finally{
            setLoading(0)
        }

    }

    function handleEvent(event,value) {
        if(event=='view_documents'){
            setShowDocuments(true)
        }
        else if(event=='upload_document'){
            setPopup({type:'upload'})
        }
    }
    async function handleChildRename() {
        const child = childInfo.child
        const name = childInfo.name || child.name
        // setLoading(RENAMING)
        await updateFeature({id:child.id,name,type:child.category})
        if(babyFeature && babyFeature.id == child.id)
        {
            setBabyFeature({...babyFeature,name:name})
        }
        setChildInfo({name:'',child:''})
    }
    
    if(showDocuments){
 
        babyEditor = <DocumentViewer 
            source={babyFeature} 
            onClose={()=>setShowDocuments(false)} 
            house={house}
            openUpload={()=>setPopup({type:'upload'})}
            className='px-2 px-sm-3'
        />
       
    }
    else if( babyFeature ){
        babyEditor = <div className='flex-grow-1 d-flex flex-column
        align-items-center justify-content-center'>
            <CircularProgress color="primary" />
            {/* <p>Loading...</p> */}
        </div>
        const ogBaby = getOriginal()

        let schema = featureSchema['simple_feature'||'feature']
        schema = (schema && schema.fields)
        const editingAndInfoChanged = editingBabyFeature && (ogBaby!=babyFeature)
        if (showingHistory){
            babyEditor = <div className='d-flex flex-column flex-grow flex-grow-1
                overflow-hidden px-2 px-sm-4'
             >
                <div className='d-flex py-2 align-items-center'>
                    <button
                        onClick={()=>setShowingHistory(false)}
                        className='bg-transparent pl-0 '
                    >
                        <ArrowBack />
                    </button>
                    <p className='ml-0 font-weight-500 '>
                        Service history
                        {!editingMultiFeature && 
                        <span className='ml-1'>
                             of {edited.name}
                        </span>
                        }
                    </p>
                </div>
                <div className='d-flex flex-column flex-grow-1 overflow-auto
                    
                '>
                    {(loading == LOADING) ? babyEditor :
                        <React.Fragment>
                            {
                                serviceHistory.map(s =>     
                                    <div key={s.id} className='d-flex p-1 align-items-center
                                    cursor-pointer  overflow-hidden hover-light'
                                        onClick={()=>history.push(`services?id=${s.id}`)}
                                    >
                                        <div className='mr-auto '>
                                            {/* <img src={getConditionIcon(s.feature)} height='24px' /> */}
                                            <p className=' mb-0 font-weight-500'>
                                                {s.symptom ? s.details.symptom : s.description}
                                            </p>
                                            <p className='text-small text-muted mt-0'>
                                                Created on {usaDate((s.created_at.split('T')[0]))}

                                                <span className='mx-2 font-weight-500 text-secondary text-small'>
                                                    #{padZero(s.id,2)}
                                                </span>
                                            </p>
                                        </div>


                                        <StatusText service={s} concise={isMobile} style={{textAlign:'right'}}/>                  
                                    </div>
                                )
                            }
                            {!serviceHistory.length &&
                                <p className='m-auto text-medium'>
                                    No service requests yet!
                                </p>
                            }
                        </React.Fragment>
                    }
                </div>
            </div>
        }
        else if (!!schema){
            const excludedFields = ['id','house','parent','has_subfeatures','icon','name','notes',
            'category_string','type',]
            if(isAdding){
                excludedFields.push('documents')
            }
            // if(!schema.category || schema.category.read_only)
            {
                excludedFields.push('category')
            }
            const fields = Object.keys(schema).filter(f => !excludedFields.includes(f))
            const is_in_service = getConditionIcon(edited).includes('in_service')
            
            
            const services_of_this_feature = services?.filter(s => s.feature_id == feature.id) || []
            const has_history = services_of_this_feature.length

            if(schema.notes){fields.push('notes')}
            babyEditor = <div className='d-flex flex-column flex-grow-1 '>
                <div className='flex-grow-1 px-3 pt-3 overflow-auto'>
                <AutoForm 
                    schema={schema} 
                    source={babyFeature}
                    setter={setBabyFeature}
                    onEvent={handleEvent}

                    fields={fields}
                />
                </div>

                {isAdding||editingAndInfoChanged?
                <div className='px-3 pb-3 mt-2 d-flex justify-content-end'>

                    <ActionButtons 
                        onNext={handleNext} 
                        onBack={handleBack} 
                        nextText={isAdding?'Create':'Save'}
                        backText='Discard'
                        loading={loading==SAVING}
                    />
                </div>
                :
                <div className=' d-flex justify-content-end 
                border-top mt-3 p-1'
                >
                    <button className={`flex-grow-1 bg-transparent p-2  ${is_in_service?'opacity-50':''} 
                    `}
                        onClick={()=>{if(!is_in_service){requestService()}}}
                    >
                        <img src={`/images/dashboard/service-request.svg`} width='20px'/>
                        <span className={`ml-2 text-medium text-primary `}
                        
                        >
                            Request service 
                        </span>
                    </button>
                    <button className={`flex-grow-1 bg-transparent p-2 ${has_history?'':'filter-grayscale'} `}
                        onClick={showFeatureHistory}
                    >
                        <img src={`/images/dashboard/service-history.svg`} width='28px'/>
                        <span className={`ml-2 text-medium text-primary`}>
                            Service history
                        </span>
                    </button>
                </div>
                }
            </div>
        }
        currentView = babyEditor
    }

    if(edited && isEditing && babyEditor){
        currentView = babyEditor
    }

    if(addingMultiFeature || editingMultiFeature){
        const babyFeatures = (edited && edited.children) || []
        let editArea = <div className='p-2 overflow-hidden d-flex flex-column flex-grow-1 overflow-auto'>
            <p className='font-weight-bold my-1'>New subfeature</p>
            {nameEditor(true,true)}
            <p className='my-1 text-medium font-weight-500 text-secondary'>
                Or use a Template
            </p>
            <div className='container-fluid overflow-auto bg-warning2  rounded-8 flex-grow-1'
                style={{maxHeight:'280px',backgroundColor:'#f0f0f0'}}
            >
                <div className='row row-cols-2'>
                    {babyTemplateList.map(bt =>
                        <div className='col p-1' key={bt.id}>
                            <TemplateTile 
                                key={bt.id} 
                                feature={bt} 
                                active={template==bt}
                                onClick={()=>setTemplate(bt)}
                            />
                        </div>
                    )}
                </div>
            </div>
            <ActionButtons onBack={handleBack} onNext={handleNext} 
                nextText='Create' backText='Discard' 
                loading={loading==SAVING}
            />
        </div> 

        if(babyEditor && !addingMultiFeature){editArea=babyEditor}
        function handleChildAction(child,action){
            if(action == 'delete'){
                deleteFeature(child)
            }else if(action == 'rename'){
                setChildInfo({
                    id:(child.category+''+child.id),
                    child,
                    name:(child.name)
                })
                setBabyFeature(child)
            }
        }
        const handleSubfeatureClick = () => {
            if(isEditing){
                setBabyFeature(null)
            }
            setErrors({})
        }

        const isBaby = (f) => babyFeature && ((babyFeature.category+''+babyFeature.id)==(f.category+''+f.id))
        const activeColor = '#5599ff50'

        const sidePanel = <div className=''>
           
            {babyFeatures.map(cf=>
                {
                const isThisEdited = babyFeature && babyFeature.id==cf.id 
                const isThisRenamed = isThisEdited && childInfo.id==(cf.category+''+cf.id)

                let name = cf.name 
                if(babyFeature && cf.id == babyFeature.id && cf.category == babyFeature.category){
                    name = babyFeature.name 
                }
                return <div
                    className={`d-flex cursor-pointer font-weight-500 pl-3 py-1  align-items-center text-medium
                        ${isBaby(cf)?' bg-primary text-white ':''}
                    `}
                    onClick={()=>{if(isEditing){setBabyFeature(cf);}setShowingHistory(false)}}
                    key={cf.category+''+cf.id}
                >
                    <img src={getConditionIcon(cf)} height='24px'/>


                    <div className={`d-flex overflow-hidden align-items-center flex-grow-1
                    ${isThisRenamed?' border bg-white  border-primary':''} `}
                    style={{borderRadius:'6px'}}
                    >
                        <Renamer 
                        name={name}
                        value={childInfo.name}
                        onChange={e=>setChildInfo({...childInfo,name:e.target.value})}
                        onRename={handleChildRename}
                        renaming={isThisRenamed}
                        onClickAway={()=> setChildInfo({name:'',child:''})}
                        />
                        {isThisRenamed?null:
                            <SimpleMenu 
                            options={['rename','delete']} 
                            onChange={a=>handleChildAction(cf,a)}
                            className='p-1 bg-transparent'
                            >
                            <MoreIcon color={isBaby(cf)?'secondary':'primary'}/>
                            </SimpleMenu>
                        }
                    
                    </div>

                    {/* </ClickAwayListener> */}

                </div> 
                }   
            )}
            <div className={`py-2 px-3 font-weight-500 cursor-pointer text-medium
            d-flex align-items-center ${(!babyFeature || edited==true)?'bg-primary text-white ':' text-primary'}`}
                onClick={handleSubfeatureClick}
            >
                <AddIcon className='mr-2 ' />
                    Add subfeature
            </div>
        </div>

        currentView = <div className='d-flex overflow-hidden flex-column flex-sm-row  flex-grow-1 '>
            
            <div className='  d-none d-sm-flex  flex-column
            flex-grow-1 border-right border-bottom
            '
            style={{maxWidth:'14em'}}
            >
                {
                    sidePanel
                }
            </div>
            <Accordion
                className='my-2 shadow-none border-bottom border-top-0 bg-light  pl-0 py-0 
                d-sm-none d-flex flex-column border-primary! '
                itemProp={{className:'py-0 p-0'}}
            >
                <AccordionSummary
                expandIcon={<ExpandMore color='primary' />}
                className=' text-muted font-weight-500 text-medium bg-light'
                >
                    {babyFeature?
                        <div>
                            <img src={getConditionIcon(babyFeature)}
                             height='24px' className='mr-2' />

                            {(babyFeature.name)}
                        </div>:
                        <div>
                              <AddIcon className='mr-2 ' />
                              Add subfeature
                        </div>
                    }
                </AccordionSummary>
                <AccordionDetails className='d-flex flex-column '>
                    {sidePanel}
                </AccordionDetails>
            </Accordion>

            <div className='flex-grow-1 d-flex flex-column 
                my-dialog feature-edit-area overflow-hidden2'
            >
                {editArea}
            </div>
        </div>

    }
    let titleBar = null
    const buttons = [
        'edit_name_/_icon','delete'
    ]
    if(isEditing && edited == babyFeature){
        buttons.unshift('create_sub_feature')
    }
    if(feature){
        titleBar = <div className='d-flex align-items-center p-2 pl-3 pt-3'>
            <img src={getFeatureIcon(feature,featureSchema)} height='28px' />
            <p className='m-0 font-weight-bold mx-2'>{feature.name}</p>
            
            {isEditing?
                <SimpleMenu
                    onChange={handleButtonClick}
                    options={buttons}
                    style={{backgroundColor:'#ffffff16'}}
                    align_x='left'
                >
                    <MoreIcon color='primary' />
                </SimpleMenu>
            :null}
        </div>
    }

    const NameIconEditor = <div className='d-flex flex-column p-3 my-dialog'>
        <div className='text-center'>
            <p className='font-weight-bold m-0 lead'>
                Edit name/icon
            </p>
            <p className='small text-muted mb-2'>Choose an icon, and name your feature </p>
        </div>
        <form className='d-flex mb-2 align-items-center' 
        onSubmit={e=>{e.preventDefault();updateNameIcon()}}
        >
            {iconEditor}
            {nameEditor()}
            <button className='d-none ' type='submit'>
                submit
            </button>
        </form>
        <ActionButtons 
            onNext={updateNameIcon}
            onBack={()=>setPopup(false)}
            nextText='Save'
            loading={loading}
        />
    </div>

    let popUpView = NameIconEditor

    if(popup && popup.category == 'upload'){
        const file = popup.file
        popUpView = <DocumentUploader 
            source={babyFeature} 
            house_id={house.id}
            onClose={()=>setPopup(false)}
        />
    }
    
    return <div>
    
        <MDialog
            open={!!edited}
            onClose={closeDialog}
            titleBar={titleBar}
            paperPadding=' pt-1'
            paperStyle={{maxWidth:'680px'}}
            // className='overflow-hidden'
        >
            <div className='p-0 my-dialog-lg flex-grow-1 d-flex flex-column justify-content-center
                overflow-hidden2222
            '>
                {currentView}
            </div>
        </MDialog>

        <MDialog open={!!popup} onClose={()=>setPopup(false)}>
            {popUpView}
        </MDialog>

    </div>
}


export function getConditionIcon(feature){
    const value = feature.status
    let icon = 'done'
    const services = window.store.getState().user.services
    let in_service = false

    const iconList = [
        'scheduled',
        'questionable',
        'healthy',
        'warning',
        'in_service',
    ]
    icon = iconList[value]

    if(!feature.children || !feature.children.length){
        // if(value<3){
        //     icon='healthy'
        // }
        // if(value==3){icon='scheduled'}
        // if(value==4){icon='warning'}
        // if(value==6){icon='questionable'}
        icon = iconList[value]
    }

    // Handling status that are out of range,including in_service as it is only a computed value
    if(value > 3){
        icon = iconList[1]
    }

    for(let s of services){
        if(!feature.category_group && (s.feature?.id) == feature.id){
            const ac = s.quotes.find(q => q.id == s.accepted_quote)  

            const completed = (s.status == -1) ||  ( ac && ac.status >= 100)
            if(completed){
                continue;
            }
            if(!ac){
                icon = 'in_service_red'
            }else{
                icon = 'in_service'
            }

            break;
        }
    }
  
    return `/images/features/condition/${icon}.svg`
}

function TemplateTile({feature,active,...props}){
    return <div 
     className='d-flex justify-content-start pl-3 hover-light bg-white
            w-100  text-muted cursor-pointer align-items-center ' 
        style={{border:`2px solid ${active?'var(--pri)':'lightgray'}`,borderRadius:'8px',padding:'6px'}}
        {...props}
    >
        <img 
            src={getFeatureIcon({...feature})} 
            height='24px' 
            className={`mr-2 ${active?'':'filter-grayscale'}`}
        >
        </img>
        <p className={`text-small m-0 ${active?'text-primary':''} `}>
        {feature.name}
        </p>
    </div>
}


export async function deleteFeature(feature){
    try {
        // setLoading(SAVING)

        const f_type = feature.hasOwnProperty('category_group') ? 'compound_feature' : 'simple_feature'
        const url = `/houses/${feature.house}/features/${f_type}/${feature.id}`
        
        try{
            await $confirm({question:`Are you sure you want to delete this feature?`, no:'Cancel'})
        }catch{
            throw false;
        }
        const res = await Axios.delete($baseUrl+url)
        $success(res)
        return true
        
    } catch (err) {
        if(err!=false){
            $error(err)
        }
        return false
    }
    // finally{
    //     setLoading(0)
    // }
}

export const selectProps = {
    variant:'outlined',
    size:'small',
    SelectDisplayProps:{
        style:{
            padding:'8px 10px',
            fontWeight:'500',
            fontSize:'0.8em',
            display:'flex',
            alignItems:'center'
        }
    },
    MenuProps:{
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },
        getContentAnchorEl: null
    },
    displayEmpty:true
}

const AntSwitch = withStyles((theme) => ({
    root: {
      width: 52,
      height: 26,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 3,
    //   color: '#4797f7',
      '&$checked': {
        transform: 'translateX(26px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
        //   backgroundColor: '#77adff',//theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 20,
      height: 20,
      boxShadow: 'none',
    },
    track: {
    //   border: `1px solid #9ec9ff`,
      borderRadius: 24 / 2,
      opacity: 1,
      backgroundColor: `#b4b4b4`,
    },
    checked: {},
  }))(Switch);

export function ActionButtons({onNext,onBack,nextText,backText,
    expand,loading,disabled,disable_back,className,noPadding,
    noBack,noNext,flip_x
}){
  
    return <div className={`d-flex justify-content-end ${!!flip_x?'flex-row-reverse':''}
        mt-auto ${className||''}  ${(noBack&&noNext)||noPadding?'':'pt-2'} `}
     >
        {noBack?null:
        <Button
            variant='contained'
            className={` px-4 ${flip_x?'':'mr-2'}  ${(expand?'flex-grow-1':'')} `}
            disableElevation    
            onClick={()=>{if(!disable_back){onBack()}}}
            disabled={disable_back}
        >
            {backText||'Cancel'}
        </Button>
        }
        {noNext?null:
        <SubmitButton
            className={` ${flip_x?'mr-2':''} ${(expand?'flex-grow-1':'')} `}
            onClick={()=>{if(!disabled){onNext()}}}
            loading={loading}
            disabled={disabled}
        >
            {nextText||'Save'}
        </SubmitButton>
        }
        
    </div>
}
export function AutoForm({fields,readOnly,categories,multiColumn,hiddenFields,errors,...props}){
    const featureSchema = useSelector(selectFeatureSchema) 
    const source = props.source
    let schema = {...props.schema}
    categories = (categories || featureSchema?.categories) || []
    const category = categories.find(c => c.id == source.category)
    let branded = category?.branded
    const user = useSelector(getUser)

    let extra_fields = []
    const hidden_fields = ['display_name','extra_details','id','category_string']
    const is_feature = source?.hasOwnProperty('icon')
    if(is_feature){
        hidden_fields.push('address')
    }
    fields = fields.filter(f => !hidden_fields.includes(f))

    if(category && schema.notes){
        extra_fields = category?.extra_fields
        const brand = {...schema.brand}
        // console.log(brand)
        // console.log(schema)

        const relevant_brands = brand.choices?.filter(b => b.categories?.includes(category.id)) || []
        brand.choices = relevant_brands

        schema = {...schema,...extra_fields,brand}
        console.log(category,source)
        extra_fields = Object.keys(extra_fields||{})
        fields=[...fields,...extra_fields]
        
    }
    const excluded_fields = ['brand','id','value','display_name',...(hiddenFields||[])]
    if(!branded){  
        fields = fields.filter(f => !excluded_fields.includes(f))
    }

    let filtered_fields = readOnly ? fields.filter(f => !!props.source[f]) : fields

    if(readOnly){
        const excluded = ['status','_type','type','extra_details','address','display_name']
        filtered_fields = filtered_fields.filter(f => !excluded.includes(f))
        if(filtered_fields.length == 0){
            return <p className='text-muted text-medium mx-auto my-3'>
                There is no additional information provided.
            </p>
        }
    }
    let grid_columns = `2fr 4fr `
    if(multiColumn){
        grid_columns += grid_columns
    }

    const desc_fields = 'description,notes'.split(',')
    filtered_fields.sort((a,b) => desc_fields.indexOf(a)-desc_fields.indexOf(b))

    const isAdding = !source.id 
    const new_filtered_fields = []
    for(let fkey of filtered_fields){
        const field = schema[fkey]
        if(field.choice_source){
            let choice_source = source[field.choice_source]
            // choice_source = Array.isArray(choice_source) ? choice_source : (choice_source?[choice_source]:[])

            schema[fkey] = {
                ...schema[fkey],
                type:'choice',
                choices:choice_source?.map(d => ({...d,
                    display_name:d.display_name||d.name||d.name||d.full_name||d.email||d.filename,
                    value:d.value||d.id})) || []
            }
        }
        if(!((isAdding && field?.update_only) || (!isAdding && field?.create_only))){
            new_filtered_fields.push(fkey)
        }
    }
    filtered_fields = new_filtered_fields
    const errorList = []
    for(let k in errors){
        errorList.push({
            key:k,
            value:errors[k]
        })
    }

    return <div className='' 
        style={!readOnly?{display:'grid',gridTemplateColumns:grid_columns,gap:'8px 0px'}:
        {display:'flex',flexDirection:'column'}}
    >
        {   
        filtered_fields.map(fkey => {
            // const [label,babyField] = AutoFieldPair({fkey,...props})
            const field = schema[fkey]
            const error = (errors || {})[fkey]
            if(desc_fields.includes(fkey)){
                schema[fkey] = {...field,type:'text'}
            }

            let babyLabel = <div>
            <p className={`text-secondary text-small m-0 mt-1 mr-3 mb-1
            font-weight-500 ${multiColumn?'ml-4':''} `}

            >
                {$prettify(field?.label)}
                {field?.required && 
                <span className='text-danger font-weight-bold ml-1'>*</span>
                }

                {field.tooltip && 
                    <Tooltip title={field.tooltip} placement='right-start'>
                        <Info fontSize='small' className='ml-1 mb-1' htmlColor='#a2a2a2' />
                    </Tooltip>
                }
                
                {error && 
                    <span className='ml-2 text-danger'
                        style={{fontSize:'0.9em'}}
                    >
                        <br/>
                        {error}
                    </span>
                }
            </p>

            </div>

            if (!field){
                return
            }

            const autoField = <AutoField fkey={fkey} readOnly={readOnly} {...props} schema={schema} noLabel />

            return <React.Fragment key={fkey}>
                {babyLabel}
                {autoField}
            </React.Fragment>
        })
        }

        {user?.is_staff && !!errorList.length && 
            <MExpand summary={<p className='text-medium font-weight-500 text-danger pl-4'>
                {errorList.length} Error(s)
            </p>}
                style={{gridColumn:`1 / span ${multiColumn?'4':'2'}`}}
                className='  mt-4'
            >
            <div className='d-flex flex-column px-4 p-2 bg-light rounded-8'
               
            >
                {/* <p className='text-medium text-danger mb-2'>
                    Errors:
                </p> */}
                {errorList.map(e => <div className={`d-flex justify-content-between
                    align-items-center text-small`} 
                    key={e.key}
                > 
                    
                    <p className='font-weight-500 '>
                        {e.key}
                    </p>
                    <p className=''>{e.value}</p>
                </div>)

                }

            </div>
            </MExpand>
        }


    </div>
    
   
}

export function AutoField({schema,readOnly,noLabel,fkey,source,setter,onEvent,...props}){

    const key = fkey
    let value = source[key]
    const emitEvent = onEvent || (()=>{alert('please provede event handler!')})

    const field = schema[fkey]
    const label=field.label

    const theme = useTheme();
    const smol = useMediaQuery(theme.breakpoints.down('xs'));
    
    const width = smol?'200px':'280px'
    const minWidth = width
    const read_only_field = field?.read_only && (!['documents'].includes(key))
    const formikRef = useRef()

    if(!schema){
        let thing = 'schema'
        
        return <p>
            Invalid {thing}
        </p>
    }
    
    let type = field.type == 'integer'?'number': field.type

    const setValue = (e,inc=0) => {
        const feat = {...source}
        let value = (e.target.value)
        if(inc && typeof value == 'number'){
            value += inc
        }
        if(type == 'number' && !value ){
            value = 0
        }
        feat[key] = value

        setter(feat)
    }

    let babyLabel = <p className='text-secondary text-medium m-0 mt-1 mr-3
    font-weight-500 '>
        {$prettify(label)}
    </p>
    const rowClass = 'd-flex justify-content-between my-1 mb-2 align-items-center'


    value = value == null ? '' : value
    const multiline_fields = ['notes']
    const is_multiline = multiline_fields.includes(key) || type == 'text'
    if(field.read_only && field.source){
        value = getDeepValue(source,field.source)
    }
    let babyField = <TextField variant='outlined' size='small'
        value={value}
        onChange={setValue}
        type={type}
        rows={4}
        multiline={is_multiline}
        className={`autofield text-small`}
        placeholder={is_multiline?`Add ${$prettify(field.label)}`:''}
        // style={{width}}
        inputProps={{maxLength:(is_multiline?1024:64),
            style:{
                padding: is_multiline?'4px':'10px 14px',
                fontWeight:'500',fontSize:'0.8em'
            }
        }}
        disabled={!!readOnly}
    />

    if(field.reset){
        babyField = <div className='d-flex '>
            {babyField}
            <button className='ml-2 font-weight-500 text-medium'
                onClick={()=>setValue({target:{value:0}})}
            >
                Reset
            </button>
        </div>
    }

    if(key == 'fields'){
        babyField = <FieldEditor 
            value={value}
            onChange={setValue}
        />
    }
    if(key == 'address' || key == 'address_1'){
        let address_key = key == 'address_1' ? key : 'address'
        const initialValues = {address:'',line1:''}
        initialValues['address'] = source[address_key]

        const formikRef = {
            current:{
                setFieldValue : (key,val)=> {
                    setValue({target:{value:val}})
                },
                values: initialValues
            }
        }

        const setGoogleAddress = ({line1,...addrs}) => {
            const new_val = {...source}
            const {city,state,zip_code} = addrs
            if(new_val.city_state_zip){
                new_val.city_state_zip =  [city,state,zip_code].join(',')
            }

            for(let key in addrs){
                const value = addrs[key]
                new_val[key] = value
            }

            new_val[address_key] = line1
            setter(new_val)
        }

        babyField = <Formik initialValues={initialValues}>
                
                <AddressField 
                
                    variant="outlined"
                    size="small"
                    inputProps={{autoFocus:true}}
                    name="address"
                    formikRef={formikRef}
                    placeholder="Address line 1" 
                    disabled={false}  
                    inputProps={{style:{padding:'8px 12px',color:'var(--black3)'}}}
                    setGoogleAddress={setGoogleAddress}
                />

        </Formik>
        
    }
    else if (key == 'file'){
        babyField = <FileField 
            value={value}
            onChange={setValue}
        />
    }
    else if (key == 'image'){
        babyField = <FileField 
            value={value}
            isImage
            onChange={setValue}
        />
    }
    else if(key == 'documents'){
        babyField =  <div className='d-flex flex-column' 
                
            >
                <DocumentUploader
                    isButton
                    source={source} 
                    house_id={source.house}
                    className='flex-grow-1'
                    // style={{minWidth}}
                />
     
                {value && value.length?
                <div className='d-flex flex-column' >
        
                    <button 
                        className=' d-flex align-items-center bg-white
                         border-thick rounded-8 text-small mt-2 '
                        onClick={()=>emitEvent('view_documents',value)}
                        style={{padding:'6px 6px'}}
                    >
            
                        <DocumentCount count={value.length} />
                
                        View all documents
                    </button>
                    
                </div>
                :null}
        </div>
    }
    else if(field.type =='boolean'){
        if(field.read_only){
            const className = 'ml-3 '
            // babyField = value ? <Check className={className} /> : <Close className={className} />
            babyField = <p className='px-3 py-2 '>
                {value ? 'Yes' : 'No'}
            </p>

        }else{
            babyField = <AntSwitch 
                checked={!!value} 
                onChange={()=>setValue({target:{value:!value}})}
                color='primary'
                className=' '
                disabled={!!readOnly}
            />
        }

    }
    else if(field.type == 'datetime'){
        if(field.read_only){
            babyField = <p className='p-2 text-muted font-weight-500 text-medium'>
               {prettyTime(value)} 
            </p>
        }else{
            babyField = <DateTimePicker
                value={value} 
                onChange={setValue}
                // color='primary'
                className=' '
                disabled={!!readOnly}
            />
        }
    }
    else if(field.type == 'choice' && field.autocomplete){
        let choices = field.choices || []
        choices = Array.isArray(choices) ? choices : [choices]
        if(field.name_source){
            const val = getDeepValue(source,field.choice_source||key)
            const name = getDeepValue(source,field.name_source)
            choices = [{id:val,display_name:name}]
        }
        babyField = <AutoComplete 
            value={value}
            onChange={setValue}
            isMultiple={!!field.multiple}
            resource={field.resource}
            itemPool={choices}
            url_filter={field.url_filter}
            filter={field.filter}

        />
    }
    else if(field.type == 'choice'){
        value = value?.value || value?.id || value

        const condition_icon = getConditionIcon({status:value,id:source.id,type:source.category})
        const in_service = condition_icon && condition_icon.includes('in_service')
        const is_status = fkey=='status' && source.hasOwnProperty('icon')

        const showPick = ((!value && value !==0) || value == -1)
        if(showPick){value = -1}
        const hide_select = (is_status&&in_service)||!!readOnly

        if(field.multiple){
            babyField = <MultipleSelect 
                field_key={fkey}
                value={value == -1 ? [] : value}
                onChange={e=>setValue(e)}
                options={field.choices}
            />

        }else{
            let apparent_value = typeof value == 'number' ? value+1 : value
            if(is_status && in_service){
                apparent_value = 4
            }

            babyField =  <Select
                    className='autofield px-1 text-small'
                    value={apparent_value} 
                    onChange={e=>setValue(e,-1)}
                    {...selectProps}
                    disabled={hide_select}
                    IconComponent={ hide_select ? (()=> null) : undefined}
                    
                >
                    {showPick?
                    <MenuItem value={0} className='text-muted'>
                        Select one
                    </MenuItem>
                    :null}
      
                    {field.choices?.map(ch =>{
                            const c_value = ch.value ?? ch.id
                            return <MenuItem key={c_value} value={typeof c_value == 'number' ? (c_value+1) : c_value}
                                className='d-flex align-items-center '
                            >
                                {is_status?
                                    <img src={getConditionIcon({status:c_value,id:source.id,type:source.category})} 
                                    height='20px' 
                                        className=' mr-1' 
                                    />
                                :null}

                                {ch.display_name || ch.display_name || ch.full_name || ch.name || ch.email}
                            </MenuItem>
                    })}

                </Select>
        }

    }
    // else if (field.type == 'field' && (!!value) && (typeof value != 'object')){
    //     babyField = <button className='border p-2 bg-white
    //      text-primary text-left pl-3 text-medium'
    //         // onClick={()=>history.replace()}
    //     >
    //         {$prettify(field.resource|| fkey)} 
    //         {!!value && 
    //             <span className='ml-1 font-weight-bold opacity-70 p-1 px-2 text-small rounded'
    //                 style={{backgroundColor:'#e2e2e2',color:'#424242'}}
    //             >
    //                 {(value)}
    //             </span>
    //         }
                
    //     </button>
    // }
    // return [babyLabel,babyField]
    const link_source = field.link_source
    const id = link_source ? getDeepValue(source,link_source) : (value.id || value)
    const hyper_link = `/admin/${field.link||'houses'}/${id}`
    console.log(hyper_link)
    const link_exists = field.link && id

    babyField = <div className={`d-flex flex-column position-relative
        ${read_only_field?'opacity-70 pointer-events-none bg-light rounded-8':''} 
    `}
        // style={{pointerEvents:"none"}}
        style={{maxWidth:'540px',filter:read_only_field?'':''}}
    >
        {babyField}
    </div>

    if(link_exists){
        babyField = <div className='position-relative'>
            {babyField}
            <button className='position-absolute p-0 text-primary cursor-pointer bg-transparent' 
            style={{top:'4px',right:'6px'}} title='Open in new tab'
            >
                {/* <Link to={new_link}> */}
                    <LinkIcon onClick={()=>window.open(hyper_link)} />
                {/* </Link> */}
            </button>
        </div>
    }

    if(noLabel){
        return babyField
    }else{
        return <div 
            className={rowClass+`  ${read_only_field?'opacity-50':''}`} 
        >        
            {babyLabel}
            {babyField}
        </div>
    }
}



export function MultipleSelect({value,onChange,field_key,options}){

    return <div className='d-flex flex-wrap p-1 border rounded'
        style={{maxHeight:'30vh',overflow:'auto',boxShadow:'inset 0 0 4px lightgray'}}
    >
        {options.slice()
        .map(o => {
            const display_name = getResourceName(o) 
            return {
                ...o,is_picked: value.includes(o.value || o.id),
                key:display_name.toLowerCase(),display_name,
            }
        })
        .sort(sortAscending('key'))
        .sort(sortDescending('is_picked'))
        .map(ro => {
        const icon_url = field_key == 'category' &&  getFeatureIcon(ro)
        const val  = ro.value || ro.id

        const is_picked = ro.is_picked
        return <button key={val} 
                className={`d-flex align-items-center p-1 px-2 border bg-white rounded-8 mr-2 mb-2 
                text-small text-primary border-primary `}

                style={{
                    ...(is_picked?{}:{opacity:'0.6',filter:'grayscale(1)'})
                }}
                onClick={()=>onChange({target:{
                    value: is_picked ? value.filter(v => v != val): [...value,val]
                }})}
            >
                {icon_url && 
                    <img src={icon_url} className='mr-2' height='24px' />
                }
                {ro.display_name}
            </button>
        }
    )}
    </div>


}


export function FieldEditor({value,onChange}){
    const proto = {
        label:"New field",
        type:"choice",
        choices: [{value:1,display_name:'Choice 1'},{value:2,display_name:'Choice 2'}],
    }
    const types = ['string','integer','text','choice','boolean']

    const o_fields = safe_parse(value) || {}
    const [fields,setFields] = useState(o_fields)
    // const [newField,setNewField] = useState({...proto})
    const [newChoice,setNewChoice] = useState('')

    function addField(new_obj){
        const new_key =  new_obj.label.trim().replace(/[ ]/g,'_').toLowerCase()
        // const new_id = Math.floor(Math.random()*32000)
        const new_fields = {...fields}
        new_fields[new_key] = {...new_obj}
        setFields(new_fields)
    }
    function setFieldProp(key,prop,value){
        const new_fields = {...fields}

        const field = {...new_fields[key]}
        field[prop] = value
        new_fields[key] = field
        
        setFields(new_fields)
    }
    const firstTime = useRef()
    useEffect(()=>{
        
        if(!firstTime.current)
        {
            const new_fields = JSON.stringify(fields)
            onChange({target:{value:new_fields}})
        }

        firstTime.current = false

    },[fields])

    const toSlug = (label) => (label||'').trim().replace(/[ ]/g,'_').toLowerCase()

    const field_keys = Object.keys(fields)

    return <div className='d-flex flex-column border-thick p-2 rounded-8'>
    
        {field_keys.map((fk,i) => {
            const fi = fields[fk]

            const type = fi.type
            const choices = fi.choices || []
            const has_choices = type == 'choice'

            const choice_viewer = has_choices && 
            <MExpand
                summary={
                    <p>
                        {choices.length} choices
                    </p>
                }
                className='m-0 ml-2'
            >

                <div className='d-flex flex-column '>
                    <form className='d-flex align-items-center'
                        onSubmit={(e)=>{
                            e.preventDefault()
                            if(!(newChoice || '').trim()){return $error('Please enter a name')}
                            const value = toSlug(newChoice) 

                            if(choices.map(c=>c.value).includes(value)){return $error('That choice already exists! ')}

                            setFieldProp(fk,'choices',[...choices,{display_name:newChoice,value}])
                            setNewChoice('')
                        }}
                    >
                        <TextField placeholder='Choice name'
                        variant='outlined' size='small' 
                            value={newChoice}
                            onChange={e=>setNewChoice(e.target.value)}
                        />
                        <button className='m-0 p-0 bg-transparent'>
                            <Add  className=' cursor-pointer text-primary' 
                            />
                        </button>
                    </form>
                    {choices.map(o => 
                        <p key={o.value} className='py-1 pl-1 d-flex align-items-center'>
                            {o.display_name}

                            <Close className='ml-auto bg-white cursor-pointer'
                                htmlColor='#cacaca'
                                onClick={()=>{
                                    // if(!(newChoice || '').trim()){return $error('Please enter a name')}
                                    setFieldProp(fk,'choices',choices.filter(c => c.value != o.value))
                                
                                }}
                            />
                        </p>
                    )}
                </div>
            </MExpand>

            return <div className={`d-flex flex-column ${i&&'border-top'} border-primary1 1rounded-8 p-2 mb-1 `} key={fk}>
            <div  
                    className='d-flex align-items-start bg-white
                     mr-1 mb-1 text-small text-primary  justify-content-between1'
            >
                    <TextField 
                        placeholder='Name of the field'
                        value={fi.label}
                        variant='outlined'
                        size='small' 
                        inputProps={{style:{padding:'12px'}}}
                        onChange={e=>setFieldProp(fk,'label',e.target.value)}
                        className='mr-auto'
                    />


                    {choice_viewer}
                    {/* <input value={fi.type} /> */}
                    <Select 
                        value={fi.type}
                        className='mx-3'
                        variant='outlined'
                        size='small'
                        SelectDisplayProps={{style:{padding:'11px 28px 11px 16px'}}}
                        onChange={e=>setFieldProp(fk,'type',e.target.value)}

                    >
                        {types.map(ft => 
                            <MenuItem value={ft} key={ft}>
                                {$prettify(ft)}
                            </MenuItem>
                        )}
                    </Select>

                    <Delete className='cursor-pointer mt-2' style={{color:'#a1a1a1'}}
                        onClick={()=>{
                            const new_fields = {...fields}
                            delete new_fields[fk]
                            setFields(new_fields)
                        }}
                    />
            </div>
                    {/* {choice_viewer} */}
            </div>
        }
        
    )}

    <button 
        className=' bg- ' style={{background:'#f1f1f1'}}
        onClick={()=>{
            const new_fields = {...fields}
            let new_key = 'new_field'+Math.round(Math.random()*999999)
            new_fields[new_key] = {type:'string',label:'New field'}
            setFields({...new_fields})}
        }
    >
           <Add htmlColor='#595959' />
        </button>
    </div>


}


function FileField({value,isImage,onChange}){
    
    const inputRef = useRef()
    const [imgUrl,setImageUrl] = useState('')

    const img_url = imgUrl || value

    const onImagePicked = (evt) => {
        const file = inputRef.current?.files[0]
        if(file){
            setImageUrl(URL.createObjectURL(file))
            onChange({target:{value:file}})
            // onChange(evt)

        }
    }  
    
    let accept = '.jpg,.png,.jpeg,.svg'
    if(isImage){
        accept = '.jpg,.png,.jpeg,.svg'
    }

    return <div className='d-flex align-items-center'>
        {
            isImage?
            <img  height='48px' src={img_url} className='mr-4' />
            :
            <a href={img_url} target="_blank" 
            className='mr-4 ml-4 text-truncate flex-grow-1'
             >
                 {img_url}
            </a>
        }

        <input type='file' ref={inputRef} onChange={onImagePicked} accept={accept}
            placeholder='Change '
        />
    </div>
}

export const getResourceName = (ro) => (ro.display_name || ro.full_name || ro.name || ro.email)
