const { createSlice } = require("@reduxjs/toolkit");

const uiSlice = createSlice({
    name:'ui',
    initialState:{
        blur:0,
        messages:[],
        dialog:'',
        last_dialog:'',
        confirm: null,
        alert:'',
    },
    reducers:{
        setConfirm: (state,action) => {
            state.confirm = action.payload;
        },
        setAlert: (state,action) => {
            state.alert = action.payload;
        },
        setDialog: (state,action) => {
            
            const new_val = Array.isArray(action.payload) ? action.payload : [action.payload]
            const new_dialog = new_val[0]

            if(!state.last_dialog || state.last_dialog.name !== new_dialog){
                state.last_dialog = state.dialog
            }
       
            state.dialog = {name:new_val[0],...new_val[1]}
        },
        goBack:(state)=>{
            
            console.log(state.dialog,state.last_dialog)
            if((state.dialog && state.last_dialog) && state.dialog.name === state.last_dialog.name){
                state.dialog = ''
            }else{
                state.dialog = state.last_dialog
            }
        },
        blurScreen: state => {
            state.blur += 1
        },
        unBlurScreen: state => {
            state.blur -= 1
        },
        addMessage: (state,action) => {
            const message = {...action.payload}
            const uniques = [
                "User doesn't exist or is deleted",
                "Could not connect to server",
                "Forbidden",
                "Request aborted"
            ]
            const avoids = [
                "Forbidden",
                "User doesn't exist or is deleted",
                "Request aborted"
            ]
            const new_message = message.message
            if(avoids.includes(new_message)){
                return
            }
            if(uniques.includes(new_message)){
                const existing = state.messages.some(m => m.message == new_message)
                if(existing){return}
            }
            message.time = Date.now()
            state.messages = [...state.messages,message]
        },
        popMessage: (state,action) => {
            const message = action.payload
            state.messages.shift();
            console.log('removed')
        }
    },
})

export const {
    blurScreen,unBlurScreen,
    addMessage,popMessage,
    setDialog,goBack,setConfirm,setAlert
} = uiSlice.actions;

export const selectBlur = state => state.ui.blur
export const selectUIMessages = state => state.ui.messages
export const selectDialog = state => state.ui.dialog
export const selectConfirm = state => state.ui.confirm
export const selectAlert = state => state.ui.alert



export const addToast = message => dispatch => {
    dispatch(addMessage(message))
    const duration = message.duration || 3000
    setTimeout(()=>{
        dispatch(popMessage(message))
    },duration)
}

export default uiSlice.reducer;