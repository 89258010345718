import React from 'react'
import { Route, useHistory ,Switch} from 'react-router-dom'
import CheckLists from './agent/Checklists'
import Documents from './agent/Documents'

export default function PotentialBuyerDashboard({path='',...props}){
    const items = [
        {name:'Documents',image:'landing/receipts',path:'dashboard/documents',desc:`Manage all your documents in one place`,},
        {name:'Checklists',image:'dashboard/tasks',path:'dashboard/checklists',desc:`Manage the checklists that you are sending out to clients`},
        {name:'Messages',image:'landing/mail',path:'messages',desc:`Send out messages to many clients at a time`},
    ]
    const history = useHistory()

    const potentialDashboard = <div>
    
        <div className=' bg-primary mt-4'>
            <div className='container d-flex justify-content-between 
                 align-items-center
            '>
                <div className=' py-4 px-1 py-sm-4 text-left d-sm-flex align-items-center pr-sm-4'>
                    <h2 className='text-white font-weight-bold '>
                        Let us help you with your <br className='d-sm-none' /> home buying
                    </h2>
                </div>
                <div className='my-4'>
                    <img src='/images/dashboard/home.svg' height="124px" 
                    style={{transform:'translateY(36%)'}} 
                    />
                </div>
            </div>
        </div>

        <div className='container mt-5'>
            <div className='row row-cols-1 row-cols-md-3'>
                
                {items.map(i => 
                    <div className='col  p-3' key={i.name}>
                        
                        <div className='d-flex flex-column h-100 ' 
                            onClick={()=>{if(!i.notYet)history.push(`${path}/${i.path}`)}}

                            style={{borderRadius:'8px',border:'1px solid lightgray',
                            filter:i.notYet?'grayscale(0.99) opacity(0.7)':'',
                            padding:'8px',cursor:i.notYet?"":'pointer'}}
                            >
                            <img src={'/images/'+i.image+'.svg'} height="128px" />
                            <p className='font-weight-bold' >
                                {i.name}
                            </p>
                            <p className='small text-muted'>
                                {i.desc}
                            </p>
                        </div>
                       
                    </div>
                )}


            </div>

        </div>         

    </div>

    return <div className='flex-grow-1 d-flex flex-column'>

        <Switch>

            {/* {subMenus.map((sm,i) =>
                <Route key={i} component={sm.component} path={`${path}/${sm.path}`} />
            )} */}
            <Route component={CheckLists} path='/dashboard/checklists'  />
            <Route component={Documents} path='/dashboard/documents'  />


            <Route render={(props)=>potentialDashboard} path='/'  />

        </Switch>

    </div>


}