import { Button, CircularProgress, Grow } from '@material-ui/core'
import React from 'react'


export default function SubmitButton({inverted,loading,disabled,...props}){

    const visibility = (loading?'visible':'hidden')
    const color = inverted?'':'primary'
    const opacity = (loading||disabled)?'0.6':'1.0'

    const cprops = {
        size:14,
        disableShrink:true,
        style:{color:'white'}
    }
    const isDisabled = disabled||(!!loading)
    return   <Button 
        variant='contained'
        color={color}
        disableElevation
        type='submit'
        disabled={isDisabled}
        className='flex-grow-1 px-1 '
        style={{backgroundColor:'var(--pri)',opacity,color: isDisabled ? '#ffffffaa' : 'white'}}
        {...props}
    >
    
        <CircularProgress
            {...cprops}
            style={{...cprops.style,visibility}}
        />
        
        <span className='mx-2'>
        {props.children}
        </span>

        <CircularProgress
            {...cprops}
            className='invisible'
        />
       
    </Button>
}