import { Button, CircularProgress, Divider,  } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ToolIcon from '@material-ui/icons/BuildOutlined'
import MoreIcon from '@material-ui/icons/MoreHoriz'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getUser } from '../../app/auth/authSlice'
import { setDialog } from '../../app/ui/uiSlice'
import { getHouse, selectFeatureSchema, selectFeatures } from '../../app/user/userSlice'
import MDialog from '../../components/common/MDialog'
import SimpleMenu from '../../components/common/SimpleMenu'
import { $prettify } from '../../utils'
import { getFeatureIcon } from '../../utils/helpers'
import FeatureEditor,{deleteFeature, getConditionIcon} from './FeatureEditor'


export default function HomeOwnerDashboard({status,...props}){

    const user = useSelector(getUser)
    const dispatch = useDispatch()
    const house = useSelector(getHouse)

    const [LOADING,ERROR] = [1,2]
    const featureSchema = useSelector(selectFeatureSchema)
    const features = useSelector(selectFeatures)

    const [edited,setEdited] = useState(true)
    
    const history = useHistory()
    function requestHomeCheckup(){
        history.push(`/services?add_new=bla`)
    }

    // let featureObj = {...features}
    // for(let k in featureObj){
    //     const val = featureObj[k]
    //     if(!(val && Array.isArray(val) && val.length)){
    //         delete featureObj[k]
    //     } 
    // }
    // delete featureObj.feature;

    const buttons = ['view_owners','about_your_home']
    if(house && house.primary_owner == user.id){
        buttons.unshift('add_a_co-owner')
    }
    function handleButtonClick(btn){
        dispatch(setDialog(btn))
    }
    function handleFeatureOptions(event,feature){
        if(event == 'edit'){
            setEdited(feature)
        }
        else if(event == 'request_service'){
            history.push(`/services?add_new=${'simple_feature'}|${feature.id}&step=1`)
        }
        else if(event == 'delete'){
            deleteFeature(feature)
        }
    }
    const featuresList = features; //Object.keys(featureObj)

    const isLoading = status == LOADING
    const houseLoading = !house || !house.address
    return <div className=' px-0 d-flex flex-column '>
         
        <div className=' bg-primary text-white  py-sm-0 '
        // style={{height:'12em'}}
        >
            <div className='container d-flex flex-sm-row  flex-column align-items-center '>

                {
                    // <CircularProgress style={{color:'white'}}  />
                    <div className='d-flex flex-column align-items-start mt-5 mt-sm-0'>
                        <div className='d-flex text-left align-items-center mb-2 mb-md-3'>
                            <h3 className=''>
                                {(house && house.address)||'Loading...'}
                            </h3>
                            <SimpleMenu
                                onChange={handleButtonClick}
                                options={buttons}
                                className=' ml-2 px-3 '
                                align_x='left'
                                style={{backgroundColor:'#ffffff16'}}
                                disabled={houseLoading}
                            >   
                                <MoreIcon htmlColor='#ffffff' size='large'
                                />
                                
                            </SimpleMenu>
                        </div>
                        <Button
                            variant='outlined'
                            className='d-flex align-items-center p-2 px-3
                            text-white border-thick border-white'
                            color='secondary'
                            style={{borderWidth:'2px'}}
                            onClick={requestHomeCheckup}
                            disabled={houseLoading}
                        >
                            <ToolIcon size='small' className='mr-2' style={{transform:'scaleX(-1)'}}/>
                            Request home checkup
                        </Button>
                    </div>
                }



                <div className=' ml-auto '>
                    <img src='/images/dashboard/home.svg' height="164px" style={{transform:'translateY(16%)'}} />
                </div>

            </div>



        </div>

        
        <div className='d-flex flex-column container pl-1 px-0 px-sm-2 px-lg-0 '>
            <h4 className='mt-5 mb-3 text-left ml-2'>
                Features
            </h4>
            <Divider className='mt-2'/>

            {!isLoading &&
            <div className='d-flex justify-content-between'>
                <button className='d-flex cursor-pointer bg-white hover-light pl-0 py-3'
                    onClick={()=>setEdited(true)}
                >
                        <AddIcon color='primary' className='mr-1' />
                        Add a new feature
                </button>

                <button className=' bg-transparent  pl-0 pr-1 pr-sm-2
                text-primary text-medium text-right'
                    onClick={requestHomeCheckup}
                >
                        <img src='/images/dashboard/service-request.svg' 
                            height='24px' className='mr-2 my-auto'
                        />
                        Request service
                </button>
            </div>
            }
            
            {status === 0 ?
            <div className='text-left text-secondary'>
                {
                    featuresList.slice().sort((a,b)=>b.children.length-a.children.length)
                    .map(
                        (f,i)=>(
                            <FeatureTile 
                                key={i} feature={f} 
                                // onClick={()=>setEdited(f)}
                                onEvent={ev=>handleFeatureOptions(ev,f)}
                                icon={getFeatureIcon(f,featureSchema)}
                            />
                        )
                    )
                }
            </div>
            : <div className='p-5 m-3 m-sm-5'> <CircularProgress   /> </div>} 

        </div>

       
        <FeatureEditor 
            edited={edited} 
            onEditChange={e=>setEdited(e)}
            onClose={()=>setEdited(null)} 
        />

    


    </div>

}


function FeatureTile({feature,icon,onEvent,...props}){
    const subFeatures = feature.children.length
    let {name,type} = feature
    const con_icon =  getConditionIcon(feature)
    const in_service = con_icon.includes('in_service')
    const options = ['delete']
    if(!in_service){
        options.unshift('request_service')
    }

    return <div className='d-flex flex-column'>
        <Divider />
        <div className='d-flex my-3 align-items-center cursor-pointer
            hover-light
        '
            {...props}
            onClick={()=>onEvent('edit')}
        >
            <img src={con_icon} height='24px' />

            <img src={getFeatureIcon(feature)} height='32px'
             className='mx-1 ml-sm-2 mr-sm-3' style={{filter:'saturate(0.0) contrast(2)'}}
            />

            <div className='d-sm-flex align-items-center
             flex-grow-1 overflow-hidden ml-1'
            >
                <p className='font-weight-500 mr-auto text-truncate flex-grow-1' >
                    {name||$prettify(type)}
                </p>
                {subFeatures?
                <p className='text-muted  text-medium'>
                    {subFeatures} sub features
                </p>
                :null}
            </div>

            {/* <div className='ml-auto text-primary text-medium'>
                <Button color='primary' onClick={()=>onEvent('edit')}>
                    <AddIcon /> Add info
                </Button>
            </div> */}
            
            <SimpleMenu className='bg-transparent border-0 px-1 '
                options={options}
                onChange={(ev)=>onEvent(ev)}
            >
                <MoreIcon size='small' color='primary' />
            </SimpleMenu>

        </div>

    </div>
}
