import React from 'react'

export default function PropertyManagerDashboard(props){


    return <div>
        <h1>welcome</h1>
        <h3>Property Manager</h3>

    </div>


}