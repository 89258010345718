import { BuildOutlined, Check, LiveTvTwoTone, MailOutline, PersonOutlined } from '@material-ui/icons'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getUser } from '../../app/auth/authSlice'
import { setDialog } from '../../app/ui/uiSlice'
import { selectMessages, userTypes } from '../../app/user/userSlice'
import { $prettify, padZero } from '../../utils'
import { AgentFeatures, AgentLandingPart } from '../../views/AgentLanding'
import { HomeOwnerFeatures, HomeOwnerLandingPart, PricingTable } from '../../views/HomeOwnerLanding'

const normalMessage = (message) => {

    let content = <div className=' text-medium '
        style={{minHeight:'480px',}}
    >
        <p>
            {message.body}
        </p>
    </div>

    return {content,}
} 
const profileUpdated = (message,user) => {

    let type = message.message_type
    let data =   safe_parse(message.data)
    let {co_owner,home_address} = data
    let lines = [
        `Hello ${user.first_name},`,
    ]

    if(type){
        if(type=='profile_updated'){
            lines = [lines,...[ 
            `Your profile information of your micasa account has been successfully updated.`,
            `
            If you didn’t make this change or if you believe an unauthorized 
            person has accessed your account,
            `]]
        }
        else if(type=='added_co_owner'){
            const view_owners = <span 
            className='text-primary hover-link cursor-pointer'
            onClick={()=>window.store.dispatch(setDialog('view_owners'))}
            >
                View Owners
            </span>

            lines = [lines,...[ 
                `
                You've added ${co_owner} as a co-owner to your micasa 
                managed property at ${home_address}
                `,
                <div>
                    You can also now promote this co-owner as the primary owner 
                    by clicking on {view_owners} or by navigating to "View Owner"
                    under the "…" option against your property address.,
                </div>
            ]]
        }else if(type=='added_co_owner_unborn'){
            lines = [lines,...[ 
                `You've added ${co_owner} as a co-owner to your micasa 
                managed property at ${home_address}`,
                <div>
                    We've sent an invitation to your new co-owner to join
                    micasa to help you co-manage the property with you.
                    Until the co-owner accepts the invitation, they 
                    won't be able to manage the property.
                </div>
            ]]
            
        }
    }

    const content =  <div className='pl-2 text-medium'>
        <p>{lines[0]}</p>

        <p>{lines[1]}</p>

        {type == 'profile_updated'?
            <p>
            {lines[2]} click
            <span className='text-primary cursor-pointer mx-1' 
                onClick={()=>window.store.dispatch(setDialog('change_password'))}
            >here
            </span>
            to reset your password immediately.
            </p>
        :lines[2]}
        <br/>
        <p>
        If you need additional help, contact 
        <a href="mailto:support@micasa.ai?subject=Help" className=' mx-1'>micasa support.</a>
        </p>
        <p >
            Sincerely,<br/>
            micasa support team.<br/>
        </p>
    </div>

    return {content,body:lines.join('\n').slice(0,120)+'...'}
}

const welcomeMessage = (user) => {
    user = user || {}
    const userType = user && user.user_type
    const isHowner = userType === userTypes.home_owner
    const isAgent = userType === userTypes.agent
    const isContractor = userType == userTypes.contractor
    const isBuyer = userType == userTypes.potential_buyer

    console.log(userType,user)

    let landingImage =  null
    let thingsToDo = null
    const small = true;
    if(isHowner){
        landingImage = HomeOwnerLandingPart({small})
        thingsToDo = HomeOwnerFeatures({small})
    }else if(isAgent){
        landingImage = AgentLandingPart({small})
        thingsToDo = AgentFeatures({small})
    }else if(isContractor){
        let things = [
            {img:'landing/tool-box',title:'Services',desc:'Keep track of your clients and provide recommendations'},
            {img:'landing/receipts',title:'Invoices',desc:'Send invoices to your clients and manage payments'},
            {img:'landing/mail',title:'Messages',desc:'Access the messages you receive from your clients'},
        ]
        if(isBuyer){
            things = [
                {img:'landing/receipts',title:'Documents',desc:'Manage all your documents in one place'},
                {img:'dashboard/tasks',title:'Checklists',desc:'Manage the checklists that you received from your agent'},
                {img:'landing/mail',title:'Messages',desc:'Access the messages you receive from your agents'},
            ]
        }
        thingsToDo = <div className=' my-2' style={{display:'grid',gridTemplateColumns:'repeat(3,1fr)'}}>
            {things.map(t => 
                
                <div key={t.title}
                    className='d-flex flex-column algn-items-center'
                >
                    <img src={`/images/${t.img}.svg`} height='64px' />
                    <p className='font-weight-500 mt-2'>
                        {t.title}
                    </p>
                    <p className='text-muted text-small '>
                        {t.desc}
                    </p>
                </div>
                
            )}
        </div>
    }

    const lines = [
        `
        Hi ${$prettify(user.first_name)}, 
        `,`
        We're thrilled you're here. Welcome! 
        `,`
        Get to know your ${isHowner?'home':'tools'} with micasa. The more you do with your new micasa account,
         the more you’ll love it.  
        `,`
        To help you get started, here are some quick and easy things you can do today.
        `,`
        Sincerely,
        `,`
        micasa Account Team.
        `

    ]

    const content =  <div className='text-medium '>

        <p className=' '>
            {lines[0]} <br/> <br/> {lines[1]}
        </p>

        {landingImage}
        
        <p className='mb-0'>{lines[2]}</p>
        <p className='mb-0'>{lines[3]}</p>

        <span className='text-center'>

        {thingsToDo}
        </span>

        <p>
            {lines[4]}
        </p>
        <p>
            {lines[5]}
        </p>

    </div>
    let body = lines.join('')

    body = body.slice(0,120)
    body += ' ...'

    return {
        content,
        body,
    } 
    
}
    
export const messageWithPlan = (message,user) => {

    let lines = [
        `
        Hi ${$prettify(user.first_name)}, 
        `
    ]
    const data = safe_parse(message.data)

    const trial_period = data.trial_period || 90
    const days_left = user.days_left || 7

    const lastLines = [
        `
        Let us know if you have any questions about our plans or need 
        any assistance! Email us at support@micasa.ai 
        with your feedback or questions. 
        `,`
        Sincerely,
        `,`
        micasa Account Team.
        `
    ]

    const greetings = [
        `
        We're excited to see you enjoy your ${trial_period} day trial subscription! Let us
         know if you have any questions about our plans or need any assistance! 
        `,`
        If you're ready for signing up for a continued subscription with
         micasa, you can choose from one of our plans: 
        `,
    ]

    const warning = [
        `
        It’s been a fun journey, but unfortunately, we’re coming to the end of your trial
         period of your micasa subscription. We’d love to keep this going! 
        `,`
        In order to continue the simplicity of keeping track of the many facets of
         home ownership all in one place, and to avail services to keep your home
          in pristine condition​, you’ll need to select one of our paid plans here. 
        `,`
        Otherwise, your trial will end in ${days_left} days and 
        you won’t be able to access your account any longer. 
        `,`
        Choose your plan:
        `
    ] 

    const consolation = [
        `
        Your subscription has expired! But don’t worry, you can 
        still keep your micasa account along with all the services 
        you have been enjoying with your subscription. 
        `,`
        All you have to do is choose one of our plans here, and
         you’ll get instant access to your account again. 
        `,`
        If you’re on the fence about whether or not you want to 
        commit, here’s a brief snapshot of what you’ll miss out on: 
        `,
    ] 

    let middleSection = <PricingTable small />
    
    const message_type = message.message_type
    if(message_type.includes('subscription_ends')){
        lines = [lines,...warning]
    }
    else if(message_type === 'subscription_'){
        lines = [lines,...greetings]
    }
    else if(message_type === 'subscription_ended'){
        lines = [lines,...consolation]
        lastLines[0] = `
        If you have any questions about any of these benefits, 
        or what else you can do with your subscription, simply 
        reply back to this email and let me know! 
        `
        middleSection = <ul>
            <li>
                Access to experts who can help you with maintaining
                 your home in pristine condition. 
            </li>
            <li>
                History of your home features, and the relevant
                 documents and/or receipts related to your home. 
            </li>
            <li>
                Notifications of seasonal upkeep of your home,
                 and reminders from your micasa team on any
                  important information related to your home. 
            </li>

        </ul>
    }
    

    const content = <div className=' text-medium  px-2'>

        {lines.map((l,i)=>
            <p key={i}>
                {l}
            </p>    
        )}

        {middleSection}

        {lastLines.map((l,i)=>
            <p key={i}>
                {l}
            </p>    
        )}

    </div>

    lines = [...lines,...lastLines]
    let body = lines.join('')

    body = body.slice(0,120)
    body += ' ...'
    
    return {
        content,
        body
    }
}

function serviceUpdate(message,user,history,onClose){

    let lines = [
        // `
        // Hi ${$prettify(user.first_name)}, 
        
        // `
    ]
    let buttonText = 'Review service request'
    const data =  safe_parse(message.data)
    const isClient = user.user_type != userTypes.contractor 
    let actor = (isClient ? data.provider : data.client) || ''
    const id = data.id
    const feature = data.feature || (data.category)
    const sid = padZero((data.sid||id),3)
    let header = `SR #: ${sid}`
    let action = ''
    const first_name = $prettify(user.first_name)
    const picked_date = data.date || '12/02/2021'
    
    const userStore = window.store.getState().user

    let details = null
    actor = actor.split(' ').map(s => $prettify(s)).join(' ')

    // if(data.event != 'service_rejected'){

    //     const things = [
    //         {key:"Category"   ,value:data.category},
    //         {key:"Symptom"    ,value:data.symptom},
    //         {key:"Description",value:data.description},
    //     ]
    //     if(isClient && data.event == 'client_new_request'){
            
            // const viders = data.providers
            // const olist =   <ol>
            //     {viders.map(v =>
            //         <li key={v}> 
            //             {v}
            //         </li>   
            //     )}
            // </ol>
            // if(data.event == 'client_new_request' || true){
            //     things.push({
            //         key:"Quotes requested from",
            //         value:olist,
            //         col:true,
            //     })
            // }
        // }
        // details = <div className='text-medium'>
            
        //     {things.map(t =>
        //         <div key={t.key}
        //         className={` d-flex mb-0 
        //             ${t.col?'flex-column':'align-items-center'}
                    
        //         `}>
        //             <p className='font-weight-500 mr-2 my-0'>
        //                 {t.key}:
        //             </p>
        //             {t.value}
        //         </div>
        //     )}

        // </div>

    // }

    function openQuote(){
   
        if(isClient){
            history.push(`/services?quote=${id}`)
            // history.push({pathname:'/',search:'for='+props.userType})

        }else{
            history.push(`/services?id=${id}`)
        }
        onClose()
    }
    function openService(){
        history.push(`/services?id=${id}`)
        onClose()
    }

    const events = {
        'client_new_request':{
            button_text:'Review service request',
            // first_line:`Thank you for submitting the Service Request #: ${padZero(id,3)} with the following issue:
            // `,
            // action_text:`We will be notifying you as we hear from the contractors.
            // `,
            first_line:`Hello, you should be soon receiving quotes from
            contractors for your issue with ${feature}`,
            onClick:openService,
            action:'Submitted',
            subject:`Service request is now in place ${feature?`for ${feature}`:''} `

        },
        'contractor_new_request':{
            button_text:'Review service request & provide quote',
            first_line:`You just received a quote request from ${actor} with micasa.
            `,
            action_text:`
            You can use the provide quote button below to access the service request 
            and/or to ask the customer any questions related to the issue.
            `,
            onClick:openQuote,
            // action:'Received request', // Not necessary
            subject:`Quote requested for fixing ${feature}`
        },
        'new_quote':{
            button_text:'View quote',
            first_line:`You just received a quote from ${actor} for the service request #${sid}`,
            action_text:`
            You can now review the quote by clicking on the button below.
            `,
            onClick: openQuote,
            action:`Quote from ${actor} ready for review`,
            subject:`Quote was provided for fixing ${feature}`

        },
        'refused_service':{
            button_text:'Request additional quotes',
            first_line:`
            Sorry, the contractor is not able to provide a quote for the issue with ${feature}
            
            `,
            action_text:`
            You can request for additional quotes by accessing the service
             request by using the button below.
            `,
            onClick:()=>{
                openQuote()
            },
            action:`No quote from ${actor}`,
            subject:`Contractor was unable to provide a quote on ${feature}`

        },
        'quote_rejected':{
            first_line:`
            Sorry, ${actor} has refused the quote provided for you.
            `,
            action_text:`
            You can request for additional quotes by accessing the service
             request by using the button below.
            `,
            onClick:()=>{
                openQuote()
            },
            action:`Quote rejected `,
            subject:`Quote rejected by homeowner for the ${feature||'requested feature'}`

        },
        'quote_accepted':{

            first_line:`
            Hello, ${actor} has accepted the quote provided for you for the feature ${feature}.
            `,
            action_text:`
            You can manage the status of the service request by clicking on the button below.
            `,
            onClick:()=>{
                openQuote()
            },
            action:`Quote accepted `,
            subject:`Congratulations, your quote was accepted`

        },
        'work_scheduled':{
            first_line:`
            Hello, this is to inform you that SR #: ${sid} is now scheduled for ${picked_date}.
            `,
            onClick:()=>{
                openQuote()
            },
            action:`Scheduled `,
            subject:`Service request now scheduled`

        },
        'work_rescheduled':{
            first_line:`
            Hello, this is to inform you that SR #: ${sid} is now rescheduled for ${picked_date}.
            `,
            onClick:()=>{
                openQuote()
            },
            action:`Scheduled `,
            subject:`Service request now rescheduled`

        },
        'requested_time_slots':{
            first_line:`
            Hello, this is to inform you that you have received request to provide alternate time slots for  SR #: ${sid}.
            `,
            onClick:()=>{
                openQuote()
            },
            action:`Alternative time-slots`,
            subject:`Request to provide time-slots `

        },
        'received_time_slots':{
            first_line:`
            Hello, this is to inform you that your contractor has provided you with alternate time slots for  SR #: ${sid}.
            `,
            onClick:()=>{
                openQuote()
            },
            action:`Received  time-slots`,
            subject:`Received alternate time-slots for SR #: ${sid}`

        },
        'work_dispatched':{
            first_line:`
            Hello, this is to inform you that the contractor is on their way to start the work 
            related to SR #: ${sid}.
            `,
            onClick:()=>{
                openQuote()
            },
            action:`Dispatched`,
            subject:`Contractor is headed your way`

        },
        'work_in_progress':{
            first_line:`
            Hello, this is to inform you that the contractor is working on 
            the service request  SR #: ${sid}.
            `,
            onClick:()=>{
                openQuote()
            },
            action:`In progress`,
            subject:`Work in progress`

        },
        'canceled_contractor':{
            first_line:`
            Hello, this is to inform you that the contractor has canceled the work related to the ${feature}.
            `,
            onClick:()=>{
                openQuote()
            },
            action:`Canceled`,
            subject:`Contractor has canceled the work`

        },
        'canceled_client':{
            first_line:`
            Hello, this is to inform you that the client has canceled the work related to the ${feature}.
            `,
            onClick:()=>{
                openQuote()
            },
            action:`Canceled`,
            subject:`Client has canceled the work`

        },
        'work_completed':{
            first_line:`
            Hello, this is to inform you that the contractor has completed the work on the service request  SR #: ${sid}.
            `,
            onClick:()=>{
                openQuote()
            },
            action:`Completed`,
            subject:`Work is now completed`

        },
        // 'quote_progress':{
        //     button_text:'Review service request',
        //     // first_line:`Thank you for submitting the Service Request #: ${padZero(id,3)} with the following issue:
        //     // `,
        //     // action_text:`We will be notifying you as we hear from the contractors.
        //     // `,
        //     first_line:`Hello, you should be soon receiving quotes from
        //     contractors for your issue with ${feature}`,
        //     onClick:openService,
        //     action:'Progress',
        //     subject:`Service request is now in place ${feature?`for ${feature}`:''} `

        // },
    }
    let event = events[data.event] || {}

    if(data.event == 'service_rejected'){
        event = events['refused_service'] || {}
    }

    lines.push(event.first_line)

    let content = <div className=' text-medium'>
         {lines.map((l,i)=>
            <p key={i}>
                {l}
            </p>    
        )}

        {/* {details} */}
        {/* 
        <button className='bg-primary rounded-8 p-2 text-white px-4 text-capitalize'
            onClick={event.onClick}
        >
            {event.button_text || `view ${isClient?'service request':'quote'}`}
        </button> */}

        {/* <p>
            Thank you,<br/>
            micasa support team.
        </p> */}

    </div>
    action = event.action
    const body = lines.join('\n') ||  'update regarding the service request'

    if(action){
        header = [action,header].join(' - ')
    }
    const result =  {content,body,header}
    if(event.subject){
        result.subject = event.subject
    }
    return result
}

function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export function prettyTime(date_time,vague){
    const datetime = (new Date(date_time))
    const now = new Date()

    let time = formatAMPM(datetime)

    const weekday = datetime.toLocaleString({},{weekday:'short'})
    let diff = Math.abs(now.getTime()-datetime.getTime())
    diff = Math.floor(diff / 1000 / 3600 / 24)

    const dateDiff = Math.abs(now.getDate() - datetime.getDate())
    const oneDayDiff = (dateDiff > 26 && dateDiff <31) || dateDiff === 1

    if(oneDayDiff && diff < 2 ){
        time = 'yesterday '+time
    }
    else if(diff && (diff < 7)){
        time = weekday +' '+ time
    }
    else if(diff >= 7){

        if(vague){
            time = diff+' days ago'
        }else{
            const month = datetime.getMonth()
            const months= ["January","February","March","April","May","June","July",
            "August","September","October","November","December"]
            let year = ''
            if(now.getFullYear() != datetime.getFullYear()){
                year = datetime.getFullYear()+' '
            }

            time = `${months[month].slice(0,3)} ${year}${datetime.getDate()}, ${time} `
        }
    }
    return time
}

export default function NotificationCard({message,onMessageRead,onClose,...props}){
    let content = {}
    const history = useHistory()
    const user = useSelector(getUser)
    const messages = useSelector(selectMessages)

    let time = ''
    if(message.time){
       time = prettyTime(message.time,true)
    }
    
    const message_type = message.message_type
    if(message_type === 'welcome'){
        content = welcomeMessage(user)
    }
    else if(message_type.includes('subscription_')){
        content = messageWithPlan(message,user)
    }
    else if(['profile_updated','added_co_owner','added_co_owner_unborn'].includes(message_type)){
        content = profileUpdated(message,user)
    }else if(message_type == 'service_update'){
        content = serviceUpdate(message,user,history,onClose)
        content = {...content,sender:content.header}
    }
    else{
        content = normalMessage(message)
    }

    const m = {...message,...content,time}


    const iconStyle = {fontSize:'1.2em'}
    let messageIcon = <MailOutline style={iconStyle} />

    if(message_type == 'message'){
        const data = safe_parse(message.data)
        let is_broadcast = data.is_broadcast
        
        // Can take this off in the future as newer messages will  have is_broadcast attribute
        if(typeof(is_broadcast) != 'boolean'){
            const message_id = data.message_id
            const message_obj = messages.find(m => m.id == Number(message_id))
            if(message_obj && !message_obj.user){
                is_broadcast = true
            }
        }

        if(is_broadcast){
            messageIcon = <img src='/images/dashboard/broadcast.svg' height="24px" />
        }
    }
    else if(message_type == 'profile_updated'){
        // messageIcon = <PersonOutlined  style={iconStyle}/>
        messageIcon = <img src='/images/dashboard/profile-mini.svg' height="14px" />
    }else if(message_type == 'service_update'){
        messageIcon = <BuildOutlined  style={{...iconStyle,transform:'scaleX(-1)'}} />
    }else if(message_type == 'checklist'){
        messageIcon = <Check  style={iconStyle} />
    }
    
    return  <div className='message-card hover-scale position-relative text-left d-flex
    my-1 p-2 pl-2 pr-1 ' 
        style={{cursor:'pointer'}}
        onClick={()=>onMessageRead(m)}
    >
        <div className={' mr-2 small '+(m.is_read?'text-muted filter-grayscale':'text-primary')} >
            {messageIcon}
        </div>
        <div className='flex-grow-1 overflow-hidden '>
            <div className='d-flex justify-content-between small pr-1'
                
            >
                <p className='font-weight-bold p-0 m-0 text-wrap'>
                    {m.sender||'micasa'}
                </p>
                <p className={' font-weight-500 p-0 m-0 text-nowrap ml-2 '+(m.is_read?'text-muted':'text-primary')}>
                    {(m.time)||''}
                </p>
            </div>

            <p className='font-weight-500 mb-0 p-0 text-small text-truncate'
            >
                {m.subject}
            </p>
            <p className='text-muted small mb-1'>
                {m.body}
            </p>
        </div>
    
    </div>
       
}

export function safe_parse(data){
    try {
        return data ? JSON.parse(data) : {}
    } catch (err) {
        console.error(err)
        return {}
    }

}