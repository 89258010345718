import React from 'react'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Circle from '@material-ui/icons/RadioButtonUncheckedOutlined';

export default function ChoiceCard({active,alignCenter,...props}){
    return <div className='d-flex my-2 py-2 pr-2' 
    {...props}
    
    style={{
        ...props.style,
        border:'2px solid '+(active ?'var(--pri)':'lightgray'),
        borderRadius:'8px',cursor:'pointer',
        paddingRight:'1em',
    }}
    >
        <div 
        className={`d-flex px-2 text-secondary
        justify-content-center align-items-${alignCenter?'center ':'start '}
        `}
        style={{paddingTop:"1px"}}
        >
            {
                active ?
                <CheckCircle className='text-primary' />
                :
                <Circle style={{color:'lightgray'}} />
            }     
        </div>
        <div className='text-left d-flex flex-column justify-content-center flex-grow-1'
           
        >
           {props.children}
        </div>
    </div>
}