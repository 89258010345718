import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'querystring'
import {login} from '../../app/auth/authSlice'
import { $wait } from '../../utils'
import { CircularProgress } from '@material-ui/core'
import Axios from 'axios'
import { $baseUrl, $error, $success } from '../../utils/http'
import { useDispatch } from 'react-redux'
import MDialog from '../../components/common/MDialog'

export default function EmailVerifier(props){
    const [emailVerify,setEmailVerify] = useState(false)

    const location = useLocation()
    const key = 'activation_code'
    const dispatch = useDispatch()

    const {showMe,hideMe} = props
    function showSelf(){
        setEmailVerify(true)
        if(showMe){
            showMe()
        }
    }
    function hideSelf(success){
        setEmailVerify(false)
        if(hideMe){
            hideMe(success)
        }
    }

    async function verifyEmail(aCode){
      
        try {
          showSelf()
          const res = await Axios.post($baseUrl+`/verify_email?${key}=${aCode}`)
          console.log('verified email succesfully')
          console.log(res.data)
          dispatch(login(res.data.user))
          hideSelf(true)
          $success(res)
        } catch (err) {
          hideSelf(false)
          $error(err)
        //   $error('Failed to verify email.')
          console.log(err)
        }
      
    }

    useEffect(() => {
        const query = qs.parse(location.search.replace('?',''))

        const aCode = query[key] 
        if(aCode){
          verifyEmail(aCode)
        }

    },[])

    const Core = <div className='p-5 d-flex flex-column align-items-center'>
        <CircularProgress color="primary" />
        <h4 className='my-3'>Verifying email</h4>
    </div>


    return <div>
        {
            props.withDialog?
            <MDialog open={emailVerify}>
            {Core}
            </MDialog>
            :
            Core
        }
    </div>
}