import React, { useState } from 'react'
import BellIcon from '@material-ui/icons/Notifications'
import ErrorIcon from '@material-ui/icons/Error'
import MailIcon from '@material-ui/icons/MailOutline'

import PopupMenu from '../common/PopupMenu'
import { useDispatch, useSelector } from 'react-redux'
import { getNotifications, markNotificationAsRead } from '../../app/user/userSlice'
import MDialog from '../common/MDialog'
import NotificationCard from "./NotificationCard"
import { getUser } from '../../app/auth/authSlice'
import { Button, Divider } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

export default function NotificationPanel({...props}){
    const notifications = useSelector(getNotifications)

    const notifs = notifications
    const messages = notifs//.map(n => ParseNotification(n,user))
    const unseen = messages.filter(m => !m.is_read)
    const seen = messages.filter(m => m.is_read)

    const noPermission = false
    const permissionUnset = false

    const [hide,setHide] = useState(false)

    // const notifs = [1,2,3]
    const history = useHistory()

    const label = <div className='d-flex align-items-center '>
        <BellIcon fontSize='small' />
        {!noPermission?

        unseen.length?
        <p className={'m-0 '+(unseen.length?'mx-1':'')}>
        {unseen.length}
        </p>
        :
        <p className='invisible'>.</p>
        
        
        :null}


        {noPermission?
            <span className='font-weight-bold ' >
                <ErrorIcon fontSize='small' color='primary'
                 style={{color:'orangered'}} 
                />
            </span>
        :null}
    </div>
    const dispatch = useDispatch()
    const buttonClass = (unseen.length > 0 && !noPermission ) ? ' bg-primary text-white ':' text-dark '

    const [message,setMessage] = useState(null)

    const titleBar = message? <div className='p-2 pl-3 py-2 pt-2 '>
        <div>
            <p className='m-0 mt-2 font-weight-500 text-wrap' >
                {message.subject}
            </p>
            <p className=' m-0 font-weight-500 text-muted' style={{fontSize:'0.8em'}}>
                {message.sender||'Micasa'}
            </p>
        </div>
 
    </div> : null

    function readMessage(message){
      
        const type = message.message_type 
        const data =  message.data ? JSON.parse(message.data) : {}

        if(type == 'message'){
            const message_id = data.message_id
            if(message_id){
                history.push(`/messages?id=${message_id}`)
            }

        }
        else if(type == 'service_update'){
            const service_id = data.sid || data.id
            const focus_on_quote = ['new_quote','received_time_slots']

            if(focus_on_quote.includes(data.event)){
                history.push(`/services?quote=${data.id||data.sid}`)
            }else
            if(service_id){
                history.push(`/services?id=${service_id}`)
            }
        }
        else{
            setMessage(message)
        }
        dispatch(markNotificationAsRead(message.id))    
        setHide(true)
    }


    function MessageList(read){
        return <div className='pb-1'>
        {messages.map((m,i)=>
             (!!m.is_read) === read?
            <NotificationCard 
                key={m.subject+' '+i} 
                onMessageRead={readMessage}
                message={m}
                onClose={()=>setMessage(null)}
            />
            :null
            
        )}
        {!notifs.length?
            <p className='text-muted m-4 text-center text-medium' >
                No new messages
            </p>
        :null}
        </div>
    }

    const pclass = 'font-weight-500 text-medium text-muted pl-2 mt-2 '
    const neither = !(seen.length+unseen.length)

    let permissionMessage = `Please grant permission to show notifications
    ${permissionUnset?'':' in the browser settings'}`

    return <div>
    <PopupMenu
        label={label}
        {...props}
        hide={hide}
        unHide={()=>setHide(false)}
        className={props.className+' '+'  small font-weight-500 px-1 '+buttonClass}
    >
        <div className='py-3 pb-3 px-2 text-left'  >
            <p className='font-weight-500 pl-2 mb-1'>
                Notifications
            </p>
            <Divider className='mb-0'/>
            <div className='notification-area '>
                {noPermission?
                    <div className='d-flex flex-column pl-3 '>
                        <p className={'text-secondary '+(permissionUnset?'':' my-3')}>
                            {permissionMessage}
                        </p>

                        {permissionUnset?    
                            <Button className='m-2 p-2'
                                disableElevation
                                color="primary"
                                variant="contained"
                            >
                                Grant permission
                            </Button>
                        :null}

                    </div>
                :
                    <div>
                    {unseen.length?
                        <React.Fragment>
                        <p className={pclass} style={{marginTop:'8px'}}>
                            Unseen
                        </p>
                        </React.Fragment>
                    :null}
                    {MessageList(false)}
                    {seen.length?
                        <React.Fragment>
                        {unseen.length?
                            <Divider className=''/>
                        :null}
                        <p className={pclass}>
                            Seen
                        </p>
                        </React.Fragment>
                    :null}
                    {neither?null:MessageList(true)}
                    </div>
                }
            </div>

        
        </div>
    </PopupMenu>

    <div>
        <MDialog
            titleBar={titleBar}
            open={!!message}
            onClose={()=>setMessage(null)}
        >
        {(!!message)?

            <div className="p-2 p-sm-2 container my-dialog-lg"
                
            >
                {message.content}
            </div>

        :null}
        </MDialog>

    </div>
    </div>

}