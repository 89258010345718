import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './counter/counterSlice';
import authReducer from './auth/authSlice'
import uiReducer from './ui/uiSlice'
import userReducer from './user/userSlice'
export default configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    ui: uiReducer,
    user: userReducer,
  },
});
