import React, { useState } from 'react'
import { Button, Popover, } from '@material-ui/core'

export default function PopupMenu({align_x="right",hide,unHide,...props}){

    const [anchorEl,setAnchorEl] = useState(null)

    const onChange = props.onChange || (()=>{})
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (e) => {
        setAnchorEl(e.currentTarget)
        if(unHide){
            unHide()
        }
    };

    return <div>

    <Button
        variant='contained'
        disableElevation
        onClick={handleClick}
        {...props}
    >

        {props.label||'button'}
    </Button>

    
     <Popover
        id="simple-menu"
        anchorEl={anchorEl}
            
        open={Boolean(anchorEl)&&!hide}
        onClose={handleClose}

        getContentAnchorEl={null}
        style={{marginTop:'8px'}}
        anchorOrigin={{ vertical: "bottom", horizontal: align_x }}

        transformOrigin={{
            vertical: 'top',
            horizontal: align_x,
            }}
        disablePortal
    >
        {props.children}
    </Popover>

    </div>

}