import React, { useState } from 'react'
import { Button, MenuItem, Menu } from '@material-ui/core'
import { $prettify } from '../../utils'
import { getFeatureIcon } from '../../utils/helpers'
import { $baseUrl } from '../../utils/http'


export default function SimpleMenu(
    {onChange,options,align_x='right',children,isIconGrid,...props})
    {

    const [anchorEl,setAnchorEl] = useState(null)

    const handleChange = onChange || (()=>{})
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (option,e) => {
        e.stopPropagation()
        handleChange(option);
        handleClose();
    };


    return <React.Fragment >

    <Button
        variant='contained'
        disableElevation
        onClick={(e)=>{e.stopPropagation();setAnchorEl(e.currentTarget)}}
        {...props}
    >

        {children||'button'}
    </Button>

    
    <Menu
        id="simple-menu"
        anchorEl={anchorEl}
         
        open={Boolean(anchorEl)}
        onClose={handleClose}

        getContentAnchorEl={null}
        style={{marginTop:'8px'}}
        anchorOrigin={{ vertical: "bottom", horizontal: align_x }}

        transformOrigin={{
            vertical: 'top',
            horizontal: align_x,
        }}

        MenuListProps={{
            style:(isIconGrid?{
                display:'grid',
                gridTemplateColumns:'repeat(6,1fr)'
            }:{})
        }}
        
      >
        {   options.length?
            options.map(
                (o) => {
                    return <MenuItem key={o.id || o} 
                        onClick={(e)=>handleClick(o,e)} 
                        className={` ${isIconGrid?'p-2 ':'px-4 p-2 pr-5'} font-weight-500 text-left `}
                        style={{fontSize:"0.9em",fontWeight:'500',padding:'4px 8px'}}
                        disabled={o[0]=='_'}
                    >
                        {
                            isIconGrid?
                            <img src={o.image_url} height='30px' />
                            :
                            $prettify(o)
                        }
                    </MenuItem>
                }
            )
            :
            <p className='p-2'>
                No options.
            </p>
        }
      </Menu>

    </React.Fragment>

}