
import { Button, CircularProgress, TextField, useTheme, useMediaQuery } from '@material-ui/core'
import { ArrowBack, ArrowForward, Edit } from '@material-ui/icons'
import Axios from 'axios'
import { default as React, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchFeatureSchema, quoteStatus, selectFeatures, selectFeatureSchema, selectServices } from '../../app/user/userSlice'
import MDialog from '../../components/common/MDialog'
import { getDay, usaDate } from '../../components/input/DatePicker'
import { to12hours } from '../../components/input/TimePicker'
import { NameBadge } from '../../components/special/HomeDialog'
import { $prettify, padZero } from '../../utils'
import { $alert, $baseUrl, $confirm, $error, $event, $success } from '../../utils/http'
import { patchQuote, protoTime, QuoteTable,
    TimeSlotCard, TimeSlotPicker,quoteToDateObj, getDateTime, ClientDetails } from '../dashboard/ContractorDashboard'
import { ActionButtons, } from '../dashboard/FeatureEditor'
import {QuoteSelector } from './ServiceAdder'
import { getFirstServiceMessage, getLatestReceivedMessage,getPickedDate } from '../../utils/helpers'
import {AutoForm} from '../dashboard/FeatureEditor'
import { selectMessages } from '../../app/user/userSlice'
import { getUser } from '../../app/auth/authSlice'
import { CardNumberElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentForm } from '../../components/special/PaymentDialog'
import { DocumentViewer, DocumentCount} from '../../components/common/DocumentViewer'
import { DocumentUploader } from '../../components/common/DocumentUploader'
import { loadStripe } from '@stripe/stripe-js'
import FieldWithLabel from '../../components/common/FieldWithLabel'
import MExpand from '../../components/common/MExpand'
import { getQuoteTotal } from '../../utils/helpers'
import StatusText from '../../components/special/StatusText'
const mediumText = 'text-muted text-medium mb-1'
const labelClass = 'text-muted text-small my-1 font-weight-500'

export default function ServiceDetail({source,feature,
    stage,setStage,onClose,    
    ...props})
{
    const services = useSelector(selectServices)
    const sr = services.find(s=>s.id == source.id)
    let accepted_quote = sr.accepted_quote? sr.quotes.find(q=>q.id==sr.accepted_quote) :null
    const service = sr

    const [message,setMessage] = useState('')
    const [currentQuote,setQuote] = useState()
    // const [stage,setStage] = useState('')
    const [timeSlot,setTimeSlot] = useState(-1)
    const [altTime,setAltTime] = useState(protoTime)
    const [pickAlt,setPickAlt] = useState(0)
    const [loading,setLoading] = useState(0)
    const [additionalProviders,setAdditionalProviders] = useState([])
    const featureSchema = useSelector(selectFeatureSchema)
    const messages = useSelector(selectMessages)
    const user = useSelector(getUser)
    const [popup,setPopup] = useState('')

    const history = useHistory()
    const dispatch = useDispatch()
    // const isMobile = window.outerWidth < 420
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    let [
        quote_list,get_more_quotes,quote_review,pick_time,re_schedule,
        rejection_details,service_rejected,reject_quote,quote_progress,
        view_invoice,finished,
        quote_details,cancel_this,completed,cancellation_details,attach_docs
    ] 
    = ['',...'123456789abcdefghijklmnopqrst'.split('')]

    cancellation_details = 'cancellation_details'
    quote_list = 'quote_list'

    get_more_quotes = 'get_more_quotes'
    quote_list = 'quote_list'
    re_schedule = 'reschedule'
    finished = 'finished'

    const quote = currentQuote || accepted_quote

    function resetFocus(quote){
        let new_stage = quote_list
        const clumped_stages = [7,8,9,10,100]
        let is_clamped = quote && clumped_stages.includes(quote.status)
        is_clamped = is_clamped

        let new_popup = ''
        const qid = source.open_quote
        const q = source.quotes.find(tq => tq.id == qid)
        if(q){
            setQuote(q)
            quote = q
        }

        if(quote && quote.status == -1){
            new_stage = cancellation_details
        }
        else if(source.status == -1 && !quote){
            new_stage = quote_list
        }
        else if(source.cancel_this){
            new_stage = cancel_this
        }
        else if(source.reschedule){
            new_stage = re_schedule
        }
        else if(source.make_payment){
            new_popup = 'make_payment'
        }
        else if(source.popup === 'get_more'){
            new_popup = ('get_quotes')
        }
        else if(quote){
            if(quote.status == 1 ){
                new_stage = (service_rejected)
            }else if(quote.status == 2 ){
                new_stage = (quote_review)
            }else if(quote.status == 7 ){
                // rescheduled 
                new_stage = (quote_progress)
            }else if(quote.status == 3){
                new_stage = (rejection_details)
            }else if(quote.status == 4){
                // new_stage = (requested_alternate_time)
                new_stage = (quote_details)
            }else if(quote.status == 5){
                new_stage = (pick_time)
            }else if(quote.status >=8 && quote.status <=10){
                new_stage = (quote_progress)
            }else if(quote.status == 100){
                new_stage = completed
            }
            else if(quote.status == 101){
                new_stage = finished
            }
        }else if(!currentQuote && is_clamped){
            new_stage = quote_list
        }
        
        setPopup(new_popup)
        setStage(new_stage)

    }
    // const features = useSelector(selectFeatures)

    useEffect(()=>{
        let accepted_quote = sr.accepted_quote? sr.quotes.find(q=>q.id==sr.accepted_quote) :null
        // setQuote(accepted_quote)
        // const tq = currentQuote && sr && sr.quotes.find(tq => tq.id == currentQuote.id)
        resetFocus(null)
        const docs = sr.documents
        setDocuments(getSelectedDocs(sr))
        dispatch(fetchFeatureSchema(source.feature))

    },[source])


    const handleQuotePicked = (q) => {
        if(q.status){
            setQuote(q)
            resetFocus(q)
        }
    }
    const textAreaProps = {
        rows:4,
        multiline:true,
        value:message,
        variant:'outlined',
        placeholder:'Type a message here...',
        onChange:e=>setMessage(e.target.value)
    }


    const allTheQuotes = <div className='d-flex flex-column  flex-grow-1'>
        {sr.quotes.slice().sort((a,b) => b.status-a.status)
        .map((q,i) => 
            <QuoteTile key={q.id} quote={q} 
                onClick={()=>handleQuotePicked(q)} 
                className={`mb-1  ${i==sr.quotes.length-1?'':'border-bottom '}`}
                showArrow
            />
        )}
  
    </div>
    let activeSlide = {
        content:allTheQuotes,
        noNext:true,
        back:'Get more quotes'
    }

    const sheduledDate = (quote?
        <p className={`my-1 text-small rounded-8 text-center p-2 border-thick
            ${quote.rescheduled?'text-warning':'text-primary'}
        `}>
            You have {quote.rescheduled?'re':''}
            scheduled this appointment for {getPickedDate(quote)}
        </p>
    :null)

    const cancelButton = <Button className={`text-small ml-3`}
        color='primary'
        size='small'
        onClick={()=>setStage(cancel_this)}
    >
        Cancel service
    </Button>

    function handleContact() {
        history.push(`/messages?to=${quote.contractor}&service=${source.id}`)
    }
    const request_details = <React.Fragment>


    </React.Fragment>

    const contractor_bar = <div className='d-flex border-bottom'>
        <button className='rounded pr-2 py-2 pl-0 bg-white'
            onClick={()=>{setQuote(null);setStage(quote_list)}}
        >
            <ArrowBack   />
        </button>
        <QuoteTile quote={quote} className='pl-0  flex-grow-1' />
    </div>

    let contractor_selected_docs = service.selected_documents.split(',').filter(d=>d[0]==='c').map(cd=>Number(cd.slice(1)))
    let quote_docs = []
    if(quote){
        const legal_ones = service.documents.filter(d => d.user_type != 0 && d.user_id == quote.contractor).map(d=>d.id)
        contractor_selected_docs = contractor_selected_docs.filter(cd => legal_ones.includes(cd))
        
        quote_docs = service.documents.filter(
            d=> !!d.user_type && contractor_selected_docs.includes(d.id)
        )
    }

     
    const quote_summary = !quote ? null :  <React.Fragment>

        {contractor_bar}

        {!!quote.contents && 
            <React.Fragment>
                <div className='d-flex justify-content-between '>
                    <p className='text-small text-muted m-2 my-1 '>
                        Quote for  {sr.feature.name} #{padZero(quote.id,3)}
                    </p>

                    <button className='text-small text-primary p-1 bg-transparent font-weight-500 d-flex
                        align-items-center'
                        onClick={()=>setStage(view_invoice)}
                    >
                        <DocumentCount count={quote_docs.length} className='mr-1' /> 
                        View documents
                    </button>
                </div>
                <QuoteTable quote={quote} />
            </React.Fragment>
        }

        {/* {!(quote && quote.message_from_contractor)?null:
            <React.Fragment>
                <p className='text-small text-muted font-weight-500 my-1'>
                    Message from contractor:
                </p>
                <p className='p-2 rounded-8 border text-small text-muted'
                    style={{minHeight:'12em'}}
                >
                    {quote.message_from_contractor}
                </p>
            </React.Fragment>
        } */}

    </React.Fragment>

    const contactButton = <span>
        <span className='d-inline-block'>
        Contact 
        </span>
        <span className='d-inline-block ml-1'>
            {(quote && quote.provider.full_name)}
        </span>
    </span>

    const [documents,setDocuments] = useState([])
    const [filePicked,setFilePicked] = useState(null)

    const commonScreens = ['quote_list','quote_progress','quote_details','completed']
    if(commonScreens.includes(stage)){
        // const serviceStatus = 'Work completed on 7/11'
        const more_quotes =  {
            action:'Get more quotes',
            onClick:()=> setPopup('get_quotes')
        }
        const cancel_service = {
            action:'Cancel service',
            onClick:()=> setStage(cancel_this)
        }
        let quote_action = more_quotes
        let cancel_action = cancel_service
        
        if([-1,100,101].includes(source.status) || quote?.status >= quoteStatus.dispatched){
            cancel_action = null
        }
        if(accepted_quote){
            // Allow getting more quotes if picked quote has refused

            // if(quote.status != -1){
            //     quote_action = cancel_service
            // }

            if([100,101].includes(accepted_quote.status) || accepted_quote.status >= 8){
                quote_action = null
            }

        }
        if(source.status == -1){
            quote_action = null
        }

        const cancelAction = !cancel_action?null:
        <button className='text-primary text-small bg-white font-weight-500 px-0 py-0'
            onClick={cancel_action.onClick}
        >
            {cancel_action.action}
        </button>
        

        let additionalDetails =  <CircularProgress color='primary' className='mx-auto my-3' />

        let schema = featureSchema['simple_feature']
        schema = (schema && schema.fields)

        const excludedFields = ['id','house','parent','has_subfeatures','icon','name','notes',
        'category_string','documents','category']
       
        let filtered_fields = []
        if(schema){
            const fields = Object.keys(schema).filter(f => !excludedFields.includes(f))
            if(schema){fields.push('notes')}
            const ignore_fields = ['status','_type','type','extra_details','address','display_name']
            filtered_fields = fields.filter(f => !!feature[f] && !ignore_fields.includes(f))

            additionalDetails = <AutoForm
                schema={schema} 
                source={service.feature}
                setter={()=>{}}
                onEvent={()=>{}}
                readOnly
    
                fields={fields}
            />
            
        }
        
        additionalDetails = <MExpand
            disabled={!filtered_fields.length}
            expandIcon={!schema?<CircularProgress size={16} className='m-1' />:null}
            summary={
                <React.Fragment>
                    <p>
                        {/* {feature.name||'Feature'} details */}
                        Additional information
                    </p>
                </React.Fragment>
            }
        >

            {additionalDetails}

        </MExpand>

        const latest_message = service ? getLatestReceivedMessage(service,messages,user.id) : {}
        // const latest_sent = service ? getLatestSentMessage(service,messages,user.id) : {}

        const first_service_message = getFirstServiceMessage(service,messages)
        
        const docs = getSelectedDocs(service)
        var docs_unchanged = (documents.length == docs.length) && documents.every(function(element, index) {
            return element === docs[index]; 
        })
    
        console.log(docs,documents,docs_unchanged)
        const new_unread = !!latest_message.message

        activeSlide = {
            back:"Cancel",
            next:"Submit",
            notYet: docs_unchanged,

            content:<React.Fragment>
                <div className='d-flex align-items-center justify-content-between'>
                    {/* <p className='font-weight-bold text-medium mr-auto'></p> */}

                    <div className='d-flex flex-column align-items-start'>
                        <StatusText ignore_latest formal 
                            service={source}
                            concise 
                            style={{marginTop:'4px'}}
                        />

                        {cancelAction}
                    </div>
                    
                    
                    {!!first_service_message && 
                        <button className={`bg-transparent ${new_unread?'':'filter-grayscale opacity-70'} pr-1`}
                            onClick={()=>history.push(`messages?to=${first_service_message.sender}&service=${service.id}`)}
                            title='View messages'
                        >
                            <img src='/images/dashboard/mail-mini.svg'  height='27px'/>
                        </button>
                    }

                </div>

                {accepted_quote && accepted_quote.status >= 100 ?
                    <div className='d-flex flex-column mb-2 mt-1'>
                        <div className='d-flex  align-items-center
                         p-2 rounded-top-8 border-thick text-medium font-weight-500
                        '
                            style={{borderBottom:'none'}}
                        >
                            <p  className='font-weight-500 mr-auto text-secondary'>
                                Billing
                            </p>
                            <span className='cursor-pointer d-flex align-items-center'
                                onClick={()=>setStage(view_invoice)}
                            >
                                <img src="images/dashboard/document.svg" height="24px" />
                                <p className='text-primary ml-1 p-0 text-small'>
                                    View invoice & docs
                                </p>
                            </span>
                        </div>
                        <div className='d-flex justify-content-between align-items-center 
                        border-thick p-2 rounded-bottom-8'>
                            <NameBadge source={accepted_quote.provider} />

                            <p className='mr-auto font-weight-500 text-medium'>
                                {accepted_quote.provider.full_name}
                                <span className='text-success text-small ml-2'>
                                    ${Number(accepted_quote.estimated_cost||0) || getQuoteTotal(accepted_quote)}
                                </span>
                            </p>
                            {accepted_quote.status == 101 ?
                                <p className='mx-2 font-weight-500 text-medium '>
                                    Paid
                                </p>
                                :
                                <button className='text-primary text-medium font-weight-500
                                bg-white pr-1 pl-0 ml-auto'
                                onClick={showMakePayment}
                                >
                                    Make payment
                                </button>
                            }
                        </div>

                    </div>
                :null}

                <div className='d-flex justify-content-between'>
                    
          
                </div>

                <MExpand
                summary={
                    <React.Fragment>
                        <p>
                            {source.feature.name}-{source.details.symptom}
                        </p>
                        {/* {cancelAction} */}
                    </React.Fragment>
                }
                >
                    <ClientDetails 
                    hideDocs
                    service={{...source,address:source.details.address}} />
                </MExpand>

                {additionalDetails}

                <FieldWithLabel
                    label='attachments'
                >
                    <div className='d-flex rounded-8 border-thick 
                    text-small  p-2 cursor-pointer align-items-center'
                    onClick={()=>setPopup('attach')}
                    >
                        <div className='m-0 d-flex align-items-center'>
                            <div className={`p-0 pl-1 font-weight-500 text-${documents.length?'primary':'muted'} `} 
                                data-testid='service_doc_count'
                            >
                                {documents.length || 'No'}
                            </div>
                            <p className='m-0 text-secondary ml-1'>
                            attachment{documents.length==1?'':'s'}
                            </p>
                        </div>

                        {/* <Edit
                            className='ml-auto text-muted '
                            fontSize='small'
                            {...props}
                        /> */}
                    </div>
                </FieldWithLabel>

                {/* {!quote?null:

                    <MExpand
                        summary={
                            <React.Fragment>
                                <p>
                                    Accepted quote from {quote.provider.full_name} 
                                </p>
                                <span className='font-weight-500 text-success '>
                                    ${getQuoteTotal(quote)}
                                </span>
                            </React.Fragment>
                        }
                    >
                        {quote_summary}
                    </MExpand>
                } */}

                <MExpand
                    expanded
                    summary={
                        <React.Fragment>
                        <p>Contractors  & Quotes</p>

                        {!quote_action?null:
                            <button className='text-primary text-small bg-white font-weight-500 pr-0'
                                onClick={quote_action.onClick}
                            >
                                {quote_action.action}
                            </button>
                        }

                        </React.Fragment>
                    }
                >
                    {allTheQuotes}
                </MExpand>


            </React.Fragment>
        }

    }
    else
    if(stage == cancel_this){
        activeSlide = {
            // next:'Contact',
            onBack:()=>{
                setStage(quote_list)
            },
            next:"Cancel service",
            back:'Back',
            content: <React.Fragment>
                <p className={labelClass}>
                    Please provide a reson for cancellation:
                </p>
            
                <TextField 
                    {...textAreaProps}
                    placeholder='Add your reason'
                />

            </React.Fragment>,
        }
    }
    else if(stage == cancellation_details){

        activeSlide = {
            noNext:true,
            back: contactButton,
            onBack:handleContact,
            content: <React.Fragment>

                <p className='text-danger font-weight-500 text-medium'>
                    Cancelled by contractor
                </p>
                {quote_summary}

                <FieldWithLabel label="Reason for cancellation:">
                    <p className='border-thick p-2 rounded-8 text-small text-muted'>
                        {(quote && quote.message_from_contractor) ? 
                            quote.message_from_contractor
                            :
                            'A reason was not provided'
                        }
                    </p>
                </FieldWithLabel>
          
            </React.Fragment>
        }

    }
    else if(stage == view_invoice){
        const handleBack = () => {
            if(!currentQuote){
                setStage(quote_list)
            }else{
                resetFocus(quote)
            }
        }
        let docsList = quote ? quote_docs :service.documents
        
        activeSlide = {
            noNext:true,
            onBack:handleBack,
            content: <React.Fragment>
  
                <DocumentViewer 
                    source={{...service.feature,documents:docsList,house:source.house}}
                    onClose={handleBack} 
                    house={{id:source.house}}
                    readOnly
                    nonDeletable
                    // filterer={d=> d.user_type != 0}
                    viewing_what='Viewing Invoice & docs attached by contractor'
                />

            </React.Fragment>
        }
    }    
    // Edit accepted quote
    else if(quote){

        const quote_info = <React.Fragment>
            {quote_summary}

            {/* {!quote.message_from_contractor?null:
                <React.Fragment>
                    <p className='text-small text-muted font-weight-500 my-1'>
                        Message from contractor:
                    </p>
                    <p className='p-2 rounded-8 border text-small text-muted'>
                        {quote.message_from_contractor}
                    </p>
                </React.Fragment>
            } */}

        </React.Fragment>


        if(stage==rejection_details || stage == reject_quote || stage == service_rejected){
            const rejectionScreen = <React.Fragment>
                {quote_summary}
                <p className={labelClass}>
                    {stage == reject_quote?
                    `To improve our offerings, share with us reason 
                    why this quote isn’t appealing to you`:
                    `Reason for refusal of service:`
                    }
                </p>
                {stage == reject_quote?
                    <TextField 
                        {...textAreaProps}
                        placeholder='Add your reason'
                    />
                :
                    <p className='p-2 my-1 text-muted text-medium border-thick rounded-8'
                        style={{minHeight:'80px'}}
                    >
                        {quote[`message_from_${stage == service_rejected?'contractor':'client'}`]}
                    </p>
                }
            </React.Fragment>
            activeSlide = {
                content:rejectionScreen,
                next:'Reject',
                noNext: stage != reject_quote,
                back:'Back',
            }
        }else if(stage==quote_review || stage==pick_time || stage == re_schedule){
            
            let inContent = 'loading.....'
            const rescheduling = stage==re_schedule || quote.status>=8
            const times = []
            for(let i=0;i<3;i++){

                let startDateString = quote['time_slot'+(i+1)+'_start']
                let endDateString = quote['time_slot'+(i+1)+'_end']
                let [sdate,start] = getDateTime(new Date(startDateString))              
                let [edate,end] = getDateTime(new Date(endDateString))
                
                times.push({start,end,date:sdate})
            }
            // const slot_expired = (n) => {
            //     const {date,start,end} = times[n]
            //     let [cdate,ctime] = getDateTime(new Date())
            //     const pickedFuture =  !date || (date>cdate || (date == cdate && start > ctime))

            //     return !(pickedFuture)

            // }
            function pickTimeSlot(n){
                const {date,start,end} = times[n]
                let [cdate,ctime] = getDateTime(new Date())

                const pickedFuture =  !date || (date>cdate || (date == cdate && start > ctime))

                if(pickedFuture){
                    setTimeSlot(n)
                }else{
                    $alert('The time slot has expired')
                }
            }

            if(stage==pick_time || stage==re_schedule){
                inContent =  <React.Fragment>

                    {stage==re_schedule?
                        <p className='my-1 text-danger text-medium'>
                            Additional charges will be applied on re-scheduling this appointment
                        </p>
                    :null}

                    <p className={`${labelClass}`}>Times proposed:</p>

                    <div className='container-fluid my-2'>
                        <div className='row  ' >
                            {times.map((t,n) => 
                                <div key={n}
                                className={`col-4 px-1 ${t.date?'':' text-primary '} hover-light`}
                                >
                                <TimeSlotCard time={t} 
                                    onClick={()=>pickTimeSlot(n)} 
                                    active={timeSlot==n || (rescheduling && timeSlot<0 && quote.time_slot_picked == n)}
                                    className={` text-small  `}
                                    style={{fontSize:'0.8em',
                                    // opacity:slot_expired(n)?'0.7':'1.0'
                                }}
                                />
                                </div>
                            )}
                        </div>
                    </div>

                    <button 
                        onClick={()=>{setPickAlt(1)}}
                        className={` border-thick 
                            ${
                                timeSlot==3?
                                'text-white bg-primary  text-small border-primary':
                                'text-muted bg-white  text-medium'
                            }
                        `}
                    >
                        {altTime.date?
                        `New time Proposed: ${getDay(altTime.date)},
                        ${usaDate(altTime.date)}, 
                        ${to12hours(altTime.start)} - ${to12hours(altTime.end)}`
                        :
                        'Propose an alternate time'
                        }
                        
                    </button>

                    <p  className={`${labelClass} mt-2`}>
                        Add a message
                    </p>
                    <TextField 
                      {...textAreaProps}
                      placeholder='Add any additional information for the contractor, 
                      this could be instructions to find your location or where to
                       find the feature in question...'
                    />

                  
                </React.Fragment>
             
              
                activeSlide = {

                    back :'Back to quote',
                    next : (timeSlot!=3)?'Accept':`Propose time`,
                    notYet :timeSlot<0 || (rescheduling && timeSlot == quote.time_slot_picked),
                    onBack: () => {
                        setStage(rescheduling?quote_progress:quote_review)
                    }
                }
                                
            }else{
                // stage = quote_review
                inContent = <React.Fragment>
                    {quote_info}
         
                    <p className='text-small text-muted'>
                        Add a message (optional):
                    </p>
                    <TextField 
                        {...textAreaProps}
                    />
                </React.Fragment>

                const iCanceled = source.status == -1
                const aquote = source.accepted_quote
                const acceptedAnother = (!!aquote && accepted_quote.status != -1 && aquote != quote.id)

                const contactOnly = iCanceled || acceptedAnother
                activeSlide = {
                    onBack: () => {
                        if(iCanceled){
                            handleContact()
                        }else{
                            setStage(reject_quote)
                        }
                    },
                    noNext: contactOnly,
                    back : contactOnly? contactButton: 'Reject quote',
                    next : 'Pick times',
                    notYet: (source.accepted_quote && (source.accepted_quote.status == -1)),
                }
              
            }

            const givenQuote = <div className='d-flex flex-column '>
        

                {inContent}
            </div>
                
            activeSlide = {
                noNext: (source.status == -1),
                ...activeSlide,
                content:givenQuote,
            }
        }
        else if(stage == quote_details){
            const quoteDetails = <div className='d-flex flex-column'>
                {quote_info}

                {sheduledDate}

            </div>

            activeSlide = {
                content:quoteDetails,
                back: contactButton,
                noNext:true,
                onBack:handleContact
            }
            
        }
        else if(stage == quote_progress || stage == completed || stage == finished){
            const dat = quoteToDateObj(quote,quote.time_slot_picked+1)
            const quoteReview = <div className='d-flex flex-column'>
                {/* {stage == completed ?
                    <p className='text-medium text-center'>
                    <b> Completed </b> 
                    <span className='font-weight-500 text-muted '>
                        on 
                            {` ${getDay(usaDate(dat.date))},
                                ${usaDate(dat.date)}, 
                                ${to12hours(dat.start)} - ${to12hours(dat.end)}`}
                    </span>

                    </p>
                :null} */}

                {quote_info}

                {quote.status >= 100 && (!!Number(quote.estimated_cost)) && 
                    <div className='d-flex justify-content-end text-medium'>
                        <p className='font-weight-500'>
                            Amount billed:
                            <span className='font-weight-bold ml-1'>
                                ${quote.estimated_cost}
                            </span>
                        </p>
                    </div>
                }

                {stage != finished && 
                    <FieldWithLabel
                        label='Message to contractor:'
                    >
                        {/* <p className={` rounded-8 border-thick p-2 `}
                            style={{minHeight:'8em'}}
                        >
                            {source.message_from_contractor}
                        </p> */}
                        <textarea rows={4} placeholder='Please type a message..'
                            onChange={e=>setMessage(e.target.value)}
                            className='big border-thick rounded-8 p-2 text-small text-muted'
                            style={{resize:'none'}}
                        >
                        </textarea>
                    </FieldWithLabel>
                }

                {/* {stage == completed ?null:

                    <div className='d-flex my-1 text-small
                    align-items-center '>

                        <p>Status:</p>
                    
                        {cancelButton}
                    </div>
                } */}
                
            </div>
            activeSlide = {
                content:quoteReview,
                back:  contactButton,
                noNext: (stage != completed && quote.status > 8) || (source.status == -1),
                next:stage == completed?'Make payment':'Reschedule',
                onBack:handleContact

            }
            if(stage == finished){
                activeSlide={
                    noNext:true,
                    back: contactButton,
                    onBack : handleContact,
                    content:quoteReview,
                }
            }
        }
    }

    async function patchService(data){
        try {
            let fdata = {...data,}
            if(stage == cancel_this){
                fdata.status = -1
            }
            setLoading(1)
            const res = await Axios.patch(`${$baseUrl}/services/${source.id}`,fdata)
            $success(res)
            onClose()
        } catch (err) {
            console.log(err)
        }finally{setLoading(0)}

    }
    async function alterTheQuote(data){
        try {
            let fdata = {...data,}
            if(quote){fdata.service_provider = quote.service_provider}
            if(stage == cancel_this){
                await $confirm({
                    question:`Are you sure you want to cancel this service?`,
                    detail:'',
                    no:'No',
                    yes:'Yes, cancel now',
                })
                fdata.status = -1
            }
            setLoading(1)
            await patchQuote(fdata,quote.id)
            // setEdited(false)
            // $success('updated quote!!')
            onClose()
        } catch (err) {
            console.log(err)
        }finally{setLoading(0)}
        
    }
    async function addMoreQuotes(){
        try {
            setLoading(1)
            const data = {service_providers:additionalProviders.map(s=>s.id)}
            const res = await Axios.patch($baseUrl+`/services/${source.id}`,data)
            $success(res)
            setStage(quote_list)
            setPopup('')
        } catch (err) {
            $error(err)
        } finally {
            setLoading(0)
        }
    }
    function makePayment(){
        $alert('Payment is not implemented yet')
    }
    
    function showMakePayment(){
        setPopup('make_payment')
    }

    function handleNext(){
        if(activeSlide.onNext){
            activeSlide.onNext()
        }
        if(stage == quote_review){
            setStage(pick_time)
        }
        else if(stage == quote_list){
            patchService({selected_documents:documents.join(','),})
        }
        else if(stage == pick_time || stage == re_schedule){
            
            // Accepting the quote
            alterTheQuote({

                time_slot_picked:timeSlot,
                // message_from_client:message,
                message:message,
                re_scheduling: stage == re_schedule,
                status: 8,

                ...(timeSlot == 3?{
                    status: 4,
                    time_slot_start: new Date(altTime.date+'T'+altTime.start).toISOString(),
                    time_slot_end: new Date(altTime.date+'T'+altTime.end).toISOString()
                }:{})
            })

            let event = ''
            if(quote.status <= 8 && stage == pick_time){event = 'quote_accepted'} 
            if(timeSlot == 3){event = 'requested_alternate_time'} 
            if(stage == re_schedule){event = 're_scheduled'}
            if(event){
                $event(event,{"client":quote.client,"contractor":quote.contractor,"service_id":quote.service.id,"user_id":user.id})
            }
            
        }
        else if(stage == reject_quote){
            // quote rejection
            alterTheQuote({
                status:3,
                message_from_client:message,
            })
            $event('quote_rejected',{"client":quote.client,"contractor":quote.contractor,"service_id":quote.service.id,"user_id":user.id})

        }else if(stage == quote_progress){
            setStage(re_schedule)
        }else if(stage == cancel_this){
            $event('service_canceled',{"client":source.client,"service_id":source.id,"user_id":user.id})
            patchService({status:-1,message_from_client:message})
        }else if(stage == completed){
            showMakePayment()
        }

    }
    const handleBack = useCallback(() => {
        if(activeSlide.onBack){
            activeSlide.onBack()
        }else if(stage == quote_list){
            // setStage(get_more_quotes)
            onClose()
        }
        else if(stage == quote_review){
            setStage(reject_quote)
        }
        else{
            setStage(quote_list)
        }
    },[stage])

    let showingList = false
    let featureName = ''
    if(['get_more_quotes',].includes(stage)){
        showingList = true
    }
    const titleBar = <div className='pl-2 pr-1 py-3 m-0  
        d-flex align-items-center 
        font-weight-bold lead'
    >
        {
        showingList?
            <React.Fragment>
                <span className='font-weight-bold'>
                Quotes 
                </span>
                <span className='text-muted mx-2 text-truncate ml-auto 
                 text-right'
                    style={{maxWidth:isMobile?'40vw':'10em'}}
                >
                    {source.feature.name}
                </span>
            </React.Fragment>
        :
            <React.Fragment>
                <span className='text-wrap font-weight-bold'>
                Service request 
                </span>
                <span className='text-muted mx-2'>
                    #{padZero((typeof(source)=='number'?source:source.id),3)}
                </span>
            </React.Fragment>

        }

    </div>

    let popupSlide = null
    
    if (popup == 'get_quotes'){ 

        const noAdditional = !additionalProviders.length
        popupSlide = {
            next:'Add',
            back:'Back',
            notYet: !additionalProviders.length,
            onNext:addMoreQuotes,
            content: <React.Fragment>
                <p className={labelClass} > 
                    Pick the additional providers:
                </p>
                <QuoteSelector
                    activeCategory={feature.category}
                    selected={additionalProviders}
                    setSelected={setAdditionalProviders}
                    ignore={source.quotes.map(q=>q.service_provider)}            
                />
            </React.Fragment>
        }


    }
    else if (popup == 'make_payment'){ 
        const amount = Number(quote.estimated_cost) || getQuoteTotal(quote)

        const stripeKey = process.env.REACT_APP_STRIPE_KEY
        const stripePromise = loadStripe(stripeKey);

        popupSlide = {
            noNext:true,noBack:true,
            next:"Pay",back:"Back",
            hideActions:true,
            content:  <Elements stripe={stripePromise}>
                <PaymentSlide 
                    amount={amount} 
                    onBack={()=>setPopup('')} 
                    quote={quote}
                    service={source}
                />

            </Elements>

        }
    }
    else if(popup == 'attach'){
        const maxAllowed = 8
        let disableUpload = (service?.documents?.filter(d => d.container_model == 'service').length)
        disableUpload = disableUpload >= maxAllowed
        popupSlide = {
            next:'Attach',
            back:'Back',
            onNext:()=>{
                setPopup('')
            },
            onBack:()=>{
                setDocuments(getSelectedDocs(source));
                setPopup('')
            },
            content: <React.Fragment>
                <p className='font-weight-bold mb-2 '>
                    Attachments
                </p>
                  <DocumentUploader
                    isButton
                    source={source} 
                    house_id={source.house}
                    style={{minWidth:'32px'}}
                    label='Upload new attachment'
                    onAddNew={d => setDocuments([...documents,d.id])}
                    // onFilePicked={f=>setFilePicked(f)}
                    disabled={disableUpload}
                />

                {disableUpload && 
                    <p className='text-small text-muted '>
                        You have reached the max limit of {maxAllowed} uploads per service request
                    </p>
                }

                {/* {!filePicked?null:
                    <p className='text-small text-warning' >
                        Please upload the file or clear it to continue.
                    </p>
                } */}

                 <DocumentViewer
                    source={service} 
                    onClose={()=>{}} 
                    house={{id:source.house}}
                    listOnly
                    selectable
                    // nonDeletable
                    filterer={d => (d.user_type == 0 )}
                    selected={documents}
                    handleChecked={(d)=>{
                        const docs =  documents
                        const checked = docs.includes(d.id)
                        if(checked){
                            setDocuments(docs.filter(td=>td!=d.id))
                        }else{
                            setDocuments([...docs,d.id])
                        }

                    }}
                    // openUpload={()=>setPopup({type:'upload'})}
                />

            </React.Fragment>
        }
    }
    let popupFooter = popupSlide && <ActionButtons 
        backText={popupSlide.back||'Back'}
        nextText={popupSlide.next||'Save'}
        onBack={popupSlide.onBack||(()=>setPopup(''))}
        onNext={popupSlide.onNext}
        expand
        className='mt-2 mb-2'
        disabled={popupSlide.notYet}
        noNext={popupSlide.noNext}
        noBack={popupSlide.noBack}
        loading={loading}
    />

    return <React.Fragment>

            <MDialog
                open={!popup}
                onClose={()=>{setPopup('');onClose()}}
                height={!isMobile?'max(50vh,400px)':''}
                titleBar={titleBar}
                footer={
                    <React.Fragment>
                        {activeSlide.hideActions?null:
                            <ActionButtons
                                backText={activeSlide.back||'Back'}
                                nextText={activeSlide.next||'Save'}
                                onBack={handleBack}
                                onNext={handleNext}
                                expand
                                className='mt-2 mb-2'
                                disabled={activeSlide.notYet}
                                noNext={activeSlide.noNext}
                                noBack={activeSlide.noBack}
                                loading={loading}
                            >
        
                            </ActionButtons>
                        }
                    </React.Fragment>
                }
            >
                <div className='d-flex flex-column flex-grow-1 my-dialog-sm overflow-auto'>
        
                
                    {/* <h4 className='font-weight-bold p-3 m-0'>
                        Quotes 
                        <span className='text-muted mx-2'>
                        {sr.feature.name}
                        </span>
                    </h4> */}
        
                    <div className='d-flex flex-column 
                    flex-grow-1 pb-2 p-1 pb-sm-2 px-sm-2'>
                        <div className='d-flex flex-column flex-grow-1 '>
                            {activeSlide.content}   
                        </div>
                        
                    </div>
        
                    <MDialog
                        open={pickAlt == 1}
                        onClose={()=>setPickAlt(0)}
                    >
                        <TimeSlotPicker
                        value={altTime}
                        onChange={time=>{setAltTime(time);setTimeSlot(3);setPickAlt(0)}}
                        onClose={()=>setPickAlt(0)}
                        />
                    </MDialog>
        
        
                
        
                </div>
        
            </MDialog> 
            {!!popup && popupSlide &&

                <MDialog
                    open={!!popup}
                    onClose={()=>{setPopup('');if(source.popup)onClose()}}
                    footer={popupFooter}
                    height={!isMobile?'max(50vh,400px)':''}
                >
                    <div className='my-dialog-sm p-2 px-3 d-flex flex-column flex-grow-1'>
                        {popupSlide.content}
                    </div>

                </MDialog>
            }
            
    </React.Fragment>
    
    

}

export function QuoteTile({quote,showArrow,...props}){
    const q = quote
    let full_name = q.provider.full_name
    if(full_name == q.provider.email){
        full_name = ''
    }
    const company_name = q.provider.company_name

    const primary_name = (company_name && !full_name) ? company_name : full_name
    const secondary_name = (company_name && full_name) ? company_name : ''


    return  <div 
        {...props}
        className={` d-flex align-items-center
        cursor-pointer pr-2 py-2 ${props.className||'pl-2'} `}
    >
        <NameBadge source={{full_name:primary_name}} />

        <div className='d-flex flex-column ml-2 mr-auto111111111111111 pr-2'>
            <p className=' text-medium m-0 font-weight-500 '>
                {primary_name}
            </p>
            {secondary_name && 
                <p className='text-small text-muted m-0'>
                    {secondary_name}
                </p>
            }
        </div>

        {/* <StatusText quote={quote}/> */}
        <StatusText concise service={{accepted_quote:quote.id,quotes:[quote]}}
            className='d-flex flex-column flex-grow-1 text-right'
        />

        {showArrow && quote.status!=0?
            <ArrowForward htmlColor='gray' className='ml-2' />
        :null}
    </div>
}

export function getSelectedDocs(quote){
    let sdocs = ''

    if(quote){
        sdocs = quote.selected_documents || (quote.service && quote.service.selected_documents) || sdocs
    }

    const ids = (sdocs).split(',').filter(d => d[0] !== 'c').map(d => Number(d))
    const existing_ones = quote.documents.map(d => d.id)
    return ids.filter(d => existing_ones.includes(d))
}



function PaymentSlide({amount,onBack,service,quote,...props}){
    
    const FieldLabel = (props) => (
        <p className='text-secondary text-left small mt-3 mb-2'>
            {props.children}
        </p>
    )

    const stripe = useStripe()
    const elements = useElements()
    
    const [pay,setPay] = useState({name:''})
    const [loaded,setLoaded] = useState(0)
    const [processing,setProcessing] = useState(false)
    const [clientSecret,setClientIntent] = useState('')

    async function fetchPaymentIntent(){

        try {
            setLoaded(0)
            const data = {
                amount,currency:'inr',
                quote_id: quote.id,
                metadata:{
                    quote_id:quote.id,
                }
        }
            const res = await Axios.post(`${$baseUrl}/payment_intent`,data)

            const {intent} = res.data
            console.log('payment_intent : ')
            console.log(intent)
            setClientIntent(intent)

            setLoaded(1)
        } catch (err) {
            onBack()
            $error('Please try again')
            console.log(err)
        } 


    }

    useEffect(()=>{

        fetchPaymentIntent()

    },[])

    async function handlePay(ev){
        // ev.preventDefault();


        setProcessing(true);
        const payload = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
          }
        });
        $event('made_payment',{"client":service.client,"service_id":service.id,"user_id":service.user})

        if (payload.error) {
          $error(`Payment failed ${payload.error.message}`);
          $event('payment_failed',{"client":service.client,"service_id":service.id,"user_id":service.user})

          setProcessing(false);
        } else {
          $success('Success!')
          const res = await patchQuote({status:101},quote.id,true)
          onBack()
          //setProcessing(false);
        }

    }

    return <div className='d-flex flex-column flex-grow-1 justify-content-between '>

        <div className='d-flex flex-column align-items-center '>
            <img src={'/images/dashboard/payment.svg'} 
                alt='' style={{height:'6em'}}
            />
            <p className='lead font-weight-500'>
                Payment of
                
                <b className='mx-2 text-secondary font-weight-bold'>
                   ${amount}
                </b>
            </p>
                
        </div>

        <PaymentForm  
            pay={pay} 
            setPay={setPay} 
            loaded={loaded} 
        />

        <ActionButtons
            nextText='Pay'
            onNext={handlePay}

            backText='Back'
            onBack={onBack}
            loading={processing}
        />

    </div>

}