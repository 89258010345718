import React, { useRef, useState } from 'react'
import { AuthField, GoogleDisclaimer } from './Authenticator'
import { Button, CircularProgress } from '@material-ui/core'
import { $error, $success, $baseUrl, $validPassword, $required } from '../../utils/http'
import Axios from 'axios'
import { useHistory } from 'react-router-dom'
import { Formik, Form,} from "formik";
import * as yup from 'yup'
import { useSelector } from 'react-redux'
import { getUser } from '../../app/auth/authSlice'
import SubmitButton from '../common/SubmitButton'

export default function ResetPassword({email,...props}){
    
    const [
        forgot,
        resetPass,
        changePass,
    ] = 'nsmw2005'.split('') 

    const [
        initial,
        notFound,
        emailSent,

        mismatch,
    ] = 'nsmw2005'.split('') 

    const history = useHistory()
    let initialState = props.resetPassword ? resetPass : forgot
    if(props.changePassword){
        initialState = changePass
    }

    function goBack(val){
        props.goBack(val)
    }

    let [status,setStatus] = useState(initialState)
    let [situation,setSituation] = useState(initial)

    const user = useSelector(getUser)
    let description = 'What is your email address?'
    let action = 'Forgotten password'

    let buttonText = 'Send Link'
    let statusText = ''
    let passText1 = 'Password'
    let passText2 = 'Retype password'
    const {onSuccess} = props

    const formikRef = useRef()
    const form = formikRef.current;

    async function handleForgot({email}){
        try {
            const res = await Axios.post($baseUrl+'/forgot_password',{email})
            setSituation(emailSent)
            $success(res)
            if(onSuccess){onSuccess(0)}
        } catch (err) {
            const res = err.response
            if(res){
                if(res.status === 404){
                    setSituation(notFound)
                }
            }
            $error(err)
            throw(err);
        }
    }
    async function handleReset({password,password2}){
        try {
            if(password != password2){
                throw 'The two passwords do not match'
            }
            const secret = props.otp
            const data = {
                new_password:password,
                secret
            }
            const res = await Axios.post($baseUrl+'/reset_password',data)
            setSituation(emailSent)
            history.push({pathname:'/',search:'for='+props.userType})
            $success(res)
            if(onSuccess){onSuccess(1)}
        } catch (err) {
            const res = err.response
            if(res){
                if(res.status === 404){
                    setSituation(notFound)
                }
            }
            const rdata = res && res.data
            formikRef.current?.setErrors({...rdata?.errors})
            const password_error = rdata?.errors?.password
            if(!password_error){
                $error(err)
            }
            throw(err);
        }
    }
    async function handleChange({password,password2}){
        try {
            const data = {
                old_password:password,
                new_password:password2,
            }
            const res = await Axios.post($baseUrl+'/change_password',data)
            setSituation(emailSent)
            $success(res)
            if(onSuccess){onSuccess(1)}
        } catch (err) {
            const res = err.response
            if(res){
                if(res.status === 404){
                    setSituation(notFound)
                }
            }
            $error(err)
            throw(err);
        }
    }


    if(status === forgot){
        
        if(situation === notFound){
            statusText = <div style={{fontSize:'0.7em',color:'gray'}}>
                <span className='text-danger' >
                    No account found - please check for typos.
                </span>
                    If you don’t have one you might want to 
                <Button className='text-primary mx-1' onClick={()=>goBack(2)} >
                    <small>

                    create an account here
                    </small>
                </Button>

            </div>
        }else if(situation === emailSent){
            statusText = <p style={{fontSize:'0.7em',color:'gray'}}>
                A link has been sent. 
                Please check your email 
                address to reset your password
            </p>
            buttonText = 'Resend link'
        }
    }else if(status === changePass || status === resetPass){
        buttonText = 'Change password'
        let ptext = 'Type your old password to set up a new password'
        if(status === changePass){
            action = 'Change password'
            passText1 = 'Type in your old password'
            passText2 = 'Type in your new password'
        }else{
            action = 'Reset password'
            ptext = 'Type a new password'
            passText1 = 'New password'
            passText2 = 'Retype password'
        }
        description =  ptext

        if(situation === mismatch){
            statusText = <p>
                The passwords do not match
            </p>
        }
    }
    
    async function handleSubmit(data,{setSubmitting}){
        setSubmitting(true)

        try {
            if(status === forgot){
                await handleForgot(data)
            }else if(status === resetPass){
                await handleReset(data)
            }else if(status === changePass){
                await handleChange(data)
            }
            
            // if(form){
            //     form.setFieldValue('password','')
            //     form.setFieldValue('password2','')
            //     form.setFieldValue('email','')

            // }
            {
                props.goBack(0)
            }

        } catch (err) {
            
        }        
     }

    let passwordValidator
    let password2Validator
    if(status!=forgot){
        passwordValidator = yup.string().required('Please type the existing password')
        password2Validator = $validPassword.required('Please type the new password')
        if(status==resetPass){
            passwordValidator = $validPassword.required('Please type a new password')
            
            password2Validator = yup.string().required('Please type the password again')
            password2Validator = password2Validator.oneOf([yup.ref('password')], 'The two passwords do not match')
        }
    }

    const validationSchema = yup.object({
        email: status === forgot? yup.string().email('Email must be valid').required('Please provide a valid email'):null,
        
        password: passwordValidator,
        password2: password2Validator,
        
    })
    return <Formik
        initialValues={{
            email: email||'',
            password:'',
            password2:'',
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        innerRef={formikRef}
    >
    {({values,errors,isSubmitting}) => 
    
    <Form className='my-dialog d-flex flex-column
     text-center p-4 pb-4 flex-grow-1 '
    >
        <div className='mt-auto d-flex flex-column'>
            <img src='/images/micasa-logo.svg' alt='' style={{height:'4em'}} />
            <h4 className='mt-3 mb-1 font-weight-bold'>{action}</h4>
            <p className=' small text-secondary' style={{fontSize:'0.9em'}}>
            {description}
            </p>

                {
                    status === forgot?
                    <AuthField
                    name='email'
                    placeholder="Email address"

                    />
                    :
                    [
                        <AuthField
                        name='password'
                        type='password'
                        placeholder={passText1}
                        key='password1'
                        />,
                        <AuthField
                        type='password'
                        placeholder={passText2}
                        name='password2'
                        key='password2'
                        />
                    ]
                }
        </div>

        <div className='d-flex   my-3 mt-auto pt-2'>
            <Button 
                variant='contained'
                disableElevation
                className='flex-grow-1 mr-2'
                onClick={()=>goBack(0)}
            >
                Cancel
            </Button>
            <SubmitButton 
                loading={isSubmitting}
                className={`px-1 ${buttonText=='Change password'?'':'flex-grow-1'} `}
            >
                {buttonText}
            </SubmitButton>

        </div>

        {statusText}

        {(status === changePass || status === resetPass)?
            GoogleDisclaimer
        :null}

    </Form>
    }

    </Formik>


}