import { Dialog, useMediaQuery, useTheme, Button, } from "@material-ui/core";

import React, { useEffect, useState } from 'react'
import {blurScreen,unBlurScreen} from '../../app/ui/uiSlice'
import { useDispatch } from "react-redux";
import CloseIcon from '@material-ui/icons/Close' 
export default function MDialog({
    onClose,
    titleBar,footer,noBorder,unClosable,paperPadding,
    fixedClose,paperStyle,neverFullscreen,height,
    ...props
})
{

    let {open} = props
    open = open || false
    const dispatch = useDispatch()

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

   
    useEffect(()=>{
            if(open){
                dispatch(blurScreen())
            }

    },[open])

    useEffect(()=>{
        return ()=>{
            if(open){
                dispatch(unBlurScreen())
            }
        }
    },[open])
    const gray = '90'

    return <Dialog
        BackdropProps={{
            style:{
                backgroundColor:`rgba(${gray+','+gray+','+gray},0.5)`
            }
        }}
        fullScreen={neverFullscreen?false:fullScreen}
        {...props}
        PaperProps={{ style:{overflow:'hidden',...paperStyle},className:' '+(paperPadding?paperPadding:' p-sm-2 p-1 ')}}
        className=''
    >   
        <div className='flex-grow-1 d-flex flex-column position-relative overflow-auto'>

            {titleBar?
                <div className='p-0 d-flex border-bottom ' >
                    <div className='d-flex flex-column flex-grow-1'>
                    {titleBar}
                    </div>
                    {fixedClose?null:
                        <button className=' p-1 bg-transparent p-2 px-sm-3' 
                            style={{zIndex:'2'}}
                            onClick={onClose}
                        >
                            <CloseIcon></CloseIcon>
                        </button>
                    }
                </div>
            :null}

            {((!titleBar || fixedClose) && !unClosable)?
                <button className='position-absolute p-1 bg-transparent ml-auto' 
                    style={{right:'0.2em',top:'0.2em',zIndex:'2'}}
                    onClick={onClose}
                >
                    <CloseIcon></CloseIcon>
                </button>
            :null}

            <div className={`p-0 d-flex flex-column overflow-auto
               ${height?` `:` flex-grow-1 `}  ${(noBorder?' border-0 ':'')} 
            `}
            style={{height}}
            >
                {props.children}
            </div>

            {
                footer?
                footer:null
            }

        </div>


    </Dialog>

}
