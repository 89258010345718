import { Route, Redirect, useLocation } from "react-router-dom";
import {getUser} from '../../app/auth/authSlice'
import { useSelector } from "react-redux";
import React, { useEffect } from 'react'

export default function ProtectedRoute({path,component:Component,...rest}){
    const location = useLocation()


    let user = useSelector(getUser)
    
    const safePath = `/${location.search}`
    let ok = user || path === safePath

    useEffect(()=>{
        if(!ok){
            window.supposed_page = location.pathname+''+location.search
        }
    },[])

     return <Route {...rest} 
        render={(props)=>(
            ok?<Component {...props} {...rest} />:<Redirect to={safePath} />
        )} 
    />

  
}