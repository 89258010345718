import React from 'react'
import { Button } from '@material-ui/core'
import LandingPart from '../components/special/LandingPart'
import { Appear } from './HomeOwnerLanding'
import { getUser } from '../app/auth/authSlice'
import { useSelector } from 'react-redux'

const featureCards = [
    {
        image:'resources.svg',
        text:'Provide your clients with resources'
    },
    {
        image:'network.svg',
        text:'Get help building a customer network'
    },
    {
        image:'mail.svg',
        text:'Keep track of client communications'
    },
]

export const AgentLandingPart = ({small}) => <LandingPart
    dark
    small={small}
    title = "Get the keys to your success"
    description={`Being an agent is rewarding, but it’s also hard work.
    We provide you with the keys you need to get your job done. Better.`}
    image='agent-welcome.svg'
>

</LandingPart>

export const AgentFeatures = ({small}) => <div className={'container '}>
    <div className='row row-cols-1 row-cols-md-3'
        style={{
            ...(small?{}:{margin:'8em 0em 6em 0em'})
        }}
    >
        {featureCards.map((f,i) =>
            <Appear key={f.text} delay={(100+i*400)}
                className='col py-2 px-2 d-flex flex-column' 
            >
                <div className={'feature-card bg-primary d-flex flex-column flex-grow-1 py-4 '+(small?'p-2':'p-4')}>
                    <img src={'/images/landing/'+f.image} alt="agent landing image" 
                    style={{maxHeight:'8em'}}
                    />
                    <p className='font-weight-bold text-white mt-3'>
                        {f.text}
                    </p>
                </div>
            </Appear>
            
        )}
    </div>
</div>

export default function AgentLandingPage(props){
    const user = useSelector(getUser)



return  <div className=' bg-pri-light'
>

        <div className='container '>
        <div className='row mt-5 mb-5'>
            <div className='col-md-7 text-left d-flex flex-column 
            justify-content-end text-white mb-5 mt-5'>
                <Appear component='h1' className='font-weight-bold display-4'
                    delay={200}
                >
                    All your tools in one place
                </Appear>
                <Appear component='p' className='my-2' delay={400}>
                Keep track of customer relationships, and help your clients manage
                 their tasks while they buy or sell a home - all in one place.
                </Appear>

                {user?null:
                    <div className='d-flex mt-3'>
                        <Appear component={Button} delay={800}
                            variant='contained'
                            className='mr-2 text-primary bg-white'
                            disableElevation
                            onClick={()=>props.displaySignIn(2)}
                        >
                            Create Account
                        </Appear>
                        <Appear component={Button} delay={1000}
                            variant='contained'
                            disableElevation
                            color='primary'
                            className='bg-pri-dark'
                            onClick={()=>props.displaySignIn(1)}
                        >
                            Sign in
                        </Appear >
                    </div>
                }
            </div>
            <div className='col-md-5'>
                <Appear component={'img'} delay={600} 
                    src='/images/landing/agent-circle.svg' style={{height:'18em'}} 
                />
            </div>

        </div>
        </div>


        {AgentLandingPart({delay:0,offset:-0.3})}
      

        {AgentFeatures({})}

        <div className='container text-white text-left mt-5 mb-5'>
            <div className='row'>
                
                <div className='col-md-8 position-relative'>
                    <Appear component={'h3'} delay={200} className='font-weight-bold display-4'>
                        Plans and Pricing
                    </Appear>
                    <Appear component={'p'} delay={400}>
                    To learn about the plans and pricing, please shoot us a message at 
                    <span 
                    style={{textDecoration:'underline',
                    margin:'2px 6px',fontWeight:'bold'}}>
                        pricing@micasa.com
                    </span>
                    <br/>
                    We’ll get back to you as soon as possible
                    </Appear>
                    
                </div>
                <div className='col-md-4 d-flex'>
                    <div className='position-relative ml-auto ml-sm-0 mb-3 mb-sm-5' 
                        style={{transform:'translate(-2em,-2.2em)'}}
                    >
                        <Appear component='svg' delay={600}    
                            className='position-absolute '
                            style={{top:'100%',right:'100%',transform:'translate(78px,-75px)',zIndex:'-1'}}
                            width="621" height="255" 
                            viewBox="0 0 621 255" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.5 240.5C31.5 150.5 165.5 68.5 245 97.5C324.5 126.5 352 147.5 424.5 135C492 130 549.5 63 621 2"
                        stroke="#057AFF" strokeWidth="4" strokeMiterlimit="3.8637" strokeDasharray="32 16"
                        />
                        </Appear>
                        <Appear component='img' delay={800}
                        duration={1.2}
                        direction="left"
                            src='/images/landing/send.svg' 
                            style={{width:'12em',zIndex:'2',}} 
                        />

                    </div>

                </div>
            </div>
        </div>


</div>

}