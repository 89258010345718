import React, { useState } from 'react'
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {getUser} from '../../../app/auth/authSlice'
import { Button, CircularProgress, Select, MenuItem } from '@material-ui/core'

import Clients from './Clients'
import Contractors from './Contractors'
import { $required } from '../../../utils/http'
import {referClient} from './Clients'
import { Form, Formik } from 'formik'
import { VFormField } from '../InitialSetup'
import * as yup from 'yup'
import CheckLists from './Checklists'
import CashFlowCalculator from './CashFlowCalculator'

const notYet = true
function MainMenu(props) {
    const items = [
        {name:'Clients',image:'clients',path:'clients',desc:`Keep track of your client communications`,},
        {name:'Checklists',image:'tasks',path:'checklists',desc:`Manage the checklists that you are sending out to clients`},
        {notYet,name:'House listings',image:'search-house',path:'house-listings',desc:`Manage the house listings that you are responsible for`},
        
        {name:'Contractors',image:'contractors',path:'contractors',desc:`Keep track of your contractors and recommend them to clients`},
        {name:'Cash flow',image:'cash-flow',path:'cash-flow',desc:`Calculate your ROI and monthly cash flow`},
        {name:'Broadcasts',image:'megaphone',abs_path:'/messages',desc:`Send out messages to many clients at a time`},

    ]
    const roleOptions = [
        {name:'Home Owner',value:0},
        {name:'Potential Buyer',value:3},
        // {name:'Property Manager',value:2},
    ]

    const [sending,setSending] = useState(false)
    const [userType,setUserType] = useState(0)
    
    const validationSchema = yup.object({

        email: yup.string().email('Please provide a valid email address.').required($required("Email")),
        // phone: yup.string().test('len', 'Mobile number must have 10 digits', 
        // $phoneValidator
        // ),

    })

    const history = useHistory()

    async function referThisClient({email}){
        setSending(true)
        await referClient({
            email,
            first_name:'',
            last_name:'',
            user_type:userType,
        })
        setSending(false)
    }
    
    return <div className=' d-flex flex-column align-items-center '>

        <div className=' w-100 bg-primary py-2 py-md-3 pb-3 mt-2 mb-3 text-white'>
            <div className='container '>
                <div className='row'>

                <div className='col-12 col-md-4 col-lg-3 d-flex align-items-center'>
                    <img src='/images/dashboard/refer-client.svg' height="64px" />
                    <h4 className='ml-2 d-inline-block my-0'>Refer a client</h4>
                </div>
                <div className='col-12 col-md-5 col-lg-7 d-flex flex-grow-1 align-items-center justify-content-center mb-2 mb-lg-0'>
                
                <Formik
                     initialValues={{email:''}}
                     validationSchema={validationSchema}
                     // validate={validateForm}
                     onSubmit={referThisClient}
                     
                >
                {({errors}) => 
                    <div className='d-flex flex-column flex-grow-1 mx-lg-4 position-relative mt-3 mt-sm-0'>
                        {errors.email && 
                            <p className='text-warning text-small text-left position-absolute'
                                style={{bottom:'100%',left:'0'}}
                            >
                                {errors.email}
                            </p>
                        }

                    <Form className='d-flex align-items-center '>
                        <VFormField 
                            ignoreError
                            size='small'
                            variant="outlined"
                            className=' flex-grow-1  my-0 my-md-0'
                            placeholder='Email address'
                            inputProps={{style:{backgroundColor:'white',borderRadius:'8px',overflow:'hidden'}}}
                            name="email"
                            />

                        <Button 
                            variant="contained"
                            type='submit'
                            style={{color:'var(--pri)',backgroundColor:'white'}}
                            disabled={sending}
                            className='ml-2 '
                        >
                                
                            {sending?
                                <CircularProgress  size={18} style={{marginLeft:'4px',margin:'4px 0'}}/>
                                :'Send'}
                        </Button>  

                    </Form>
                    </div>

                
                }
                </Formik>
                
                
                </div>
                <div className='col-12 col-md-3 col-lg-2 d-flex align-items-center justify-content-end'>
                    <Select
                            variant='outlined'
                            value={userType} 
                            size='small'
                            className='mr-2 font-weight-500 my-auto'
                            color='secondary'
                            SelectDisplayProps={{
                                style:{
                                    color:'white',
                                    padding:'10px 18px',
                                    paddingRight:'32px'
                                }
                            }}
                            onChange={e=>setUserType((e.target.value))}
                        >
                        {roleOptions.map(ro =>
                            <MenuItem key={ro.name} value={ro.value} >
                                {ro.name}
                            </MenuItem>

                        )}
                    </Select>

                    
                </div>

                </div>

            </div>
        </div>

        
        <div className='container '>
            <div className='row row-cols-1 row-cols-md-3'>
                
                {items.map(i => 
                    <div className='col  p-3' key={i.name}>
                        
                        <div className='d-flex flex-column h-100 ' 
                            onClick={()=>{history.push(i.abs_path||(`${props.path}/${i.path}`))}}

                            style={{borderRadius:'8px',border:'1px solid lightgray',
                            filter:i.notYet?'grayscale(0.99) opacity(0.7)':'',
                            padding:'8px',cursor:i.notYet?"":'pointer'}}
                            >
                            <img src={'/images/dashboard/'+i.image+'.svg'} height="128px" />
                            <p className='font-weight-bold' >
                                {i.name}
                            </p>
                            <p className='small text-muted'>
                                {i.desc}
                            </p>
                        </div>
                       
                    </div>
                )}


            </div>

        </div>         

    </div>
}

export default function AgentDashboard(props){

    const user = useSelector(getUser)
    
    const match = useRouteMatch()
    const path = (match && match.path) || 'notfound'

         
    const subMenus = [

        {component:Clients,path:'clients',},
        {component:Contractors,path:'contractors',},

        {component:CheckLists,path:'checklists',},
        {component:Clients,path:'clients',},
        {component:CashFlowCalculator,path:'cash-flow'},

    ]
    console.log(path)

    return <div className='flex-grow-1 d-flex flex-column'>

        <Switch>

            {subMenus.map((sm,i) =>
                <Route key={i} component={sm.component} path={`${path}/${sm.path}`} />
            )}

            <Route render={(props)=><MainMenu {...props} path={path} />} path='/'  />
        </Switch>

    </div>

}
