import { padZero } from '.'
import { usaDate } from '../components/input/DatePicker'
import { safe_parse } from '../components/special/NotificationCard'

/***
 * Shows wether another quote is accepted
 * @param {*} quote
 * @returns boolean
 */
export function didAcceptAnother(quote){
    const ac_quote = quote.service.accepted_quote
    const acceptedAnother = (!!ac_quote && ac_quote != -1 && ac_quote != quote.id)

    return acceptedAnother
}


/**
 * Self explanatory
 * @param {*} quote 
 * @returns 
 */

export function getQuoteTotal(quote){
    let {contents,items} = quote || {contents:'[]'}

    try {
        items = items || JSON.parse(contents || '[]')
    } catch (err) {
        console.log(err)
    }

    return items.reduce((a,b)=> (a+Math.abs(Number(b[2])*Math.abs(Number(b[1])))),0)
}




export function getLatestQuote(service){
    if(service.accepted_quote && service.status > 7){
        return service.quotes.find(q=>q.id==service.accepted_quote)
    }else{
        return service.quotes.length ? service.quotes.reduce((a,c)=>c.updated_at>a.updated_at?c:a) : null
    }
}
export function getFirstServiceMessage(service,messages){
    // const contractors = service.quotes.map(q => q.contractor)
    let first_message = messages.find(m => {
        const data = safe_parse(m.data) || {}

        return data.service == service.id 
    })
    return first_message
}

export function getMyLatestServiceMessage(service,messages,self){
    let latest_quote = getLatestQuote(service)
    let latest_message = null
    
    messages.forEach(m => {
        const data = safe_parse(m.data) || {}
        if((data.service == service.id) && m.created_at > latest_quote.updated_at){

            if((!latest_message || (latest_message.created_at < m.created_at))){
                latest_message = {...m.message_from,sender:self,
                    message:"Waiting for a response from contractor"
                    ,created_at:m.created_at
                }

            }
        }
    })
    return latest_message
}
export function getLatestSentMessage(service,messages,user){
    let latest_quote = getLatestQuote(service)
    let latest_message = {}
    // const contractors = service.quotes.map(q => q.contractor)

    const threads = getThreads(messages,user)

    for(let t of threads){
        const m = t.messages.length && t.messages[0]
        const data = safe_parse(m.data) || {}

        if((data.service == service.id) && m.sender==user.id && (latest_quote && latest_quote.created_at < m.created_at)){

            latest_message = {
                message:"Waiting for a response ",
                created_at:m.created_at
            }
     
        }
    }
 
    return latest_message
}


export function getLatestReceivedMessage(service,messages,user_id){
    // let latest_quote = getLatestQuote(service)
    let latest_message = {}
    // const contractors = service.quotes.map(q => q.contractor)
    messages.forEach(m => {
        const data = safe_parse(m.data) || {}

        const read_by_me = m.read_by.split(',').map(n=>+n).includes(user_id)
        if((data.service == service.id) && m.sender!=user_id && !read_by_me){
            const uid = m.message_from.id

            if((!latest_message.message || (m.created_at > latest_message.created_at))){
                // latest_quote = new_quote;
                latest_message = {...m,...m.message_from,sender:uid,
                    message: 'Received a message'||m.message,
                    created_at:m.created_at
                }

            }
        }
    })

    // if(latest_message.message){
    //     const latest_reply = messages.filter(m => m.user == latest_message.sender).reduce((a,b)=>a.created_at>b.created_at?a:b,{})
    //     // console.log(latest_message,user_id)
    //     console.log('latest reply = ')
    //     console.log(latest_reply)

    //     if(latest_reply.created_at > latest_message.created_at){
    //         latest_message = {}
    //     }
    // }
 
    return latest_message
}

export function getPickedDate(q){

    const picked = q.time_slot_picked
    const dateString = q[`time_slot${picked<3?(picked+1):''}_start`] || ''
    const date = dateString.split('T')[0]
    return usaDate(date)

}

export function getPickedTime(q){

    const picked = q.time_slot_picked
    const dateString = q[`time_slot${picked<3?(picked+1):''}_start`] || ''
    const time = dateString.split('T')[1]
    const dateTime = new Date(dateString)
    const hours = dateTime.getHours()
    const minutes = dateTime.getMinutes()
    const am_or_pm = (hours<12)?'am':'pm'
    return `${hours}:${padZero(minutes)} ${am_or_pm}`

}



export function getThreads(messages,user){
    let threads = {}

    const chat_messages = messages.filter(m => m.recipients.includes(user.id) || m.user==user.id || m.sender == user.id)

    for(let c of chat_messages){
        const is_broadcast = (c.sender == user.id) && !c.user
        let key = (c.sender == user.id) ? c.user : c.sender
        if(c.recipients.length > 1 && !c.user && c.sender != user.id){
            key = c.sender
        }
        
        if(is_broadcast){
            key = c.recipients.slice().sort((a,b)=> a-b).join('_')
        }
        let thread = threads[key] 
        
        if(!thread){
            thread = {...c,messages:[]}
            thread.recipients = c.recipients
            if(is_broadcast){
                thread.recipient_list = c.recipient_list
                for(let r of c.recipients){
                    let baby_thread = threads[r]
                    if(!baby_thread){
                        baby_thread = {...c,messages:[c]}
                    }else{
                        baby_thread.messages.push(c)
                    }
                }
            }else {
                if(c.sender != user.id){
                    thread.contact = c.message_from 
                }else{
                    thread.contact = thread.recipient_list[0] 
                }
                thread.recipient_list = [thread.contact]
            }
            threads[key] = thread
        }

        thread.messages.push(c)

    }

    threads = Object.keys(threads).map(k => ({key:k,...threads[k]}))
    for(let t of threads){
        t.messages = t.messages.slice().sort((a,b)=> a.created_at>b.created_at?-1:1)
    }
    // Order threads by their latest message
    threads = threads.sort((a,b) => {
        let am = a.messages[0]
        let bm = b.messages[0]
        am = am && am.created_at
        bm = bm && bm.created_at

        return am>bm?-1:1
    })
    return threads
}


export function getFeatureIcon(feature){
    const icons = window.store.getState().user?.featureSchema?.icons || []

    let icon_link = ''

    const icon_id =  feature?.icon  || feature?.category?.icon
    const icon = icons.find(i => i.id == icon_id)

    if(icon){
        icon_link = icon.image_url
    }
    else{
        icon_link =`/images/features/feature.svg` 
    }

    return icon_link
}

/**
 * This is a function that returns a value within an object's heirarchy.
 * @param {Object} data
 * The object which is to be indexed. 
 * @param {String} key 
 * The key of the value to be extracted.
 * eg: 'user.age'
 * @returns 
 * The value.
 */
export function getDeepValue(data,key){
    let val = null
    const lineage =  key.split('.')
    lineage.forEach((key_part,i) => {
        if(i == 0){val = data}
        if(val == null){return}
        val = val[key_part]
    });
    return val
}

/**
 * A function that returns a sorting function.
 * @param {String} key
 * Key used to sort the array. 
 * @returns 
 * The function that sorts the array in ascending order.
 */
export function sortAscending(key,ascending=true){
    const sign = ascending ? 1 : -1
    if(!key){
        return (a,b) => (a>b?1:-1)*sign
    }
    return (a,b) => (a[key]>b[key]?1:-1)*sign
}

/**
 * A function that returns a sorting function (descending).
 * @param {String} key
 * Key used to sort the array. 
 * @returns 
 * The function that sorts the array in descending order.
 */
export function sortDescending(key){
    return sortAscending(key,false)
}