
import { TextField } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import AddressField from '../../../components/input/AddressField'
import { $prettify } from '../../../utils'
import { FieldWithLabel } from '../../services/ServiceAdder'
import * as yup from 'yup'
import MDialog from '../../../components/common/MDialog'
import { ContactPicker } from '../../messages/Messages'
import { useDispatch, useSelector } from 'react-redux'
import { fetchResource, selectClients } from '../../../app/user/userSlice'
import { $baseUrl, $error, $success } from '../../../utils/http'
import Axios from 'axios'
import { getUser } from '../../../app/auth/authSlice'


export default function CashFlowCalculator({...props}){


    return <div>
        <div className='d-flex flex-column   '>

            <div className='border-bottom border-top py-2 my-2 mb-3 sticky-top bg-white'>
                <div className='d-flex align-items-center container px-1 px-sm-2'>
                    <Link className=' mr-3' to="/dashboard">
                        <ArrowBack fontSize="large" />
                    </Link>

                    <p className='lead font-weight-bold '>
                    Cash Flow Calculator
                    </p>
                </div>

            </div>

            <div className='container d-flex flex-column px-1 px-sm-2'>
                <Estimator />   

            </div>
            

        </div>
    </div>

}

const isPercent = true
const notMoney = true
// The insertion order of keys is preserved
const annualIncome = 'annual_rental_income'
const purchasePrice = 'purchase_price'


const listing_price = 312500
const percent_default = 96
const rent_default = 1875
const insurance_amt_default = 1000

const expenseConfig = {

    property_tax:{default:2.64,percent_of:purchasePrice},
    hoa_fees:{label:'HOA Fees',default:540},
    trash:{default:0},
    insurance:{default:(insurance_amt_default/rent_default*100/12),percent_of:annualIncome},

    maintenance_cost:{default:2,percent_of:annualIncome},
    vacancy:{label:'Vacancy/TurnOver',default:5,percent_of:annualIncome},
    property_management_cost:{default:10,percent_of:annualIncome},

}

const fieldConfig = {

    // Pricing
        listing_price:{default:listing_price},
        percent_list_price:{label:"% of list price",isPercent,default:percent_default},
        purchase_price:{default: listing_price*percent_default/100},
        
    // Income
        monthly_rental_income:{default:rent_default},
        annual_rental_income:{
            // label:'Annual gross rental income'
    },

    // Loan info
        ltv:{label:"LTV",default:80,isPercent},
        term:{default:360,notMoney},
        rate:{default:3.80,isPercent},

        ltv2:{label:"LTV",isPercent},
        term2:{default:180,notMoney},
        rate2:{default:8.75,isPercent},

        down_payment:{default:20,percent_of: purchasePrice},
         

    // Expenses
        ...expenseConfig,

    // computed
        cap_rate:{isPercent,notMoney}

}
const cfield_class = ' col-6 col-md-4 col-sm-4'

function CBox({name,isPercent,label,colored,value,className,col_class,...props}){
    label = label || $prettify(name)
    const context = useContext(StateContext)
    value = value || context.values[name] || ''

    let color
    if(colored){
        value = Number(value)
        if(value<0){
            color = '#FF9999'
        }else if(value>0) {
            color = '#90EE90'
        }
        else {
            color = 'whitesmoke'
        }
    }
    return <div className={'d-flex flex-column px-1 '+(col_class||cfield_class)+' '+className}
    >
        {/* <TextField 
            value={value}
            disabled={true}
            variant='outlined'
            size='small'
        /> */}
        <p className='text-small mt-1 text-secondary text-left font-weight-500 text-nowrap11'
        >
            {label} 
        </p>

        <p className='p-1 px-2 border-thick rounded-8 
         text-right text-medium w-100 overflow-hidden'
         style={{backgroundColor:color}}
        >
            {value && (isPercent ? value.toFixed(2) : format(Number(value).toFixed(2)))  }
            <span className={`${isPercent?'':'invisible'} ml-auto`}>%</span>
        </p>
    </div>
}

function CField({name,label,required,className,handleChange,col_class,...props}){
    const field = fieldConfig[name]
    label = label || field.label || $prettify(name)

    const context = useContext(StateContext)
    const [localAmount,setLocalAmount] = useState('')
    const [editingAmount,setEditingAmount] = useState(false)
    const setFieldValue = (value,key=name) => {
        context.setState(state => {
            const newState = {...state}
            newState[key] = value
            return newState
        })
    }

    function computePercentages(val){

    }

    const inputClass = 'bg-transparent pl-1 py-1 text-medium  border-top-0 border-bottom-0 border-right-0'
    const percent_of = field.percent_of
    const notMoney = field.notMoney
    const total = Number(context.values[percent_of] || context.state[percent_of] || 0)
    const per_color = 'whitesmoke'//'floralwhite'

    let amount = 0
    let percent = 0
    let displayAmount = 0
    let displayPercent = 0

    const inputValue = context.state[name]
    const touched = inputValue != field.default

    if(percent_of){
        percent = inputValue
        displayPercent = percent

        if(editingAmount){
            displayAmount = localAmount
        }else{
            displayAmount = Number(percent)/100*total
            displayAmount = displayAmount.toFixed(2)
        }
        
        amount = displayAmount
    }else{
        amount = inputValue
        displayAmount = amount

        displayPercent = (amount ? amount/total*100 : (field.percentage)) || 0
        percent = displayPercent
    }
    if(!field.isPercent && !notMoney){
        displayAmount = format(displayAmount)
    }

    const reg_numbers_only = /[^0-9\.]+/g

    function handleAmountChange(value){
        value = value.replace(reg_numbers_only, '');
        const val = unFormat(value)
        if(percent_of){
            if(editingAmount){
                setLocalAmount(val)
            }
            const new_percent = Number(val)/total*100
            setFieldValue(new_percent);
        }else{
            setFieldValue(val);
            if(handleChange){handleChange(val)}

            if(isPercent){
                const newState = {...context.state}
                newState[name] = val
                limitLtv2AndDownPayment(name,Number(val),newState,setFieldValue)
            }
        }
        // computePercentages(val)
    }
    function handlePercentChange(value){
        value = value.replace(reg_numbers_only, '');
        setFieldValue(value)
        // setFieldValue(Number(value)/100*total)
    }

    const perSymb = <span className='pr-1' >%</span>
    return <div className={`px-1 d-flex flex-column ${className} ${col_class||cfield_class}`}>
        <p className='text-small mt-1 text-secondary text-left font-weight-500 text-nowrap'
        >
            {label}  
            {/* {percent_of && `total:${total}`} */}
        </p>

        <div className='d-flex rounded-8 border-thick overflow-hidden'>
            
            <div className='d-flex align-items-center flex-grow-1'
                // style={{opacity:(!!value)?1:0.5}}
                style={{backgroundColor:required?'#ffe4c4aa':''}}
            >
                <input 
                    placeholder={displayAmount}
                    value={displayAmount}
                    onChange={e=>{handleAmountChange(e.target.value)}}
                    onFocus={()=>{
                        setLocalAmount(unFormat(displayAmount))
                        setEditingAmount(true)
                    }}
                    onBlur={()=>{
                        setLocalAmount('')
                        setEditingAmount(false)
                    }}
                    disabled={!total&&percent_of}
                    // variant='outlined'
                    // size='small'
                    // type="number"
                    className={`${inputClass} pl-2 dark-placeholder ${touched?'':'opacity-70'}`}
                    style={{width:'100%',minWidth:'64px',borderLeft:'none',}}
                />
                {field.isPercent && perSymb}
            </div>

            {
                percent_of &&
                <div className='d-flex align-items-center' style={{backgroundColor:per_color}}>
                    <input 
                        className={inputClass}
                        style={{width:'36px',borderLeft:'1px solid lightgray',}}
                        value={displayPercent}
                        // type="number"
                        onChange={e=>handlePercentChange(e.target.value)}
                    />
                    {perSymb}
                </div>
            }
            
        </div>
        
    </div>
}
function DivWithLabel({label,className,...props}){
    return <div className={`position-relative border rounded
        ${className} py-2 px-1  px-sm-3 my-2
    `}>
        {label&&
            <p style={{'top':'0',left:'4%',transform:'translateY(-50%)',color:'#0769BA'}}
                className='position-absolute bg-white px-2 text-medium'
            >
                {label}
            </p>
        }

        {props.children}
    </div>
}
const StateContext = React.createContext({
    state:{},
    setState:()=>{},
    values:{}
})

function Estimator(){
 
    const initialValues = {}
    const keys = Object.keys(fieldConfig)
    for(let k of keys){
        const field = fieldConfig[k]
        let val = field.default
        if(val == null){val = ''}
        initialValues[k] = val
    }

    const user = useSelector(getUser)
    const [state,setState] = useState(initialValues)
    const [address,setAddress] = useState(null)
    const [shouldSend,setShouldSend] = useState(0)
    const [recipients,setRecipients] = useState([])
    const clients = useSelector(selectClients)

    const setFieldValue = (key,value) => {
        setState(ostate => {
            const newState = {...ostate}
            newState[key] = value
            return newState
        })
    }
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(fetchResource({resource:'clients'}))
    },[])

    const valueRef = useRef()

    async function sendToClients(values){
        try {
            setShouldSend(2)
            const formatted_address = address.address
            const report = {
                address,
                formatted_address,
                
                ...values
            }
            // const data = {
            //     report
            // }
            const fdata = {
                // data:JSON.stringify(data),
                message:`Estimate for ${formatted_address}`,
                subject:'',
                recipients: recipients.map(r=>r.id),
                report:JSON.stringify(report),
                sender:user.id,
            }
            const form_data = new FormData()
            Object.keys(fdata).forEach(fkey => {
                form_data.append(fkey,fdata[fkey])
            })

            const config = {headers: {'Content-Type': 'multipart/form-data'}}
            const res = await Axios.post(`${$baseUrl}/messages`,form_data,config)
            $success(res)
            setShouldSend(0)

        } catch (err) {
            $error(err)
            setShouldSend(1)
        }
    }

    const values = {
        annual_rental_income: 12* Number(state.monthly_rental_income),
        
    }
     for(let i of ['',2]){
        const loan_amount = (state.purchase_price) * Number(state['ltv'+i]) /100
        values[`loan_amount`+i] = loan_amount
        values[`payment`+i] = getEMI(loan_amount,Number(state['term'+i]),Number(state['rate'+i]))
        // (state.purchase_price) / Number(state['term'+i]) * Number(state['rate'+i]) /100
    }

    const purchase_price = Number(state.purchase_price)
    // Down payment amount
    values.amount = Number(state.down_payment||'0') / 100 * purchase_price 
    values.total_payment = Number(values['payment']||'0') + Number(values['payment2']||'0')
    // values[`annual_debt`] = (state.purchase_price) / Number(state['term'+i]) * Number(state['rate'+i]) /100
    
    // Expenses
    const expenseKeys = Object.keys(expenseConfig)
    let totalExpenses = 0
    for(let e of expenseKeys){
        const field = expenseConfig[e]

        const val = Number(state[e])
        let expense = val
        const percent_of = field.percent_of 
        if(percent_of){
            const total = Number(state[percent_of]||values[percent_of])
            expense = (val/100) * total
        }
        totalExpenses += expense
        // console.log(e+' '+expense)
    }
    // console.log(totalExpenses)
    values.annual_operating_expenses = totalExpenses

    values.gross_income = values.annual_rental_income
    values.operating_expense = values.annual_operating_expenses
    values.net_operating_income = values.gross_income - values.annual_operating_expenses

    values.annual_debt = Number(values.total_payment) * 12
    values.annual_cash_flow = values.net_operating_income - values.annual_debt
    values.monthly_cash_flow = values.annual_cash_flow / 12

    values.cap_rate = (values.gross_income - values.annual_operating_expenses)*100/purchase_price

    const display_values = {...state,...values}
    for(let key in display_values){
        let val = display_values[key]
        const field = fieldConfig[key]
        
        val = val || 0
        console.log(val)
        if(field && field.percent_of){
            const percent_of = field.percent_of
            const total = Number(values[percent_of] || state[percent_of] || 0)

            const amount = Number(val)/100*total
            display_values[key+'_amount'] = format(Number(amount).toFixed(2))
        }

        const notMoney = (field && (field.isPercent ||  field.percent_of || field.notMoney))
        val = (Number(val)||0).toFixed(2)
        display_values[key] = (notMoney ? val : format(val)) 
    }

    valueRef.current = display_values

    const loanInformation = <DivWithLabel label='Loan information' className='container-fluid  flex-grow-1 '>
    
        <div className='row no-gutters'
        >
            {['st','nd'].map((p,i) => 
                {
                    const s = i ? (i+1) : ''    
                    return <React.Fragment  key={i}>
                        <div className='d-flex align-items-center col-12 col-md-411111 '>
                            <p className='text-small font-weight-500 mt-2 mb-1 '>
                                {i+1}{p} Mortgage
                            </p>
                        </div>
                        {
                            // ['ltv','loan_amount','term','rate','payment'].map(key =>
                            //     <CField name={key+s} label={$prettify(key)} key={key+s} />
                            // )
                        }
                        <CField name={'ltv'+s}       label="LTV" required={!i} />
                        <CBox name={'loan_amount'+s} label={'Loan amount'}  />
                        <CField name={'term'+s}      label={'Term'}  required={!i} />
                        <CField name={'rate'+s}      label={'Rate'} required={!i}  />
                        <CBox name={'payment'+s}     label={'Payment'}  />
                
                    </React.Fragment>
                }
            )}
        </div>
        <div className='row no-gutters mt-2'>
            {/* <div className={' d-none d-md-flex '+cfield_class} /> */}
            <CField name='down_payment' className='mr-auto' />

            <CBox name='amount'  className='col-3'/>
            <CBox name='total_payment' className='col-3' colored/>
        </div>


    </DivWithLabel>

    const expenses = <DivWithLabel label='Expenses' className='d-flex flex-wrap align-content-start flex-grow-1 ml-md-2'>

        <CField name='property_tax' />
        <CField name='hoa_fees' />
        <CField name='trash' />
        <CField name='insurance' />

        <CField name='maintenance_cost'/>
        <CField name='vacancy' />
        <CField name='property_management_cost'  col_class='col-6' />

        <CBox name='annual_operating_expenses' className='ml-auto' col_class='col-6' />

    </DivWithLabel>
    const formikRef = useRef()
    const disable_send = !( !!address ) 

    return <div className='d-flex flex-column '>
    <StateContext.Provider value={{state,setState,values}}>

        <div className='row no-gutters'>
            <div className='col-12 col-sm-6'>
                <p label='' className='text-left font-weight-500 text-secondary text-small mb-1 ' >
                    Address of the listing
                </p>
                {/* <input 
                    className='w-100 rounded-8 border-thick border-muted pl-2'
                    value={address}
                    onChange={e=>setAddress(e.target.value)}
                /> */}
                <Formik
                    initialValues={{line1:''}}
                    validationSchema={
                        yup.object({
                            line1: yup.string().required('Please pick an address'),
                        })
                    }
                    onSubmit={()=>{}}
                    innerRef={formikRef}
                >
                    {({values})=>
                        <Form>

                            <AddressField
                                name="line1"
                                formikRef={formikRef}
                                setGoogleAddress={(g_address,{formatted_address})=>{
                                    formikRef.current?.setFieldValue('line1',formatted_address)
                                    setAddress({...g_address,address:formatted_address})
                                }}
                                placeholder="Enter your address" 
                                
                            />
                        </Form>
                    }
                </Formik>
            </div>

            <div className='col-12 col-sm-6 text-primary font-weight-500 
            d-flex align-items-center justify-content-end pt-2 py-2 cursor-pointer'
                onClick={()=>!disable_send && setShouldSend(1)}
                style={{
                        ...(disable_send&&{opacity:'0.5',}),
                }}
            >
                <img src={`/images/dashboard/send-small.svg`} height='28px' />
                <p className='ml-2'>
                    send to clients
                </p>
            </div>
            
        </div>

        <div className='d-flex flex-column flex-md-row'>

            <DivWithLabel id='Pricing' className='d-flex flex-wrap flex-grow-1 '>
                
                <CField name='listing_price' required
                    handleChange={val=>{setFieldValue('purchase_price',
                        Number(state.percent_list_price)/100*Number(val)
                    )}}
                />
                <CField name='percent_list_price' required
                    handleChange={val=>{setFieldValue('purchase_price',
                          (Number(val)/100)*Number(state.listing_price)
                    )}}
                />
                <CField name='purchase_price' 
                        handleChange={val=>{setFieldValue('percent_list_price',
                            Number(val)/Number(state.listing_price)*100
                      )}}
                />

            </DivWithLabel>

            <DivWithLabel label='Income' className='d-flex flex-wrap flex-grow-1 ml-md-2'>
                <CField name='monthly_rental_income' required
                // handleChange={e=>setFieldValue('annual_rental_income',Number(e.target.value)*12)} 
                />
                <CBox name='annual_rental_income' />
            </DivWithLabel>
        </div>

        <div className='d-flex flex-column flex-md-row'>
            {loanInformation}
            {expenses}
        </div>

        <DivWithLabel label='Total to consider' className='d-flex flex-wrap '>
            {
                ['gross_income' ,'operating_expense' ,'net_operating_income' ,
                'annual_debt' ,'annual_cash_flow' ,'monthly_cash_flow' ,
                'cap_rate'
                ].map((key,i) => 
                    <CBox name={key} col_class='col-6 col-md-3 col-sm-3 ' 
                    key={key} colored={i>3} isPercent={i==6}
                    
                    />
                )
            }
            {/* <CBox name='gross_income' />
            <CBox name='operating_expense' />
            <CBox name='net_operating_income' />
            <CBox name='annual_debt' />
            <CBox name='cash_flow' />
            <CBox name='monthly_cash_flow' />
            <CBox name='cap_rate' /> */}

        </DivWithLabel>


        <MDialog
            open={!!shouldSend}
            onClose={()=>setShouldSend(0)}
            titleBar={
                <div className='d-flex p-2 align-items-center'>
                    <img  src='/images/dashboard/profile.svg' height='20px' 
                        className='mr-2' style={{filter:'brightness(0)'}}
                    />
                    <p className='font-weight-500 text-medium lead'>
                        {'Clients'}
                    </p>
                </div>
            }
        >
            <ContactPicker 
                recipients={recipients}
                contacts={clients.map(c => ({id:c.user,email:c.email,name:c.full_name||c.email}))}
                setRecipients={setRecipients}
                onClose={()=>setShouldSend(0)}
                onSubmit={()=>sendToClients(display_values)}
                loading={shouldSend==2}
                disabled={shouldSend==2}
                submitText='Send'
            />
        </MDialog>
        
    </StateContext.Provider>
    </div>
}



function getEMI(price,term,rate_percentage){

    const intr = (rate_percentage/1200);

    return price * intr / (1 - (Math.pow(1/(1 + intr), term)))


}


function format(number){
    const value = Number(number)
    const minus = value < 0
    const [whole,fraction] = String(Math.abs(number)).split('.')
    let rev_string = (whole).split('').reverse().join('')
    const len = rev_string.length
    const commas = Math.floor((len-1)/3)
    let comma_added = ''
    for(let i=0;i<commas;i++){
        comma_added += rev_string.substring(i*3,(i+1)*3)+','
    }
    comma_added += rev_string.substring(commas*3,len)
    comma_added = comma_added.split('').reverse().join('')

    let point = (fraction ? ('.'+fraction):'')
    if(point.length == 2){point += '0'}
    const sign = minus?'- ':''
    return `${sign}$${comma_added}${point}`
}

function unFormat(str){
    const regex = /[$,]/g;
    const properly_replaced = (String(str)||'0').replace(regex,'')
    return Number(properly_replaced)
}

function limitLtv2AndDownPayment(name,value,state,setFieldValue){
    // let value = state[name]

    var ltv1 = Number(state.ltv);
    var ltv2 = Number(state.ltv2)
    
    if(name == 'ltv2'){
        var remainingPercentage = 100 - ltv1;

        if (value < 0) value = 0;
        ltv2 = value
        if (ltv2 >= remainingPercentage) {
            setFieldValue(remainingPercentage,'ltv2');
            setFieldValue(0,'down_payment')
        }
        else{
            setFieldValue(100 - ltv1 - ltv2,'down_payment')
        }
    }
    else if(name == 'down_payment'){
        if (value < 0) value = 0;
        down_payment = value
        // if (value > remainingPercentage) {value = remainingPercentage;setFieldValue(remainingPercentage,'down_payment')}

        let new_ltv = 100 - down_payment
        
        if(new_ltv > 80){
            setFieldValue(new_ltv - 80,'ltv2')
            new_ltv = 80
        }else{
            setFieldValue(0,'ltv2')
        }

        setFieldValue(new_ltv,'ltv')
        
    }
    else if(name == 'ltv'){
        var down_payment = state.down_payment
        let ltv2 = state.ltv2 || 0
        ltv1 = value
        // let purchase_price = state.purchase_price
        
        const maxLtv = 80
        const remaining = 100 - ltv1

        if(ltv1 >= maxLtv){
            ltv1 = maxLtv
            setFieldValue(maxLtv,'ltv');
        }

        setFieldValue(0,'ltv2')
        setFieldValue((100-ltv1),'down_payment')


    }
}

