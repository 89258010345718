export const allowed_icons = [
    "feature",

    "air_conditioner",  
    "faucet",      
    "microwave_oven",  
    "switch",
    "bathroom",         
    "fence",       
    "oven",            
    "window",
    "door",             
    "outside",      
    "roof",
    "electrical",       
    "foundation",  
    "sink",
    "fan",              
    "water_heater",      
    "surveillance",
    "internet",       
    "security",       


    "swimming_pool",
    "carpet",
    "floor_heating",
    "porch",
    "washer",
    "garage",
    "refrigerator",
    "yard",
    "dish_washer",
    "hard_wood_floor",
    "shed",

]