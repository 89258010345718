import React from 'react'

function Mini(props){
    return <p style={{fontWeight:'bold',color:'var(--pri-light)'}}>
        {props.children}
    </p>
}

function Section(props){
    return <span style={{fontWeight:'bold',}}>
        {props.children}
    </span>
}
function Email(props){
    return <a href="mailto:support@micasa.ai?subject=Help" className=' ml-1'>
        {props.children}
    </a>
}

const disclaimer = <p>
    The Content included in this Web site has been compiled from a variety of
    sources and is subject to change without notice as are any services, programs,
    offerings, or technical information described in this Web site. micasa.ai makes no
    representation or warranty whatsoever regarding the completeness, quality, or
    adequacy of the Web site or Content, or the suitability, functionality, or operation
    of this Web site or its Content. By using or submitting information about your
    product or services to this Web site, you assume the risk that the Content on this
    Web site may be inaccurate, incomplete, offensive, or may not meet your needs
    and requirements. micasa.ai SPECIFICALLY DISCLAIMS ALL WARRANTIES, EXPRESS
    OR IMPLIED.
</p>

export function Terms(){
    return <div style={{fontSize:'0.8em'}}>
    <Mini>
        Scope of Terms of Use
    </Mini>
    <p>
        These terms of use (“Terms of Use”) are applicable to all websites (referred to
        hereafter as the &quot;Websites&quot;) created, generated, provided, maintained, managed,
        and/or hosted by micasa.ai. and/or its worldwide direct or indirect subsidiaries
        and affiliates (hereinafter collectively referred to as “micasa.ai”), regardless by
        themselves or through any third party vendors or contractors. These Terms of Use
        also apply to any contents, resources, materials contented in, made available by
        and/or provided through the Websites, including but not limited to software,
        manuals, reports, articles, services, press releases, graphics, texts, multimedia
        materials, related websites, and product information (collectively referred to
        hereafter as the &quot;Contents&quot;).
    </p>
     <Mini>
     Acceptance of Terms of Use
    </Mini>
    <p>
        Please carefully review these Terms of Use before using these Websites. By using
        or accessing these Websites and/or Contents, you acknowledge you agree to be
        bound by these Terms of Use and to comply with all applicable laws and
        regulations, including without limitation applicable privacy laws and regulations
        regarding the transmission of technical data from the country in which you reside.
        If you do not agree to any of these terms of use, please do not use the websites
        and the contents.
    </p>
     <Mini>
     Ownership and Intellectual Property Right
    </Mini>
    <p>
        The Websites and all Contents are protected by copyright and are owned or
        controlled by micasa.ai
        You acknowledge that micasa.ai owns all legal rights, titles and interests in and to the
        Websites and Contents, including any micasa.ai trade names, trademarks, logos,
        domain names, and other distinctive brand features therein (whether those rights
        happen to be registered or not, and wherever in the world those rights may exist),
        and that they are protected by worldwide copyright laws and treaty provisions. You
        may not reproduce, modify, copy, distribute, transmit, display, perform, publish,
        license, create derivative works from, transfer, or sell any Contents, and/or any other
        information, software, products or services obtained from the Websites without
        micasa.ai’s prior written permission.
        micasa.ai does not grant any express or implied right to you under any intellectual
        property rights, including but not limited to patents, copyrights, trademarks, or
        trade secret.
    </p>
    <Mini>
        Restrictions of Use
    </Mini>
    <p>
        You are restricted from using Content and/or the Website in the following
        manner:
        <br/>
        a) You may not use micasa.ai’s logo, trade names, wording, graphics, trademarks
        (whether registered or un-registered) or any other element or elements that
        comprise the Websites&#39; and/or Contents’ general &quot;look and feel&quot; in other website
        or any other work.
        <br/>
        b) You may not use the Websites and/or Content to produce material that is
        unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar,
        obscene, libelous, invasive of another&#39;s privacy, hateful, or racially, ethnically or
        otherwise of an offensive or adult nature.
        <br/>
        c) You may not use the Websites and/or Contents to molest or otherwise harass
        or harm another;
        <br/>
        d) You may not collect or store any personal information about other users or
        entities.
        <br/>
        e) You may not use the Websites and/or Contents in any manner that could
        damage, disable, overburden, or impair any micasa.ai server, or network(s)
        connections, disobey any requirements, procedures, policies or regulations of
        networks connected to the Website and/or Contents or interfere with any other
        party&#39;s use and enjoyment of the Websites and/or Contents.
        <br/>
        f) You may not attempt to gain unauthorized access to any Websites and/or
        Contents, other accounts, computer systems or networks connected to any
        micasa.ai server, Websites and/or Contents, through hacking, password mining or
        any other means or obtain or attempt to obtain any materials or information
        through any means not intentionally made available through the Websites and/or
        Contents.

    </p>
    <Mini>
        Disclaimer of Liability
    </Mini>
    <p>
        You expressly understand and agree that your use of the websites and contents is
        at your sole risk and that the websites and contests are provided &quot;as is&quot; and &quot;as
        available&quot; without any express or implied warranty of any kind including
        warranties of merchantability, noninfringement of intellectual property, of fitness
        for any particular purpose.
        Any contents downloaded or otherwise obtained through the use of the websites
        is done at your own discretion and risk and you will be solely responsible for any
        damage to your computer system or other device or loss of data that results from
        the download or access of any such contests.
        You expressly understand and agree that micasa.ai shall not be liable to you for
        any loss or damage which may be incurred by you, including but not limited to
        loss or damage as a result of any reliance placed by you on the completeness,
        accuracy or existence of any contents, or any changes micasa.ai may make to the
        services, products, documents, websites, contents or for any permanent or
        temporary cessation in the provision of the websites (or any features within the
        websites).
        In no event shall micasa.ai be liable for any direct, indirect, special or

        consequential damages or any damages whatsoever resulting from loss of use,
        data or profits, whether in an action of contract, negligence or other tortious
        action, arising out of or in connection with the performance, use or inability to
        use any software, documents, contents, information, or the websites.

    </p>
    <Mini>
        Revisions
    </Mini>
    <p>
        micasa.ai reserves the right to modify, suspend, withdraw or discontinue,
        temporarily or permanently, the Websites and/or the Contents, in whole or in
        part, at any time without notice. You acknowledge and agree that micasa.ai shall
        not be liable to you or to any third party for any modification, suspension,
        withdrawal or discontinuance of the Contents.
        micasa.ai may at any time revise and/or update these Terms of Use at its sole
        discretion. By using the Websites and/or accessing to the Contents, you agree to
        be bound by any such revisions and/or updates.
    </p>
    <Mini>
        Termination of Use and Indemnification
    </Mini>
    <p>
        Your rights to use the Websites and/or the Contents will be terminated
        automatically without notice if you fail to comply with the Terms of Use. In this
        regard, micasa.ai will not bear any responsibility to you.
        You agree to indemnify and hold micasa.ai and its officers, agents, employees,
        partners and licensors harmless from any claim or demand, including reasonable
        attorneys&#39; fees, made by any third party due to or arising out of your use of the
        Websites, access to the Contents, connection to the Websites, and/or your
        violation of these Terms of Use.

    </p>
    <Mini>
        General
    </Mini>
    <p>
        These Terms of Use constitute the entire agreement between you and micasa.ai
        and govern your use of the Websites and/or Contents.
        The Terms and the relationship between you and micasa.ai shall be governed by
        the laws of Washington, USA. If any provision of these Terms of Use is found by a
        court of competent jurisdiction to be invalid, the parties nevertheless agree that
        the court should endeavor to give effect to the parties&#39; intentions as reflected in
        the provision, and the other provisions of the Terms of Use remain in full force
        and effect.
        In the case of any violation of these rules and regulations, micasa.ai reserves the
        right to seek all remedies available by law and in equity for such violations.
    </p>
    </div>
}


export function Privacy(){

    return <div style={{fontSize:'0.8em'}}>
    <Mini>
        Proprietary Rights
    </Mini>
    <p>
        We understand the importance of online privacy to our Business Customers, so
        we have established this Privacy Policy to let you know what information we may
        collect from you on as part of our business model and how we may use and share
        that information. Please take a moment to review the terms of our Privacy Policy.
        By using our website or providing information regarding your business or
        property to micasa.ai, you agree to and accept the terms of our Privacy Policy.

    </p>
    <Mini>
    Personal Information
    </Mini>
    <p>
    <Section>Information We May Collect</Section>  “Personal Information” means any information that
    may be used to identify an individual, including, but not limited to, a first and last
    name, a physical address, a phone number or an email address, and your
    communication preferences.
    Further, if we contact you by email or other means, we will collect certain
    Personal Information. We may also collect certain limited information about your
    interests and preferences (“preferences information”), such as your businesses
    and property and related information. If we ever use any preferences information
    together with any Personal Information, then such preferences information will
    be treated as Personal Information under this Privacy Policy.
    <br/>
    <Section>How We May Use Your Information</Section> If you provide us with Personal Information,
    we will retain and use that information for certain limited purposes. For example,
    we will use your Personal Information in our Company database and by providing
    this rich data, you are consenting that this may further be disseminated to other
    businesses or to people who access our database. If you would like to understand
    more about our business and the data we collect and keep, please do not hesitate
    to contact us via email at <Email>support@micasa.ai</Email>. We reserve the right to send you
    certain communications relating to the Business database updates, such as
    service announcements or similar administrative or transactional messages,
    without offering you the opportunity to opt out of receiving them.
    <br/>
    <Section>With Whom We May Share Your Information</Section> We may sometimes use other
    businesses to perform certain services for us, such as maintaining the Site and our
    mailing lists, sending postal mail (if necessary) and providing marketing assistance
    and data analysis. We may provide Personal Information to those businesses
    when that information is necessary for them to complete a requested transaction
    or otherwise perform their duties. micasa.ai will take reasonable steps to help
    ensure that these third-party service providers are obligated to protect Personal
    Information on micasa.ai’s behalf.
    We reserve the right to disclose visitor information to our website in special cases
    when we have reason to believe that disclosing this information is necessary to
    identify, contact or bring legal action against someone who may be causing injury
    to or interference with (either intentionally or unintentionally) our rights or
    property, other visitors, or anyone else that could be harmed by such activities.
    We also reserve the right to disclose visitor information when we believe that the
    law requires it.
    <br/>
    <Section>Aggregate Information</Section>
    From time to time, we may collect general, non-personal, statistical information
    about the use of our database, website etc., such as how many visitors visit a
    specific page on the Site, how long they stay on that page and which hyperlinks, if
    any, they “click” on. We collect this information through the use of “cookies” and
    other tracking technologies, which are discussed in greater detail below. We
    collect this information in order to determine which areas of the Site are most
    popular and to enhance the Site for visitors. We may group this information into
    aggregate visitor data in order to describe the use of the Site to our existing or
    potential business partners or other third parties, or in response to a government
    request. We may also group preferences information that we collect from visitors
    into aggregate data for the same purposes. However, please be assured that this
    aggregate data will in no way personally identify you or any other visitors to the
    Site.
    </p>

    <Mini>
    Cookies and Other Tracking Technologies
    </Mini>
    <p>
    Some of our Web pages utilize “cookies” and other tracking technologies. A
    cookie is a small text file that a Web site transfers to a hard drive for record-
    keeping purposes. For example, we may use cookies to collect information about
    website activity. Most browsers allow you to control cookies, including whether
    or not to accept them and how to remove them. You may set most browsers to
    notify you if you receive a cookie, or you may choose to block cookies with your
    browser. However, please be aware that some features of the Site may not
    function properly or may be slower if you refuse cookies. Tracking technologies
    may record information such as Internet domain and host names; Internet
    protocol (IP) addresses; browser software and operating system types;
    clickstream patterns; and dates and times that our Site is accessed. An IP address
    is a number that is automatically assigned to your computer whenever you are
    surfing the Web. Web servers, the big computers that “serve up” Web pages,
    automatically identify your computer by its IP address. It is not our practice to link
    the information we record using cookies or other tracking technologies to any
    Personal Information you submit while on the Site. However, we reserve the right
    to use cookies, IP addresses and other tracking technologies to identify a visitor
    when we believe it is necessary to enforce compliance with the Site’s policies, to
    protect the Site, our visitors, clients or others, or when we believe that the law
    requires it.
    </p>
    <Mini>
    Security
    </Mini>
    <p>
        We intend to take reasonable and appropriate steps to protect the Personal
        Information that you share with us from unauthorized access or disclosure. When
        you transmit Personal Information to our database, your information is protected
        by both a “firewall”
        (a combination of computer hardware and software that helps keep unauthorized
        visitors from accessing information within our computer network) and industry
        standard SSL (Secure Socket Layer) encryption. Unfortunately, no data
        transmission over the Internet can be guaranteed to be 100% secure. As a result,
        while we strive to protect your Personal Information and privacy, we cannot
        guarantee or warrant the security of any information you disclose or transmit to
        us online and cannot be responsible for the theft, destruction or inadvertent
        disclosure of your Personal Information.
    </p>
    <Mini>
        Children’s Privacy
    </Mini>
    <p>
        micasa.ai is not intended for use and does not engage in business with
        minors/children under 13. We do not knowingly collect Personal Information
        from anyone under 13 years of age. If you are under 13, please do not submit any
        Personal Information on our website or to any of our agents.
    </p>
    <Mini>
        Changes to Our Privacy Policy
    </Mini>
    <p>
        The terms of this Privacy Policy may change from time to time. We will notify you
        of any material changes to this Privacy Policy and your continued use of our
        website and updates to our database following the posting of changes to these
        terms will mean you accept those changes.
    </p>
    <Mini>
        Governing Law
    </Mini>
    <p>
        This Privacy Policy and the privacy practices of micasa.ai are subject exclusively to
        the laws or rules of the State of Washington within the United States of America.
        micasa.ai makes no representation that this Privacy Policy and such practices
        comply with the laws of any other country. Visitors who use the Site and reside
        outside the United States do so on their own initiative and are responsible for
        compliance with local laws, if and to the extent local laws are applicable.
        Contacting Us
        If you have any comments or questions regarding our Terms of Use or Privacy
        Policy, please contact micasa.ai at <Email>support@micasa.ai</Email>. All rights reserved
    </p>
    <Mini>
        DISCLAIMER
    </Mini>
   
    {disclaimer}

    

</div>
}


export function Disclaimer(){

    return <div style={{fontSize:'0.8em'}}>
        <h4 style={{color:'var(--pri)'}}>
            Disclaimer
        </h4>
        {disclaimer}

    </div>

}