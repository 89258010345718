import React, { useEffect, } from 'react'
import HomeOwnerLandingPage from './HomeOwnerLanding'
import AgentLandingPage from './AgentLanding'
import { useLocation } from 'react-router-dom'
import qs from 'querystring'

import EmailVerifier from './auth/EmailVerifier'
import {getPricing,fetchPlans} from '../app/user/userSlice'
import { useSelector, useDispatch, } from 'react-redux'

export default function Landing(props){
    const {userType} = props
    const agent = 'agents'

    const location = useLocation()
     
    const pricing = useSelector(getPricing)
    const dispatch = useDispatch()

    useEffect(()=>{
        if(Array.isArray(pricing) && pricing.length === 0){
          dispatch(fetchPlans())
        }
    },[])
          
    useEffect(() => {

        const query = qs.parse(location.search.replace('?',''))
        
        // if(location.pathname=='/reset_password'){}
    
        if(props.userTypes.includes(query.for) && query.for !== userType){
             props.switchUserType(query.for)
        }
        

      }, []);

      const cprops =  {displaySignIn:props.displaySignIn,pricing,handleSignInDialog:props.handleSignInDialog}
      
    return <div className='flex-grow-1 d-flex flex-column '>
      {
        ( userType === agent?
          <AgentLandingPage {...cprops} />:
          <HomeOwnerLandingPage {...cprops}/>
        )
      }

      <EmailVerifier withDialog/>

    </div>

        

}