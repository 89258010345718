import { Edit } from '@material-ui/icons'
import React from 'react'
import { $prettify } from '../../utils'

export default function FieldWithLabel({label,editable,onEdit,required,withBox,...props}){
    return <div className={`d-flex flex-column ${props.className} `}>
        <div className='d-flex my-1 align-items-center'>
            <p className='my-0 text-small text-secondary
             text-left font-weight-500'
            >
                {$prettify(label||'label')}
            </p>
            {editable && 
                <Edit fontSize='small' 
                    // style={{fontSize:"0.98rem"}} 
                    className='ml-1 text-primary cursor-pointer opacity-90'
                    onClick={onEdit}
                />
            }

            <p className='text-small text-muted  mb-0 ml-auto'>
            {required?<span>Required*</span>:null}
            </p>
        </div>
        {withBox?
            <div className='d-flex rounded-8 border-thick 
            p-2 text-small font-weight-500 text-secondary'>
                {props.children}
            </div>
        :props.children}
    </div>  
}