import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, CircularProgress, Divider, Drawer, Menu, TextField, useMediaQuery, useTheme, } from '@material-ui/core'
import { Add, ArrowBack, CheckBoxOutlined, Close, ExpandMore, MailOutlined } from '@material-ui/icons'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../app/auth/authSlice'
import { fetchMessages,selectServices,selectMessages, selectQuotes, userTypes, markNotificationAsRead,
     fetchResource, selectContractors, selectClients,setMessages, getNotifications } from '../../app/user/userSlice'
import MDialog from '../../components/common/MDialog'
import { $alert, $baseUrl, $error, $success } from '../../utils/http'
import { ActionButtons } from '../dashboard/FeatureEditor'
import { fetchFeatures, fetchFeatureSchema, fetchHouse, fetchQuotes, fetchServices, getHouse } from '../../app/user/userSlice'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'querystring'
import { padZero } from '../../utils'
import { prettyTime, safe_parse } from '../../components/special/NotificationCard'
import { DocumentUploader } from '../../components/common/DocumentUploader'
import { clientFilterer } from '../dashboard/agent/Clients'
import { getThreads } from '../../utils/helpers'

export default function Messages(props){
    const [LOADING,SAVING] = '123456'.split('').map(n=>Number(n))
    const [loading,setLoading] = useState(0)

    const [boxType,setBoxType] = useState(1)

    const theme = useTheme()
    const isMobile =  useMediaQuery(theme.breakpoints.down('xs'))

    const [thread,setThread] = useState(!isMobile)
    const [picker,setPicker] = useState(0)

    const user = useSelector(getUser)
    const dispatch = useDispatch()
    
    const quotes = useSelector(selectQuotes)
    const services = useSelector(selectServices)

    const clients = useSelector(selectClients)
    const contractors = useSelector(selectContractors)
    const notifications = useSelector(getNotifications)

    const initialFormData = {subject:'',message:''}
    const [recipients,setRecipients] = useState([])
    const [formData,setFormData] = useState({...initialFormData})
    const [filesPicked,setFilesPicked] = useState([])
    const messages = useSelector(selectMessages)
    const history = useHistory()

    const [startTyping,setStartTyping] = useState(/*!isMobile*/false)
    const boxTypes = ["Inbox","Outbox"]

    const location = useLocation()
    const query = qs.parse(location.search.replace('?',''))

    const service = query.service
    const checklist = query.checklist

    useEffect(()=>{
        const user_type = user.user_type

        const resources = []
        
        resources.push(
            dispatch(fetchMessages()),
            dispatch(fetchResource({resource:'quotes'})),  
            dispatch(fetchServices())  
        )

        if(user_type == userTypes.agent){
            resources.push(
                dispatch(fetchResource({resource:'clients'})),
                dispatch(fetchResource({resource:'contractors'}))
            )
        }

        if(!messages.length){
            setLoading(LOADING)
            Promise.all(resources).then(res => {
    
            }).catch(err => {
                console.log('Error loading messages!!')
            }).finally(()=>{
                setLoading(0)
            })
        }

        if(checklist){
           setPicker(1) 
        }

    },[])

    useEffect(()=>{
        setFilesPicked([])
    },[thread])

    useEffect(()=>{
        if(thread){
            const new_thread = threads.find(t => t.key == thread.key)
            if(new_thread){
                setThread(new_thread)
            }
        }
    },[messages])

    async function readAllUnreadMessages(sender){
        const unread_messages = []
        const read_by = `${user.id}`
        
        const new_messages = [...messages].filter(m => m.sender == sender)
        const thread_message_ids = new_messages.map(nm => nm.id)

        for(let i in new_messages){
            const m = new_messages[i]
            const read_by_me = m.read_by.split(',').map(n=>+n).includes(user.id)
            if(!read_by_me && m.sender != user.id){
                unread_messages.push(m)
                new_messages[i] = {...m,read_by}
                console.log('reading message!!')

            }
        }

        if(unread_messages.length){
            dispatch(setMessages(new_messages))
        }

        for(let m of unread_messages){
            console.log('reading real message!!')
            try {
                const res = await Axios.patch(`${$baseUrl}/messages/${m.id}`,{read_by})
            } catch (error) {
                console.log(error)
            }
        }
        
        const unread_notifications = notifications.filter(n=>!n.is_read&&n.message_type=='message').map(n => {
            const data = safe_parse(n.data)
            const message_id = Number(data?.message_id || 0)
            return {
                id:n.id,
                message_id,
            }
        }).filter(n => thread_message_ids.includes(n.message_id))

        for(let un of unread_notifications){
            dispatch(markNotificationAsRead(un.id))
        }
    }

    const setActiveThread = (t,clear=true) => {
        if(t == true){
            discardMessage()
        }

        setThread(t)

        if(clear && (query.service || query.to || query.id)){
            history.replace(location.pathname)
        }

        readAllUnreadMessages(t.sender)
    }

    let contacts = []
    let temp_contacts = []
    {
        const utype = user.user_type 
        
        if(utype == userTypes.agent){
            const c_contractors = contractors.map(c => ({
                name:c.full_name,
                email:c.email,
                id:c.contractor,
            }))
            const c_clients = clients.map((c,i) => ({
                name:c.full_name,
                email:c.email,
                id: c.user,
            }))
            if(checklist){
                temp_contacts = c_clients
            }else{
                temp_contacts = [...c_clients,...c_contractors]
            }
            temp_contacts = temp_contacts.filter(c=> c.id != null)
        }
        else if(utype == userTypes.contractor){
            temp_contacts = quotes.map(q => ({
                name:q.service.client,
                email:q.service.client_email,
                id:q.client,
            }))
        }else{
            // home owner
            const flatQuotes = []
            services.forEach(s => {
                const mquotes = [...s.quotes]
                for(const q of mquotes){
                    if(!flatQuotes.some(tq => tq.contractor == q.contractor)){
                        flatQuotes.push(q)
                    }
                }
            })
            temp_contacts = flatQuotes.map(q => ({
                name:q.provider.full_name,
                email:q.provider.email,
                id:q.contractor,
            }))
        }
        for(let c of temp_contacts){
            if(!contacts.some(tc => tc.id == c.id)){
                contacts.push(c)
            }
        }
    }

    useEffect(()=>{
        const query = qs.parse(location.search.replace('?',''))
        const message_id = query.id
        const service_id = query.service

        if(messages.length){

            if(message_id){
                const new_thread = threads.find(t => t.messages.some(m=> m.id == message_id))
                if(new_thread){
                    setActiveThread(new_thread,false)
                }
            }
 
        }
        const target = query.to
        if(target){
            if(service_id){
                setFormData({...initialFormData,subject:`RE: Service#${padZero(service,3)}`})
            }
            
            const target_thread = threads.find(t => t.contact && (t.contact.id == target))
            if(target_thread){
                setActiveThread(target_thread,false)
            }else{
                setThread(true)
            }

            const user = contacts.find(c=>c.id == target)
            // console.log(contacts)
            if(user){
                setRecipients([user])
            }
        }

    },[messages,location])

    
    const threads = getThreads(messages,user)

    async function sendMessage(){
        try {
            setLoading(SAVING)

            let data = null

            const fdata = {
                ...formData,
                sender:user.id,
            }

            if(service){
                data = {service}
            }else if (checklist){
                const [i,n] = checklist.split('_')
                data = {checklist}
                fdata.checklist = i
            }
            fdata.data = JSON.stringify(data)

            // Responding to existing thread
            if(thread && thread !== true){
                if(thread.contact){
                    fdata.recipients = [thread.contact.id]
                }else{
                    fdata.recipients = thread.recipients
                }
            }else{
                // Creating new broadcast message thread.
                fdata.recipients = recipients.filter(r=>r.id>=0).map(r=>r.id)
            }

            const form_data = new FormData()
            Object.keys(fdata).forEach(fkey => {
                form_data.append(fkey,fdata[fkey])
            })

            if(filesPicked.length){
     
                form_data.append(`file_count`, filesPicked.length)
                for(let i in filesPicked){
                    form_data.append(`file_${i}`, filesPicked[i].file)
                }
                
                form_data.append(`file`, filesPicked[0].file)
                // form_data.append('filename', filesPicked.name)
            }

            const config = {headers: {'Content-Type': 'multipart/form-data'}}
            const res = await Axios.post($baseUrl+`/messages`,form_data,config)
            const new_message = res.data.data 
            if(new_message){
                dispatch(setMessages([...messages,new_message]))
                const key = recipients.map(r=>r.id).slice().sort((a,b)=> a-b).join('_')
                let contact = recipients.length == 1 ? {...recipients[0]} :null
                const existing_thread = threads.find(t=>t.key == key) || {}
                const new_thread = {
                    ...new_message,
                    key,
                    contact,
                    messages:[new_message,...(existing_thread.messages||[])]
                }  
                console.log(new_thread)
                setThread(new_thread)
            }
            discardMessage()
            // dispatch(fetchMessages())
            setFormData({...initialFormData})
            setFilesPicked([])
            // $success(res)

        } catch (err) {
            $error(err)
        }
        finally{
            setLoading(0)
        }
    }
    // const [showDrawer,setShowDrawer] = useState(false)

    let messageArea = <p className=' text-medium text-muted  my-auto mx-auto'>
        {threads.length?
        `Please pick a message to view it`:
        `There are no new messages`
        }
    </p>
    const inputProps = {
        // variant:'',
        // size:'small',
        className:'my-2',
    }
    const btnClass = 'text-primary  bg-white d-flex align-items-center text-small p-2 my-2'

    const capsuleClass = `p-2 border-thick rounded-8 mr-1 px-3 text-medium  `
    const backButton = <button className='d-sm-none pl-1 bg-transparent'
        onClick={()=>setThread(null)}
    >
        <ArrowBack/>
    </button>
    const personIcon = <img src='/images/dashboard/profile.svg' 
    height='20px' className='mr-2 ml-1' style={{filter:'brightness(0)'}} />
    const rlen = recipients.length
    const peeps = isMobile? recipients.slice(0,1) : recipients

    const recipientChooser = <div className={` d-flex align-items-center
     cursor-pointer hover-light rounded-8 p-2 py-2 flex-wrap mw-100`}
        onClick={()=>setPicker(1)}
    >
        <img src="images/dashboard/recipients.svg" 
            height='24px' className='mr-2 ml-1' 
        />

        {rlen?

            peeps.map((r,i) => 
                <div className={` p-2 ${capsuleClass}  d-flex mb-1`}
                    style={{borderRadius:'36px'}} key={i}
                >
                    <p className='text-wrap  ml-1 font-weight-500 text-dark'>
                        {r.name}
                    </p>

                    {isMobile && recipients.length>1 && 
                        <span className='ml-1 text-muted text-small'> 
                            {` and ${recipients.length-1} others` }
                        </span>
                    }
                </div>    
            )
        :   
            <p className='ml-2 mt-2 mb-1 text-primary text-medium'>
                Choose recipients
            </p>
        }
    </div>


    const documentUploader = <DocumentUploader 
        isButton
        buttonOnly
        label={isMobile?'Upload file/picture':''}
        noBorder
        className='border-0'
        onFilePicked={(name,file,errors)=>{
                if(file){
                    setFilesPicked(
                        [   
                            ...filesPicked.filter(f=>!f.errors || !f.errors.length),
                            {name:name.split('\\').slice(-1)[0],file,errors}
                        ]
                    )
                }

            }
        }
    />
    const file_errors = filesPicked.reduce((errs,f) => [...errs,...f.errors] ,[])
    const file_error_messages = !!file_errors.length && 
        <p className='text-danger text-small ml-2 text-left'>
            {file_errors.join(',')}
        </p>

    const filesThatArePicked = <div >
        <div className='d-flex align-items-end flex-wrap mr-auto mb-2'
        >   
            {filesPicked.slice().reverse().map((f,i) =>
                <FileBadge 
                    key={f.name+i}
                    source={f}
                    badOne={f.errors && f.errors.length}
                    onClear={()=>setFilesPicked(filesPicked.filter(tf=>tf!=f))} 
                />
            )}

            {thread && thread != true &&
                <ServiceButton service={(service)} className='ml-auto' 
                    quotes={quotes} user={user}
                />
            }
            {thread && thread!= true &&
                <ChecklistButton checklist={checklist} />

            }
        </div> 
        {file_error_messages}
    </div>

    function  discardMessage() {
        setRecipients([]);
        setFilesPicked([]);
        setFormData({...initialFormData});
        history.replace(location.pathname)
    }

    const message_stripped = (formData.message && formData.message.trim())
    if(thread === true){
        messageArea = <div className='d-flex flex-column px-3 pt-1 pb-2 overflow-auto'>
            <div className='d-flex text-left font-weight-500 text-muted'>
                {backButton}
                
                {recipientChooser}
            </div>
            <div className='d-flex flex-column border-thick rounded-8 my-2 overflow-hidden'>
                {/* {user.user_type == userTypes.agent && 
                <React.Fragment>
                    <div className='d-flex align-items-center '>
                        <p className='text-small text-muted ml-3 mr-2'>
                            Subject:
                        </p>
                        <input
                            // label='Subject'
                            placeholder="Type you subject"
                            {...inputProps}
                            value={formData.subject}
                            onChange={e=>setFormData({...formData,subject:e.target.value})}
                            className='border-0 rounded-0 my-2 border-bottom p-2 text-small flex-grow-1'
                            style={{outline:'none'}}
                        />
                    </div>
                    <Divider />
                </React.Fragment>
                } */}
                <textarea
                    {...inputProps}
                    placeholder='Start typing your message here'
                    rows={isMobile?4:6}
                    style={{resize:'none',outline:'none'}}
                    value={formData.message}
                    onChange={e=>setFormData({...formData,message:e.target.value})}
                    className='big border-0 rounded-0  p-3 text-small'
                />

                {filesThatArePicked}

                <div className='d-flex align-items-center 
                justify-content-between pr-2 p-2'
                >

                    {documentUploader}
                    <ServiceButton service={(service)} />
                    <ChecklistButton checklist={checklist} />

                </div>

            </div>


            <ActionButtons
                backText='Discard'
                nextText='Send'
                disable_back={!(message_stripped||formData.subject||recipients.length||checklist)}
                disabled={file_errors.length||!recipients.length||!message_stripped
                    // ||(recipients.length>1 && !formData.subject)
                }
                onNext={sendMessage}
                onBack={discardMessage}
                loading={!!loading}
                flip_x
            />

        </div>
    }else if(thread){
        const rlen = thread.recipient_list.length
        const first_one = thread.recipient_list[0]
        const peepl = []
        if(thread.recipient_list && thread.recipient_list.length){
            peepl.push(...thread.recipient_list)
        }else{
            peepl.push(thread.message_from)
        }
        const tpeepl = isMobile ? peepl.slice(0,1) : peepl

        messageArea = <div className='text-left p-1 px-sm-3 d-flex flex-column
         overflow-hidden flex-grow-1'
        >
            <div className='d-flex py-1 py-sm-2 align-items-center flex-wrap'>
                {backButton}
                {/* <p className='text-medium mr-2 font-weight-500 text-muted'>
                    From
                </p> */}

                {
                    tpeepl.map((p,i) => 
                        <div className={` p-2 ${capsuleClass}  d-flex `}
                            style={{borderRadius:'36px'}}
                            key={i}
                        >
                            <p className='text-wrap  ml-1 font-weight-500 text-dark'>
                                {p.name}
                            </p>

                            {isMobile && peepl.length>1 && 
                                <span className='ml-1 text-muted text-small'> 
                                    {` and ${peepl.length-1} others` }
                                </span>
                            }
                        </div>
                    )
                }
            </div>

            <Divider className=' mb-211 d-none d-sm-block' />

            <div className={`d-flex flex-column-reverse ${isMobile?'overflow-auto ':'overflow-auto '} 
                px-1222 border-thick11 rounded-8111 border-bottom py-1 pl-1 pr-2 pr-sm-1 `}
                // style={{backgroundColor:'#f3f3f3'}}
            >
                {
                    thread.messages.map(m=>
                        <MessageBubble 
                            message={m} 
                            isMobile={isMobile} 
                            thread={thread}
                            key={m.id}
                        />
                    )
                    
                }
            </div>
            
            {(startTyping||service)
            ?
            <div className='rounded-8 border-thick pb-2 mt-2'>

                <textarea 
                    placeholder='Start typing your message here'
                    value={formData.message} 
                    className='border-0 w-100 text-medium p-2'
                    style={{minHeight:'4em',outline:'none'}}
                    onChange={e=>setFormData({...formData,message:e.target.value})}
                />

                {filesThatArePicked}

                <div className='d-flex justify-content-between align-items-center mx-2'>
                    {documentUploader}

                    <ActionButtons 
                        noPadding
                        backText='Discard'
                        nextText='Send'
                        onBack={()=>setStartTyping(false)}
                        loading={!!loading}
                        disabled={!formData.message||file_errors.length}
                        
                        onNext={()=>{
                            sendMessage()
                            // setActiveThread(true);
                            // setBoxType(1);
                            // setRecipients([thread.message_from])
                        }}
                    />
                

                </div>

            </div>
            :
            <div className='cursor-pointer rounded-8 border-thick mt-2 p-2
             text-primary text-medium font-weight-500'
                    onClick={()=>setStartTyping(true)}
            >
                Type a new message
            </div>
            }
            


   
        </div>
    }


    const boxSwitcher = <div className='d-flex  '>
        {boxTypes.map( (t,i) => 
            <div 
                key={t}
                className={` flex-grow-1 px-3 py-2 py-3 font-weight-500 
                cursor-pointer border-bottom text-center border-bottom  
                ${boxType==i?'border-primary  text-primary':'text-muted'} `}
            >
                {t}
            </div>
        )}
    </div>
    

    const messageListColumn =  <div className='d-flex flex-column border-right 
    text-left overflow-auto flex-grow-1'
    >
        <div className='border-bottom '>

            <h4 className='px-sm-4 p-3 font-weight-bold m-0'>
                Messages
            </h4>

        </div>

        <div className='d-flex overflow-auto flex-grow-1 flex-column '
         
        >
            <div className={` d-flex p-3 cursor-pointer hover-light
            text-medium font-weight-500
                ${(thread == true)?'bg-primary text-white':'text-primary'}
            `}
                onClick={()=>{setActiveThread(true);}}
            >
                <Add className='mr-2'/>
                New message
            </div>
                
            {!!loading && !messages.length && 
                <div className='d-flex flex-column align-items-center p-4 my-5'>
                        <CircularProgress color='primary' />
                </div>
            }

            {/* <div className='d-flex flex-column flex-grow-1 overflow-auto'> */}

                {threads.map(t => 
                {
                    const is_active = thread && (thread.key == t.key)
                    const sender = (t.contact && t.contact.name)||'Micasa'

                    let people = sender
                    if( t.recipients && t.recipients.length){
                        const peeps = t.recipient_list
                        const first_one = peeps[0].name
                        people = <span className=''>
                            {peeps.length == 1 ? null :
                            <span className={`mr-1 p-2 text-small rounded-extra `}
                                style={{backgroundColor:'#00000020'}}
                            >
                                {peeps.length}
                            </span>
                            }   
                            {peeps.map(r=>r.name).join(',')}

                            {/* <span className='text-truncate mr-2'>
                            {first_one} 
                            </span>
                            
                            and {peeps.length-1} others
                            */}

                        </span>
                    }
                    const messages = t ? t.messages : []
                    const latest_message = messages.length && messages[0]
                    let caption = ''
                    if(latest_message){
                        caption = (!user && latest_message.subject) || latest_message.message
                    }
                    return <div key={t.key}
                        className={`p-3 pl-4 border-top
                            text-medium cursor-pointer hover-light  overflow-hidden
                            ${(thread && thread.key == t.key)?'bg-primary text-white':''} 
                        `}
                        style={{maxWidth:(isMobile?'100vw':'')}}
                        onClick={()=>setActiveThread(t)}
                        >
                            
                            <p className='font-weight-500 text-truncate '
                            >
                                {people}
                            </p>
                            
                            <p className={`text-small  text-truncate mt-1
                                ${is_active?'text-white':'text-secondary'} `}
                            >
                            {caption}

                            </p>
                        </div>
                    }  
                )}
                    
            {/* </div> */}
            
            {/* {(new Array(18).fill(1).map((a,i)=>
                <p key={i}>
                    papa
                </p>
            ))} */}

        </div>

    </div>

    const mobileViewer = <div className='d-flex flex-column d-sm-none flex-grow-1'>

        {!thread?
            messageListColumn:

            <div className={`d-flex flex-column ${isMobile?'overflow-hidden':'overflow-hidden'} flex-grow-1`}>
                {messageArea}
            </div>
        }

    </div>
    const desktopViewer = <div className='d-none d-sm-flex flex-grow-1 '>

        <div className='d-flex flex-column ' style={{width:'var(--message-panel-width)'}} >
            {messageListColumn}
        </div>
    
        <div className='d-flex flex-column overflow-hidden flex-grow-1'>
            {messageArea}
        </div>

    </div>


    return <div className={` mt-1 border-top border-left border-right
    rounded-8 container px-0 flex-grow-1  ${isMobile?'overflow-hidden':'overflow-hidden'}  d-flex`}
        // style={{display:'grid',gridTemplateColumns:'1fr 2.4fr'}}
        style={{borderBottomLeftRadius:'0',borderBottomRightRadius:'0'}}
    >
        {mobileViewer}
        {desktopViewer}
        {/* <Drawer open={showDrawer}
            onClose={()=>setShowDrawer(false)}
            // style={{width:'8em'}}
        >
            {messageListColumn}
        </Drawer> */}

        <MDialog
            open={!!picker}
            onClose={()=>setPicker(0)}
            titleBar={
                <div className='d-flex p-2 align-items-center'>
                    <img  src='/images/dashboard/profile.svg' height='20px' 
                        className='mr-2' style={{filter:'brightness(0)'}}
                    />
                    <p className='font-weight-500 text-medium lead'>
                    {checklist?'Clients':'Contacts'}
                    </p>
                </div>
            }
        >
            {
                picker == 1?
                <ContactPicker
                    contacts={contacts}
                    recipients={recipients}
                    setRecipients={setRecipients}
                    isMobile
                    onClose={()=>setPicker(0)}
                />
                :
                <div>
                    document picker
                </div>

            }

        </MDialog>
    </div>

}


function FileBadge({source,onClear,badOne}) {
    const handleClick = () => {
        if(source && !onClear){
            const url = `${$baseUrl.replace('/api','')}${source.file}`
            window.open(url)
            // window.location.href = 
        }
    }
    return  <div className={`d-flex p-1 border rounded-8 ml-2 pr-2
    align-items-center ${onClear?'':'cursor-pointer'} ${badOne?'border-danger text-danger':''} `}
        onClick={handleClick}
        style={{borderColor:'#DADADA'}}
    >
        <img src='/images/dashboard/document.svg' height='20px' />

        <p className='ml-2 text-small font-weight-500 '>
            {source.filename || source.name }
        </p>

        {onClear && 
            <button className='border p-0 ml-2 rounded-8 px-1'
                onClick={onClear}
            >
                <Close style={{fontSize:'0.8em'}}

                />
            </button>
        }

    </div>
}

export function MessageBubble({message,thread,isMobile}){
    const m = message
    const user = useSelector(getUser)

    const is_broadcast = !m.user && m.sender == user.id // Uncomment in production

    const by_me = m.sender == user.id
    let to_one_side = '' 

    // if(thread.contact){
    //     to_one_side = `m${by_me?'l':'r'}-auto w-75`
    // }

    let sender =    <p className={`text-primary font-weight-500 
        text-small text-capitalize `}
    >
        {by_me? 'you':(thread.contact&&thread.contact.name)}
    </p>

    
    if(is_broadcast){
        sender = <div className='d-flex '>
            <img src="/images/dashboard/broadcast.svg" height='36px' />
            <div className='ml-1'>
                {sender}
                <p className='text-small text-muted '>
                {m.subject}
                </p>
            </div>
        </div>
    }
    const parsedData = (m && m.data && JSON.parse(m.data)) || {}
    const {service,checklist} =  parsedData
    
    return <div className={`chat-bubble bg-white rounded-8 border pb-2
     text-left my-1 mb-1 
         ${to_one_side}
    `}
        key={m.id}
    >
        <div className='d-flex justify-content-between p-2'>
            {sender}

            <p className='text-muted text-small'>
                {prettyTime(m.created_at)}

                {/* <span className='text-medium font-weight-500 text-success ml-2'>
                {m.read_by ? 'Y' : 'N'}
                </span> */}
            </p>
        
        </div>
        
        <p className='text-medium px-2 pb-2 pt-1' 
            style={{minHeight:isMobile?'30px':'30px'}}
        >
            {m.message}
        </p>

        <div className='d-flex align-items-end'>
            
            {m.documents && m.documents.map(d => 
                <FileBadge source={d} key={d.id} />
            )}
        
            <ServiceButton service={service}
                className={'ml-auto'} 
            />
            
            <ChecklistButton checklist={checklist} className={`ml-auto `} />
        </div>

    </div>
}

const ServiceButton = ({service,className}) => {
    const history = useHistory()
    const user = useSelector(getUser)
    const quotes = useSelector(selectQuotes)

    function openService(){
        let url = `/services?id=${service}`
        if(user.user_type != userTypes.home_owner){
            const quote = quotes.find(q => q.service.id == service)
            if(quote){
                url = `/services?id=${service}`
            }
        }
        history.push(url)
    }
    
    return service ?
        <button className={` border rounded-8   text-nowrap
        bg-white ml-2 text-small p-1 pr-2 ${className} mr-2`}
            onClick={openService}
        >
            <img src='/images/dashboard/service-request.svg' height='16px'
                // style={{filter:'brightness(0) invert(1)'}}
                className='mr-2'
            />
            Service #{padZero(service,3)}
        </button>
    :null
}
const ChecklistButton = ({checklist,className}) => {
    const history = useHistory()
    const user = useSelector(getUser)
    const canView = [userTypes.potential_buyer,userTypes.agent].includes(user.user_type)

    // const user = useSelector(getUser)
    // const quotes = useSelector(selectQuotes)
    const [cid,cname] = (checklist && checklist.split('_')) || []

    function openChecklist(){
        if(!canView){
            // return $alert('Checklists can only be viewed by agents and potential buyers')
            return $alert(`Please share a different email address with your agent to have the checklist sent to you.`)
        }
        let url = `/dashboard/checklists?id=${cid}`
        if(user.user_type == userTypes.potential_buyer){
            url = `/dashboard/documents?checklist=${cid}`
        }
     
        history.push(url)
    }
    
    return checklist ?
        <button className={` border rounded-8   text-nowrap ${canView?'':'opacity-50 disabled'}
        bg-white ml-2 my-1 text-small p-1 px-2 ${className} mr-2`}
            onClick={openChecklist}
        >
            {/* <img src='/images/dashboard/service-request.svg' height='16px'
                className='mr-2'
            /> */}
            <CheckBoxOutlined className='mr-2' />

            {cname}
        </button>
    :null
}



export function ContactPicker({recipients,disabled,setRecipients,contacts,isMobile,onSubmit,loading,submitText,onClose=()=>{}}){
    const [contactFilter,setContactFilter] = useState('')
    const query_text = contactFilter.toLowerCase()
    const user = useSelector(getUser)
    const can_broadcast =  user.user_type == userTypes.agent

    return <div className='my-dialog-md p-3 d-flex flex-column overflow-hidden'
        style={{height:isMobile?'':'60vh'}}
    >
        {/* recipient picker */}
        <div className='d-flex mb-2'>
            <TextField size="small" variant='outlined'
                placeholder='search by name,profile or email'
                className='flex-grow-1 mr-1'
                value={contactFilter}
                onChange={e=>setContactFilter(e.target.value)}
            />
            <Button variant="contained" size='small' disableElevation>
                Search
            </Button>
        </div>
        <div className={`flex-grow-1 overflow-auto border rounded py-1
            ${disabled?'opacity-50 ':''}
        `}
            
        >

            {contacts.slice().filter(clientFilterer(query_text)).sort((a,b)=> a.name?.toLowerCase()<b.name?.toLowerCase()?-1:1)
            .map(c => 
            { 
                const checked = recipients.some(r=> r.id == c.id)
                return <div className='d-flex cursor-pointer py-1
                    align-items-center hover-outline'
                        key={c.id}
                        onClick={()=>{
                            if(disabled){return}
                            if(!can_broadcast){
                                setRecipients([c])
                                onClose()
                            }
                            else if(checked){setRecipients(recipients.filter(r=>r.id!=c.id))}
                            else{setRecipients([...recipients,c])}
                        
                        }}
                    >
                        <Checkbox
                            checked={checked}
                            className='mr-2'
                            color='primary'
                            size='medium'
                        />
                        <div className='d-flex flex-column'>
                            <p className='m-0 font-weight-500 text-medium'>
                            {c.name}
                            </p>

                            {!c.email?null:   
                                <div className='text-primary text-small'>
                                    <MailOutlined className='mr-2 text-small'
                                        style={{fontSize:'0.98em'}}
                                    />
                                    {c.email}
                                </div>
                            }
                        </div>
                    </div>
                }

            )}
        </div>



        {contacts.length?
            <div className='d-flex justify-content-end mt-auto align-items-center '>
                {can_broadcast &&
                    <React.Fragment>
                        {!!recipients.length && 
                            <p className='text-primary mr-auto font-weight-500 text-medium'>
                                {recipients.length} selected
                            </p>
                        }
                        <ActionButtons
                            disabled={!recipients.length}
                            nextText={submitText||'Select'}
                            backText='Cancel'
                            onBack={onClose}
                            onNext={onSubmit||onClose}
                            loading={loading}
                        />
                    </React.Fragment>
                }
            </div>
        :
            <p className='text-muted text-small my-auto text-center '>
                You don't have any contacts to send messages.
            </p>
        }
    </div>


}