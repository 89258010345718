import React from 'react'
import { useSelector } from 'react-redux'
import { getUser } from '../../app/auth/authSlice'
import { quoteStatus, selectMessages } from '../../app/user/userSlice'
import { usaDate } from '../../components/input/DatePicker'
import { $prettify } from '../../utils'
import { didAcceptAnother, getQuoteTotal,getLatestQuote,getPickedTime,getPickedDate } from '../../utils/helpers'


export default function StatusText({service,ignore_latest,concise,formal,
    latest_message,quote,className,fontWeight,...props}){
    const user = useSelector(getUser)
    
    let subText = null
    let status_text = 'Requested for quotes'
    let color = 'gray'
    let style = {fontWeight:'500'}

    const sr = service
    let accepted_quote = !quote && (sr.accepted_quote? sr.quotes.find(q=>q.id==sr.accepted_quote) :null)
    let q = accepted_quote || quote 
    const detailed = formal
    const messages = useSelector(selectMessages)
    const isProvider = user.user_type != 0

    if(service && !ignore_latest){
        const latest_quote = getLatestQuote(service)
        if(!quote){
            q = latest_quote
        }
    }

    let canceled_text = 'Service canceled'
    console.log('service = ')
    console.log(q)
    const eservice = service || q.service

    if(latest_message){
        console.log(latest_message)
        status_text = latest_message

        color = 'lightslategray'
    }
    else if(eservice && eservice.status == -1){
        if(!concise){
            canceled_text = `${isProvider?'the client':'you'}`
            canceled_text = `Service was canceled by ${canceled_text}`
        }
        status_text = canceled_text
        color = 'red' 
    }
    else if(q && q.status == -1){
        if(!concise){
            canceled_text = `Service was canceled by ${isProvider?'you':'the contractor'}`
        }
        status_text = canceled_text
        console.log(q)
        console.log('service was cancelled')
        color = 'orangered'
    }
    else if(isProvider && didAcceptAnother(q)){
        status_text = 'Service is no longer needed'

        color = 'orangered'
    }
    else if(q){
        const colors = [

            {statuses:[1,3,101],color:'red'},
            {statuses:[4,5,6,7],color:'orange'},
            {statuses:[2],color:'var(--green)'},
            {statuses:[8,9,10],color:'var(--green)',style:{fontWeight:'500'}},
            {statuses:[100],color:'lightslategray',style:{fontWeight:'bold'}},

        ]
        colors.some(c=>{
            if(c.statuses.includes(q.status)){
                color = c.color
                style = c.style || style
                return true;
            }
        })
        
        status_text = $prettify(Object.keys(quoteStatus).find(k => quoteStatus[k] == q.status)||'') || q.status_text

        if(q.status == quoteStatus.refused_service){
            if(!isProvider && !concise){
                status_text = `Service was refused by ${q.provider.full_name}`
            }
            color = '#ff4422'
        }

        if(quote){
            if(quote.service.status == -1){
                status_text = canceled_text
                color='red'
            }
            else if(quote.status == 0){
                status_text = 'Requested by '+(quote.service.client)
            }
            else if(quote.status == 2){
                const estimated_cost = getQuoteTotal(quote)
                status_text = `${isProvider?'Quoted for ':''} $${Number(estimated_cost).toFixed(2)}`
                style = {fontWeight:'500',}
            }

            if(isProvider  && quote.service ){
                const s = quote.service
                if(s.status >= 100 && q.status < 100){
                    status_text = 'Work was completed by another contractor'
                    color='orangered'
                }
            } 
        }

        if(q.status == quoteStatus.quote_provided){
            style = {fontWeight:'500',}
            const estimated_cost = getQuoteTotal(q)
            const cost  = Number(estimated_cost).toFixed(2)
            
            status_text = `${isProvider?'Total':'Total'}: $${cost}`
            if(service){
                const given_ones = service.quotes.filter(q => q.status >= 2)
                if(given_ones.length > 1){
                    status_text = `Received ${given_ones.length} quotes`
                }
            }

        }
        const picked_date = getPickedDate(q)
        const completed_date = q.completed_on ? usaDate(q.completed_on.split('T')[0]) : picked_date

        if(q.rescheduled && formal && !isProvider){
            subText = <p className='text-danger text-small mw-100 text-wrap'>
                You have incurred additional charges due to rescheduling
            </p>
        }
        
        if(q.status == quoteStatus.scheduled){
            color = status_text == quoteStatus.scheduled ? 'green' : color

            status_text = detailed ? `Work scheduled` : 'Scheduled'
            if(status_text == 'Scheduled' && q.rescheduled){
                status_text = detailed ? 'Work is now rescheduled' : 'Rescheduled'
            }

            if(!concise){
                status_text = <span>
                    {[
                        status_text+' for '+picked_date,
                        ' at '+(getPickedTime(q))
                    ].map(t => 
                        <span key={t} className='d-inline-block ml-0'>
                            {t}
                        </span>
                    )}          
                </span>
            }

        }else if(q.status == quoteStatus.dispatched){
            if(formal){
                status_text = 'Contractor is on their way to start work'
            }
        }


        const billedAmount = Number(q.estimated_cost)
        const invoiceAmount =  q.status >=100 && (billedAmount || getQuoteTotal(q)) 

        if(q.status == 100){
            if(!!billedAmount){
                if(isProvider){
                    status_text = `Billed $${invoiceAmount} on ${completed_date} ` 
                }else{
                    status_text = detailed ? `Payment due in the amount of $${invoiceAmount}` : 'Payment due'
                }

                color = '#212121'
            }else{
                status_text = `Work completed ${!concise?`on ${completed_date}`:''} `
            }
        }
        else if(q.status == 101){
            if(!!invoiceAmount){
           
                status_text = `Payment made on ${completed_date}`
                
                color = '#212121'
            }
        }

        // if(q.status == 4 && q.rescheduled){
        //     status_text = "Rescheduled for an alternate time"
        //     color = 'orange'
        // }
        
    }
    else if(sr.status == 0){
        const given_quotes = sr.quotes.filter(q=>q.status==2)
        if(given_quotes.length){
            color='var(--pri)'
            status_text = <span>
                Received quote 
            </span>
        }else{
            status_text = 'Requested for quotes'
        }
    }
    if(formal){
        if(service && service.status != -1){
            color = "black"
        }
        style.fontWeight = '500'
    }
    if(fontWeight){style.fontWeight=fontWeight}
    // if(!formal){
    //     style.fontWeight=200
    // }
  
    return <div className={`d-flex flex-column ${concise?'flex-grow-1':''} `} {...props}
    >
        <p style={{color,...style}}
        className={` my-0 p-0 text-medium  text-wrap
            ${className}
        `}
        >
            {status_text}
        </p>
        {subText}
    </div>
}
