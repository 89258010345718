import { CircularProgress, TextField } from '@material-ui/core'
import {
    CardCvcElement,
    CardExpiryElement, CardNumberElement, Elements, useElements, useStripe
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../app/auth/authSlice'
import { goBack, setDialog } from '../../app/ui/uiSlice'
import { getPricing } from '../../app/user/userSlice'
import { $baseUrl, $error, $success } from '../../utils/http'
import { ActionButtons } from '../../views/dashboard/FeatureEditor'
import PlanCard from './PlanCard'


const FormField = (props) => <TextField {...payProps} {...props} />

const payProps = {
    variant:'outlined',
    placeholder:'----',
    size:'small',
    style:{padding:'2px',color:'gray'},
    inputProps:{style:{color:'#000000be'}} 
}



const stripeKey = process.env.REACT_APP_STRIPE_KEY
const stripePromise = loadStripe(stripeKey);

export default function PaymentDialog(props){

    return <Elements stripe={stripePromise}>
        <PaymentDialogInner {...props}/>
    </Elements>
}

function PaymentDialogInner({onClose,defaultPlan,...props}){

    let [currentPlan,setCurrentPlan] = useState(-1)
    const elements = useElements()
    let [step,setStep] = useState(0)
    const dispatch = useDispatch()

    let [pay,setPay] = useState({
        name:'',
        card:['','','',''],
        expMonth:'',
        expYear:'',
        cvc:''
    })

    function subscribedSuccessfully(){
        $success('Subscribed successfully!')
        onClose();
        localStorage.setItem('latestInvoicePaymentIntentStatus','')
    }

    function handleRequiresPaymentMethod({
        subscription,
        paymentMethodId,
    }) 
    {
        if (subscription.status === 'active') {
        // subscription is active, no customer actions required.
        return { subscription, paymentMethodId };
        } else if (
        subscription.latest_invoice.payment_intent.status ===
        'requires_payment_method'
        ) {
        // Using localStorage to manage the state of the retry here,
        // feel free to replace with what you prefer.
        // Store the latest invoice ID and status.
        localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
        localStorage.setItem(
            'latestInvoicePaymentIntentStatus',
            subscription.latest_invoice.payment_intent.status
        );
        throw { message: 'Your card was declined.' };
        } else {
        return { subscription, paymentMethodId };
        }
    }

    async function createSubscription({ paymentMethodId, }) {
        // const current_plan = plans[currentPlan]
        const data = {
            // customerId: customerId,
            paymentMethodId: paymentMethodId,
            planId: currentPlan,
            // priceId: current_plan.stripe_price_id
        }

        try {
            let result = await Axios.post($baseUrl+'/create_subscription',data)
            let subscription = result.data.subscription
            if (result.error) {
                console.log(result.error)
                // The card had an error when trying to attach it to a customer.
                throw result;
            }
            
            handleRequiresPaymentMethod({subscription,paymentMethodId})
            
            subscribedSuccessfully()

            setTimeout(()=>{
                window.location.reload()
            },500)

            return subscription
            
        } catch (err) {
            throw(err)
        }
    }
    
    const [loading,setLoading] = useState(0)
    const user = useSelector(getUser)
    const plans = useSelector(getPricing)
    const stripe = useStripe()
    const isTrailing = user.plan_status === 0

    const userPlan = user.current_plan 
    // const changePlan = !isTrailing && (userPlan != currentPlan)
    const disableNext = !isTrailing && (userPlan === currentPlan)


    useEffect(()=>{
        if(currentPlan === -1 && plans && Array.isArray(plans) && plans.length){
            if(defaultPlan != null){
                setCurrentPlan(plans[defaultPlan].id)
            }
            else if(userPlan != null){
                setCurrentPlan(userPlan)
            }else{
                setCurrentPlan(plans[0].id)
            }
        }
    },[plans])

    async function changePlan(){
        if(!isTrailing)
        {
            const data = {
                planId: currentPlan,
            }
            try {
                setLoading(1)
                const res = await Axios.post(`${$baseUrl}/change_subscription`,data)
                
                $success(res)
            } catch (err) {
                $error(err)
            }finally{
                setLoading(0)
            }
        }
    }
    async function makePayment(){
        const cardElement = elements.getElement(CardNumberElement)
    
        // setLoading(PATCHING)
        let success = false
        try {
    
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });
    
            if (error) {
                console.log('[createPaymentMethod error]', error);
            } else {
                console.log('[PaymentMethod]', paymentMethod);
                const paymentMethodId = paymentMethod.id;
     
                // Create the subscription
                console.log('attempting subscription')
                await createSubscription({paymentMethodId});

            }
    
            if(error){throw error.message}
    
            success = true
        } catch (err) {
            $error(err);
            throw err
        } finally{
            if(!success){
                setLoading(0)
            }
        }
    }
    
    const paymentScreen ={
        heading:'Payment details',
        caption:`Please enter payment details`
        ,
        nextText:'Finish',
        content: <PaymentForm pay={pay} setPay={setPay} />
    }

    const pickPlan = {
        heading: isTrailing?'Select a package':'Your current plan',
        caption: isTrailing?`You can change this later`:'You can change it later',
        nextText: isTrailing?'Next':'Change plan',
        content: <div className='m-2'>


            {!plans.length?
                <CircularProgress color='primary' className='my-4'/>:
            plans.map((plan,i) => (
                <PlanCard  
                    key={plan.plan}
                    onClick={()=>setCurrentPlan(plan.id)}
                    active={currentPlan === plan.id}
                    plan={plan}
                    trialPeriod={i>0?0:3}
                    isTrailing={isTrailing}
                >
                 </PlanCard>
            ))}

            <p className='small font-weight-500 m-0 my-2 ' style={{fontWeight:'500',color:'#939393'}}>
                For more information about the packages,view
                <span className='text-primary mx-1 cursor-pointer '
                    onClick={()=>dispatch(setDialog('pricing'))}
                >
                    packages & pricing
                </span>
            </p>
        </div>
    }

    let steps = [
        // verifyAccount,
        // accountVeriifed,
        // whoAreYou, // 1
        // homeAddress, // 2
        // homeFeatures, // 3
        pickPlan, // 4
        paymentScreen, // 5
    ]

    const totalSteps = steps.length - 2
    const currentStep = step - 1

    let {heading,caption,content,nextText} = step < steps.length? steps[step] :{}

    function takeStep(inc=1){
        if((step ==1  && inc < 0) || (step === 0 && inc > 0)){
            if(!isTrailing && inc > 0){
                if(!window.confirm("are you sure you want to change the plan?")){return}
                else{
                    return changePlan()
                }
            }
            setStep(step+inc)
        }
        else if(step === 1 && inc === 1){
            makePayment()
            setLoading(1)
        }
        else if(step === 0 && inc === -1){
            dispatch(goBack())
        }
    }


    return <div className='px-sm-4 px-3 py-3 d-flex flex-column h-100
    position-relative text-center flex-grow-1 mx-auto my-dialog'>

        <div className='mt-auto py-3'>
            <img src={step==1?'/images/dashboard/payment.svg':'/images/micasa-logo.svg'} 
            alt='' style={{height:step==1?'6em':'4em'}} />

            <h4 className='mt-3 mb-1 font-weight-bold'>
                {heading}
            </h4>
            <p style={{color:'gray',maxWidth:'24em',marginLeft:'auto',marginRight:'auto'}}>
                {caption}
            </p>
             
            {content}
            
                
        </div>

        <div className='mt-auto mb-3 '>
            
            <ActionButtons 
                expand
                backText={step>0?'Back':'Cancel'}
                onBack={()=>takeStep(-1)}

                nextText={nextText||'Next'}
                loading={loading>0}
                disabled={disableNext}
                onNext={()=>takeStep(1)}

            />

        </div>

        {currentStep>0?
            <p className='small text-muted mb-1 '>
                Step {currentStep} of {totalSteps}
            </p>
        :null}
        
        {/* <MDialog
            open={showPlans}
            onClose={()=>setShowPlans(false)}
        >   
            <div>

                <PricingTable small displaySignIn={(st,pp)=>{setShowPlans(false);setCurrentPlan(plans[pp].id)}} />
            </div>

        </MDialog> */}
        
    </div>
}


export function PaymentForm({pay,setPay,loaded=true,...props}){
    const payStyle = {
        borderRadius:'6px',
        padding:'10px',
        border:'1px solid lightgray'
    }
    const [elemsLoaded,setElemsLoaded] = useState(0)


    return  <div className=' mb-3 mb-4  position-relative'>
                        
        <FieldLabel>
            Name
        </FieldLabel>
        <FormField value={pay.name} onInput={e=>setPay({...pay,name:e.target.value})}
            type="text"
            className='w-100'
            placeholder='Enter your name as appears on card'
        />
        <FieldLabel>
            Card number
        </FieldLabel>

        <div style={payStyle}>
        <CardNumberElement 
        onReady={()=>{setElemsLoaded(i => i+1)}}
        options={{
            placeholder:'credit card number',
            style: {
                    base: {
                        fontSize: '16px',
                        color: '#424770',
                    },
                    invalid: {
                        color: '#ff6633',
                    },
                },
            }}
        />
        </div>

        <div className='d-flex'>
                <div className='mr-3 flex-grow-1'>
                    <FieldLabel>
                        Expiration
                    </FieldLabel>
                    <div style={payStyle}>
                        <CardExpiryElement 
                            onReady={()=>{setElemsLoaded(i => i+1)}}
                        />
                    </div>
                </div>
                <div className='flex-grow-1'>
                    <FieldLabel>
                        CVC
                    </FieldLabel>

                    <div style={payStyle}>
                        <CardCvcElement 
                            options={{placeholder:'3 digits on card back',}}
                            onReady={()=>{setElemsLoaded(i => i+1)}}
                        />
                    </div>
                
                </div>
        </div>
            
        {(elemsLoaded>1&& loaded)?null:
            <div className="position-absolute w-100 h-100 bg-white d-flex flex-column"
            style={{top:'0',left:'0'}}
            >
                <CircularProgress className='mx-auto my-auto' />                           
            </div>    
        }
        

    </div>

}


const FieldLabel = (props) => (
    <p className='text-secondary text-left small mt-3 mb-2'>
        {props.children}
    </p>
)