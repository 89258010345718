

// const primary = 'var(--pri)'
// const secondary = 'var(--sec)'


export default {


    button:{
        borderRadius:'8px'
    },
    outlined:{
        border:'1px solid',
    },
    tableFeature:{
        fontWeight:'bold',
        color:'gray',

    }



}