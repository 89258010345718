import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import React from 'react'

export default function MExpand({summary,expanded,className,expandIcon,...props}){

    return <Accordion
 
        defaultExpanded={expanded}

        className={` shadow-none pl-0 py-0 ${className||'mt-2 mb-1'} bg-transparent `}
        itemProp={{className:'py-0 p-0'}}
        style={{border:'none'}}
        {...props}
    >
            <AccordionSummary
             expandIcon={expandIcon||<ExpandMore fontSize="default" />}
             className=' text-muted font-weight-500 text-medium pl-2 p-0 pr-3'
             style={{border:'2px solid #e3e3e3',borderRadius:'8px'}}
            >
                <div className='d-flex justify-content-between align-items-center 
                 flex-grow-1
                '>
                    {summary}
                </div>
            </AccordionSummary>
            <AccordionDetails className='d-flex flex-column px-2 pb-2 '>
                    {props.children}
            </AccordionDetails>
    </Accordion>

}