
import React, { useState, useEffect, useRef } from 'react'
import { Button, TextField, Checkbox, Link, CircularProgress } from '@material-ui/core'
import MDialog from '../common/MDialog'
import { useDispatch, useSelector } from 'react-redux'
import {getUser, sign_in,sign_up} from '../../app/auth/authSlice'
import {googlelogin,facebooklogin} from '../../utils/SocialLogin'
import { $success, $error, $baseUrl, $validPassword, $required, $event} from '../../utils/http'
import ResetPassword from '../special/ResetPassword'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'querystring'
import { Formik, Form, Field ,useField} from "formik";
import * as yup from 'yup'
import Axios from 'axios'
import { queryHelpers } from '@testing-library/react'
import SubmitButton from '../common/SubmitButton'
import { fetchNotifications, userTypes } from '../../app/user/userSlice'
import { useContext } from 'react'
import { setDialog } from '../../app/ui/uiSlice'

export function AuthField({className,type,...props}) {
    const cprops = {...props}
    const setter = cprops.setter
    delete cprops.setter

    const [field,meta] = useField(props)
    const errorText = meta.error && meta.touched  ? meta.error : ''
    const placeholder = props.placeholder
    const label = placeholder ? '' : (props.label||props.name)
    return <TextField
        {...field}
        error={!!errorText}
        helperText={errorText}
        size='small'
        variant='outlined'
        // style={{marginTop:'8px'}}
        className={className||'my-2'}
        label={label}
        placeholder={placeholder}
        type={type||'text'}
        onInput={props.onInput}
        onBlur={(e)=>{
            field.onBlur(e);
            if(props.onBlur){
                props.onBlur(e)
            }
        }}
        // inputProps={{autoComplete:"new-password"}}
    />
}

export const GoogleDisclaimer = <p className='text-muted ' style={{fontSize:"0.8rem"}}>
        This page is protected by reCAPTCHA, 
        and subject to the Google
        <a className='text-primary mx-1' target="_blank" href='https://www.google.com/intl/en/privacy.html'>
                Privacy Policy 
        </a>
        and 
        <a className='mx-1' target="_blank" href='https://policies.google.com/terms'>
            Terms of Service.
        </a>
</p>


export default function Authenticator(props) {
    const [FORGOT,RESET,DELETE] = [42,43,41]

    const past_remember = localStorage.getItem('remember',false)
    const [remember,setRemember] = useState(!!past_remember)
    function flipRemember(){
        localStorage.setItem('remember',remember?'':'true');
        setRemember(!remember);
    }
    let [loading,setLoading] = useState(false)
    let [query,setQuery] = useState({})

    const [agree,setAgree] = useState(false)
    const [subscribe,setSubscribe] = useState(true)

    const [showText,setShowText] = useState(0)

    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const user = useSelector(getUser)

    const {showSignIn,displaySignIn,userType} = props 
    
    const [otp,setOTP] = useState('')
    const formikRef = useRef()

    
    const closePopup = () => {
        const dialog = query.dialog
        // if(dialog){}
        const pathName = location.pathname//+dialog?('?dialog='+dialog):''
        history.replace(pathName)
        displaySignIn(0)
    }

    const flipSignIn = (reset) => {
        displaySignIn(showSignIn>1?1:2)
        const form = formikRef.current
        if(form){
            if(reset != false){
                form.resetForm();
            }else{
                form.setErrors({})
            }
        }
    }
    function onSignIn(){
        closePopup()

        if(window.supposed_page){
            history.push(window.supposed_page)
            window.supposed_page = ''
        }
    }
    function forgotPassword(){
        displaySignIn(FORGOT)
    }
    function backToAuth(from){
        displaySignIn(from || 1)
    }
    let [initialData,setInitialData] = useState({
        firstName:'',
        lastName:'',
        email: (localStorage.getItem('email')||''),
        referral_code:''
    })

    const isSignUp = showSignIn>1

    const email_error = <span>
        {isSignUp?
        'This email address is already registered, '
        :
        'There is no registered account with that email address, '
        }
        <span style={{cursor:'pointer'}}
        className='text-primary px-1 d-inline-block hover-link'
            onClick={()=>{flipSignIn(false);}}
        >
            {isSignUp?'Sign in':'Create account'}
        </span>
        instead
    </span>

    const account_locked = <span>
        
        Your account has been locked due to many unsuccessful attempts, please 
        <span className='text-primary font-weight-500 hover-link mx-1 text-nowrap'
            onClick={forgotPassword}
        >
            reset your password
        </span>
        to continue.
       
    </span>

    async function checkEmailExists() {
        const form = formikRef.current
        if(form && !form.errors.email){
            const email = form.values.email
            try {
                const res = await Axios.post($baseUrl+'/login',{email,password:'email_check'})
            } catch (err) {
                const res = err.response
                const is404 =  res?.status == 404
                const notFound = is404 && !isSignUp
                const alreadyExists = !is404 && res?.status != 403 && isSignUp
                if(res && (notFound || alreadyExists)){
                    form.setErrors({user_exists:email_error})
                }
            }
        }
    }

    useEffect(()=>{
        
        let query = location.search.replace('?','')
        query = qs.parse(query)
        const totp = query.reset_password 
            if(totp){
            setOTP(totp)
            displaySignIn(RESET)
            }
        setQuery(query)
        if(query.delete_account){
            displaySignIn(DELETE)
        }

        let {first_name,last_name,email,referral_code,sign_up} = query
        if(referral_code || sign_up){
            displaySignIn(2)
        }else if(email){
            if(!user){
                displaySignIn(1)
            }
        }
        if(first_name||last_name||email){
            setInitialData({
                firstName:first_name||'',
                lastName:last_name||'',
                email:email||'',
                referral_code
            })
        }
        

      
    }
    ,[])

 
    async function signUp({email,password,firstName,lastName,subscribe_to_newsletter}){
        try {
            setLoading(true)
            const res = await dispatch(sign_up({
                email,password,
                first_name:firstName,
                last_name:lastName,
                referral_code:initialData.referral_code,
                subscribe_to_newsletter

            }))
            $event('sign_up',{method: 'Password'});            
            flipSignIn()

            let user_type = Object.keys(userTypes).find(k => userTypes[k] == user.user_type)
            if(user_type){
                $event('user_type',{category:'user',value:user_type})
            }

        } catch (err) {
            // $error(err)
            const res = err.response 
            const rdata = res && res.data
            const form = formikRef.current
  
            if(rdata && rdata.errors && form){
                form.setErrors({...form.errors,
                    ...rdata.errors
                })
            }
            if(res && res.status == 409 && form ){
                const err_message = email_error
                
                form.setErrors({...form.errors,
                    user_exists:err_message
                })
            }
        }
        finally
        {
            setLoading(false)
        }
        console.log('sign up ')
    }
    async function signIn({email,password}){
        try {
            setLoading(true)
            const res = await dispatch(sign_in({email,password,remember}))
            $event('login',{method: 'Password'});
            dispatch(fetchNotifications())
            console.log(res)
            
            localStorage.setItem("email",remember?email:'')

            console.log('sined in!!')
            onSignIn()
            
        } catch (err) {   
            const form = formikRef.current
            const res = err.response || {}
            if(res && res.status == 404 && form ){
                
                form.setErrors({...form.errors,
                    user_exists:email_error
                })
            }
            else if(res && res.status == 400){
                form.setErrors({...form.errors,
                    wrong: (res.data && res.data.message)||'Bad request'
                })
            }
            else if(res && res.status == 403){
                form.setErrors({...form.errors,
                    user_exists:account_locked
                })
            }else{
                $error(err)
            }
        }finally{
            setLoading(false)
        }
    }

    async function loginWithSocial(provider){
        try {
            if(isSignUp && !agree){
                const form = formikRef.current
            }
            setLoading(true)
            console.log(formikRef)
            const subs = (formikRef.current && formikRef.current.values.subscribe_to_newsletter)
            const subscribe_to_newsletter = subs ? 'True' : 'False'
            const data = {subscribe_to_newsletter}
            const referral_code = query.referral_code
            if(referral_code){
                data.referral_code = referral_code
            }

            const res = await provider(data)
            dispatch(fetchNotifications())
            onSignIn()
            $success(res)
        } catch (err) {
            let message = 'social sign in failed'
            if(err && err.error === 'popup_closed_by_user'){message = err.error}
            else if(typeof err != 'string'){
                $error(err || message)
            }
            console.log(message)
        }finally{
            setLoading(false)
        }
    }
 
    function handleFormSubmit(data,{setSubmitting}){
        setSubmitting(true)
        console.log(data)
        if(isSignUp){
            if(!agree){
                return $error('You must accept the terms and privacy policy')
            }
            signUp(data)
        }else{
            console.log(data)
            signIn(data)
        }
        setSubmitting(false)
    }
    let passwordValidator = yup.string().required('Please provide a password')
    if(isSignUp){
        passwordValidator = $validPassword.required('Please provide a password')
    }

    const validationSchema = yup.object({
        email: yup.string().required($required('Email')).email('Please provide a valid email address.'),
        password: passwordValidator,
        ...(isSignUp?{
            firstName: yup.string().required($required('First name')).max(12),
            lastName: yup.string().required($required('Last name')).max(12),
        }:{}),

    })


    const loginWithGoogle = () => loginWithSocial(googlelogin)
    const loginWithFacebook = () => loginWithSocial(facebooklogin)

    const buttonText = isSignUp?'Sign up':'Sign in'

    const Line = <svg width="80" height="2" viewBox="0 0 166 2" fill="none" style={{flexGrow:'1'}}>
    <line y1="1" x2="166" y2="1" stroke="#EAEAEA" strokeWidth="2"/>
    </svg>
    
    let hitSubmit = false
    const errorClass = 'text-left text-small m-0 mb-1 text-danger mx-2'

    const AuthForm = <Formik
            initialValues={{
                email: initialData.email,
                password:'',
                firstName:initialData.firstName,
                lastName:initialData.lastName,
                subscribe_to_newsletter:true,
            }}
            validationSchema={validationSchema}
            validate={(values)=>{
                const errors = {}
                if(!agree && isSignUp && hitSubmit){
                    errors.agree = 'Please agree to the terms and privacy policy'
                    checkEmailExists()
                }
                return errors
            }}
            onSubmit={handleFormSubmit}
            innerRef={formikRef}
        >
            {({ errors,touched,values,setFieldValue,resetForm,setTouched}) => (
        <Form className='bg-white p-2 p-sm-4 px-md-5 d-flex flex-column
        text-center text-muted px-4 justify-content-center flex-grow-1'
            action=''
            style={{fontSize:'0.8em',maxWidth:'36em'}}
        >
        <img src='/images/micasa-logo.svg' alt='' style={{height:'4em'}} />
        <h3>Welcome {isSignUp?'to micasa':'back'}</h3>
        <p className='text-muted'>
            {isSignUp?'Create account ':'Sign in '}
            or 
            <Link  style={{cursor:'pointer'}}
            className='text-primary ml-1 px-0'
                onClick={()=>{flipSignIn();}}
            >
                {!isSignUp?'Create account ':'Sign in '}
            </Link>
        </p>
        
            {
                isSignUp?
                <div className='d-flex'>
                    <AuthField 
                        className='mr-2 my-2 flex-grow-1'
                        name="firstName"
                        label='First name'
                        // validate={(val)=>!val?'Required':''}
                        />
                    <AuthField 
                        name="lastName"
                        label='Last name'
                        className='ml-1 my-2 flex-grow-1'
                        />

                </div>
                :null
            }
            
        <AuthField 
            name="email"
            label='Email address'
            onBlur={checkEmailExists}
        />

        <p className={errorClass}>
            {errors.user_exists||errors.user_does_not_exist}
        </p>
        
        <AuthField 
            name="password"
            label='Password'
            type='password'
            onInput={()=>{
                if(values.password && values.password.length > 3){
                    setTouched({...touched,'password':true})
                }
            }}
        />

        <p className={errorClass}>
            {errors.wrong}
        </p>
        

        {
            isSignUp?
            <div className='d-flex flex-column  text-left'>
                <div className='d-flex align-items-center  my-2'>
                        <Checkbox
                            size='medium'
                            className='p-0 mr-2'
                            checked={agree}
                            color='primary'
                            onClick={() => {setAgree(!agree);delete errors.agree}}
                        ></Checkbox>
                        <span>

                        I agree to 
                        <Link style={{cursor:'pointer'}} 
                        onClick={()=>dispatch(setDialog('terms'))}
                        className='text-primary mx-1'>
                        Terms of Service 
                        </Link>
                        and
                        <Link style={{cursor:'pointer'}}
                        onClick={()=>dispatch(setDialog('privacy'))}
                        className='text-primary mx-1'>
                            Policies
                        </Link>
                        </span>
                    </div>

                    <p className={errorClass}>
                        {errors.agree}
                    </p>

                    {/* <div className='d-flex align-items-start my-2'>
                        <Checkbox
                            size='medium'
                            className='p-0 mr-2'
                            checked={values.subscribe_to_newsletter}
                            color='primary'
                            onClick={() => {
                                setFieldValue('subscribe_to_newsletter',!values.subscribe_to_newsletter)
                            }}
                        ></Checkbox>
                        <span>
                            Yes, I’d like to receive newsletter 
                            & promotional emails from micasa
                        </span>
                        
                    </div> */}

            </div>
            :
            <div className='d-flex align-items-center my-1'>
                    <label className='font-weight-500 cursor-pointer'>
                        <Checkbox
                            size='medium'
                            className='p-0 mr-2'
                            checked={remember}
                            color='primary'
                            onChange={flipRemember}
                        ></Checkbox>
                    Remember me
                    </label>

                    <span className='ml-auto'>
                        
                    <div style={{cursor:'pointer',fontWeight:'500',}}
                    className='my-link border-0 rounded bg-white'
                    onClick={forgotPassword}
                    color="primary"
                    >
                        Forgot password?
                    </div>
                
                    </span>
                </div>
            
        }

        <SubmitButton loading={loading} className='my-2' onClick={()=>{hitSubmit=true}}>
            {buttonText}
        </SubmitButton>
        
        <div className='d-flex justify-content-stretch align-items-center'>
            {Line}
            <p className='mb-1 mx-2'>
            Or {buttonText} with
            </p>
            {Line}
        </div>

        <div className='d-flex my-3'>
                <Button
                    className='flex-grow-1 mx-2'
                    variant='contained'
                    onClick={loginWithGoogle}
                    disableElevation
                    style={{backgroundColor:'#EAEAEA'}}
                >
                    <img src="/images/landing/google.png" 
                    style={{height:'24px'}}
                    alt=''
                    className='mx-2' ></img>
                    Google
                </Button>
                <Button
                    className='flex-grow-1 mx-2'
                    variant='contained'
                    onClick={loginWithFacebook}
                    disableElevation
                    style={{backgroundColor:'#EAEAEA'}}
                >
                    <img src="/images/landing/facebook.png" 
                    alt=''
                    style={{height:'24px'}}
                    className='mx-2' ></img>
                    Facebook
                </Button>
        </div>

        {isSignUp?
        <div className='d-flex align-items-start my-2'>
                <Checkbox
                    size='medium'
                    className='p-0 mr-2'
                    checked={values.subscribe_to_newsletter}
                    color='primary'
                    onClick={() => {
                        setFieldValue('subscribe_to_newsletter',!values.subscribe_to_newsletter)
                    }}
                ></Checkbox>
                <span className='text-left'>
                    Yes, I’d like to receive newsletter 
                    & promotional emails from micasa
                </span>
                
        </div>
        :null}

        {GoogleDisclaimer}
                
        </Form> )}
    </Formik>

    const ResetPasswordForm = <ResetPassword
        goBack={backToAuth}
        resetPassword={showSignIn==RESET}
        otp={otp}
        userType={userType}
        email={formikRef?.current?.values.email}
        onSuccess={(type)=>{displaySignIn(type)}}
    >

    </ResetPassword>

    function goHome(){
        history.push('/')
    }

    async function deleteAccount(){
        try {
            const url = $baseUrl+'/delete_account'
            const data = {user_details:query.delete_account}
            const res = await Axios.post(url,data)
            $success(res)
            displaySignIn(0)
            goHome()
        } catch (err) {
            $error(err)
        }
    }

    const DeleteUserForm = <div className='p-5'>
        <p>Are you sure you want to delete your account?</p>
        <div className='d-flex justify-content-center'>
            <Button
                disableElevation
             onClick={goHome} variant='contained' className='mr-2'>
                No
            </Button>

            <Button
            disableElevation
                variant='contained'
                color='primary'
                onClick={deleteAccount}
            >
                Yes
            </Button>
        </div>
    </div>

    let form = AuthForm
    
    if(showSignIn >= FORGOT){
            form = ResetPasswordForm
    }else if(showSignIn === DELETE){
        form = DeleteUserForm 
    }
    
    return <MDialog
        open={showSignIn>0}
        onClose={()=>displaySignIn(0)}
    >

    {form}

    </MDialog>
    
}