import React, { useState, useRef, useEffect } from 'react'
import {  Button, Checkbox, CircularProgress, ClickAwayListener, Divider, FormControlLabel, MenuItem, Select, TextField, Tooltip } from '@material-ui/core'
import {ClientContractorField, LoadingCard,NothingFound,
     ClientCard, ClientListClass, ClientEditor,ItemBar, ClientMessageList, ReferralMessage } from './Clients'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import MDialog from '../../../components/common/MDialog'
import { Formik, Form } from 'formik';
import * as yup from 'yup'
import Axios from 'axios'
import { $alert, $baseUrl, $confirm, $error, $http, $phoneValidator, $required } from '../../../utils/http'
import { $wait } from '../../../utils'
import { VFormField } from '../InitialSetup'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMessages, fetchResource, getContractorSchema, selectContractors, selectFeatureSchema, setFeatureSchema } from '../../../app/user/userSlice'
import SimpleMenu from '../../../components/common/SimpleMenu'
import { useHistory } from 'react-router-dom'
import { ActionButtons } from '../FeatureEditor'
import AddressField from '../../../components/input/AddressField'
import { allowed_icons } from '../../../utils/constants'
import { Add, Cancel, Close, CloseRounded } from '@material-ui/icons'
import { getUser } from '../../../app/auth/authSlice'
import { getFeatureIcon, sortAscending } from '../../../utils/helpers'

export default function Contractors(props){
    let [showAdder,setShowAdder] = useState(false)

    const contractors = useSelector(selectContractors)
    let [loading,setLoading] = useState(true)
    const [edited,setEdited] = useState(null)
    let [query,setQuery] = useState('')

    const [step,setStep] = useState(1)
    const [specialities,setSpecialities] = useState([])

    const dispatch = useDispatch()
    const contractorSchema = useSelector(getContractorSchema)
    const featureSchema = useSelector(selectFeatureSchema)
    const history = useHistory()
    const onBoarded = !!edited?.user && edited.signed_up

    async function fetchContractors(){
        // dispatch(fetchResource({resource:'clients'}))
        try {
            setLoading(true)
            await dispatch(fetchResource({resource:'contractors'}))
        } catch (err) {
            console.log(err)
        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        fetchContractors();
        // dispatch(fetchSchema({resource:'contractor'}))

    },[])

    useEffect(()=>{
        setSpecialities((edited && edited.id)  ? edited.expertise:[])
        setFormData(initialValues)
    },[edited])

    let initialValues = {
        line1:'',
        line2:'',
        city:'',
        state:'',
        zip_code:'',
        
        email:'',
        mobile_number:'',
        company_name:'',

        first_name:'',
        middle_name:'',
        last_name:'',
        provider_type:0,
    }

    if(edited){
        for(let key in initialValues){
            initialValues[key] = edited[key] ?? ''
        }        
        if(initialValues.provider_type == ''){initialValues.provider_type = null}
        initialValues['mobile_number'] = edited['phone_number_primary'] || ''

        let [first_name,last_name] = edited.full_name? (edited.full_name.split(' ')) : [edited.first_name,edited.last_name]

        const [line1,line2] = (edited['address']||'').split(',')
        const [city,state,zip_code] = (edited['city_state_zip']||'').split(',')

        initialValues = {...initialValues,line1,line2,city,zip_code,state,first_name,last_name} 
    }

    const [formData,setFormData] = useState(initialValues)

    
    const formRef = useRef()
    const form = formRef.current

    function ContractorField({...props}){
        return <ClientContractorField disabled={onBoarded}  {...props}/>
    }

    const contractorFields = [
        {
            name:'Name',
            content: <div className='d-flex flex-column mb-2'>
                <div className='d-flex'>
                    <ContractorField name='first_name' newClassName='flex-grow-1 mb-0'  />
                    {/* <ContractorField className='flex-grow-1 ml-2' name='middle_name' />    */}
                    <ContractorField name='last_name' newClassName='flex-grow-1 ml-1 mb-0' />
                </div>
                {/* <ContractorField name='last_name' /> */}
            </div>,
            orNext:true,
        },
        {
            name:'Company Name',
            content: <ContractorField placeholder='Enter company name' name='company_name' />
        },
        {
            name:'Phone number',
            content: <VFormField isphone placeholder='Enter phone number' name='mobile_number' 
                className='mb-3 pointer-events-none'
                disabled={onBoarded} inputProps={{style:{padding:'8px 12px',color:'var(--black3)'}}}
            />
        },
        {
            name:'Email address',
            content: <ContractorField placeholder='Enter email address' name='email' />,
            orNext:true,
        },
        {
            name:'Provider type',
            contentDynamic:({values,setFieldValue}) => <div className='mb-2 '>
                {providerTypes.map((p,i) =>
                    <FormControlLabel
                        key={p.value}
                        disabled={onBoarded}
                        control={
                            <Checkbox color="primary"
                                checked={values.provider_type == p.value}
                                onClick={e=>(setFieldValue('provider_type',values.provider_type==p.value?null:p.value))}
                                className='py-0 px-2'
                            />
                        }
                        label={p.display_name}
                        style={{color:'var(--black3)',fontSize:'0.4em'}}
                        className='my-0 font-weight-500 mb-2'
                    />
                )}
            </div>
        },
        {
            name:'Address',
            contentDynamic:({errors}) =>
            <div className='d-flex flex-column mb-2'>

                <AddressField
                    variant="outlined"
                    size="small"
                    inputProps={{autoFocus:true}}
                    name="line1"
                    formikRef={formRef}
                    placeholder="Address line 1" 
                    disabled={onBoarded}  
                    inputProps={{style:{padding:'8px 12px',color:'var(--black3)'}}}
                    setGoogleAddress={({location})=>console.log(location)}
                />
                <ContractorField placeholder='Address line 2' name='line2' className='mt-2'/>

                <div className='d-flex'>
                    <ContractorField className='flex-grow-1'  name='city'/>
                    <ContractorField className='flex-grow-1 mx-2'  name='state'/>
                    <ContractorField className='flex-grow-1 ml-211 '  name='zip_code' />    
                </div>
            </div>
        },
 
    ]

    const validationSchema = yup.object({
        // first_name: yup.string().required($required('First name')),
        // last_name: yup.string().required($required('Last name')),
        email: yup.string().email('Please provide a valid email address.')/*.required($required("Email"))*/,
        line1: yup.string().required('Address is required'),
        mobile_number: yup.string().test('len', 'Mobile number must have 10 digits', 
        $phoneValidator
        ),

    })


    function handleNext(){

        const form = formRef.current
        console.log(form)
        if(form){
            form.submitForm()
        }else if(edited){
            handleSubmit({...edited})
        }
    }
    async function handleSubmit(data){
        console.log(edited,step)
        if(!edited && step == 1){
            setStep(2)
            return
        }

        data.email = data.email || null
        const method = edited?'patch':'post'
        const end = edited?('/'+edited.id):''
        data.phone_number_primary = data.mobile_number
        data.categories  = `,${specialities.join(',')},`
        data.expertise  = specialities

        data = {...data,
            address:data.line1+','+data.line2,
            city_state_zip:[data.city,data.state,data.zip_code].join(','),
        }

        try {
            setLoading(2)
            const res = await Axios[method]($http.$baseUrl+'/contractors'+end,data)
            $http.$success(res)
            setEdited(null)
            setShowAdder(false)
            setStep(1)
            fetchContractors()
        } catch (err) {
            const res = err.response
            if(res && res.status == 400){
                setStep(1)
                const errs = res.data
                const form = formRef.current
                if(form){
                    form.setErrors({...form.errors,...errs})
                }
            }else{
                $http.$error(err)
            }
        }finally{
            setLoading(false)
        }
    }

    function hideModal(){
        setEdited(null)
        setShowAdder(false)
        setFormData(initialValues)
        setSpecialities([])
        setStep(1)
    }

    const query_text = query.toLowerCase()
    const filteredContractors =  contractors.filter(q =>
         (
               (q.email||'').toLowerCase().includes(query_text)
            || q.full_name.toLowerCase().includes(query_text)
            || q.company_name?.toLowerCase().includes(query_text)
            || q.address?.toLowerCase().includes(query_text)      
         )
    )
    const categories = getCategoriesList(featureSchema)

    const sections = categories.map(cat => ({
        icon:getFeatureIcon(cat),
        name: cat.display_name,
        list: contractors.filter(c => c.categories.includes(`,${cat.value},`) || (!cat.value && [',,','',' '].includes(c.categories))
        ),
    }))
    
    sections.push({
        name: 'Other',
        icon: `/images/features/feature.svg`,
        list: contractors.filter(c => {
            const has_other_ones = c.categories.split(',').filter(Boolean).some(cat => isNaN(cat))
            return c.categories.includes(`,0,`) || ['',' ',',,'].includes(c.categories) || has_other_ones
        }),
    })
   
    let lists

    if(query_text){
        lists = <React.Fragment>
            {!!filteredContractors.length && 
                <p className=' font-weight-500 my-4'>Top matches</p>
            }

            {filteredContractors.length>0?

            <div className={ClientListClass}>

                {filteredContractors.map((c,i)=>

                    <div className='col px-2 py-2' key={i}>
                        <ClientCard {...c} onClick={()=>setEdited(c)} isContractor/>
                    </div>
                                        
                )}

            </div>
            :
            <div className='text-center my-2 py-3 my-sm-5 p-sm-5 col-12 text-secondary'>   
                <NothingFound text="No matches found" name="contractor" />
            </div>
            }

        </React.Fragment>

    }else{
        lists =  <React.Fragment>

            {sections.map(s=>
                s.list && !!s.list.length && 
                    <React.Fragment key={s.name}>    
                        <div className='d-flex mt-4 mb-2 align-items-center '>
                            <img src={s.icon} height='42px' className='filter-grayscale opacity-70' />
                            <p className=' font-weight-500 ml-2 lead'>{s.name}</p>
                        </div>

                        <div className={ClientListClass}>

                            {s.list.map((c,i)=>

                                <div className='col px-2 py-2' key={i}>
                                    <ClientCard {...c} onClick={()=>setEdited(c)} isContractor/>
                                </div>
                                                    
                            )}

                        </div>
                    </React.Fragment>
                
            )}


        </React.Fragment>

    }

    const contractorLists = <div className='container text-left '>
     
        {lists}
        
    </div>

    function validateForm(values) {
        const {email,first_name,last_name,mobile_number,company_name} = values
        const errors = {}
        if(!email && !mobile_number){
            console.log(email,mobile_number)
            errors.email = 'One of email or phone number is required'
        }
        if(!company_name){
            if(!first_name && !last_name){
                errors.company_name = 'Either full name or company name is required'
            }else
            if(!first_name){
                errors.first_name = 'First name is required'
            }else
            if (!last_name){
                errors.last_name = 'Last name is required'
            }
        }
        return errors
    }
    

    const handleBack = () => {

        if(step == 1 && (!edited || !edited.id)){
            hideModal()
        }
        else{
            setStep(1);
            if(!edited && form){setFormData(form.values)}
        }
    }

    const pickingCategories = step == 2

    const categoryPicker = !!pickingCategories && 
    <div className='container-fluid px-0'>
        <p className='font-weight-500 text-center pb-3 pt-2 '>
            What are their areas of expertise?
        </p>
        <ExpertisePicker
            disabled={onBoarded}
            {...{value:specialities,onChange:setSpecialities,featureSchema}}
        />
    </div>
    const ContractorInfo = <div className='flex-grow-1 d-flex flex-column'>
            {pickingCategories && categoryPicker }
             
            <div className={`flex-grow-1  flex-column container-fluid
                ${pickingCategories?'d-none':'d-flex'}
            `}>
                <ReferralMessage source={edited} type='contractor' />

                <Formik
                    innerRef={formRef}
                    initialValues={edited?initialValues:formData}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    validate={validateForm}
                >
                    {({values,setFieldValue,errors}) =>
                    <Form className= {`${pickingCategories?'d-none':''}  row no-gutters px-1`}>

                        {contractorFields.map(cf => 
                            <React.Fragment key={cf.name}>
                                <div className='col-3 small pl-0 ' 
                                    style={{fontWeight:'800',color:'var(--black6)'}}
                                >
                                    {cf.name}
                                </div>
                                <div className={`col-9 d-flex flex-column pr-0 ${onBoarded?'opacity-70':''}  `}>
                                    {cf.content || (cf.contentDynamic && cf.contentDynamic({values,setFieldValue,errors}))}
                                </div>
                                {/* {cf.orNext &&
                                    <React.Fragment>
                                        <div className='col-3'>
                                        </div>
                                        <div className='col-9 font-weight-500 text-muted text-medium text-center mb-1'>
                                            OR
                                        </div>
                                    </React.Fragment>
                                } */}
                            </React.Fragment>
                        )}

                    </Form>
                    }
                </Formik>
            </div>
            
            
    </div>

    const ContractorAdder = <div className='p-3 my-dialog-xl '>
        
        {ContractorInfo}

    </div>

    let activeSlide = loading? <LoadingCard /> : (contractors.length? contractorLists: <NothingFound name='contractor' />)
    function editCategories(){
        setStep(2)
    }
    async function deleteContractor(){
        try {
            const okay = await $confirm({question:'Are you sure you want to delete this contractor?'})
            const res = await Axios.delete($http.$baseUrl+'/contractors/'+edited.id)
            $http.$success(res)
            fetchContractors()
            setEdited(null)
        } catch (err) {
            if(err){
                $http.$error(err)
            }
        }
    }
    const handleContextMenu = (e) => {
        if(e == 'delete'){
            deleteContractor()
        }else if(e == 'contact'){
            history.push(`/messages?to=${edited.user}`)

        }
    }
    const options = ['contact']
    if(edited && !edited.user){
        options.push('delete')
    }
    const titleBar = <div className='d-flex 
        align-items-center '>

        <p className=' ml-4 my-2 font-weight-500'
            style={{color:'var(--black3)'}}
        >
            {edited?(edited.company_name||edited.full_name):'New contractor'}
            
        </p>
        <SimpleMenu
            onChange={handleContextMenu}
            options={options}
            className='px-2 bg-white mx-1'
        >
            <MoreHoriz style={{fontSize:"1.5em"}} 
                className='mx-3 text-primary'
            />
        </SimpleMenu>
    </div>

    const editorTabs = [
        {
            name:'Information',
            content:ContractorInfo,
            noActions:onBoarded
        },
        {
            name:'Expertise',
            content:ContractorInfo,
            noActions:onBoarded
        },
        {
            name:'Messages',
            content:ClientMessageList({client:edited,isContractor:true,loading}),
            disabled: !onBoarded,
            noActions:true,
        }
    ]
    async function fetchTheMessages(){
        try{
            setLoading(1)
            await dispatch(fetchMessages())
        }finally{
            setLoading(0)
        }
    }

    const handleTabChange = (t) => {
        if(t.name == 'Expertise'){
            editCategories()
        }
        else if(t.name == 'Messages'){
            fetchTheMessages()
        }
        if(edited && t.name == 'Information'){
            handleBack()
        }
    }

    const actionButtons = <ActionButtons 
        onBack={handleBack}
        backText={step == 1 ? 
            'Discard':'Back'
        }
        nextText= {
            (!edited && step==1)?'Next':'Save'
        }
        onNext={handleNext}
        loading={loading}
        className='pt-1 border-top mb-2 mx-2'
        noPadding
    />

    return <div className='d-flex flex-column flex-grow-1'>
         <ItemBar itemName='Contractor' addNew={()=>setShowAdder(true)} 
          value={query}
          onChange={e=>setQuery(e.target.value)}
        />

        <div className='d-flex flex-column flex-grow-1 '>

            {activeSlide}

        </div>

        <MDialog
            open={showAdder||!!edited}
            onClose={hideModal}
            titleBar={titleBar}
            paperPadding=' pt-1'
            paperStyle={{maxWidth:'1200px'}}
        >
            <div className='my-dialog-lg d-flex flex-column flex-grow-1 overflow-hidden ' >

                {
                showAdder?
                <React.Fragment>
                    <div className=' my-dialog-xl d-flex flex-column overflow-hidden flex-grow-1'>
                        <div className='d-flex flex-grow-1 overflow-auto '>
                            {ContractorAdder}
                        </div>
                        <div className=' '>
                            {actionButtons}
                        </div>
                    </div>
                </React.Fragment>
                :
                (!!edited?
                <ClientEditor item={edited} 
                    tabs={editorTabs} 
                    className='flex-grow-1'
                    onTabChange={handleTabChange}
                    actionButtons={actionButtons}
                >
                </ClientEditor>
                :null)
                }
            </div>
            
        </MDialog>


    </div>
}
function get_icon_or_empty(icon){
    return allowed_icons.includes(icon) ? icon : 'feature'
}

export function ExpertisePicker({disabled,value:specialities,onChange:setSpecialities, ...props}){
    const [isAdding,setIsAdding] = useState(false)
    const [featureName,setFeatureName] = useState('')
    const featureSchema = useSelector(selectFeatureSchema)
    const user = useSelector(getUser)
    const dispatch = useDispatch()
    const [loading,setLoading] = useState(0)
    
    const categories = specialities.filter(s=>!isNaN(s)).map(Number)
    
    const custom_categories = featureSchema?.categories?.filter(c=>c.requested_by == user.id)
    // const [featureRequests,setFeatureRequests] = useState(custom_categories)
    // const featureRequests = custom_categories;
 
    async function add_feature_request(category_name) {
        // setFeatureRequests([...featureRequests, feature])
        // setSpecialities([...specialities,feature])

        try {
            setLoading(1)
            const data = {name: category_name}
            const res = await  Axios.post(`${$baseUrl}/categories`,data)
            const new_cat = res.data
            
            setSpecialities([...specialities,new_cat.id])
            dispatch(setFeatureSchema({
                schema:{
                    ...featureSchema,
                    categories: [...featureSchema.categories,new_cat]
                }
            }))

        } catch (error) {
            $error(error)
        } finally{
            setLoading(0)
        }
    }  
    function remove_feature_request(feature) {
        // setFeatureRequests(featureRequests.slice().filter(f=>f != feature))
        setSpecialities(specialities.slice().filter(f=>f != feature))
    }

    const toggleSpeciality = c => {
        const cat = c//String(c)
        const ind = specialities.indexOf(cat)
        if(ind!=-1){
            setSpecialities(specialities.filter(s => s!=cat))
        }else{
            setSpecialities([...specialities,cat])
        }
    }

    const features = getCategoriesList(featureSchema)
    const stripped = featureName.replace(/[ ]/g,'')
    const name_already_exists = custom_categories.find(c => c.name.replace(/[ ]/g,'') == stripped) 
    const isNameValid = !!stripped && !name_already_exists

    return <div  
        className={`row no-gutters ${disabled?'opacity-70':''}`}
        // style={{display:'grid',gridTemplateColumns:'repeat(3,1fr)'}}
    >
        {features.slice().sort(sortAscending('display_name')).
        map(c=>
            {
                const icon = get_icon_or_empty(c.icon)

                return <div className={`col-6 col-sm-4 text-medium py-1 px-0 pr-1 
                    ${disabled?'disabled':'cursor-pointer'}
                `}
                    key={c.value}
                    style={{
                        filter:(categories.includes(c.value||c.id))?'':'grayscale(1.0) opacity(0.6)',
                        userSelect:'none'
                    }}
                    onClick={()=>!disabled && toggleSpeciality(c.value||c.id)}
                >
                    <Tooltip title={c.display_name}
                    >

                        <div className='d-flex text-primary font-weight-500 rounded-8
                        border-primary align-items-center border py-1 pr-3 pl-2'
                        >
                            <img src={getFeatureIcon(c)} 
                                height="24px" className='mr-2'
                            />
                            <p className='m-0 text-small text-primary text-truncate'>
                                {c.display_name}
                            </p>
                        </div>
                    </Tooltip>
                </div>
            }
        )}

        <ClickAwayListener onClickAway={()=>setIsAdding(false)}>
        <form className='col-12 d-flex mt-2'
            onSubmit={e => {
                e.preventDefault();
                if(isNameValid && !loading){
                    add_feature_request(featureName.replace(/,/g,''))
                    setFeatureName('')
                }
            }}
        >
            {!disabled && 
                <div className={`d-flex  font-weight-500 rounded-8 cursor-pointer border-thin border-primary
                        align-items-center py-2 px-2 ${isAdding&&false?' border-primary':'filter-grayscale'}
                        opacity-70
                    `}
                    onClick={()=>setIsAdding(!isAdding)}
                    title={isAdding?"Cancel":"Add a feature"}
                >
                    {isAdding?
                        <Close color='primary'/>
                        :
                        <Add color='primary'/>
                    }
                    {/* <img src={`/images/features/feature.svg`} height="24px"  /> */}
                    {!isAdding && 
                        <p className='m-0 text-small text-primary ml-1 mr-2 ' 
                        >
                            Add another
                        </p>
                    }
                </div>
            }

            {isAdding && 
                
                <React.Fragment>
                    
                    <input className='mx-1 mx-sm-2 border-thin rounded-8 flex-grow-1 flex-shrink-1 pl-2'
                        maxLength='48'
                        style={{minWidth:'20px',borderColor:'gray'}}
                        onChange={e=>setFeatureName(e.target.value)} value={featureName}
                        placeholder="Name of the feature"
                        autoFocus
                    />
                    <button className={`border border-primary bg-white rounded-8 
                        text-primary ${isNameValid?'':'opacity-50'}
                    `} 
                    type="submit" title="Add feature" disabled={!isNameValid||loading}
                    >
                        {loading?
                            <CircularProgress size='4' color='primary'/>: 
                            <Add/>
                        }
                    </button>
                    {/* <button className='bg-white rounded-8 text-secondary border ml-1' 
                        onClick={()=>setAddingFeatureRequest(false)}
                    >
                        <Close/>
                    </button> */}
                </React.Fragment>
            }

        </form>
        </ClickAwayListener>
        {name_already_exists && 
            <p className='text-small text-warning text-center col-12'>
                A feature with that name already exists.
            </p>
        }
{/* 
        {!!featureRequests.length && 
            <div className='col-12 '>
                <div className='d-flex align-items-center'>
                    <Divider className='flex-grow-1'/>
                    <p className='text-center text-muted mt-2 mb-2 mx-3'>
                        Other
                    </p>
                    <Divider className='flex-grow-1'/>
                </div>
                <div className='d-flex flex-wrap  pr-2 '>
                    {featureRequests.map((fr,i)=> 
                        <div className='d-flex border mw-100 rounded-8 p-1 border-primary1 text-primary 
                        mr-1 mb-1 px-2 align-items-center text-medium '
                            key={fr+i}
                        >
                            <p className='text-truncate flex-grow-1 overflow-hidden p-0 m-0 mx-1'>
                                {fr}
                            </p>

                            {!disabled && 
                                <Close color="primary" className='cursor-pointer ml-1 opacity-50'
                                    fontSize='small'
                                    onClick={()=>remove_feature_request(fr)}
                                />
                            }
                        </div>      
                    )}
                </div>
            </div>
        } */}

    </div>

}

export function getCategoriesList(featureSchema){
    const fs = featureSchema
    return (fs && fs.categories)||[] 
}


export const providerTypes = [
    {value:0,display_name:'Certified'},
    {value:1,display_name:'Certified and Bonded'},
    {value:2,display_name:'Bonded'},
]