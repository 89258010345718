import { Button, CircularProgress, TextField, useMediaQuery, useTheme } from '@material-ui/core'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { $baseUrl } from '../../utils/http'
import MDialog from '../common/MDialog'



export default function Help({open,onClose}){


    const defaultQuestions = [
        {
            question:`How can I change my email address of my account?`,
            answer:`At this time we don't support changing the email address on the website, you will have to email us at support@micasa.ai with your request.`
        },
        {
            question:`How do I change my password?`,
            answer:`You can change your password by navigating to Edit Profile option
             under "My Account" link on the top right corner of the portal or homeowner center. `
        },
        {
            question:`How can I change my home address in my account?`,
            answer:`To change your home address in your account, you'll have to email us at support@micasa.ai
             with your current address in the account, and the new address to be replaced with, along with the proof of address document.`
        },
        {
            question:`How can I edit the home facts to update information?`,
            answer:`You can edit them in the my-home section of the dashboard page.`
        },

    ]
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
    const [loading,setLoading] = useState(false)
    const [query,setQuery] = useState('')
    const [questions,setQuestions] = useState(defaultQuestions.map(d => ({...d,order:0})))

    useEffect(()=>{
        fetchFAQs();
    },[])

    async function fetchFAQs(){
        try {
            setLoading(true)
            const res = await Axios.get($baseUrl+'/faqs')
            const data =  res.data
            console.log(data)
            setQuestions(data)
        } catch (err) {
            console.log('failed to load faqs')
        } finally {
            setLoading(false)
        }

    }

    const title = <div className=' d-flex flex-column  mt-3 mb-2 text-center'>
        <h3 className='font-weight-bold my-3 '>
            Help & FAQs
        </h3>

        <div className='d-flex mb-3 px-2 px-sm-4 px-md-5'>

            <TextField  className=' flex-grow-1 '
            placeholder='Search by keyword, phrase or text' variant='outlined' size='small'
                onChange={e=>setQuery(e.target.value)}
            >
            </TextField>

            <Button variant='contained' disableElevation size='small' className='ml-2'>
                Search
            </Button>
        </div>


    </div>

    const micasaMail = 'support@micasa.ai'
    const subject = 'Help'
    const body = ''
    const emailLink = `mailto:${micasaMail}?subject=${subject}&body=${body}`

    
    const qtext = query.toLowerCase()
    const filteredQuestions = questions.filter(q =>
         (q.question.toLowerCase().includes(qtext)||q.answer.toLowerCase().includes(qtext))
    )
    const footer = <div>
        <p className='px-2 pb-3 pt-2 mt-3 small text-secondary text-center text-wrap'>
            If you don’t find the help you’re looking for, please reach out to us by 
            <span className='text-primary font-weight-500 mx-1'
                style={{cursor:"pointer"}}
                onClick={()=>window.location.href=emailLink}
            >
                clicking here
            </span>
        </p>

    </div>
    return <MDialog 
        noBorder
        open={true}
        onClose={onClose}
        titleBar={title}
        footer={footer}
        paperPadding=' p-2 p-sm-4 '
        fixedClose
        height={isMobile?'':'45vh'}
    >   

        <div className={`my-dialog-md px-4 pb-4 pt-3 d-flex flex-column mx-auto
         align-items-center  flex-grow-1 overflow-auto `}
            style={{backgroundColor:'#f2f2f2'}}
        >
            {loading ? 
                <CircularProgress className=' my-auto ' color='primary' />
                :
                <React.Fragment>
                    {
                        filteredQuestions.length?
                        filteredQuestions.sort((a,b) => b.order-a.order)
                        .map(q => 
                            <div key={q.question} style={{fontSize:'0.9em'}}>
                                <p className='font-weight-500 mb-1'>
                                    {q.question}
                                </p>
                                <div className='text-muted'
                                    dangerouslySetInnerHTML={{__html:q.answer}}
                                >
                                    {/* {q.answer} */}
                                </div>

                            </div>
                        ):
                        <div className='text-muted text-center p-4 my-auto'>
                            Could not find anything that matches your search
                        </div>
                    }
                </React.Fragment>
            }
        </div>

    </MDialog>
}