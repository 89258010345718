import { Button, Chip, CircularProgress, ClickAwayListener, Popper, TextField, useMediaQuery, useTheme } from "@material-ui/core";
import { ArrowDropDown, Search } from "@material-ui/icons";
import Axios from "axios";
import { useRef, useState } from "react";
import { $prettify } from "../../utils";
import { $baseUrl, $error } from "../../utils/http";


export default function AutoComplete({value,onChange,filter,url_filter,isMultiple,resource,itemPool=[],...props}) {
    // isMultiple = true
    // const [value,setValue] = useState(isMultiple?[1]:null)
    // const onChange = (e) => setValue(e.target.value)
    resource = resource || 'users'

    const theme = useTheme()
    const isMobile =  useMediaQuery(theme.breakpoints.down('xs'))

    const [loading,setLoading] = useState(0)
    const [results,setResults] = useState([])
    const [queryText,setQueryText] = useState('')
    const [searchAnchor,setSearchAnchor] = useState(null)
    const [selectedResult,setSelectedResult] = useState(1)

    const [resultPool,setResultPool] = useState([])

    const finalPool = [...resultPool,...itemPool]
    
    const default_name = $prettify(resource? resource.slice(0,-1) : 'Item') + ' '+value 
    const activeItem = !isMultiple && value && (finalPool.find(it => it.id == value || it.value == value) || {id:value,display_name:default_name})

    function openSearch(e){
        setSearchAnchor(e.target.parentElement)
        fetchResults('').then(res => setResults(res))
    }
    
    const inputRef = useRef()
    function handleKeyUp(e){

        // console.log(e.keyCode)
        // up,down arrows
        if ([38, 40].includes(e.keyCode)) {
          const ind = selectedResult;
          const maxInd = results.length;
          let newInd = ind;
    
          let diff = -1;
          // down arrow
          if (e.keyCode == 40) {
            diff = 1;
          }
          newInd = Math.max(1, Math.min(maxInd, ind + diff));
          setSelectedResult(newInd);
          const item_element = document.getElementById(newInd);
          // console.log(item_element);
          if (item_element) {
            item_element.scrollIntoView(false);
          }
        } else if (e.keyCode == 13) {
          // handle enter key
          if (selectedResult) {
            const item_element = document.getElementById(selectedResult);
            if (item_element) {
              item_element.click();
            }
          }
        }

    }


    async function fetchResults(query){
        try {
            const uri = resource
            setLoading(1)
            url_filter = url_filter ? '&'+url_filter : ''
            const res = await Axios.get(`${$baseUrl}/${uri}?search=${query}${url_filter}`)
            const results = res.data.results || res.data.data || res.data
            if(filter){
                return results.filter(filter)
            }
            return results
        } catch (error) {
            $error(error)
        } finally{
            setLoading(0)
        }
    }

    async function handleInputChange(e){
        const text = e.target.value
        setQueryText(text)

        // if(text && text.length > 1)
        {
            const res = await fetchResults(text)
            setResults(res)
        }
    }

    async function handleResultClick(item){
        let new_value = item.id 

        if(isMultiple){
            if(!value.includes(new_value)){
                new_value = [...value,new_value]
            }else{
                new_value = value
            }
        }

        onChange({target:{value:new_value}})

        if(new_value != value){
            setResultPool([...resultPool,item])
        }

        if(!isMultiple){
            setSearchAnchor(null)
        }
    }

    function removeValue(item){
        const val = item.id || item.value
        const new_values = value.filter(id => id != val)
        onChange({target:{value:new_values}})
    }

    const loseFocus = () => {setSearchAnchor( null);setQueryText('')}
    const selectedItems = !isMultiple?[]: (value||[]).map(id => finalPool.find(pi => pi.id == id || pi.value == id) || ({id:id,display_name:default_name}))

    const buttonMessage = isMultiple ? 'Add more' :  'Select One'

    const bRadius = '20px'
    const searching = false
    return <div>

        <ClickAwayListener onClickAway={loseFocus}>

                {/* <div className={classes.search}>
                    <SearchIcon />
                </div> */}
                <div className='d-flex flex-column p-1 border rounded-8'
                      style={{minWidth:isMobile?'':'280px',maxWidth:'420px'}}
                >
                    {isMultiple && 
                        <div className='d-flex flex-wrap my-1' sytle={{minHeight:'24px'}}>
                            {selectedItems.map(item =>
                                <Chip key={item.id} className='mb-1 mr-1'
                                    label={item.display_name||item.name||item.email||item.full_name}
                                    onDelete={()=>removeValue(item)}
                                    size='small'
                                />
                            )}
                        </div>
                    }

                    <Button
                        onClick={openSearch}
                        variant={'outlined'}
                        className='d-block '
                    >
                        {activeItem?
                            <div className='d-flex justify-content-between pl-2 pr-1'>
                                <p>
                                {(activeItem.display_name||activeItem.full_name||activeItem.name||activeItem.email)}
                                </p>

                                <ArrowDropDown />
                            </div>
                            
                            :
                            <p className='text-secondary text-medium'>
                            {buttonMessage}
                            </p>
                        }
                    </Button>

                </div>
        

        </ClickAwayListener>

        <Popper
            open={!!searchAnchor}
            anchorEl={searchAnchor}
            container={searchAnchor}

            // disablePortal={true}
            flip
            onClose={loseFocus}
            style={{ width: isMobile?'':'100%',zIndex:'12',marginTop:'12px',maxWidth:'320px',top:'12px' }}
            placement="bottom"
            // disablePortal
            modifiers={{
                flip: {
                    enabled: false,
                },
            }}

            className='bg-light rounded shadow-sm d-flex flex-column shadow overflow-hidden '
        >

            <div
            className={'d-flex border-bottom'}
            style={{
                backgroundColor: searching ? 'transparent' : '#00000011',
                 zIndex:'12'
            }}
            // style={{position:'relative',backgroundColor:'red'}}
            >
                <input
                    className={`p-2  border-0 flex-grow-1`}
                    placeholder="Search"
                    ref={inputRef}
                    // onFocus={e => setSearchAnchor(e.target.parentElement)}
                    // style={{backgroundColor:'red',position:'relative'}}
                    style={{
                    ...(searching ? { backgroundColor: '#00000044', borderTopRightRadius: bRadius, borderTopLeftRadius: bRadius } : null),
                    }}
                    value={queryText}
                    onChange={handleInputChange}
                    onKeyUp={handleKeyUp}
                    autoFocus
                />
                <div className='p-2'>
                    {
                    loading == 1 ?
                        <CircularProgress size='18px' color='primary' className='mx-1' />
                    : <Search />
                    }
                </div>

            </div>
        
            {
                // !(queryText?.length>1) ?
                // <p className='p-5 text-muted'>
                //    Search for {resource}
                // </p>
                // :
                results?.length?
                <div className='d-flex flex-column overflow-auto rounded-bottom ' style={{maxHeight:'240px'}}>
                    {results.map((item,i) => {
                        const isSelected = selectedResult == i+1
                        return <p key={item.id}  className='p-2 px-3 hover-dark cursor-pointer text-left text-small '
                            onClick={()=>handleResultClick(item)} id={i+1}
                            style={{backgroundColor: isSelected ? '#ebf6ff' : 'white'}}
                        >
                            {item.display_name||item.full_name||item.name||item.email}
                        </p>
                    }
                    )}     
                </div>
                :
                <p className='p-5 text-muted'>
                    {loading == 1 ?
                        'Loading...'
                        :
                        'Nothing found'
                    }
                </p>
            }




        </Popper>


    </div>
}