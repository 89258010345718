import { default as React, useEffect, useState } from 'react';
import { $error } from '../../utils/http';
import {VFormField,} from '../../views/dashboard/InitialSetup'
import PlaceIcon from '@material-ui/icons/Room'
import { ClickAwayListener } from '@material-ui/core';
import { useField } from 'formik';


function PlaceCard({place,active,handleClick}){
    const p = place
    const {
        main_text,secondary_text,
        main_text_matched_substrings,
        secondary_text_matched_substrings
    } = p.structured_formatting

    const main_substrings = getMatchedParts(main_text,main_text_matched_substrings)
    const second_substrings = getMatchedParts(secondary_text,secondary_text_matched_substrings)

    return <div className=' py-2 px-1 pb-3 d-flex cursor-pointer pac-item' 
        style={{width:'100%',backgroundColor:(active?'rgb(192, 222, 251)':'')}}
        onClick={handleClick}
    >
        <PlaceIcon htmlColor={active?'red':'#929292'} fontSize='small' />
        <p className='m-0 text-truncate text-muted d-block text-small'>
            <span className='text-small mx-1 ' style={{color:'black'}}>
                {main_substrings}
            </span>
            <span className='text-tiny text-muted ' >
                {second_substrings}
            </span>
        </p>
    </div>
}

export default function AddressField({formikRef,setGoogleAddress,setplacescount,placeTouched,...props}){
    const [predictions,setPredictions] = useState(null)
    const [autoService,setAutoService] = useState(null)
    const [show,setShow] = useState(false)
    const [field,meta] = useField(props)
    
    function fetchDetails(place){
        console.log(autoService)
        const div= document.getElementById('places-dropdown')
        const placesService = new window.google.maps.places.PlacesService(div);

        placesService.getDetails({placeId:place.place_id},
            fillInAddress);
        
    }
    function fillInAddress(place){
        const formik = formikRef.current
        // const place = autocomplete.getPlace();
        // console.log(place)

        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        console.log(lat,lng)
        const location = `SRID=4326;POINT (${lng} ${lat})`

        const fieldMap = {
            street_number:  {field:'line1',type:'short_name'},
            floor:          {field:'floor',type:'short_name'},
            route:          {field:'line2',type:'short_name'},
            locality:       {field:'city',type:'short_name'},

            sublocality_level_1:       {field:'sublocality1',type:'short_name'},
            sublocality_level_2:       {field:'sublocality2',type:'short_name'},
            administrative_area_level_2:       {field:'municipality',type:'short_name'},


            administrative_area_level_1:    {field:'state',type:'short_name'},
            postal_code:    {field:'zip_code',type:'short_name'},
            country:        {field:'country',type:'short_name'},
        }

        if(!formik){return;}

        // used for checking if user changed any feild from google address
        const googleAddress = {}
      
        for (const component of place.address_components) {
           const addressType = component.types[0];
            
          const fieldConf =  fieldMap[addressType]
          if(fieldConf) {
            const val = component[fieldConf.type];
            googleAddress[fieldConf.field] = val
          }
        }
        // If there is no city
        if(!googleAddress.city){
            googleAddress.city = googleAddress.sublocality1 || googleAddress.sublocality2 || googleAddress.municipality
        }
        delete googleAddress.municipality
        delete googleAddress.sublocality2
        delete googleAddress.sublocality1

        // if(placeData){

        //     googleAddress.line1 =  placeData.terms[0].value
        //     googleAddress.line2 =  placeData.terms[1].value

        //     googleAddress.city =  placeData.terms[2].value
        //     googleAddress.zip_code =  placeData.terms[3].value
        //     googleAddress.country =  placeData.terms[4].value

        // }else {
        //     return;
        // }

        if(!googleAddress.line1){
            return $error('Please pick an address with street/door number')
        }
        googleAddress.location = location
        const formatted_address = place.formatted_address
        const place_id = place.place_id
        console.log(place)
 
        // for (const fieldConf in fieldMap) {
        //     formik.setFieldValue(fieldConf.field,"");
        // }

        googleAddress.line1 += ' '+googleAddress.line2
        
        let floor = googleAddress.floor
        delete googleAddress.floor

        if(floor){
            floor = numberSuffix(floor)+' floor'
            googleAddress.line2 = floor
        }else{
            googleAddress.line2 = ''
        }
        
        

        for(let field in googleAddress){
            // console.log(field,googleAddress[field])
            formik.setFieldValue(field,googleAddress[field]);
        }
        formik.setFieldValue('google_place_id',place_id);

        // Line2 is an optional field for (floor number,flat number within apartment etc)
        delete googleAddress.line2
        setGoogleAddress?.(googleAddress,{formatted_address,place_id})

        setShow(false)
    }

    function handlePrediction(tpredictions,text){
        if(setplacescount){
            let count =  tpredictions?tpredictions.length:0
            if(text == ' '){
                count = 1
            }
            console.log(count,text)
            setplacescount(count)
        }
        setPredictions(tpredictions)
        console.log(tpredictions)
    }


    function handlePlaceSelect(index){
        console.log('index = '+index)
        fetchDetails(predictions[index])
    }

    const handleKeyDown =  event => {
        if(predictions){
            if(event.keyCode == 13){
                return handlePlaceSelect(active)
            }

            const len = predictions.length
            if (event.keyCode === 38) { // up
                setActive(Math.max(0,active-1))
            }else if (event.keyCode === 40){ // down
                setActive(Math.min(len-1,active+1))
            }
        }
    };

    useEffect(()=>{

        const input = document.getElementById('hahaGoogle')
        if(input && window.google){
            
            const options = { types: ["address"],  
                componentRestrictions: {country: ['usa','ca']}  
            }
            // const autocomplete = new window.google.maps.places.Autocomplete(input, options);
            const autocompleteService = new window.google.maps.places.AutocompleteService();
            const placesService = null;


            // autocompleteService.setFields(["address_component"]);

            setAutoService({autocompleteService,placesService})

            // autocomplete.setFields(["address_component"]);
            // autocomplete.addListener("place_changed", ()=>fillInAddress(autocomplete));
            
        }
        // document.addEventListener('keyup',handleKeyDown)


    },[])

    function fetchPredictions(e){
        setShow(true)
        let text = e.target.value || ' '
        console.log(text)
        const  {autocompleteService} = autoService
        autocompleteService.getPlacePredictions({input:text,types: ["address"],componentRestrictions: {country: ['usa','ca']}},
            (preds)=>handlePrediction(preds,text))

    }
    const [active,setActive] = useState(-1)
    const errorText = (meta?.error) || ''

    return <ClickAwayListener onClickAway={()=>{
        if(placeTouched){placeTouched();}
        setShow(false)
        }}>

    <div className='position-relative d-flex flex-column'
      onKeyUp={handleKeyDown}
    >
        <VFormField {...props} 
            inputProps={{
                ...(props.inputProps||{}),
                id:"hahaGoogle",
                autoComplete:'new-password'
            }} 
            placeholder={props.placeholder||"Address Line 1"}
            onInput={fetchPredictions}
            onFocus={()=>{setShow(true)}}
            onBlur={e=>e.stopPropagation()}
            ignoreError
        />

        {(predictions && show)?
        <div className='position-absolute bg-white border text-left'
         style={{top:'100%',zIndex:'3',width:'100%'}}
        >
               { predictions.map( (p,i) =>
                    <PlaceCard place={p}  key={p.description} active={active==i} 
                        handleClick={(e)=>{
                            setShow(true);handlePlaceSelect(i)}}
                    />
                )}
                <div className='d-flex justify-content-end m-1'>
                    <img src='/images/dashboard/powered_by_google.png' />
                </div>
        </div>
        :null}

        {errorText && 
            <p className='text-danger text-small'>
                {errorText}
            </p>
        }

        <div id="places-dropdown"></div>
    </div>
    </ClickAwayListener>

}




function numberSuffix(number){
    const num = Number(number) % 20
    let suffix = 'th'
    switch(num){
        case 1:
            suffix = 'st'
            break;
        case 2:
            suffix = 'nd'
            break;
        case 3:
            suffix = 'rd'
            break;
    }

    return number+suffix
}


function getMatchedParts(mtext,matched_substrings){
    let offset = 0;
    const main_parts = [] 
    const len = mtext.length

    if(!matched_substrings){
        return mtext
    }
    
    for(let mat of matched_substrings){
        const start = mat.offset;
        const end = start +  mat.length

        // Push the previous non-matched part
        if(offset !== start){
            main_parts.push(
                <span key={offset}>
                    {mtext.slice(offset,start)}
                </span>
            )
        }
        
        main_parts.push(
            <span className='font-weight-bold' key={start}>
                {mtext.slice(start,end)}
            </span>
        )
        
        offset = end
    }
    if(offset < len-1){
        main_parts.push(
            <span key={offset}>
                {mtext.slice(offset,len)}
            </span>
        )
    }
    return main_parts
}