
import React, { useEffect, useRef, useState } from 'react'
import {padZero} from '../../utils'
import { MenuItem, Select } from '@material-ui/core'
import DatePicker from './DatePicker'

export const to12hours = tim => {
    const [h,m] = tim.split(':').map(t=>Number(t))
    let th = h>12?h-12:(h?h:12)
    return `${(th)}:${padZero(m)} ${h>=12?'PM':'AM'}`
}

export const selectProps = {
    variant:'outlined',
    size:'small',
    SelectDisplayProps:{
        style:{
            padding:'10px 18px',
        }
    },
    MenuProps:{
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },
        getContentAnchorEl: null
    },
    displayEmpty:true
}

export default function TimePicker({value,onChange,fullMinutes,oldModel,...props}){

    const allTimes = []
    const minute_step = 30

    for(let i=0;i<24;i+=1){
        for(let j=0;j<60;j+=minute_step){
            allTimes.push([i,j])
        }
    }
    

    const hours = []
    const minutes = []
    const ampm = ['AM','PM']

    for(let i=0;i<12;i+=1){
        hours.push(i+1)
        if(fullMinutes){
            for(let j=0;j<5;j+=1){
                minutes.push(i*5+j)
            }
        }else{
            minutes.push(i*5)
        }
    }

    let [ih,im] = (value || '').split(':').map(m=>Number(m))

    // Add a menu item for non hh:00 or hh:30 values 
    if(oldModel && value && im%10){
        console.log(ih,im)
        const newIndex = (ih * (60/minute_step) + Math.floor(im / 30)) +1 
        allTimes.splice(newIndex,0,[ih,im])
    }

    const ia = ih>=12
    ih = ih>12?(ih-12):(ih?ih:12)
    const h = value ? (ih) : '0'
    const m = value ? (im) : '0'
    const a = (ia?'PM':'AM')

    console.log(value)


    const changeTime = onChange || (()=>{})

    function setTime(key,value){
        let newTime = [h,m,a]
        newTime[key] = value
        console.log(key,value)
        const [th,tm,ta] = newTime
        console.log(th,tm)

        let ih = th+((ta=='PM'&&th!=12)?12:0)
        ih = (ih==12&&ta=='AM')?0:ih
        changeTime(`${padZero(ih)}:${padZero(tm)}`)
    }
    const firstTime = useRef(true);

    const selProps = {
        ...selectProps,
        SelectDisplayProps:{
            style:{
                padding:'12px 32px 12px 24px',
                maxHeight:'120px',
                borderRadius:'0'
            },
        },
        style:{borderRadius:'0'}
    }
    if(!oldModel){
        return <div {...props} className={`d-flex align-items-center ${props.className} `} >
            {[[h,hours],[m,minutes],[a,ampm]].map(
                (t,i) => 
                <React.Fragment key={i}>
                    <Select value={t[0]} onChange={e=>setTime(i,e.target.value)}
                    {...selProps}
                    className={`${i!=1?(i<1?'rounded-left':'rounded-right'):'border-0 mr-12'} mr-0
                        flex-grow-1 
                    `}
                    key={i}
                    >
                    <MenuItem value={''}>
                        ---
                    </MenuItem>
                    {t[1].map(tick=>
                        <MenuItem key={tick} value={tick}>
                            {padZero(tick)}
                        </MenuItem>
                    )}
                    </Select>
                    {/* {!!i?null:
                    <p className='m-0 font-weigh-bold p-1'>:</p>
                    } */}
                </React.Fragment>
            )}
        </div>
    }else{
        return <Select className=' d-flex flex-column px-1 px-sm-2 font-weight-500'
            {...selectProps}
            SelectDisplayProps={{
                style:{
                    padding:'12px 12px',
                    maxHeight:'120px'
                },
            }}
            value={value.slice(0,5)}
            onChange={e=>changeTime(e.target.value)}
        >
            {allTimes.map(([h,m]) =>
                <MenuItem key={h+100} value={padZero(Math.floor(h))+':'+padZero(m)}>
                    {Math.floor(h%12 || 12)}:{padZero(m)} {h>=12?'PM':'AM'}
                </MenuItem>
            )}
    
        </Select>
    }

}

export function DateTimePicker({
    value = '2021-12-21T10:10:00Z',
    onChange=(()=>{})
    ,oldModel,...props}
){
    value = (value||'').trim()
    const [dat,tim] = (value).split('T')

    const [date,setDate] = useState(dat || '')
    const [time,setTime] = useState(tim?.replace(':00Z','').replace('Z','')|| '')

    useEffect(()=>{
        const dstr = date+'T'+time
        if(date && time){
            console.log(date)
            console.log(time)

            let dateTime = new Date(dstr).toISOString()
            onChange({target:{value:dateTime}})
        }
    },[date,time])

    return <div className='d-flex '>
        <DatePicker 
          value={date}
          onChange={setDate}
          className='mr-2'
        />
        <TimePicker
            oldModel
            // fullMinutes
            value={time}
            onChange={setTime}
        />

    </div>


}