import React from 'react'


export default function Error(props){

    let error = 404


    return <div className='d-flex flex-column flex-grow-1 
    justify-content-center align-items-center'>

        <div>
            <h1 className='text-primary font-weight-bold display-3'>
                404
            </h1>
            <p style={{color:'gray'}}>
                The page you are looking for is not found
            </p>

        </div>
        
    </div>


}