import {createSlice} from '@reduxjs/toolkit'
import Axios from 'axios'
import { $error, $success, $baseUrl, $event } from '../../utils/http'
import { useSelector } from 'react-redux'
import { fetchNotifications,initSocketIO } from '../user/userSlice'

export const authSlice = createSlice({
    name:'auth',
    initialState:{
        user:null,
        fresh:true,
    },
    reducers:{
        setFresh:(state,action) => {
            state.fresh = action.payload
        },
        setUser: (state,action) => {
            state.user = action.payload
        },
        log_in: (state,action) => {
            const {remember,...userData} = action.payload
            state.user = userData
            console.log(userData.token)
            userData.remember = remember
            localStorage.setItem('user',JSON.stringify(userData))
            localStorage.setItem('lastSeen',Date.now())      
            // if(remember){
            //     console.log('remember!!')
            // }else{
            //     localStorage.setItem('user','')
            // }

            Axios.defaults.headers.common.Authorization = 'token '+userData.token;
        },
        logout: (state) => {
            if(!state.user){return }
            if(window.socket){
                window.socket.emit('logout',String(state.user.id))
            }
            socialLogout(state.user)
            $event('logout');
            state.user = null
            localStorage.setItem('user',null)
            delete Axios.defaults.headers.common.Authorization
        }
    }
})

export const getUser = state => state.auth.user
export const {log_in,logout,setUser} = authSlice.actions

export const login = (payload) => async (dispatch,getState) => {
    try {

        const new_user = payload 
        dispatch(log_in(new_user))
        const user = getState().auth.user
        if(new_user && new_user.token){
            dispatch(fetchNotifications())
            initSocketIO(new_user,dispatch,getState)
        }
        return new_user

    } catch (err) {
        $error(err)
        throw err
    }
}

export const sign_in = ({remember,...creds}) => async dispatch => {

    try {
        const url =  $baseUrl+'/login'
        console.log(url,creds)
        const res = await Axios.post(url,creds)

        $success(res)
        dispatch(login({...res.data.user,remember}))
    } catch (err) {
        // $error(err)
        throw err
    }
    return true
}

export const sign_up = creds => async dispatch => {

    try {
        const url =  $baseUrl+'/sign_up'
        console.log(url,creds)
        const res = await Axios.post(url,creds)

        $success(res)
        // dispatch(login(res.data))
    } catch (err) {
        throw err
    }
    return true
    
}

export function socialLogout(user){
    let type = 1
    const FB = window.FB

    if(user.facebook_uid){type = 2}
    if(type === 2){
        FB?.getLoginStatus((response) => {
            if (response.status === 'connected') {
                FB.logout(() => {
                    console.log('logged out of Facebook!');
                    window.location.reload();
                })
            } 
            return false;
        })
    }
}

export const fetchUser = (user_id) => async (dispatch,getState) => {
    try {
        const res = await Axios.get($baseUrl+'/users/'+user_id+'')
        const user = res.data
        
        const fresh = getState().auth.fresh
        
        dispatch(authSlice.actions.setFresh(false))
        const local_user = JSON.parse(localStorage.getItem('user'))
        localStorage.setItem('user',JSON.stringify({...local_user,...user}))

        await dispatch(setUser(user))
        return user 
    } catch (err) {
        throw err
    }

}

export const patchUser = (data) => async (dispatch,getState) => {
    let user = getState().auth.user
    try {
        const userData = {...user}
        const user_id = userData.id
        const res = await Axios.patch($baseUrl+'/users/'+user_id+'',data)
        user = res.data.data
        if(user){
            await dispatch(setUser(user))
        }
        return res 
    } catch (err) {
        throw err
    }

}


export default authSlice.reducer