import {createSlice} from '@reduxjs/toolkit'
import Axios from 'axios'
import { $baseUrl, $error, $success } from '../../utils/http'
import io from 'socket.io-client';
import { fetchUser } from '../auth/authSlice';
import { $prettify } from '../../utils';
import { safe_parse } from '../../components/special/NotificationCard';

const genericResources = [
    'quotes',
    'services',
    'clients',
    'contractors',
    'symptoms',
    'checklists',

]
const genericData = {}
const genericSetters = {}
const genericSelectors = {}

for(let r of genericResources){
    genericData[r] = []
    const thing = `${$prettify(r)}`
    genericSetters[`set${thing}`] = (state,action) => {state[r] = action.payload}
    // genericSelectors[`select${thing}`] = state => 
}

export const userSlice = createSlice({
    name:'user',
    initialState:{
        plans:[],
        notifications:[],
        messages:[],
        featureSchema:null,
        house:null,
        FRE:null,
        features:[],

        schema:{},
      
        ...genericData,

    },
    reducers:{
        setFeatureSchema(state,action){
            const {feature,schema} = action.payload
            if(feature){
                if(!state.featureSchema){state.featureSchema={}}
                if(!state.featureSchema[feature]){state.featureSchema[feature]={}}
                state.featureSchema[feature].fields = schema
            }else{
                state.featureSchema = schema
            }
        },        
        setFRE: (state,action) => {state.FRE = action.payload},
        ...genericSetters,
        setMessages: (state,action) => {state.messages = action.payload},

        setHouse: (state,action) => {state.house = action.payload},
        setFeatures: (state,action) => {state.features = action.payload},
        setPlans: (state,action) => {state.plans = action.payload},
        resetNotifications: (state,action) => {state.notifications = action.payload},
        pushNotification:(state,action) => {
            const newOne = {read:false,...action.payload,}
            state.notifications.unshift(newOne)
        },
        setSchema:(state,action)=>{
            const {resource,schema} = action.payload
            state.schema[resource] = schema
        },
        markAsRead:(state,action) => {
            const id = action.payload
            const message = state.notifications.find(m=> m.id == id)
            if(message){
                message.is_read = true
            }
        },
        popNotification:(state,action) => {
            const index = action.payload
            const [popped] = state.notifications.splice(index,1)
        },
      
    }
})

export const getPricing = state => state.user.plans
export const getUserType = state => state.auth.user && state.auth.user.user_type
export const getNotifications = state => state.user.notifications
export const getHouse = state => state.user.house
export const getFRE = state => state.user.FRE
export const selectFeatureSchema = state => state.user.featureSchema
export const selectFeatures= state => state.user.features

export const getHouseSchema = state => state.user.schema.house
export const getContractorSchema = state => state.user.schema.contractor
export const selectServiceSchema = state => state.user.schema.service

export const selectServices = state => state.user.services
export const selectQuotes = state => state.user.quotes
export const selectChecklists = state => state.user.checklists

export const selectSymptoms = state => state.user.symptoms
export const selectMessages = state => state.user.messages

export const selectClients = state => state.user.clients
export const selectContractors = state => state.user.contractors


// export const getContractorSchema = state => state.user.getContractorSchema



export const userTypes = {
    home_owner:0,
    agent:1,
    property_manager:2,
    potential_buyer:3,
    contractor:4,
};
const roleNames = []
for(let k in userTypes){
    const v = userTypes[k]
    roleNames[v] = k
}
export const userTypeNames = roleNames

export const quoteStatus = {
    canceled:-1,

    refused_service:1,
    quote_provided:2,
    quote_rejected:3,

    requested_alternate_time:4,
    provided_alternate_time:5,

    scheduled:8,
    dispatched:9,
    in_progress:10,

    completed:100,
    paid:101,

    requested: 0,
}

const {setPlans,} = userSlice.actions
export const {pushNotification,setMessages,setServices,
    popNotification,markAsRead,setFRE,setFeatureSchema,
    setChecklists,setClients,
    resetNotifications,setHouse} = userSlice.actions
const { setFeatures,setQuotes,
    setSchema,setSymptoms,
    } = userSlice.actions

export const fetchPlans = () => async dispatch => {
    try {
        const url =  window.baseUrl+'/pricing'
        const res = await Axios.get(url)
        
        dispatch(setPlans(res.data))
    } catch (err) {
        $error(err)
        throw err
    }
    return true
}

export const fetchHouse = (id) => async dispatch => {

    try {
        const house_res = await Axios.get($baseUrl+`/houses/${id}`)
        console.log('fetched house!!  ')
        console.log(house_res.data)
        dispatch(setHouse(house_res.data))

    } catch (err) {
        const res = err.response 
        if(res && res.status == 404){
            $error("House was not found")
        }else{
            $error(err)
        }
        throw err
    }
    return true
}
export const fetchSchema = ({resource}) => async (dispatch,getState) => {
    try {
        const existingSchema = getState().user.schema[resource]
        if(existingSchema){return}

        let url = `houses`
        switch(resource){
            case "house":
                url = `houses`
                break
            case "contractor":
                url = `contractors`
                break
            case "service":
                url = `services`
                break
        }
        url = `${$baseUrl}/${url}`

        const res = await Axios.options(url)
        dispatch(setSchema({resource,schema:res.data.actions.POST}))
    } catch (err) {
        $error(err)
        throw err
    }
}
export const fetchFeatureSchema = (feature) => async (dispatch,getState) => {
    try {
        let f_category = feature?.category
        const featureSchema = getState().user.featureSchema
        const defaultExists = !f_category && featureSchema && (Object.keys(featureSchema).length > 8)
        const featureExists = f_category && featureSchema && featureSchema[f_category] && featureSchema[f_category].fields
        
        if(defaultExists){return}
        if(defaultExists||(featureExists && f_category!='feature')){return}

        
        const all_things = Axios.options($baseUrl+`/houses/${420}/features`)
        const specific = Axios.options($baseUrl+`/houses/${420}/features/${f_category||'simple_feature'}`)
        const promises = [specific]
        

        if(!feature){
            promises.unshift(all_things)
        }

        const [res1,res2] = await Promise.all(promises)
        
        let feature_data = null
        
        if(feature){
            f_category = 'simple_feature'
            feature_data = res1.data.actions.POST
            
            // const category = featureSchema.categories.find(f => f.value==feature.category)
            // const extra_fields = category?.extra_fields
            // feature_data = {...feature_data,...extra_fields}
        }
        else{
            feature_data = {...res1.data}
            feature_data.simple_feature = {}
            feature_data.simple_feature .fields = res2.data.actions.POST

            let {categories,category_groups} = feature_data

            categories = categories.map(cat => {                    
                const extra_fields = cat.fields && safe_parse(cat.fields)
                delete extra_fields.notes
                return {...cat,extra_fields,}
            })
            feature_data.categories = categories
            const baseUrl = $baseUrl.replace('/api','') 
            feature_data.icons = (feature_data.icons||[]).map(i => ({...i,
                image_url:`${baseUrl}${i.image}` 
            }))
            // feature_data.baby_features = categories
            feature_data.category_list = category_groups.map(cat_group => ({
                ...cat_group,
                sub_features: categories.filter(cat => cat.parent == cat_group.id)
            }))
            // feature_data.category_choices = categories
        }
        dispatch(setFeatureSchema({feature:f_category,schema:feature_data}))
    

    } catch (err) {
        $error(err)
        throw err
    }
} 


export const fetchFeatures = (active_home) => async (dispatch,getState) => {
    try {
        const schema = getState().user.featureSchema
        console.log(schema)
        function getCategory(feature){
            if(feature.category_group){return schema.category_groups.find(cg => cg.id == feature.category_group)}
            if(feature.category){return schema.categories.find(cg => cg.id == feature.category)}
        }
        if(!schema?.categories?.length){return []}

        const res = await Axios.get($baseUrl+`/houses/${active_home}/features`)
        let featuresList = res.data.data
        featuresList = featuresList.map(c => {
            const extra_details = c.extra_details && safe_parse(c.extra_details)
            const child = {...extra_details,...c,/*type: c.category_group?'feature':'simple_feature'*/}

            const cat = getCategory(child)

            child.name = child.name || cat?.name || cat?.icon
            child.icon = child.icon || cat?.icon

            delete child.extra_details
            return child
        })

        const features = featuresList.filter(f=> f.parent == null).map(f => ({...f,children:[]}))
        const children = featuresList.filter(f=> f.parent != null)
        children.forEach(c => {
            const parent = features.find(f => f.id == c.parent && f.hasOwnProperty('category_group'))
            if(parent){
                parent.children.push(c)
            }
        })
        const flatFeatures = [...children,...features]
        for(let f of flatFeatures){
            f.name = f.name || $prettify(f.type)
        }
    
        dispatch(setFeatures(features))

    } catch (err) {
        $error(err)
        throw err
    }
    return true
}
export const fetchServices = () => async (dispatch,getState) => {
    try {
        const res = await Axios.get($baseUrl+`/services`)
        const services = res.data?.results || res.data
        for(let s of services){
            s.q_updated_at = s.updated_at
            s.type = 'service'

            for(let q of s.quotes){
                if(q.updated_at > s.q_updated_at){
                    s.q_updated_at = q.updated_at
                }
            }
            const feat = s.feature
            const extra_details = (feat?.extra_details && safe_parse(feat.extra_details)) || {}
            s.feature = {...extra_details,...feat,}

        }
        dispatch(setServices(services))
    } catch (err) {
        $error(err)
        throw err
    }
    return true
}
export const fetchQuotes = () => async (dispatch,getState) => {
    try {
        // const quotes = getState().user.quotes
        const res = await Axios.get($baseUrl+`/quotes`)
        dispatch(setQuotes(res.data?.results || res.data))
    } catch (err) {
        $error(err)
        throw err
    }
    return true
}

export const fetchResource = ({resource}) => async (dispatch,getState) => {
    try {
        const res = await Axios.get($baseUrl+`/${resource}`)
        dispatch(
            userSlice.actions[`set${$prettify(resource)}`](res.data)
            // setServices(res.data)
        )
        return res.data?.results || res.data
    } catch (err) {
        $error(err)
        throw err
    }
    return true
}

export const fetchSymptoms = () => async (dispatch,getState) => {
    try {
        const res = await Axios.get($baseUrl+`/symptoms`)
        dispatch(setSymptoms(res.data))
    } catch (err) {
        $error(err)
        throw err
    }
    return true
}
export const fetchMessages = () => async dispatch => {
    try {
        const url =  window.baseUrl+'/messages'
        const res = await Axios.get(url)
        
        dispatch(setMessages(res.data))
    } catch (err) {
        $error(err)
        throw err
    }
    return true
}

export const markNotificationAsRead = (id) => async (dispatch,getState) => {

    try {
        const notifications = getState().user.notifications
        const message = notifications.find(m=> m.id == id)
        if(message){
            if(!message.is_read){
                const house_res = await Axios.patch($baseUrl+`/notifications/${message.id}`)
                dispatch(markAsRead(id))
            }
        }else{
            return
        }

    } catch (err) {
        // $error(err)
        throw err
    }
    return true
}

export const fetchNotifications = () => async (dispatch) => {
    try {
      const res = await Axios.get($baseUrl+'/notifications')
      let notifications = res.data
    //   console.log(notifications)
  
      dispatch(resetNotifications(notifications))
  
    } catch (err) {
      $error(err)
    }
  
  }

export default userSlice.reducer
export function initSocketIO(user,dispatch,getState){

    if(user){
      let baseUrl = $baseUrl
      baseUrl = baseUrl.replace('/api','')
      const socket = io(baseUrl,{query:{token:user.token},});

    const old_socket = window.socket
    if(!!old_socket){
        if(old_socket.close){
            old_socket.close()
        }
        if(old_socket.disconnect){
            old_socket.disconnect()
        }
        // (old_socket.close||old_socket.disconnect)()
    }

      window.socket = socket

      socket.on('message',(data)=>{
        console.log('Received data!!!')
        // alert(JSON.stringify(data))
        dispatch(pushNotification(data))
      })

      socket.on('update',(data)=>{
        console.log('Received update!!!')
        // alert(JSON.stringify(data))
        const house = getState().user.house
        const user = getState().auth.user
        const {level,message} = data
        if(level =='house' &&house){
            dispatch(fetchHouse(house.id))
        }
        else if(level =='features' &&house){
            dispatch(fetchFeatures(house.id))
        }
        else if(level =='services'){
            dispatch(fetchServices())
        }
        else if(level =='quotes'){
            dispatch(fetchQuotes())
        }
        else if(level =='categories'){
            dispatch(fetchFeatureSchema())
        }
        else if(level =='symptoms'){
            dispatch(fetchSymptoms())
            console.log(`%c Fetching symptoms...`,'color:green;')
        }
        else if(level =='checklists'){
            dispatch(fetchResource({resource:'checklists'}))
        }
        else if(level =='message'){
            const messages = getState().user.messages
            if(!messages.some(m=>m.id == message.id)){
                dispatch(setMessages([...messages,message]))
            }
        }
        if(level =='user' && user){
            dispatch(fetchUser(user.id))
        }

      })

      socket.on('message_read',(data)=>{
        console.info('Read a message!!!')
        dispatch(markNotificationAsRead(data))
        
      })
    }
}
