import { Accordion, AccordionDetails, AccordionSummary, Checkbox, CircularProgress, MenuItem, Select } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import BackIcon from '@material-ui/icons/ArrowBack'
import EditIcon from '@material-ui/icons/Edit'
import Axios from 'axios'
import qs from 'querystring'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { getUser } from '../../app/auth/authSlice'
import {
    getHouse,
    selectFeatures, selectFeatureSchema, selectServices,
    selectSymptoms
} from '../../app/user/userSlice'
import { DocumentUploader } from '../../components/common/DocumentUploader'
import { DocumentViewer } from '../../components/common/DocumentViewer'
import FieldWithLabel from '../../components/common/FieldWithLabel'
import MDialog from '../../components/common/MDialog'
import MExpand from '../../components/common/MExpand'
import { NameBadge } from '../../components/special/HomeDialog'
import { $prettify, padZero } from '../../utils'
import { allowed_icons } from '../../utils/constants'
import { getFeatureIcon } from '../../utils/helpers'
import { $baseUrl, $error, $event, $success } from '../../utils/http'
import { getCategoriesList } from '../dashboard/agent/Contractors'
import { ActionButtons, getConditionIcon, selectProps } from '../dashboard/FeatureEditor'
import { getSelectedDocs, QuoteTile } from './ServiceDetail'

export default function ServiceAdder({feature_id,service_id,onClose,...props}){
    const [WHICH_FEATURE,ISSUE,ATTACH,GET_QUOTES,SUMMARY] = (new Array(6)).fill(0).map((s,i)=>i)
    const rootFeatures = useSelector(selectFeatures)
    const location = useLocation()
    const query = qs.parse(location.search.replace('?',''))
 
    const [category,fId] = (feature_id && feature_id.split('|').map(f => Number(f))) || [null,null]
    // console.log(fType,fId)
    console.log(category,fId,rootFeatures)
    let active_feature = feature_id && getFeatureById({id:fId,category},rootFeatures)
    
    const [step,setStep] = useState(query.step?Number(query.step):WHICH_FEATURE)
    const [parentFeature,setParentFeature] = useState(active_feature && active_feature.parent)
    const [activeFeature,setActiveFeature] = useState(active_feature)
  
    const [selectedProviders,setSelectedProviders] = useState([])
    const symptoms = useSelector(selectSymptoms)
    const services = useSelector(selectServices)
    const [edited,setEdited] = useState(null)
    const isMobile = window.outerWidth < 576

    const [popup,setPopup] = useState(0)

    const [loading,setLoading] = useState(0)
    const initialData = {
        symptom: -1,
        description:"",
        documents:[],
        contractors:[],
    }
    const user = useSelector(getUser)
    const house = useSelector(getHouse)
    const service = services.find(s=>s.id==service_id)

    active_feature = activeFeature || active_feature 

    const [documents,setDocuments] = useState([])
    const [formData,setFormData] = useState(initialData)
    const features = (parentFeature && parentFeature.children) || 
                rootFeatures.slice().sort((f1,f2)=>(f2.children.length-f1.children.length))
   
    async function handleSubmit(data){
        try {
            setLoading(2)

            data.user = user.id
            if(active_feature){
                data.house = active_feature.house 
                data.feature_id = active_feature.id
                // data.feature_type = active_feature.category
                // data.feature = active_feature.id
            }
            
            delete data.contractors
            data.service_providers = formData.contractors.map(sp=>sp.id)
            data.selected_documents = formData.documents.join(',')

            let method = 'post'
            let uri = ''
            if(edited){
                method = 'patch'
                uri = '/'+edited.id
                data.description = data.description + newDetails
            }else{
                $event('service_requested',{"client":user.id,"user_id":user.id})
            }

            let form_data = data
            let config;

            /// Uncomment this to allow file upload during service-request creation 

            // form_data = new FormData()
            // Object.keys(data).forEach(key => {
            //     form_data.append(key,form_data[key])
            // })

            // form_data['file_count'] = filesPicked.length
            // filesPicked.forEach((file,i)=>{
            //     form_data.append(`file_${i}`,file.file)
            // }) 
            // config = {headers: {'Content-Type': 'multipart/form-data'}}

            const res = await Axios[method]($baseUrl+`/services${uri}`,form_data,config)

            $success(res)
            onClose()
        } catch (err) {
            $error(err)
        }finally{
            setLoading(0)
        }
    }
 
    const fieldProps = {
        variant:'outlined',
        size:'small',
        inputProps:{style:{fontSize:'0.8em',fontWeight:'500'}}
    }
   
    const handleFeatureClick = (f) => {
        if(f.children && f.children.length){
            setParentFeature(f)
        }else{
            setActiveFeature(f)
        }
    }

    useEffect(()=>{
        if(activeFeature){
            const active_feat = getFeatureById(activeFeature,rootFeatures)
            if(active_feat){
                setActiveFeature(active_feat)
            }
        }
        // If page is reloaded,get feature id from url and set it as activeFeature
        if(feature_id && !activeFeature){
            const feature = getFeatureById({id:fId,category:category},rootFeatures) 
            console.log(feature)
            setActiveFeature(feature)
        }
    },[rootFeatures])

    useEffect(()=>{

        if(service_id){
            const s = services.find(s=>s.id==service_id)
            if(s){
                let active_feature = getFeatureById({id:s.feature_id},rootFeatures)
                setActiveFeature(active_feature)
                console.log(s)
                const providers = s.quotes.map(s=>s.provider)

                setFormData({
                    symptom: s.symptom || 0,
                    description:s.description,
                    documents: getSelectedDocs(s),
                    contractors:providers
                })
                setSelectedProviders(providers)
                setEdited({...s,providers})

                setStep(SUMMARY)
            }
            
        }

    },[service_id])
    
    let feature_name = activeFeature?(activeFeature.name):null
    if(!feature_name){
        if(service){
            feature_name = service.feature.name
        }else{
            feature_name = 'Feature'
        }
    }
    const reviewing = step == SUMMARY

    const PencilIcon = (props)=> <EditIcon 
        className='ml-auto text-primary '
        fontSize='small'
        {...props}
    />

    const category_id = (activeFeature || active_feature || {}).category
    let symps = symptoms.filter(s=> (s.category == category_id))

    const issue = <FieldWithLabel
        label='Issue'
    >
        <Select
            {...selectProps}
            onChange={e=>setFormData({...formData,symptom:e.target.value-1})}
            value={(formData.symptom+1)}
            style={{width:'100%',paddingLeft:'4px'}}
            // IconComponent={
            //     !reviewing?
            //     ExpandMore:
            //     PencilIcon
            // }
        >
            {formData.symptom<0?
                <MenuItem value={0}>
                    Please select one
                </MenuItem>
            :null}

            <MenuItem value={1}>
                Other
            </MenuItem>

            {symps.map(c=>
            <MenuItem key={c.id} value={c.id+1}>
                {c.name}
            </MenuItem>
            )}
        </Select>
    </FieldWithLabel>

    const [newDetails,setNewDetails] = useState('')
    // const des_ref = useRef()
    const description = <FieldWithLabel
        label='Description'
        required={!formData.symptom}
        // editable={!!reviewing}
        // onEdit={()=>{if(des_ref.current){des_ref.current.focus()}}}
    >
        <div className='d-flex flex-column position-relative '>
            <textarea
                // ref={des_ref}
                className='big p-2 rounded-8 border-thick text-medium'
                rows={4}
                style={{resize:'none',outline:'none'}}
                placeholder='Please provide any additional information'
                value={formData.description+''+newDetails}
                onChange={e=>{
                    if(service_id){
                        let val = e.target.value
                        val = val.slice(formData.description.length)
                        setNewDetails(val)
                    }else{
                        setFormData({...formData,description:e.target.value})
                    }
                }}
            >
            </textarea>
            {/* {!reviewing?null:
                <PencilIcon className='position-absolute text-muted ' 
                    style={{top:'4px',right:'4px',pointerEvents:'none'}}
                />
            } */}

        </div>
    </FieldWithLabel>
    const [filesPicked,setFilesPicked] = useState([])
    const [fileError,setFileError] = useState('')

    const [touchedFeatures,setTouchedFeatures] = useState([])
    const service_feature = active_feature || (service && service.feature && service.documents && 
        {...service.feature,documents:service.documents,house:service.house})
    const fakeFieldClass = `p-2 px-3 border-thick rounded-8 text-small text-muted
    font-weight-500 `

    let featureDetails =  <React.Fragment>
                {(service_id&&service)?
                <FieldWithLabel
                    label='Address'
                >   
                    <p className={fakeFieldClass}>
                        {service.details.address}
                    </p>
            
                </FieldWithLabel>
                :null}
                <FieldWithLabel
                    label='Feature'
                >   
                    <p className={fakeFieldClass}>
                        {feature_name}
                    </p>
                    
                </FieldWithLabel>
                {issue}
                {description}
    </React.Fragment>

    if(service_id && service ){
        featureDetails = <MExpand 
            expanded
            summary={<p>
    {(service.feature.mname)+'-'+(service.details.symptom)}
            </p>}
        >
            {featureDetails}
        </MExpand>
    }

    let serviceDate = ''
    if(service){
        const date = new Date(service.created_at)
        serviceDate = `${padZero(date.getDate())}/${padZero(date.getMonth()+1)}`
    }

    function attachMore(){
        setPopup(ATTACH)
        setDocuments(formData.documents)
    }


    const getSomeQuotes = {
        title:`Get some quotes`,
        caption:'Select which contractors you would like to use',
        notYet:(!selectedProviders.length),
        extraBack:'Back',
        onNext:()=>{
            setFormData({...formData,contractors:selectedProviders})
        },
        onBack:()=>{
            if(!!popup){
                setSelectedProviders(formData.contractors)
            }
        },
        content:<div className='mt-3'>

            <QuoteSelector  
                activeCategory={activeFeature && activeFeature.category}
                selected={selectedProviders}
                setSelected={setSelectedProviders}         
                ignore={(edited&&edited.providers.map(p=>p.id))||[]}       
            />

        </div>
    }

    const attachDocuments = {

        title:`Anything to attach?`,
        caption:'We’ve listed documents of the selected feature',
        extraNext:'Attach',
        extraBack:'Back',
        onNext:()=>{
            setFormData({...formData,documents})

        },
        onBack:()=>{
            if(popup){
                setDocuments(formData.documents)
            }
        },
        content:<div>

            {service_feature?
                <DocumentUploader
                    isButton
                    source={service_feature} 
                    house_id={service_feature.house}
                    style={{minWidth:'32px'}}
                    label='Upload new attachment'
                    onAddNew={d => setDocuments([...documents,d.id])}
                    // buttonOnly
                    // onFilePicked={(fname,file,errors)=>{
                    //     if(file && !errors.length){
                    //         setFilesPicked([...filesPicked,file])
                    //     }
                    // }}
                />
            :null}

            {service_feature?

            <React.Fragment>
                {/* <p className='text-small text-muted font-weight-500 '>
                    Feature documents
                </p> */}
                <DocumentViewer
                    source={service_feature} 
                    onClose={()=>{}} 
                    house={{id:service_feature.house}}
                    listOnly
                    selectable
                    nonDeletable
                    selected={documents}
                    filterer={d => (d.user_type == 0 )}
                    handleChecked={(d)=>{
                        const docs =  documents
                        const checked = docs.includes(d.id)
                        if(checked){
                            setDocuments(docs.filter(td=>td!=d.id))
                        }else{
                            setDocuments([...docs,d.id])
                        }
    
                    }}
                    // openUpload={()=>setPopup({type:'upload'})}
                />

                {/* {
                    !!filesPicked.length && 
                    <div className='d-flex flex-column'>
                        <p className='text-small font-weight-500'>
                            Newly uploaded:
                        </p>
                        {filesPicked.map((f,i) => 
                            <div className='d-flex mb-1' key={f?.name+i}
                            >

                                <button className='p-1 bg-white ml-1 pl-2 mr-2'
                                onClick={()=>setFilesPicked(filesPicked.filter(tf=>tf!=f))}
                                >
                                    <Close htmlColor='var(--pri)' />
                                </button>

                                <img src='/images/dashboard/document.svg' 
                                    width={'20px'}
                                />

                                <p className=' ml-2 text-secondary text-medium flex-grow-1'>
                                    {f.name}
                                </p>
                            </div>
                        )}
                    </div>
                } */}

            </React.Fragment>


            
            :'list of files!'}

      
        </div>
    }
    const doc_count = formData.documents.length+(service_id?0:filesPicked.length)
    const steps = [
        {
            title:'Request service',
            caption:'What feature would you like to service?',
            content:<div className=''
            >
               {parentFeature?
                    <div className='d-flex py-3 pl-2  border-bottom 
                    align-items-center font-weight-500 '
                        style={{display:parentFeature?'flex':'none'}}
                    >
                        
                            <React.Fragment>
                                <button onClick={()=>setParentFeature(null)}
                                    className='bg-white p-0 m-0 mr-3'
                                >
                                    <BackIcon/> 
                                </button>
                                {parentFeature &&($prettify(parentFeature.name))}
                            </React.Fragment>
                    
                    </div>
                :null}
                <div className='overflow-auto  rounded-8 p-1 '
                >
                    {features.map(f=>{
                        const is_this_active = active_feature && f.id == active_feature.id && f.category == active_feature.category
                        const condition_icon = getConditionIcon(f)
                        const is_in_service = condition_icon.includes('in_service')
                        const unique_key = f.category+'_'+f.id
                        const is_touched = touchedFeatures.includes(unique_key)
                        return <div className={`d-flex pl-1 pr-2 py-3 border-bottom   
                        align-items-center cursor-pointer
                        ${is_in_service?'opacity-50!!!':''}
                        ${active_feature && is_this_active?
                            'bg-primary rounded-8 text-white':'text-primary'} 
                        `}  key={unique_key}
                            onClick={()=>{
                                const tf = unique_key
                                if(!touchedFeatures.includes(unique_key)){
                                    setTouchedFeatures([...touchedFeatures,unique_key])
                                }
                                if(!is_in_service){handleFeatureClick(f)}
                            }}
                        >
                            <img src={condition_icon} height='28px' />
                            {parentFeature?null:
                                <img src={getFeatureIcon(f)} height='32px'
                                    className='ml-2'
                                    style={{filter:is_this_active?'brightness(0) invert(1)':'brightness(0)'}}
                                />
                            }
                            <div className='d-flex flex-column mx-2 ml-3 flex-grow-1'>
                                <div className='d-flex justify-content-between'>

                                    <p className={`m-0  font-weight-500
                                        ${is_this_active?'text-white':'text-black'}
                                        ${parentFeature?'text-medium':''}
                                    `}>
                                        {$prettify(f.name)}
                            
                                    </p>

                                    {f.children && f.children.length?
                                        <p className={`m-0 text-small  ml-auto font-weight-500
                                            ${is_this_active?'text-white':'text-muted'}
                                        `}>
                                            {f.children.length?`${f.children.length} sub features`:''}
                                        </p>
                                    :null}

                                </div>
                                {(!is_touched||!is_in_service)?null:
                                    <p className='text-small text-danger m-0 '>
                                        Already has a service request in place
                                    </p>
                                }
                            </div>
                                         
                        </div>
                    })}
                </div>

            </div>,
            notYet:(!active_feature)
        },
        {
            title:`What's the issue?`,
            caption:'',
            content:<div>
                {issue}
                {description}
            </div>,
            notYet:formData.symptom<0 || (formData.symptom==0 && (newDetails+formData.description).length<1)
        },
        attachDocuments,
        getSomeQuotes,
        {
            title:service_id?'':`Request summary`,
            caption:'',
            next:'Submit',
            back:(service_id!==true)?'Cancel':"Back",
            content:<div>
                {!service?null:
                    <div className='d-flex align-items-center '>
                        <p className='font-weight-bold text-medium mr-auto'>
                            Request submitted on {serviceDate}
                        </p>
                    </div>
                }
                {featureDetails}
    
                <FieldWithLabel
                    label='attachments'
                    editable
                    onEdit={attachMore}
                >
                    <div className='d-flex rounded-extra border-thick 
                    text-small  p-2 cursor-pointer align-items-center'
                    onClick={attachMore}
                    >
                        <div className='m-0 d-flex align-items-center'>
                            <div className={`p-0 pl-1 font-weight-500 text-${documents.length?'primary':'muted'} `} >
                                {doc_count || 'No'}
                            </div>
                            <p className='m-0 text-secondary ml-1'>
                            attachment{doc_count==1?'':'s'}
                            </p>
                        </div>

                        {/* {editIcon} */}
                    </div>
                </FieldWithLabel>
                {!!reviewing?
                    <FieldWithLabel
                        label='Contractors'
                        editable
                        onEdit={()=>setPopup(GET_QUOTES)}
                    >
                        <div className='d-flex rounded-extra border-thick p-2 
                        cursor-pointer align-items-center'
                        onClick={()=>setPopup(GET_QUOTES)}
                        >
                            <p className=' m-0 text-small mx-1 text-primary font-weight-500'>
                                {selectedProviders.length} 
                                <span className='ml-1'>
                                    selected{selectedProviders.length?':':''}
                                </span>
                            </p>
                            <p 
                            className='text-muted text-small m-0 text-truncate'
                            >
                                {selectedProviders.map(sp=>sp.full_name).join(', ')}
                            </p>
                            
                            {/* {editIcon} */}
                            
                        </div>
                    </FieldWithLabel>
                    :
                    <MExpand
                        summary={<React.Fragment>
                            <p>
                                Contractors & Quotes
                            </p>

                            <button className='text-primary bg-transparent text-small ml-auto mr-0 pr-0'
                                 onClick={()=>setPopup(GET_QUOTES)}
                            >
                               Get more quotes
                            </button>

                        </React.Fragment>
                        }
                    >
                            {service && service.quotes.map(q =>
                                    <QuoteTile quote={q} showArrow={!true} key={q.id} />
                            )}

                    </MExpand>
            
          
                }

            </div>
        },
    ]
    const history = useHistory()

    const max = steps.length-1


    const currentStep = steps[Math.abs(step)]
    const popupStep = steps[popup]
    const topStep = popup ? popupStep : currentStep

    const handleNext = () => {
        if(topStep.onNext){
            topStep.onNext()
        }
        
        if(!!popup){
            return setPopup(0)
        }
        if(step+1 == steps.length){
            handleSubmit({...formData})
        }else{
            setStep(step+1)
        }
    }
    const handleBack = () => {
        if(topStep.onBack){
            topStep.onBack()
        }
        if(!!popup){
            return setPopup(0)
        }else{
            setStep(Math.max(step-1,0))
        }
        if(step == 0){
            onClose()
        }
        if(step == SUMMARY){
            if(service_id !== true){
                onClose()
            }
        }
    }

    const renderSlice = (activeSlice,isPopup) => {
        const actionButtons = <ActionButtons
            className='my-1 '
            onNext={handleNext}
            onBack={handleBack}
            disabled={!!activeSlice.notYet}
            nextText={activeSlice.next|| (!isPopup?'Next':(activeSlice.extraNext||'Save'))}
            backText={activeSlice.back||(!isPopup?'Back':(activeSlice.extraBack||'Cancel'))}
            loading={loading}
        />

        const targetSlice =  <React.Fragment>
            <div className='d-flex flex-column flex-grow-1 overflow-hidden '>
                <h5 className='font-weight-bold '>
                    {activeSlice.title}
                </h5>
                {activeSlice.caption?
                    <p className='text-small text-muted'>
                        {activeSlice.caption}
                    </p>
                :null}
                <div className='flex-grow-1 overflow-auto pr-1'>
                {activeSlice.content}
                </div>
            </div>

            
        </React.Fragment>

        // if(isPopup){
        //     return <React.Fragment>
        //         {targetSlice}
        //         {actionButtons}
        //     </React.Fragment>
        // }else{
        // }
        return [targetSlice,actionButtons]
    }

    const [targetSlice,actionButtons] =  renderSlice(currentStep) || []
    const [popupSlice,actionButtons2] =  (!!popup && renderSlice(popupStep,true)) || []

    return <React.Fragment>
        <MDialog
            open={!popup}
            onClose={onClose}
            titleBar={
                (service_id) &&      
                <h5 className='font-weight-bold p-3 m-0  d-flex align-items-center'>
                    Service request 
                    <p className='text-muted mx-2 '>
                        #{padZero(service_id,3)}
                    </p>
                </h5>
            }
            height={(!isMobile && service_id)?'45vh':''}
            footer={actionButtons}
        >

            <div className='my-dialog-sm p-1
            flex-grow-1 d-flex flex-column overflow-hidden
            '>
                {targetSlice}

            </div>

        </MDialog>
        {!!popup && [GET_QUOTES,ATTACH].includes(popup) &&

            <MDialog
                open={!!popup}
                onClose={()=>setPopup(0)}
                footer={actionButtons2}
                height={!isMobile?'max(45vh,400px)':''}
            >
                <div className='my-dialog-sm p-2 px-3 d-flex flex-column'>
                    {popupSlice}
                </div>

            </MDialog>
        }
    </React.Fragment>
    
}



export function QuoteSelector({activeCategory,ignore=[],selected,setSelected,...props}){

    const featureSchema = useSelector(selectFeatureSchema)

    const [providers,setProviders] = useState([])
    const categories = getCategoriesList(featureSchema)
    const [loading,setLoading] = useState(1)

    const activeCat = categories.find(c => c.value== activeCategory)
    console.log(activeCat)
    async function fetchProviders(cat){
        try {
 
            setLoading(1)

            const res = await Axios.get($baseUrl+`/contractors/category/${cat||0}`)
            let bois = res.data

            setProviders(bois)
        } catch (err) {
            $error(err)
        } finally {
            setLoading(0)
        }
    }

    const providerTypes = ['Certified','Certified and Bonded','']

    useEffect(()=>{

        fetchProviders(activeCategory)

    },[])

    let slide = <div className='d-flex flex-column align-items-center mt-4'>

        <CircularProgress/>
        <p>
            Loading...
        </p>

    </div>

    if(!loading){
        const morePossible = !providers.length || providers.some(p => !ignore.includes(p))
        slide = <React.Fragment>
            {providers.length?
                providers.map(p=>
                {
                let full_name = p.full_name
                if(full_name == p.email || full_name == p.email){
                    full_name = ''
                }
                if(!full_name){
                    full_name = (p.first_name || '') +' '+ (p.last_name || '')
                    full_name = ''.trim()
                }
                const company_name = (p.company_name||'').split(' ').map(s => $prettify(s)).join(' ')
            
                const primary_name = (company_name && !full_name) ? company_name : full_name
                const secondary_name = (company_name && full_name) ? company_name : p.email

                const ignored =  ignore.includes(p.id)
                const checked = selected.some(s => s.id == p.id) || ignored
                return <div className={`d-flex py-2 pr-2 pl-0 border-bottom 
                    align-items-center cursor-pointer ${ignored?'bg-light opacity-50':'hover-light '}`}
                    key={p.id}
                    onClick={()=>{
                        if(ignore.includes(p)){return}

                        if(!!checked){
                            setSelected(selected.filter(sp=>sp.id!=p.id))
                        }else{
                            setSelected([...selected,p])
                        }
                    }}
                >
                    <Checkbox
                        checked={checked}
                        color='primary'
                        size='medium'
                        className=''
                    />
                    <NameBadge source={p}  />

                    <div className='d-flex flex-column overflow-hidden'>
                        <p className='m-0 text-truncate'>
                            {primary_name}
                        </p>
                        {secondary_name && 
                            <p className='text-small text-muted m-0 text-truncate'>
                                {secondary_name}
                            </p>
                        }
                    </div>
                    {/* <p>
                        {JSON.stringify({primary_name,secondary_name})}
                    </p> */}

                    <p className='ml-auto m-0 text-success text-medium text-right'>
                        {providerTypes[(p.provider_type||0)]}
                    </p>
                </div>
                }
                )
            :   <div className='d-flex flex-column  my-4 py-4
                    justify-content-center align-items-center'>
                    <img src='/images/landing/tools.svg' height='86px' />
                    <p className='text-muted my-2'>
                        No contractors found for this feature
                    </p>
                </div>
            }

            {morePossible?null:
                <p className='text-small text-danger my-3'>
                    There are no additional providers for this feature.
                </p>
            }
        </React.Fragment>

    }
  
    return <React.Fragment>
        {!activeCat?null:
            <p className='p-2 rounded-8 border-thick '>
                <img src={getFeatureIcon(activeCat||{})} height='24px'
                    className='mr-2'
                />
                {activeCat.display_name}
            </p>
        }

        <div className='d-flex flex-column  overflow-auto'
            style={{maxHeight:'50vh'}}
        >
            {slide}
        </div>

    </React.Fragment>

}


function getFeatureById({id,category},features){

    let flatFeatures = [...features]
    features.forEach(rf => {
        flatFeatures = [...flatFeatures,...rf.children.map(rc=>({...rc,parent:rf}))]
    });
    let active_feature = flatFeatures.find(f=> f.id==id && f.category == category)

    return active_feature
}

