import  qs from 'querystring'
import Axios from 'axios'
import {login} from '../app/auth/authSlice'
import store from '../app/store'
import { $event } from './http'

const config = {
    headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
}

async function LoginUsingData(data){
    try {
        console.log(data)
        const res = await Axios.post(window.baseUrl+'/social_login',qs.stringify(data),config)
        console.log(res.data)
        store.dispatch(login(res.data.user))
        console.log('successfully logged socially!!')
    } catch (err) {
        console.log(err)
        throw(err)
    }
}
const googleClientId = process.env.REACT_APP_GOOLE_OAUTH_ID
export function googlelogin(formData) {
    const gapi = window.gapi
    return new Promise( (res,rej) => {
        gapi.load('auth2', function () {
            let auth2 = gapi.auth2.init({
                client_id: googleClientId,
                fetch_basic_profile: true,
                scope: 'profile  email openid',
                prompt: 'consent'
            });
            
            auth2.signIn().then(async function () {
                const data = {
                    ...formData,
                    'full_name': auth2.currentUser.get().getBasicProfile().getName(),
                    "email": auth2.currentUser.get().getBasicProfile().getEmail(),
                    "auth_provider": "google",
                    "social_id": auth2.currentUser.get().getBasicProfile().getId(),
                 }
                console.log('logged in using google!!')
                console.log(data)
                try {
                    await LoginUsingData(data)
                    $event('login',{method: 'Google'});
                    res(true)      
                } catch (err) {
                    rej(err.error||err)
                }
            }).catch( err => {
                console.log(err)
                rej(err.error||err)
            });
        },(err)=>{
            rej(err.error||err)
        });

    })
}

export function facebooklogin(data) {
    const FB = window.FB
    return new Promise( async (res,rej) => 
            FB.getLoginStatus(function (response) {
                if (response.status === 'connected') {
                    DoFacebookLogin(FB,data).then(dres => res(dres))
                } else {
                  
                    FB.login(function (login_response) {
                        if(login_response.status === 'connected'){
                            console.log(login_response)
                            console.log('connected!!!')
                            DoFacebookLogin(FB,data).then(dres => res(dres))
                        }
                        else{
                            rej('Failure')
                            console.log(login_response)
                        }
                        return false;
                    }, { scope: 'public_profile,email',return_scopes: true});
                }
            return false;
        })
    )
}


async function DoFacebookLogin(FB,formData) {
    return new Promise( (res,rej) => {
        FB.api('/me?fields=id,email,name',async function (response) {
            var email = response.email;
            var full_name = response.name;
            var social_id = response.id
            var is_valid = false
            if (email && full_name && social_id) {
                is_valid = true
            }
            if (is_valid) {
                const data = {
                    ...formData,
                    'full_name': response.name,
                    "email": response.email,
                    "auth_provider": "facebook",
                    "social_id": response.id,
                    
                }
                console.log('logged in using facebook!!!')
                console.log(data)
                try {
                    await LoginUsingData(data)
                    $event('login',{method: 'Facebook'});
                    res(true)      
                } catch (err) {
                    rej(err)
                }
            }else{
                rej('Invalid credentials')
            }
        });
    })
   
}
