import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Terms, Privacy, Disclaimer } from '../../content/texts';


export default function Legal(props){

    const match = useRouteMatch('/legal/:type')

    const type = match &&  match.params.type;



    return <div className='p-4 text-left container'>
        {
            type === 'terms'?

            <Terms />
            :(
                type === 'privacy'?
                <Privacy />
                :
                <Disclaimer />
            )

        }

        

    </div>
}