import React from 'react';
import ChoiceCard from "../input/ChoiceCard";





export default function PlanCard({plan,isTrailing,trialPeriod,...props}){

    return <ChoiceCard 
        {...props}
    >
            <div className='d-flex align-items-center '>
                <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'>
                    <p style={{fontWeight:'500',margin:'0',fontSize:'1.0em'}}
                        className='mr-3'
                    >
                        {plan.plan}
                    </p>
                    <p 
                        className=' my-0 py-0 text-small font-weight-500' 
                    style={{color:'var(--pri)'}}
                    >
                        ${plan.price} a month
                    </p>
                </div>
                {isTrailing && (!!trialPeriod)?
                    <span 
                    className='text-white px-2 py-1 mx-0 rounded ml-auto small'
                        style={{backgroundColor:'var(--pri)'}}
                    >
                       {trialPeriod} trial
                    </span>
                :null}
            </div>

            <p style={{color:'gray',margin:'4px 2px',fontSize:'0.7em'}}>
                {plan.setup_description}
            </p>

    </ChoiceCard>
}