import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectDialog, setDialog } from '../../app/ui/uiSlice'
import Help from './Help'
import { getUser } from '../../app/auth/authSlice'
export default function Footer({...props}){
    const {userType} = props
    const user = useSelector(getUser)

    const location = useLocation()
    const isLanding = ['/','/home'].includes(location.pathname)
    
    const isAgent = userType === 'agents' && isLanding
    const dispatch = useDispatch()
    const dialog = useSelector(selectDialog)
    const isMobile = window.outerWidth < 420

    const textStyle = {}
    if(isAgent){textStyle.color = 'white'}

    const [showText,setShowText] = useState(0)

    if(user?.is_staff){return <div className='mt-2'></div>}

    const buttonClass = 'ml-3 text-medium font-weight-500 d-inline-block cursor-pointer'
    const isMessages = location.pathname === '/messages'
    const isChecklists = location.pathname === '/dashboard/checklists'

    const helpButton = !(user?.is_staff) &&  <button 
        className={` border p-1 px-3   border-bottom-0
            ${((isAgent&&false)?'bg-primary text-white':'bg-white text-primary')} 
        `}
        style={{fontSize:'14px',fontWeight:'500',
        borderRadius:'0px',
        borderTopRightRadius:(isMessages||isChecklists)?'0px':'6px',
        borderTopLeftRadius:'6px',
        // right:'0px',
        // top:'0px',
        // position:'absolute',
        // transform:'translateY(-100%)'

    }}
        onClick={()=>{dispatch(setDialog('help'))}}
    >
        Help & FAQs
    </button>

    return <div className={`  ${(isAgent?'bg-pri-light':'')} `}>

        <div 
            className={`container  px-0 d-flex 
                    justify-content-end ${(isAgent?'':' border-bottom')} 
                    ${(isMessages||isChecklists)?'border-left':''}
            `}
        >
            {(isMessages || isChecklists) && 
                <div className='mr-auto border-right d-none d-sm-block' style={{width:'var(--message-panel-width)'}}  />
            }
            {helpButton}
        </div>
        {/* <div className={`d-flex justify-content-end border-bottom
            ${isMessages?'border-left':''} `}
        >
        {helpButton}
        </div> */}
    
        <div style={{backgroundColor:isAgent?'var(--pri)':'white'}}
            className={` ${isMessages?'d-none d-sm-block ':''} `}
        >    
            <div 
            className={`container pt-2 py-3 pb-sm-4 position-relative ${isAgent?'border-primary':''} `} 
            
            >
            

                <div className='row landing-page '>

                    <div className=' col-12 col-sm-6 text-center text-sm-left'>

                        <p style={{
                            display:'block',
                            color:'gray',
                            ...(isAgent?textStyle:{}),
                            margin:'0'
                        }}
                        >
                            ® micasa {(new Date()).getFullYear()}. All Rights Reserved.
                        </p>

                    </div>
                    <div className=' col-12 col-sm-6 d-md-flex justify-content-end  px-0'>
                        <div 
                            className={buttonClass}
                            onClick={()=>{dispatch(setDialog('privacy'))}}
                            style={textStyle} 
                            >
                            Security & Privacy
                        </div>
                        <div className={buttonClass}
                            onClick={()=>{dispatch(setDialog('terms'))}}
                            style={textStyle}
                            >
                            Terms of use
                        </div>

                    </div>
                        
                    {
                    dialog && dialog.name=='help'?
                        <Help
                            onClose={()=>dispatch(setDialog(null))}
                        >

                        </Help>
                    :null}

                </div>

            </div>
        </div>
    </div>
    

}