import React from 'react'
import { Appear } from '../../views/HomeOwnerLanding'

export default function LandingPart({small,delay=400,dark,animated,children,...props}){
    const {title,description,image} = props
    return <Appear 
    className={`${dark?'bg-pri-dark':'bg-primary'}
     container  rounded pb-5 `+(small?' mt-0 ':' mt-5')
    } 
    style={{marginBottom:small?'1.4em':'10em'}}
    {...props}
    delay={delay+100}
    >
        <div className='row ' 
        style={{transform:'translateY(32px)',}}
        >
            <div className='col-md-5 text-white text-left order-md-2'>
                <Appear component='h4' className=' landing-display-2 font-weight-bold'
                    delay={delay+200}
                    animated
                >
                    {title}
                </Appear>
                <Appear component='p' className='' delay={delay+600}>
                    {description}
                </Appear>
                {
                    children
                }

            </div>
            <Appear className='col-md-7' delay={delay+400} animated>
                <img src={'/images/landing/'+image} alt='landing-image' 
                className='position-relative w-100 '
                style={{ maxWidth:'22em',top:'4em'}}
                />
            </Appear>


        </div>
    </Appear>

}