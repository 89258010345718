import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectDialog,setDialog,goBack, selectConfirm, setConfirm, selectAlert, setAlert } from '../../app/ui/uiSlice'
import MDialog from '../common/MDialog'
import ProfileDialog from './ProfileDialog'
import { Button, useMediaQuery, useTheme } from '@material-ui/core'
import ResetPassword from './ResetPassword'
import { PricingTable } from '../../views/HomeOwnerLanding'
import qs from 'querystring'
import { useHistory, useLocation } from 'react-router-dom'
import { getUser } from '../../app/auth/authSlice'
import { $error, $warning } from '../../utils/http'
import HomeDialog from './HomeDialog'
import { Privacy, Terms } from '../../content/texts'
import { selectFeatureSchema, userTypes } from '../../app/user/userSlice'
import PaymentDialog from './PaymentDialog'

const confirm_yes = new CustomEvent('confirm',{detail:true})
const confirm_no = new CustomEvent('confirm',{detail:false})

// const PaymentDialog = React.lazy(() => import('./PaymentDialog'))

export default function DialogViewer(props){
    const dialog = useSelector(selectDialog)
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const user = useSelector(getUser)
    // const house = useSelector(getHouse)
    const confirm = useSelector(selectConfirm)
    const alert = useSelector(selectAlert)

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

    function closeDialog(){
        dispatch(goBack())
        history.replace(location.pathname)
    }
    function hideDialog(){
        dispatch(goBack())
    }

    const featureSchema = useSelector(selectFeatureSchema)
    const [profileTab,setProfileTab] = useState(0)

    let dialogImage = ''
    let dialogTitle = ''
    let dialogContent = null
    let dialogCaption = ''
    let dialogHeight = null
    
    const isContractor = user?.user_type == userTypes.contractor

    // const is_primary = (house && user) && (house.primary_owner) && house.primary_owner === user.id
    const dialogs = {
        profile: {
            title: 
            isContractor?
            <React.Fragment>
                {
                    ['Profile',"Expertise"].map((p_tab,i) => 
                        <span className={` mx-sm-2 p-2 px-3 cursor-pointer border
                            ${profileTab==i?'text-primary border-primary rounded-8':'border-white'} 
                        `}
                            style={{fontSize:'1rem'}}
                            onClick={()=>setProfileTab(i)}
                        >
                            {p_tab}
                        </span>    
                    )
                }
            </React.Fragment>: "Profile"
            ,
            image : '/images/dashboard/profile.svg',
            imageStyle:{filter:'brightness(0)'},
            content:<ProfileDialog profileTab={profileTab}  onClose={closeDialog} />,
            height:isMobile?'':'max(70vh,700px)',
        },
        payment_details: {
            content: <React.Suspense fallback={<div className='text-warning'>Loading....</div>}>
                <PaymentDialog onClose={closeDialog} defaultPlan={dialog.plan} />
            </React.Suspense>
        },
        change_password:{
            content:<ResetPassword changePassword goBack={hideDialog} />
        },
        pricing:{
            title:'Packages & pricing',
            content: <PricingTable small noHeading />
        },
        "add_a_co-owner":{
            content: <HomeDialog type={1} goBack={hideDialog} payload={dialog}/>
        },
        view_owners:{
            title:'Owners',
            content: <HomeDialog type={2} goBack={hideDialog} payload={dialog}/>,
        },
        my_properties:{
            content: <HomeDialog type={3} goBack={hideDialog} payload={dialog}/>
        },
        about_your_home:{
            title:'General information',
            image : '/images/features/feature.svg',
            content: <HomeDialog type={4} goBack={hideDialog} payload={dialog}/>
        },
        terms:{
            title:'Terms of Use',
            content:
            <div className=' pl-2 '>
                <Terms></Terms>
            </div>
        },
        privacy:{
            title:'Privacy policy',
            content:
            <div className=' pl-2 '>
                <Privacy className=' '></Privacy>
            </div>
        },
     
    }

    const d_name = dialog && dialog.name
    const accepted = Object.keys(dialogs)
    const showDialog =accepted.includes(d_name)

    useEffect(()=>{
        let query = location.search.replace('?','')
        query = qs.parse(query)
        
        if(accepted.includes(query.dialog)){
            if(user){
                dispatch(setDialog(query.dialog))
            }else{
                $warning("Please login and try again")
            }
        }else{
            // closeDialog()
            console.log('please pick an accepted dialog')
        }

    },[user])

    let imageStyle = null
    if (accepted.includes(d_name)){
        
        const d = dialogs[d_name] 
        
        dialogImage = d.image
        dialogTitle = d.title
        dialogContent = d.content
        dialogCaption = d.caption
        imageStyle = d.imageStyle
        dialogHeight = d.height
    }


    let titleBar = <div className='d-flex align-items-center pb-2 pl-2 pl-sm-3 pr-2  pt-1 my-1'>
        {dialogImage?
            <img src={dialogImage} width="28px" className='ml-2 mr-3' 
                style={imageStyle}
            />
        :null}
        <p className='m-0 lead font-weight-bold mr-1'>{dialogTitle}</p>

        {dialogCaption}

    </div>

    if(!dialogTitle){
        titleBar = ''
    }
    const noo = ()=>{window.dispatchEvent(confirm_no);dispatch(setConfirm(null))}
    const yess  = ()=>{window.dispatchEvent(confirm_yes);dispatch(setConfirm(null))}
    const alertDialog = alert
    return <React.Fragment>
        <MDialog open={showDialog} onClose={closeDialog}
            titleBar={titleBar}
            height={dialogHeight}
        >
            {dialogContent}
        </MDialog>

        <MDialog
            open={!!confirm||!!alert}
            onClose={noo}
            unClosable
            neverFullscreen
        >
            {confirm?
            <div className='d-flex flex-column flex-grow-1 my-dialog p-3 p-sm-4
             justify-content-center justify-conent-sm-between'>
                <p className=' m-0 mb-1 '>
                    {confirm.question||'Are you sure?'}
                </p>
                <p className='text-medium text-muted '>
                    {confirm.detail}
                </p>

                <div className='d-flex justify-content-end mt-3'>
                    <Button className='px-4 mr-2'
                        variant='contained'
                        disableElevation
                        onClick={noo}
                    >
                        {confirm.no||'No'}
                    </Button>
                    <Button className=' px-4'
                        variant='contained'
                        
                        color='primary'
                        onClick={yess}
                    >
                        {confirm.yes||'Yes'}
                    </Button>
                </div>
            </div>
            :
            alertDialog?
                <div className='px-4 py-3 d-flex flex-column my-dialog'>
                    <p className=' '>
                    {alertDialog||'Alert!!!!'}
                    </p>

                    <Button className=' px-3 mt-2 ml-auto'
                        variant='contained'
                        
                        color='primary'
                        onClick={()=>dispatch(setAlert(null))}
                    >
                        Ok
                    </Button>
                </div>
            :null
            }

        </MDialog>

    </React.Fragment>
}