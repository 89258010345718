
import { Checkbox, CircularProgress, Divider, MenuItem, Select, TextareaAutosize } from '@material-ui/core'
import { Add, ArrowBack, Close, Delete, DragIndicator, LibraryAddCheck } from '@material-ui/icons'
import Axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { getUser } from '../../../app/auth/authSlice'
import { fetchResource,selectChecklists,setChecklists, userTypes } from '../../../app/user/userSlice'
import { $alert, $baseUrl, $confirm, $error, $success } from '../../../utils/http'
import { ActionButtons } from '../FeatureEditor'
import qs from 'querystring'
import { MExpand } from '../../services/ServiceAdder'

export default function CheckLists(){
    const user = useSelector(getUser)
    const [edited,setEdited] = useState(true)
    const dispatch = useDispatch()
    const location = useLocation()

    const [listName,setListName] = useState('')
    const [taskName,setTaskName] = useState('')
    const isAdding = edited === true

    async function fetchLists(){
        try{
            setLoading(1)
            const res = await dispatch(fetchResource({resource:'checklists'}))
            console.log(res)
            setEditedList(res[0]||true)
        }
        finally{
            setLoading(0)
        }
    }

    const [loading,setLoading] = useState(0)
    const [tasks,setTasks] = useState([])
    const checkLists = useSelector(selectChecklists)
    const [adding,setAdding] = useState(false)
    const [updating,setUpdating] = useState(0)
    const [updateQueue,setUpdateQueue] = useState({})

    const [editedTask,setEditedTask] = useState(null)
    const history = useHistory()

    const [dragIndex,setDragIndex] = useState(null)
    const [dropIndex,setDropIndex] = useState(null)

    const setEditedList = (elist) => {
        setEdited(elist)
        setListName(elist.name)
        setTaskName('')
        if(window.location.pathname.includes('checklists')){
            history.replace(location.pathname)
        }

    }

    useEffect(()=>{
        if(checkLists.length){
            setEditedList(checkLists[0])
        }
        fetchLists()
        console.log('first render of checklistss!!!!')
    },[])

const list = ['Name'/*,'status'*/]
    let columns = list.join(',')
    const lines = [columns]

const firstDummy =  ['"First item"'/*,'Done'*/]
    const secondDummy = ['"Second item"'/*,'Pending'*/]

    const dataSample = `${columns}\n${firstDummy}\n${secondDummy}`

    let i = 1
    if(edited && edited.tasks){
        edited.tasks.forEach(t => {
            lines.push([`"${t.name}"`/*,(t.status?'Done':'Pending')*/].join(','))
            i += 1;
        })
    }
    const dataString = lines.join('\n')

    function handleDownload(){
        const url = "data:application/octet-stream,"+dataString
    }

    function handleFileInput(e){

        const files = e.target.files
        const file = files[0]
         console.log(file)
     
        if(file){
            const fr = new FileReader()
            fr.onload=function(){
                const text = fr.result
                console.log(text)

                const lines = CSVToArray(text).slice(1)
                const tasks = []
                console.log(lines)
                for(let i in lines){
                    let [name,status] = lines[i]
                    if(name){
                        tasks.push({
                            name,
                            status: false,
                            key:i,
                        })
                    }
                }
                setTasks(tasks)
            }
            fr.readAsText(file)
        }

    }

    useEffect(()=>{
        if(edited && edited !== true){
            const new_clist = checkLists.find(c=>c.id == edited.id)
            setEdited(new_clist||true)
        }

        const query = qs.parse(location.search.replace('?',''))
        const cid = query.id
        if(checkLists?.length){
            const clist = checkLists.find(c=>c.id == cid)
            if(clist){
                setEditedList(clist)
            }else if(cid){
                $alert("The checklist you are looking for doesn't exist/ or is deleted")
                history.replace(location.pathname)
            }
        }
            
    },[checkLists])

    const noneYet = checkLists.length ?
    <p className='m-auto text-muted text-medium'>
        Please pick a checklist to edit
    </p> 
    :
    <div className='d-flex flex-column align-items-center m-auto'>
            <img src='/images/dashboard/tasks.svg' height='128px' />
            <p className='font-weight-500 mt-2 lead'>
                No checklists yet
            </p>
            <p className='text-small text-muted'>
                Try adding a checklist using the button top left
            </p>
    </div>

    const lprops = {size:18,}
    const blueLoader = <CircularProgress {...lprops}  className='text-primary' />
    const whiteLoader = <CircularProgress {...lprops} className='text-white' />

    async function handleAddTask(){
        if(edited == true){
            const maxKey = tasks.reduce((a,c) => Math.max(a,c.key),0)
            const newKey = maxKey+1
            const newTask = {
                name: taskName || `Task ${newKey}`,
                status: false,
                key: newKey,
            }
            setTasks([...tasks,newTask])
            setTaskName('')
        }else{
            /// Add task
            try {
                setAdding(2)
                const data = {
                    name: taskName,
                    checklist: edited.id,
                    order: (edited.tasks.length)
                }
                const res = await Axios.post(`${$baseUrl}/actionitems`,data)
                $success(res)
                const new_task = res.data
                const new_tasks = [...edited.tasks,new_task]
                const new_edited = {...edited,tasks:new_tasks}
                setEdited(new_edited)
                dispatch(setChecklists([...checkLists.filter(c=>c.id!=new_task.checklist),new_edited]))
                setTaskName('')
            } catch (err) {
                $error(err)
            } finally {
                setAdding(true)
            }
        }
    }
    
    async function checkListCUD(keepExisting){
        try {
            const shouldDelete = keepExisting == false
            if(shouldDelete){
                try {
                    await $confirm({question:"Are you sure you want to delete this checklist?"})
                } catch (err) {
                    return err;
                }
            }
            let method = isAdding ? 'post' : 'patch'
            if(shouldDelete){
                method = 'delete'
            }
            const uri = (!isAdding || keepExisting)? `/${edited.id}` : '' 

            setLoading(keepExisting?1:-1)
            const data = {
                name:listName,
                tasks: tasks, 
            }
            if(!isAdding){
                delete data.tasks
            }
            const res = await Axios[method](`${$baseUrl}/checklists${uri}`,data)
            const new_edited = res.data
            const new_list = checkLists.filter(c=>c.id!=edited.id)
            setTasks([])
            setListName('')
            if(shouldDelete){
                setEdited(true)
            }else{
                new_list.push(new_edited)
                setEdited(new_edited)
            }
            dispatch(setChecklists(new_list))
            $success(res)
        } catch (err) {
            $error(err)
        } finally {
            setLoading(0)
        }
    }
    async function updateDeleteTask(t,shouldDelete){
        setEditedTask(null)
        if(edited == true){
            let newTasks
            console.log(t)
            if(!shouldDelete){
                newTasks = tasks.slice().map(tt => {
                    if(tt.key == t.key){
                        console.log(t)
                        return {...tt,...t,}
                    }else{
                        return tt
                    }
                })
            }else{
                newTasks = tasks.slice().filter(tt => tt.key != t.key)
            }
            setTasks(newTasks)
            setTaskName('')
        }else{
            try {
                const updates = {...updateQueue}
                updates[t.id] = {...t}
                setUpdateQueue(updates)
                if(t.name){
                    setTaskName('')
                }
                if(t.name == ''){
                    return 
                }
                setUpdating(t.id*(shouldDelete?-1:1))
                const method = shouldDelete ? 'delete' : 'patch'
                let data = {}
                if(!shouldDelete){
                    data = {
                        ...t
                    }
                }
                const res = await Axios[method](`${$baseUrl}/actionitems/${t.id}`,data)
                $success(res)

                const new_tasks = edited.tasks.slice().filter(tt=>tt.id != t.id)
                if(!shouldDelete){
                    const updated_one = res.data 
                    new_tasks.push(updated_one)
                }
                const new_edited = {...edited,tasks:new_tasks}
                setEdited(new_edited)
                dispatch(setChecklists([...checkLists.filter(c=>c.id!=edited.id),new_edited]))
            } catch (err) {
                $error(err)
                const updates = {...updateQueue}
                updates[t.id] = undefined
                setUpdateQueue(updates)
            } finally {
                setUpdating(0)
            }
        }
    }

    let checkListDetail = !loading? noneYet : <CircularProgress className='text-primary m-auto '/>
    const roundClass = `d-flex align-items-center rounded-8 border p-2
     cursor-pointer text-primary font-weight-500 mb-3 pl-4 text-medium`

    if(edited){
        let taskList = edited == true ? tasks : edited.tasks
        if(edited != true){
            taskList = taskList.slice().sort((a,b) => a.order - b.order)
        }
        console.log(edited)
        checkListDetail = <div className='d-flex flex-column p-sm-3 p-2'>
            <div className={` rounded-8 border p-2  mb-3 pb-4`}>
                <div className='d-flex p-2 align-items-center '>
                    <p className='text-muted text-small mr-2'>
                        Name:
                    </p>
                    <input  
                        className='border-0 font-weight-500 
                         flex-grow-1 text-medium  placeholder-light' 
                        placeholder='Please enter the name of the checklist'
                        value={listName}
                        onChange={e=>setListName(e.target.value)}
                    />

                    {user.user_type == userTypes.agent && 
                        <div  
                            className={`ml-auto d-flex align-items-center 
                            cursor-pointer text-primary font-weight-500 text-medium
                            `}
                            onClick={()=>history.push(`/messages?checklist=${[edited.id,edited.name].join('_')}`)}
                        >
                            <img src='/images/dashboard/send-small.svg' height='28px' />
                            <p className='  ml-2'>
                                Send to clients 
                            </p>
                        </div>
                    }
                    
                </div>
                <Divider/>

                {/*edited && edited!==true &&*/  !adding?
                        <div className='d-flex p-2 py-3 align-items-center  cursor-pointer '
                            onClick={()=>{setAdding(true);setTaskName('')}}
                        >
                            <Add className='text-primary ' />
                            <p className='text-primary text-medium font-weight-500 ml-2'>Add new</p>
                        </div>
                    :
                        <form className='d-flex pl-3 my-3 pr-3'
                            onSubmit={e=>{e.preventDefault();handleAddTask()}}
                        >
                            <input  
                                className='rounded-8 p-1 pl-3 text-medium border-thick 
                                flex-grow-1 placeholder-light '
                                placeholder='Add task description '
                                value={editedTask?'':taskName}
                                onChange={e=>setTaskName(e.target.value)}
                            />

                            <button className='bg-primary text-white text-small ml-2 rounded-8 px-3 py-0 
                                d-flex align-items-center justify-content-center
                            '
                                disabled={edited != true && (/^ *$/.test(taskName))}
                            >
                                {adding == 2 ? 
                                    whiteLoader
                                    :
                                    'Add'
                                }
                            </button>
                        </form>
                }

                {!taskList.length && 
                    <p className='text-medium text-muted'>
                        No tasks yet
                    </p>
                }
                {taskList.map((t,i) => {
                    let isEdited = !!editedTask
                    if(isEdited){
                        if(editedTask.id){
                            isEdited = editedTask.id == t.id
                        }else{
                            isEdited = editedTask.key == t.key
                        }
                    }
                    const style = {cursor:'grab'}
                    if(dragIndex == i){
                        style.backgroundColor = 'whitesmoke'
                    }

                    if(dropIndex == taskList.length && i == (dropIndex -1)){
                        style.borderBottom = '2px solid black'
                    }else if(dropIndex == i){
                        style.borderTop = '2px solid black'
                    }

                    async function reorderTasks(newList){
                        try {
                            const data = {order:newList.map(li => li.id)}
                            const res = await Axios.patch(`${$baseUrl}/checklists/${edited.id}`,data)
                            setEdited(res.data)
                        } catch (error) {
                            $error(error)
                        }
                    }

                    const handleDragEnd = (e) => {
                        setDragIndex(null)
                        setDropIndex(null)
                        const insertIndex = (dragIndex < dropIndex ? (dropIndex-1) : dropIndex)
                        if(dragIndex == insertIndex){
                            console.log('Dragged to itself!!')
                            return
                        }
                        const newList = [...taskList]
                        const [removed] = newList.splice(dragIndex,1)
                        newList.splice(insertIndex,0,removed)
                        
                        console.log(newList)
                        if(edited == true){
                            setTasks(newList)
                        }else{
                            setEdited({...edited,tasks:newList})
                            reorderTasks(newList)
                        }
                    }
                    const handleDragOver = e => {
                        const rect = e.currentTarget.getBoundingClientRect()
                        const isFirstHalf =  e.clientY < (rect.top + rect.height * 0.5)

                        setDropIndex(isFirstHalf?i:i+1)
                    }

                    return <div className='checklist-item d-flex align-items-start ' key={i}
                        draggable
                        onDragStart={e=>setDragIndex(i)}
                        onDragOver={handleDragOver}
                        style={{...style,marginBottom:'4px'}}
                        onDragEnd={handleDragEnd}
                    >
                        <DragIndicator 
                            className=' ml-2 '
                            htmlColor='lightgray'
                            style={{cursor:'grab',marginTop:'4px'}}

                        />
                        <div className='ml-2 mr-1 '>
                            {updating == t.id ?
                                <div className='ml-1'>
                                    {blueLoader}
                                </div>
                                :
                                <Checkbox 
                                    size='medium'
                                    checked={t.status}
                                    color='primary'
                                    className=''
                                    onClick={() => updateDeleteTask({...t,status:!t.status,key:t.key})}
                                    style={{padding:'4px 6px'}}
                                    // className={`${isAdding?'opacity-20':''}`}
                                    // disabled={isAdding}
                                />
                            }
                        </div>  

                        

                        {isEdited ?
                            <form onSubmit={e=>{e.preventDefault();updateDeleteTask({...t,key:t.key||t.id,name:taskName});setEditedTask(null)}}
                                style={{flex:"1",display:'flex',}}
                            >
                                <TextareaAutosize className='border-thick p-1 rounded-8  placeholder-light ' 
                                    value={taskName}
                                    onChange={e=>setTaskName(e.target.value)}
                                    // onBlur={()=>setEditedTask(null)}
                                    onBlur={()=>{updateDeleteTask({...t,key:t.key||t.id,name:taskName});setEditedTask(null)}}
                                    style={{flex:'1',fontSize:'0.9em'}}
                                />
                                <button className='invisible' />
                            </form>
                            :
                            <div className='flex-grow-1 overflow-hidden'>        
                                <p className='text-medium cursor-pointer py-1 '
                                    style={{textAlign:'left',paddingRight:'8px',wordWrap:'break-word'}}
                                    onClick={()=>{setEditedTask(t);setTaskName(t.name)}}
                                >
                                    {updating == t.id ? ((updateQueue[t.id]?.name)||t.name) : t.name}
                                </p>
                            </div>
                        }

                        {/* <button className='bg-primary text-white ml-3 p-1 '
                            onClick={()=>updateDeleteTask(t)}
                        >
                            Save
                        </button> */}

                        {dragIndex == null &&
                            <div className='ml-2 mr-1 ml-auto'>
                                {updating == -t.id ?
                                    blueLoader
                                    :                                
                                    <button className=' p-1 bg-white'
                                        onClick={()=>updateDeleteTask(t,true)}
                                    >
                                        {updating == -t.id ?
                                            blueLoader
                                            :                                
                                            <Delete htmlColor='lightgray'
                                             className='' />
                                        }
                                    </button>
                                }
                            </div>
                        }
  
                    </div>}
                )}

            </div>

            {edited == true && 
                <div className='d-flex p-2 rounded-8 border 
                justify-content-between font-weight-500 text-medium'
                >
                    <a href={"data:application/octet-stream,"+encodeURI(dataSample)}
                        download={`${listName||'data_sample'}.csv`} className='d-flex align-items-center'
                    >
                        <img src='/images/dashboard/download.svg' height='24px'
                        />
                        <p className='ml-2  text-left'>
                            Download sample sheet
                        </p>
                    </a>
                    
                    <label className='mr-2 my-0 mb-0 text-left  hover-link text-primary'
                        htmlFor='import-file-picker'
                    >
                        <img src='/images/dashboard/import.svg' height='24px' className='mr-2'
                        />
                        Import sheet
                    </label>
                    <input type='file' className='d-none' 
                        id='import-file-picker'
                        onInput={handleFileInput}
                    />
                </div>
            }
            
            {(isAdding || edited.name != listName) &&
            <ActionButtons
                disabled={(/^ *$/.test(listName))}
                nextText='Save'
                onNext={checkListCUD}
                backText='Discard'
                onBack={()=>{setAdding(false);setListName(edited?.name||'');setTasks([])}}
                loading={loading}
                className='mb-2'
            />
            }
            {
            !isAdding && 
            <React.Fragment>
                <div className={` ${roundClass}  cursor-pointer d-flex justify-content-between pr-3`}
                    onClick={handleDownload}
                >
                    <a href={"data:application/octet-stream,"+encodeURI(dataString)}
                        download={`${edited.name}.csv`}
                        className='d-flex align-items-center'
                    >
                        <img src='/images/dashboard/import.svg' height='24px'
                        style={{transform:'scale(-1,1)'}}
                        />
                        <p className='ml-2  text-left'>
                            Export checklist
                        </p>
                    </a>              

                    <button className=' bg-white ml-auto d-flex align-items-center text-primary p-0'
                        onClick={()=>checkListCUD(false)}
                    >
                        {(loading == -1) ? blueLoader :
                            <Delete htmlColor='indianred'/>
                        }
                        <span className='ml-2'>
                        Delete checklist
                        </span>
                    </button>
                </div>
            </React.Fragment>
            }            

        </div>
    }
    const tile = 'font-weight-500 text-medium p-3 cursor-pointer '
    const normalTile = ' text-primary'
    const activeTile = ' text-white bg-primary '

    const checkListNav = <div className='d-flex cursor-pointer text-dark
        align-items-center p-3 pr-3 border-bottom'
    >
        <Link to='/dashboard'>
            <ArrowBack />
        </Link>

        <p className='font-weight-bold lead ml-2'>
            Checklists
        </p>
    </div>

    const checkListsSorted = checkLists.slice().sort((a,b)=>(a.id-b.id))
    const checkListList = <React.Fragment>
        <div
            className={`
                align-items-center d-flex
                ${tile} ${edited===true?activeTile:normalTile}

            `}
            onClick={()=>{setEdited(true);setTasks([]);setListName('');setTaskName('')}}
        >
            <Add className='mr-2'/>
            <p className=' font-weight-500 '>
                New checklist
            </p>
        </div>
        {!checkLists.length && !!loading &&
            <CircularProgress className='mx-auto my-5' />
        }
        {checkListsSorted.map(c => {
            const isActive = edited && edited.id == c.id

            return <div 
                className={` ${tile}
                    ${isActive?activeTile:normalTile}
                `}
                key={c.id}
                onClick={()=>setEditedList(c)}
            >
                <p className='text-left pl-3 '>
                {c.name}
                </p>
            </div>
        })}
    </React.Fragment>

    return <div className='container px-0 d-flex flex-grow-1 border-top border-right border-left'
        style={{borderTopLeftRadius:'8px',borderTopRightRadius:'8px',}}
    >
            <div className='d-none d-sm-flex flex-column border-right py-2 '
                style={{width:'var(--message-panel-width)'}}
            >
                {checkListNav}

                {checkListList}
            </div>
            <div className='d-flex flex-column flex-grow-1 overflow-hidden'>
                <div className='d-flex flex-column d-sm-none'>
                    {checkListNav}
                    {/* <MExpand
                        className='mx-3'
                        summary={<div> 
                            <p className='text-primary font-weight-500 '>
                                {(edited && edited.name && 
                                    <span>
                                        <LibraryAddCheck className='mr-1'/>
                                        {edited.name}
                                    </span>
                                ) 
                                || 
                                <span>
                                    <Add className='mr-1' />
                                    New Checklist
                                </span>

                                }
                            </p>
                        </div>}
                    >
                        {checkListList}
                    </MExpand> */}
                    <Select
                        variant={"outlined"}
                        className="mx-2 mt-2"
                        SelectDisplayProps={{style:{textAlign:'left',fontWeight:'500',padding:'12px 16px'}}}
                        value={edited && (edited.id || true)}
                        onChange={e=>{
                            setEdited(checkLists.find(c=>c.id==e.target.value)||true)
                        }}
                    >
                        <MenuItem className='text-left text-primary' value={true}
                            style={{fontWeight:'500'}}
                        >
                            <Add className='mr-2 text-primary' />
                            New Checklist
                        </MenuItem>
                        {
                            checkListsSorted.map(cl => 
                                <MenuItem key={cl.id} value={cl.id}
                                    style={{fontWeight:'500',fontSize:'0.9rem'}}
                                >
                                    <LibraryAddCheck className='mr-2 text-primary opacity-70'/>
                                    {cl.name}
                                </MenuItem>
                            )
                        }

                    </Select>
                </div>
                    {checkListDetail}
            </div>

    </div>
   
}


/**
 * CSVToArray parses any String of Data including '\r' '\n' characters,
 * and returns an array with the rows of data.
 * @param {String} CSV_string - the CSV string you need to parse
 * @param {String} delimiter - the delimeter used to separate fields of data
 * @returns {Array} rows - rows of CSV where first row are column headers
 */
 function CSVToArray (CSV_string, delimiter) {
    delimiter = (delimiter || ","); // user-supplied delimeter or default comma
 
    var pattern = new RegExp( // regular expression to parse the CSV values.
      ( // Delimiters:
        "(\\" + delimiter + "|\\r?\\n|\\r|^)" +
        // Quoted fields.
        "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
        // Standard fields.
        "([^\"\\" + delimiter + "\\r\\n]*))"
      ), "gi"
    );
 
    var rows = [[]];  // array to hold our data. First row is column headers.
    // array to hold our individual pattern matching groups:
    var matches = false; // false if we don't find any matches
    // Loop until we no longer find a regular expression match
    while (matches = pattern.exec( CSV_string )) {
        var matched_delimiter = matches[1]; // Get the matched delimiter
        // Check if the delimiter has a length (and is not the start of string)
        // and if it matches field delimiter. If not, it is a row delimiter.
        if (matched_delimiter.length && matched_delimiter !== delimiter) {
          // Since this is a new row of data, add an empty row to the array.
          rows.push( [] );
        }
        var matched_value;
        // Once we have eliminated the delimiter, check to see
        // what kind of value was captured (quoted or unquoted):
        if (matches[2]) { // found quoted value. unescape any double quotes.
         matched_value = matches[2].replace(
           new RegExp( "\"\"", "g" ), "\""
         );
        } else { // found a non-quoted value
          matched_value = matches[3];
        }
        // Now that we have our value string, let's add
        // it to the data array.
        rows[rows.length - 1].push(matched_value);
    }
    return rows; // Return the parsed data Array
 }