import { Button, CircularProgress, TextField, useMediaQuery, useTheme } from '@material-ui/core'
import { ChatBubbleOutline, CheckCircle, Close, MoreHoriz, RadioButtonUnchecked } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import DownIcon from '@material-ui/icons/ExpandMore'
import Axios from 'axios'
import qs from 'querystring'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { getUser } from '../../app/auth/authSlice'
import { setDialog } from '../../app/ui/uiSlice'
import { fetchFeatureSchema, fetchMessages, fetchQuotes, selectFeatureSchema, selectMessages, selectQuotes } from '../../app/user/userSlice'
import { DocumentCount, DocumentViewer } from '../../components/common/DocumentViewer'
import MDialog from '../../components/common/MDialog'
import SimpleMenu from '../../components/common/SimpleMenu'
import DatePicker, { getDay, usaDate } from '../../components/input/DatePicker'
import TimePicker, { to12hours } from '../../components/input/TimePicker'
import { NameBadge } from '../../components/special/HomeDialog'
import { $prettify, padZero } from '../../utils'
import { $baseUrl, $confirm, $error, $event, $success } from '../../utils/http'
import FieldWithLabel from '../../components/common/FieldWithLabel'
import MExpand from '../../components/common/MExpand'
import { getSelectedDocs } from '../services/ServiceDetail'
import { getFeatureIcon, getFirstServiceMessage, getLatestReceivedMessage } from '../../utils/helpers'
import { ActionButtons, AutoForm,} from './FeatureEditor'
import { didAcceptAnother, getQuoteTotal } from '../../utils/helpers'
import StatusText from '../../components/special/StatusText'

export const protoTime = {date:'',start:'06:00',end:'10:00'}

const ikeys = ['description','qty','price']


export async function patchQuote(data,quote_id,silent=false){
    let fdata = {...data}
    try {
        const res = await Axios.patch($baseUrl+`/quotes/${quote_id}`,fdata)
        
        if(!silent){
            $success(res)
        }
    } catch (err) {
        $error(err)
    }
}
export function TimeSlotCard({time,active,...props}){
    const t = time
    return  <div
    {...props}
     className={`border-thick rounded-8 
    justify-content-center py-3 ${props.className}
     ${active?'bg-primary border-primary':''}
    d-flex flex-column align-items-center cursor-pointer`}
  
    style={{height:'100px',...props.style}} 
    >
     {t.date?
        <React.Fragment>
            <p className={`m-0 font-weight-500 text-medium ${active?'text-white':''}`}>
            {getDay(usaDate(t.date))}
            </p>
            <p className={`m-0 text-small ${active?'text-white':' text-muted'}`}>
            {usaDate(t.date)}
            </p>
            <p className={`m-0 small ${active?'text-white':' text-muted'} `}>
            {to12hours(t.start)}-{to12hours(t.end)}
            </p>

        </React.Fragment>
         :
        <React.Fragment>
             <AddIcon size="18" />
             <p className='font-weight-500 m-0 text-snall'>
                 Add time
            </p>
        </React.Fragment>
     }
    </div>
}
const isItemHalfEmpty = (item) => (item.some(i=>!i||i==' ') && (item.reduce((a,i)=>a+i) != '') )

export function QuoteTable({quote,itemList,setItemList,setItemValue,editable,...props}){

    const items = itemList || JSON.parse(quote.contents||'[]')

    const littleText = 'text-muted text-medium mb-1'
    const total = getQuoteTotal({items})
    const creamStyle = {backgroundColor:'#FeFeFe'}

    const gridStyle = {display:'grid',gridTemplateColumns:'0.5fr 1fr 1fr 1fr'}

    return <div className='d-flex flex-column mr-0 my-2'>
        <div className='d-flex flex-column'
        >
            <div className='quote-table'>
                {['item',...ikeys].map((c,i) => 
                <div className={`text-capitalize pb-1
                text-medium pt-1 border-bottom pl-1
                 text-muted font-weight-500 
                 ${i==ikeys.length&&!editable?'text-right':''}
                `} 

                    key={c}
                    style={creamStyle}
                >
                    {c}
                </div>
                )}
            </div>

            <div style={{maxHeight:'16em'}} 
                className='overflow-auto quote-table'
            >

                {items.map((i,n)=>[n+1,...i]).map((i,k)=>
                    <React.Fragment 
                    key={k} 
                    >
                        {
                            i.map((val,j)=> 
                            {
                                const lastCol = j == (ikeys.length)
                                const firstRow =  k == 0
                                return <div key={j+k} className={`p-0 py-1 border-0 
                                    text-medium d-flex align-items-center
                                        ${editable?'':' py-2'}
                                    `} 
                                    >
                                        {editable&&j?
                                        <input 
                                            value={val}
                                            onChange={e=>{
                                                let tval = e.target.value
                                                if(j>1){
                                                    tval = tval.replace(/\D/g,'')
                                                }
                                                setItemValue(k,j-1,tval)
                                            }}
                                            // type={j>1?'number':'text'}
 
                                            style={{width:lastCol?'70%':'100%',marginRight:'4px'}}
                                            min="1"
                                            max="999999"
                                            className={`rounded-8 border-thick p-2
                                                ${val==''&&isItemHalfEmpty(i.slice(1))?'hover-danger':''}
                                            `}
                                        
                                        />
                                        :
                                        <p className={`m-0 pl-1 text-medium ${lastCol&&!editable?'text-right ml-auto':''} `}>
                                        {lastCol?'$':''}{val}
                                        </p>
                                        }
                                        {editable&&lastCol?
                                            <button className={`ml-0 cursor-pointer
                                                px-0 pl-0 
                                                bg-transparent ${firstRow?'text-light':'hover-danger'}`}
                                                onClick={e=>{
                                                    const nlist = itemList.slice()
                                                    nlist.splice(k,1)
                                                    setItemList(nlist)
                                                }}
                                                disabled={firstRow}
                                            >
                                                <Close />
                                            </button>
                                        :null}
                                    </div>
                                }
                            )
                        }
                    </React.Fragment>
                )}
            </div>

        </div>

            <div className='d-flex justify-content-end align-items-center  '>
                <p className='text-right text-success 
                py-1 text-medium font-weight-500111'
                style={creamStyle}
                >
                    Total quote: ${total.toFixed(2)}
                </p>

                {editable &&

                    <button className={`ml-1 cursor-pointer
                        px-0 pl-0 invisible
                        bg-transparent text-light `}
                        disabled={true}
                    >
                        <Close />
                    </button>
                }

            </div>

    </div>
}
export const getDateTime = (date) => {
    const time = `${padZero(date.getHours())}:${padZero(date.getMinutes())}`
    const dateStr = `${(date.getFullYear())}-${padZero(date.getMonth()+1)}-${padZero(date.getDate())}`

    return [dateStr,time]
}

export const quoteToDateObj = (quote,slot='') => {
    const ds1 = quote[`time_slot${slot}_start`]
    const ds2 = quote[`time_slot${slot}_end`]
    
    
    if(!ds1 || !ds2){return {...protoTime}}

    const d1 =  new Date(ds1)
    const d2 =  new Date(ds2)

    const date = ds1.split('T')[0]
    const start= `${padZero(d1.getHours())}:${padZero(d1.getMinutes())}`
    const end= `${padZero(d2.getHours())}:${padZero(d2.getMinutes())}`
    return {date,start,end}
} 

export function ClientDetails({service,hideDocs}){
    const feature = service.feature
    const  user = useSelector(getUser)
    console.log(feature)
    const is_client = user.user_type == 0 
    const detailKeys = [
        ...(is_client ? [ 
            {key:'feature',value:(feature.name ||'feature')},
            {key:'issue',value:((service.details && service.details.symptom )|| service.symptom)},
        ] : [
            {key:'client'},
        ]),
        
        {key:'address'},
        // {
        //     key:'description',
        //     value: !service.description?null:
        //     <p style={{minHeight:'50px'}} className=''>
        //     {service.description}
        //     </p>
        // },
    ]
    const docs =  service.documents? service.documents : []

    return  <div>
        {detailKeys.map(k => {
                const val = k.value || service[k.key] 
                return !val ? null : <FieldWithLabel key={k.key} withBox label={k.key}  >
                    {val}
                </FieldWithLabel>
        })}

    </div>
}

function QuoteProvider({edited,onClose,...props}){
    // const isMobile = window.outerWidth < 420
    
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

    const quotes = useSelector(selectQuotes)
    const quote = quotes.find(q=> q.id == edited.id)
    const service = quote && quote.service
    const feature = quote && quote.feature || {}
    const button_props = {
        variant:'contained',
    }
    const protoItem = ['','','']
    const defaultItems = []
    for(let i = 0;i < 5;i += 1){
        defaultItems.push([...protoItem])
    }

    const [items,setItems] = useState(defaultItems)
    const [times,setTimes] = useState([1,2,3].map(i=>({...protoTime})))
    const [newStatus,setNewStatus] = useState(quote.status)
    const [loading,setLoading] = useState(0)
    const [invoiceAmount,setInvoiceAmount] = useState(Number(quote.estimated_cost) || getQuoteTotal(quote))
    const [pickedDocuments,setPickedDocuments] = useState([])

    const history = useHistory()
    const user = useSelector(getUser)
    const messages = useSelector(selectMessages)

    const goneWithAnother = didAcceptAnother(quote)

    const [
        request_details,compose_quote,pick_time,

        view_docs,view_details,billing_details,invoice_list,

        quote_details,re_schedule,refuse_service,
        rejection_details,service_refused,quote_rejected,quote_progress,
        completed,cancel_this,canceled,cancellation_details
    ] 
    = `123456789abcdefghijklmnopqrstuvwxyz`.split('')

    const [stage,setStage] = useState(0)

    const [message,setMessage] = useState('')
    const [editedTime,setEditedTime] = useState(-1)


    function handleContextClick(e){
        if(e =='provide_quote'){
            setNewStage(compose_quote)
        }else if(e == 'refuse_request'){
            setNewStage(refuse_service)
        }
    }

    const labelClass = 'text-muted font-weight-500 text-medium my-1 '

    function resetFocus(){
        if(quote){

            const contents = quote.contents
            if(contents){
                try {
                    const items = JSON.parse(contents)
                    setItems(items)
                } catch (err) {
                    console.log(err)
                }
            }

            let new_stage = request_details
            const st = quote.status

            if(quote.service.status == -1){
                new_stage = cancellation_details
                
            } else
            if(quote){
                setTimes([1,2,3].map(n => quoteToDateObj(quote,n)))
                if(st == 1 ){
                    new_stage = (service_refused)
                }else if(st == 2 ){
                    new_stage = (quote_details)
                }else if(st == 7 ){
                    new_stage = (quote_details)
                }else if(st == 3){
                    new_stage = (rejection_details)
                }else if(st == 4){
                    new_stage = (pick_time)
                
                    setTimes([1,2,3].map(n => quoteToDateObj(quote,n)))
                }else if(st == 5){
                    new_stage = (quote_details)
                    
                }else if(st >=8 && st <=10){
                    new_stage = (quote_progress)
                }else if(st >= 100){
                    new_stage = (completed)
                }else if(st == -1){
                    new_stage = canceled
                }
            }
         
            setNewStage(new_stage)
        }
    }
    const internal_update = useRef(false)
    const pastStage = useRef(request_details)

    const setNewStage = (new_stage) => {
        pastStage.current = stage
        setStage(new_stage)
    }

    useEffect(()=>{

        if(!internal_update.current){
            resetFocus()
        }
        internal_update.current = false  
    },[(edited && edited.id)])
    const featureSchema = useSelector(selectFeatureSchema)
    const dispatch = useDispatch()

    const messageField = <TextField 
        multiline={true}
        placeholder='Add an optional message'
        rows={5}
        variant='outlined'
        value={message}
        onChange={e=>setMessage(e.target.value)}
    />

    const orderDetails = <React.Fragment>
        <FieldWithLabel label='Issue'>
            <p className='p-2 border-thick rounded-8 text-small font-weight-500 text-muted'>
            {feature.name} - {((service.details && service.details.symptom )|| service.symptom)} 
            </p>
        </FieldWithLabel>

        {service.description && 
            <FieldWithLabel label='description'>
                <p style={{minHeight:'50px'}}
                 className='p-2 border-thick text-small font-weight-500 text-muted rounded-8'
                >
                    {service.description}
                </p>
            </FieldWithLabel>
        }

    </React.Fragment>
    
    const isItemEmpty = (item) => (item.some(i=>!i||i==' '))
    const isItemImpartial = (item) => !(item.every(i=>!!i) || item.every(i=>!i||i==' '))


    let activeSlide = {
        content:orderDetails,
    }
    function handleContact(){
        history.push(`/messages?to=${quote.client}&service=${quote.service.id}`)
        
    }
    const expandableRequest = orderDetails

    let featureDetails =  <CircularProgress color='primary' className='mx-auto my-3' />

    let schema = featureSchema['simple_feature']
    schema = (schema && schema.fields)

    const excludedFields = ['id','house','parent','has_subfeatures','icon','name','notes',
    'category_string','documents','category']
    
    let filtered_fields = []
    if(schema){
        const fields = Object.keys(schema).filter(f => !excludedFields.includes(f))
        if(schema){fields.push('notes')}
        filtered_fields = fields.filter(f => !!feature[f] && !['status','_type','extra_details','type'].includes(f))

        featureDetails = <AutoForm
            schema={schema} 
            source={feature}
            setter={()=>{}}
            onEvent={()=>{}}
            readOnly

            fields={fields}
        />
    }
    const noAdditionalInfo = !filtered_fields.length

    featureDetails = <MExpand
        disabled={noAdditionalInfo}
        summary={<p>
            {/* {feature.name || 'Feature'}  */}
            Additional information</p>}
        onClick={()=>dispatch(fetchFeatureSchema(feature))}
    >
        {featureDetails}
    </MExpand>

    
    // const contactClient = `Contact ${service.client}`
    const contactClient = <span>

        <span className='d-inline-block'>
        Contact 
        </span>
        {/* <span className='d-inline-block ml-1'>
            {(service.client)}
        </span> */}

        {/* <br className='d-none d-sm-inline' /> */}
        <span className='d-none d-sm-inline-block ml-1'>
            {(service.client)}
        </span>
        <span className='d-inline-block d-sm-none ml-1'>
            client
        </span>
    </span>

    const statuses = [
        // {key:'Scheduled',value:8},
        {key:'Dispatched',value:9},
        {key:'In progress',value:10},
        {key:'Complete',value:100},

    ]

    const cancelButton = <Button className={`text-medium ml-3`}
        color='primary'
        size='small'
        onClick={()=>setNewStage(cancel_this)}
    >
        Cancel service
    </Button>

    const first_service_message = getFirstServiceMessage(service,messages)
    let latest_message = getLatestReceivedMessage(service,messages,user.id)
    let other_guy = quote.client
    latest_message = latest_message.created_at > quote.updated_at ? latest_message : {}

    const new_unread = !!latest_message.message

    // const new_unread = (last_message && (!last_message_mine ||( last_message.created_at > last_message_mine.created_at)))

    const quoteStatus = <div className='font-weight-500 lead d-flex justify-content-between 
        align-items-center'
    >
        <StatusText formal ignore_latest quote={edited} />

        {!!first_service_message && 
            <button
                className={`bg-transparent ${new_unread?'':'filter-grayscale opacity-50'} pr-0`}
                onClick={()=>history.push(`messages?to=${other_guy}&service=${service.id}`)}
                title='View messages'
            >
                <img src='/images/dashboard/mail-mini.svg' height='27px' />
            </button>
        }
    </div>

    const selected_docs = getSelectedDocs(quote)
    let contractor_selected_docs = service.selected_documents.split(',').filter(d=>d[0]==='c').map(cd=>Number(cd.slice(1)))
    const legal_ones = quote.documents.filter(d => d.user_type != 0 && d.user_id == user.id).map(d=>d.id)
    contractor_selected_docs = contractor_selected_docs.filter(cd => legal_ones.includes(cd))

    const viewDocUploader = () => {
        setNewStage(invoice_list)
        setPickedDocuments(contractor_selected_docs)
        // setDocuments(service.selected_documents.split(',').filter(d=>d[0] === 'c').map(cd=>Number(cd.slice(1))))
    }

    let disable_upload = [cancellation_details,canceled].includes(stage)

    const completion_date = new Date(quote.completed_on)
    const is_completed = edited.status === 101 
    if(is_completed){
        const is_7_days_over = ((new Date()).getTime() - completion_date.getTime()) > (7*24*3600*1000)
        if(is_7_days_over){
            disable_upload = true
        }
    }

    const document_details = <div className='d-flex justify-content-between mt-2'>
        <button className='d-flex  align-items-center bg-white text-small text-primary pl-0 '
            onClick={()=>{setNewStage(view_docs)}}
        >
            <DocumentCount count={
                selected_docs.length+
                quote.documents.filter(
                    d=>!!d.user_type && !selected_docs.includes(d.id) && contractor_selected_docs.includes(d.id)
                ).length
            }/>
            View documents
        </button>
        <button className={`d-flex align-items-center text-primary bg-white text-small pr-0
            ${disable_upload?'opacity-50':''}
        `}
        disabled={disable_upload}
            onClick={viewDocUploader}
        >
            <img src={'/images/dashboard/upload.svg'} height='24px' className='mr-1' />
            <p className='text-wrap '>
                Upload documents 
                <span className='d-none ml-1 d-sm-inline'>
                and/or pictures
                </span> 
            </p>
        </button>
    </div>
    
    const client_details = <React.Fragment>

        <div className='d-flex border-bottom py-2 mt-2'>
            <NameBadge source={{full_name:service.client}} />
            <div className='mx-2'>
                    <p className='font-weight-500 '>
                        {service.client}
                    </p>
                    <p className='text-medium text-muted'>
                        {service.address}
                    </p>
            </div>
            <button className='text-primary ml-auto text-medium bg-white pr-0'
                onClick={()=>setNewStage(view_details)}
            >
                View details
            </button>
        </div>

        {document_details}

    </React.Fragment>

    const statusRadio = <div className='row row-cols-sm-3 row-cols-2 '>
        {statuses.map((s,i) =>
            <div className={`col px-1 `} key={i}>
                <div className={` d-flex align-items-center 
                cursor-pointer border-thick p-1 rounded-8 my-1
                    ${s.value == newStatus?'border-primary':''}
                `}
                    key={s.key}
                    onClick={()=>{
                        if(quote.status <= s.value){
                            setNewStatus(s.value)
                    }}}
                >
                    {s.value <= newStatus?
                        <CheckCircle color='primary' fontSize='default'/>
                        :
                        <RadioButtonUnchecked htmlColor="lightgray"  />
                    }
                    <p className='mx-2'>
                    {s.key}
                    </p>
                </div>
            </div>
        )}

    </div>

    if(stage == request_details){

        activeSlide = {
            noBack:true,noNext:true,

            content:<div className='d-flex flex-column flex-grow-1'>
                {quoteStatus}
                {client_details}
          
                {orderDetails}

                {featureDetails}

            </div>
        }
    }
    else if(stage == refuse_service || stage == cancel_this){

        activeSlide = {
            onBack:()=>{
                setNewStage(pastStage.current)
            },
            next:(stage == cancel_this)?'Cancel service':'Refuse request',
            content:<div className='d-flex flex-column'>

                <p className='font-weight-500 text-medium text-muted mb-2'>
                {stage == cancel_this?
                'Reason for cancellation:':
                `Why can't you accept your request?`
                }
                </p>
                {messageField}
            </div>
        }

    }
    else if(stage == view_details){
        activeSlide = {
            onBack:()=>{
                setNewStage(pastStage.current)
            },
            noNext:true,
            content: <div className='d-flex flex-column'>

                <ClientDetails service={{...service,feature,documents:quote.documents}} />

                {orderDetails}

                {featureDetails}

            </div>
            
        }
    }
    else if(stage == invoice_list){
        const onBack = ()=>setStage(pastStage.current)
        const documents = quote.documents
        const maxAllowed = 8
        const disableUpload = documents.filter(d => d.user_type != 0).length >= maxAllowed       
        activeSlide = {
            back:'Back',
            next:'Attach',
            onNext: async ()=>{
                const ho_docs = service.selected_documents.split(',').filter(d=>d[0]!=='c')
                const selected_documents = [...ho_docs,...pickedDocuments.map(d=>`c${d}`)].join(',')
                
                try {
                    setLoading(1)
                    await patchQuote({selected_documents},quote.id)
                    onBack()
                } finally {
                    setLoading(0)
                }
            },
            onBack,
            content:<React.Fragment>
                

                <DocumentViewer 
                    viewing_what='Viewing Invoices & documents/pictures'
                    source={{...service,type:'service',documents:documents,house:service.house,}}
                    onClose={()=>setStage(pastStage.current)}
                    house={{id:service.house}}
                    filterer={d => d.user_type != 0 && d.user_id == user.id}
                    disableUpload={disableUpload}
                    selectable
                    selected={pickedDocuments}
                    handleChecked={(d)=>{
                        const docs =  pickedDocuments
                        const checked = docs.includes(d.id)
                        if(checked){
                            setPickedDocuments(docs.slice().filter(td=>td!=d.id))
                        }else{
                            setPickedDocuments([...docs,d.id])
                        }
    
                    }}
                    onAddNew={d => setPickedDocuments([...pickedDocuments,d.id])}


                />
                {disableUpload && 
                    <p className='text-small text-muted '>
                        You have reached the max limit of {maxAllowed} uploads per service request
                    </p>
                }

            </React.Fragment>
        }
    }
    else if(stage == billing_details){
        const handleSave = async () => {
            try {
                setLoading(1)
                await patchQuote({
                    estimated_cost:invoiceAmount,
                    service_provider:quote.service_provider,
                    message
                },quote.id)
            } finally {
                onClose()
                setLoading(0)
            }
        } 
        activeSlide = {
            class:'my-dialog-sm',
            onBack:()=>{
                setStage(completed)
            },
            back:'Discard',
            next:'Save',
            onNext:handleSave,
            content:<div className='d-flex flex-column '>
                <div className='d-flex align-items-center justify-content-between'>
                    <p className='font-weight-bold'>
                        Billing
                    </p>
                    <button className='bg-transparent text-primary text-small d-flex align-items-center'
                        onClick={viewDocUploader}
                    >
                        <img src='/images/dashboard/upload.svg' height="32px" className='mr-2' />
                        Upload invoices
                    </button>
                </div>
                <p className='text-small text-muted'>
                You can now provide the client with their invoice amount, 
                and don’t forget to add any additional documents including 
                a copy of final invoice, and pictures taken at the job 
                </p>
                <FieldWithLabel label="Invoice amount(including tax)">
                    <TextField  
                        // type='number'
                        variant='outlined'
                        size='small'
                        value={`$${invoiceAmount}`}
                        onChange={e=>setInvoiceAmount(e.target.value.replace('$',''))}
                    />
                </FieldWithLabel>
                <FieldWithLabel label="Add a message">
                    <textarea 
                        className='big text-small'
                        style={{resize:'none'}}
                        value={message}
                        onChange={e=>setMessage(e.target.value)}
                        placeholder='Add any additional information to the client ...' 
                    />
                </FieldWithLabel>
            </div>
        }
    }
    else if(stage == view_docs){

        activeSlide = {
            onBack:()=>{
                setStage(pastStage.current)
            },
            noNext:true,
            content: <div className='d-flex flex-column'>

                <DocumentViewer 
                    readOnly
                    filterer={d=> selected_docs.includes(d.id) || contractor_selected_docs.includes(d.id)}
                    source={{documents:quote.documents,house:service.house}}
                    onClose={()=>setStage(pastStage.current)}
                    house={{id:service.house}}
                />

            </div>
            
        }
    }
    else if (stage == compose_quote){

        const setItemValue = (i,key,value) => {
            const nitems = [...items]
            nitems[i][key] = value
            setItems(nitems)
        }
       activeSlide = {
           next:'Pick times',
           notYet: isItemEmpty(items[0]) || items.some(i => isItemImpartial(i)),

           content:<div className='d-flex flex-column flex-grow-1'>
                <p className={`${labelClass} `}>Quote</p>

                <div className='d-flex flex-column overflow-auto'
                    style={{minHeight:'360px'}}
                >
                    <QuoteTable editable setItemValue={setItemValue} 
                        itemList={items} 
                        setItemList={setItems} 
                    />
                </div>
                
                <div className='d-flex justify-content-center mb-2 mt-auto pt-2'>
                        <button className='text-primary border-thick border-primary bg-white rounded-8
                            align-items-end d-flex justify-content-center
                        ' 
                            onClick={()=>setItems([...items,[...protoItem]])}
                        >
                            <AddIcon />
                            Add item
                        </button>
                </div>
            </div>
       }
    }
    else if (stage == pick_time){
     
        const altTime = quoteToDateObj(quote,'')
        const pickedSame = times.some((t,i) => times.some((t1,j) => (i!=j&&t.date==t1.date&&t.start==t1.start)))
        console.log(times)
        const notPickedAll = times.some(t => !t.date)
        activeSlide = {
            next:'Send',
            notYet: notPickedAll,
            content: <div className='d-flex flex-column'>
                {quote.rescheduled?
                    <p className='my-1 text-danger text-small text-center'>
                        Client has rescheduled this appointment and requested an alternate time
                    </p>
                :null}

                <p className={`${labelClass} `}>Propose times</p>
                <div className='container-fluid my-2 '>
                    <div className='row  ' >
                        {times.map((t,n) => 
                            <div key={n}
                            className={`col-4 px-1 ${t.date?'':' text-primary '} hover-scale`}
                            >
                            <TimeSlotCard time={t} onClick={()=>setEditedTime(n)} />
                            </div>
                        )}
                    </div>
                </div>
         
                {altTime.date?
                <React.Fragment>
                    <button 
                            // onClick={()=>{setPickAlt(1)}}
                            className={` border-thick text-danger
                                ${
                                    // timeSlot==3?
                                    // 'text-white bg-primary  text-small border-primary':
                                    ' bg-white  text-small'
                                }
                            `}
                        >
                        
                        {`Time Proposed by client: ${getDay(altTime.date)},
                            ${usaDate(altTime.date)}, 
                            ${to12hours(altTime.start)} - ${to12hours(altTime.end)}`}
                    </button>
                </React.Fragment>
                :null}
                      
                {/* {quote.message_from_client && 
                    <React.Fragment>
                        <p className={`${labelClass} `}>Message from client:</p>
                        <p className='border-thick p-2 rounded-8 text-small text-muted'>
                            {quote.message_from_client}
                        </p>
                    </React.Fragment>
                }
                         */}
                <p className={`${labelClass} `}>Add a message</p>

                {messageField}

            </div>
        }

    }
    else if(stage == cancellation_details){
        activeSlide = {
            noNext:true,
            onBack:()=>onClose(),
            content: <div className='d-flex flex-column '>
                {quoteStatus}

                {client_details}
                {expandableRequest}
                
                {quote.message_from_client && 
                    <FieldWithLabel
                        label={'Reason for cancellation:'}
                    >
                        <p 
                            className='p-2 border-thick rounded-8 text-muted text-small'
                        >
                            {quote.message_from_client||'No reason was provided.'}
                        </p>
                    </FieldWithLabel>
                }
            </div>
        }
    }
    else if (stage == service_refused || stage == quote_rejected || stage == canceled){
        const qr = stage == quote_rejected
        const happened = {}
        happened[quote_rejected] = 'quote rejection'
        happened[service_refused] = 'request denial'
        happened[canceled] = 'cancellation'

        activeSlide = {
            noNext:true,
            content:<div className='d-flex flex-column '>
                {quoteStatus}
                {client_details}
                {orderDetails}
                {quote.message_from_contractor?
                    <React.Fragment>
                        <p className={labelClass}>
                            Reason for {happened[stage]}:
                        </p>
                        <p className='text-small p-2 border rounded text-muted'>
                            {quote[`message_from_${qr?'client':'contractor'}`]}
                        </p>
                    </React.Fragment>
                :null}
            </div>
        }

    }
    else if (stage == quote_details){

        activeSlide = {
            // next:'Save and send changes',
            noNext:true,
            back: contactClient,

            content:<div className='d-flex flex-column '>
                    {quoteStatus}
                    {client_details}
                    <p className='text-muted text-medium '>
                        {/* Price breakdown for service */}
                        Quote provided to client for {edited.feature.name} #{padZero(edited.service.id,3)}
                    </p>
                    <QuoteTable quote={quote} />
                    
                    <p className={labelClass}>
                        Time slots provided:
                    </p>
                    <div style={{
                        display:"grid",
                        gridTemplateColumns:'repeat(3,1fr)',
                        gap:'4px'
                    }}>
                        {times.map((t,n) => 
                             
                                <TimeSlotCard time={t} key={n}/>
                              
                        )}
                    </div>
                    {/* <FieldWithLabel>
                            {quote.message_from_client}
                    </FieldWithLabel> */}

                    {/* <div className='d-flex align-items-center '>
                        <p className={`${labelClass} `}>Status</p>
                        
                        {cancelButton}

                    </div> */}

            </div>
        }
    }
    else if (stage == quote_progress || stage == completed){
        const completed = quote.status == 100
        const  billed = !!quote.estimated_cost
        const paid = quote.status > 100
        activeSlide = {
            onNext: !completed ? null : () => {
                setNewStage(billing_details)
            },
            next: completed?(billed?'Update billing':'Next'):`Save ${isMobile?'':'and send changes'}`,
            back: contactClient,
            notYet: !completed && quote.status == newStatus,
            noNext: !!paid,

            content:<div className='d-flex flex-column container-fluid'>
                    {quoteStatus}
                    {client_details}

                    {/* <p className={`my-1 text-small rounded-8 text-center p-2 border-thick
                        ${quote.rescheduled?'text-danger':'text-primary'}
                    `}>
                        Client has {quote.rescheduled?'re':''}
                        scheduled this appointment for {getPickedDate(quote)}
                    </p> */}
                    
                    <p className='text-muted text-medium '>
                        {/* Price breakdown for service */}
                        Quote provided to client for {edited.feature.name} #{padZero(edited.service.id,3)}
                    </p>
                    <QuoteTable quote={quote} />

                    {/* {quote.message_from_client?
                        <React.Fragment>
                            <p className='text-small text-muted font-weight-500'>
                                Message from client:
                            </p>
                            <p className='border-thick rounded-8 text-small text-muted p-2 px-3'>
                            {quote.message_from_client}
                            </p>
                        </React.Fragment>
                        :null
                    } */}
                    {!!quote.rescheduled &&
                        <p className='text-danger text-small'>
                            Client has rescheduled this appointment.
                        </p>
                    }
                    
                    {!paid && 
                        <React.Fragment>
                            <div className='d-flex align-items-center justify-content-between mb-2'>
                                <p className={`${labelClass} `}>Status</p>
                                {cancelButton}
                            </div>

                            {statusRadio}
                        </React.Fragment>
                    }

            </div>
        }
    }

    async function handleNext(){

        if(!activeSlide.notYet){
            if(activeSlide.onNext){
                return activeSlide.onNext()
            }

            if(stage == pick_time || stage == refuse_service){
                handleSubmit()
            }
            else if(stage == compose_quote){
                const contents = JSON.stringify(items.filter(i=>!isItemEmpty(i)))
                const estimated_cost = 0//getQuoteTotal({items})

                setLoading(1)
                try {
                    internal_update.current = true
                    await patchQuote({
                        service_provider:quote.service_provider,
                        contents,estimated_cost,
                    },quote.id)
                    setNewStage(pick_time)
                } catch (err) {
                    console.log('Error while patching quote items')
                    console.error(err)
                }finally{
                    setLoading(0)
                }
                
            }
            else if (stage == quote_progress){
                handleSubmit()
            }
            else if (stage == cancel_this){
                cancelService()
            }
            else{

            }

        }
    }

    async function cancelService(){
        const data = {
            service_provider:quote.service_provider,
            message_from_contractor:message,
            status: -1

        }
        try {
            await $confirm({
                question:`Are you sure you want to cancel this service?`,
                detail:'',
                no:'Cancel'
            })
            setLoading(1)
            const res = await patchQuote(data,quote.id)
            $event('service_canceled_by_contractor',{"contractor":quote.contractor,"service_id":service.id,"user_id":quote.client})

            onClose()
        } catch (err) {
            
        }
        finally{
            setLoading(0)
        }
    }
    function handleBack(){
        if(activeSlide.onBack){
            activeSlide.onBack()
        }else 
        if(stage == pick_time){
            setNewStage(compose_quote)
        }else
        if([quote_progress,request_details,quote_details,completed].includes(stage)){
            handleContact()
        }
        else if([service_refused].includes(stage)){
            onClose()
        }
        else{
            onClose()
        }
    }
    async function handleSubmit(){
        try {
            let data = {
                service_provider:quote.service_provider,
                message:message,
            }
            if(stage  == refuse_service){
                data.status = 1
                data.message_from_contractor = message
                delete data.message
                $event('refused_service',{"contractor":quote.contractor,"client":quote.client,'user_id':quote.client})
            }
            else if(stage  == quote_progress){
                data.status = newStatus
                const event = {
                    9:'dispatched',
                    10:'work_started',
                    100:'work_completed'
                } [newStatus]
                if(event && (quote.status != newStatus)){
                    $event(event,{"client":quote.client,"service_id":service.id,"user_id":quote.client,"contractor_id":quote.contractor})
                }
            }
            else if(stage == pick_time){                
                const timmes = {}
                for(let i=0;i<3;i++){
                    const tim = times[i]
    
                    timmes[`time_slot${i+1}_start`] = new Date(tim.date+'T'+tim.start).toISOString()
                    timmes[`time_slot${i+1}_end`] = new Date(tim.date+'T'+tim.end).toISOString()
                    
                }
                data = {
                    ...data,
                    status: quote.status == 4 ? 5 : 2,
                    ...timmes,                    
                }
                let event = 'quote_provided'
                if(quote.status ==  4){event = 'provided_alternate_time'} 
                $event(event,{"contractor":quote.contractor,"client":quote.client,'user_id':quote.client})
            }
            setLoading(1)
            // const res = await patchQuote({status:1,message})
            const res = await patchQuote(data,quote.id)
            onClose()
        } catch (err) {
            $error(err)
        }finally{
            setLoading(0)

        }
    }

    function pickTime({date,start,end}){
        const tims= [...times]
        tims[editedTime] = {date:date,start,end}
        setTimes(tims);
        setEditedTime(-1)
    }
    
    return <MDialog open={true}
        onClose={onClose}
        height={isMobile?'':'max(50vh,400px)'}
        titleBar={
            edited?
            <h4 className='font-weight-bold m-3'>
                Service request
                <span className='text-muted mx-2'>#{padZero(service && service.id,3)}</span>
            </h4>
            :null
        }
        footer={
            <React.Fragment>
                {!(activeSlide.noNext && activeSlide.noBack) ?
                <div className='d-flex flex-column pt-1 mb-1 '>
                    <ActionButtons
                        disabled={activeSlide.notYet}
                        nextText={activeSlide.next}
                        backText={activeSlide.back||'Back'}
                        expand
                        noNext={activeSlide.noNext}
                        noBack={activeSlide.noBack}
                        onBack={handleBack}
                        onNext={handleNext}
                        loading={loading}
                    />
                </div>
                :
                <div className='d-flex pt-1 mb-1 '>
                    <Button
                        {...button_props}
                        className='flex-grow-1 mr-2'
                        disableElevation
                        onClick={handleContact}
                    >
                        {contactClient}
                    </Button>
                    {( !(goneWithAnother)) &&
                        <SimpleMenu
                            options={['provide_quote','refuse_request']}
                            {...button_props}
                            color='primary'
                            className='flex-grow-1'
                            onChange={handleContextClick}
                        >
                            Reply To request
                            <DownIcon size='15' />
                        </SimpleMenu>
                    }
                </div>
            }
            </React.Fragment>
        }
    >
   
    <div className=' d-flex flex-column
     flex-grow-1 px-1 pb-2 pt-2'
    >
        <div className={` d-flex flex-column flex-grow-1 pb-1 px-sm-2
                ${activeSlide.class||'my-dialog-md'}
        `}
        >
            {activeSlide.content}       

            <MDialog open={editedTime>=0}
                onClose={()=>setEditedTime(-1)}
            >
                <TimeSlotPicker 
                    onChange={time=>pickTime(time)}
                    onClose={()=>setEditedTime(-1)}
                    value={times[editedTime]}
                    times={times}
                    slot={editedTime}
                />
    
            </MDialog>
            
        </div>
    </div>
 

</MDialog>





}

function ServiceTile({s,onClick,...props}){

    const service = s.service
    const feat = s.feature || {}
    // let status_text = s.status_text || ''
    const updatable = [8,9,10].includes(s.status) && s.service.status != -1
    console.log(s)
    const messages = useSelector(selectMessages)
    const user = useSelector(getUser)

    const unread_message = s.unread_message
    const actions = ['view',]
    const history = useHistory()
    if(unread_message?.message){
        actions.unshift('respond_to_home_owner')
    }
    else if(s.status > 8 && s.status <= 10){
        actions.unshift('update_status')
    }

    
    const handleAction = (action) => {
        if(action == 'view'){
            if(onClick){onClick()}
        }else if(action == 'update_status'){
            if(onClick){onClick()}
        }else if(action == 'respond_to_home_owner'){
            history.push(`/messages?to=${s.client}&service=${s.service.id}`)
        }
    }

    return <div className='
        py-3 px-1 border-bottom cursor-pointer d-flex'
        key={s.id}
        onClick={onClick}
        {...props}
        // style={{display:'grid',gridTemplateColumns:'6fr 3fr'}}
    >
        <div className='d-flex align-items-center overflow-hidden flex-grow-1'>
            
            <img src={getFeatureIcon(feat)} height='36px'/>

            <div className='d-flex flex-column align-items-sm-center 
                flex-sm-row overflow-hidden flex-grow-1  mx-2 mr-3
                '>

                <p className=' font-weight-500 text-truncate mr-3'>
                {feat.name}
                </p>

                <StatusText quote={s} fontWeight='400'/>
                {unread_message?.message && 
                    <p className='mx-auto text-secondary text-medium d-flex align-items-center'
                        onClick={()=>handleAction('respond_to_home_owner')}
                    >
                     <ChatBubbleOutline color='primary' className='ml-2 mr-1' fontSize='small'/>
                        Received a message
                    </p>
                }

            </div>

        </div>

        
        {/* <div className='d-flex justify-content-end align-items-center '> */}
           
            <div className='d-none d-sm-block '>

                {actions.length == 1?null:
                    <button className='bg-white text-primary text-medium ml-2 '
                        onClick={()=>handleAction(actions[0])}
                    >
                        {$prettify(actions[0])}
                    </button>
                }
            </div>

            <SimpleMenu options={actions}
                className='bg-white text-primary'
                onChange={a=>handleAction(a)}
            >
                <MoreHoriz />
            </SimpleMenu>
            
        {/* </div> */}

        
    </div>
}

export default function ContractorDashboard(props){

    const quotes = useSelector(selectQuotes)

    const [loading,setLoading] = useState(1)
    const [edited,setEdited] = useState(null)

    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()

    const user = useSelector(getUser)
    const messages = useSelector(selectMessages)
    const contractor = user?.linked_data?.contractor

    const fetchServiceRequests = async () => {
        setLoading(1)
        try {
            await dispatch(fetchQuotes())
            dispatch(fetchMessages())

        } catch (err) {
            
        } finally {
            setLoading(0)
        }
    }
    
    useEffect(()=>{
        fetchServiceRequests()
    },[])

    useEffect(()=>{
        const query = qs.parse(location.search.replace('?',''))
        const id = query.id
        if(quotes && id){
            let quote = quotes.find(q => q.service.id == id)

            quote = quote || quotes.find(q=> q.id == id)
            setEdited(quote)
        }

    },[quotes,location])

    const service = edited && edited.service
    const feature = edited && edited.feature

    let filtered_quotes = [
        {
            key:'new services',
            condition: q => (q.status==0 && q.service.status == 0),
        },
        {
            key:'services in progress',
            condition: q => (q.status >0 && q.status < 100),
        },
        {
            key:'completed services',
            condition: q => (q.status>=100 || q.service.status >= 100)
        },
        {
            key:'Refused services',
            condition: q => {
    
                return (q.status==1 || didAcceptAnother(q))
            }
        },
        {
            key:'canceled services',
            condition: q => (q.service.status==-1 || q.status == -1)
        }
    ].reverse()

    let remaining = [...quotes]
    for(let q of filtered_quotes){
        q.list = remaining.filter(q.condition)
        remaining = remaining.filter(tq => !q.condition(tq))
    }
    filtered_quotes.reverse()


    function handleClose(){
        setEdited(false)
        history.replace(location.pathname)
    }

    return <div className='my-3'>
        <div className=' bg-primary '>
            <div className='container d-flex justify-content-between 
                 
            '>
                <div className=' py-4 px-1 py-sm-4 text-left d-sm-flex align-items-center pr-sm-4'>
                    <h2 className='text-white font-weight-bold'>
                        Manage your service <br className='d-sm-none' /> requests in one place
                    </h2>
                </div>
                <div>
                    <img src='/images/dashboard/contractors.svg' 
                    height='160px' style={{transform:"rotate(5deg) translateY(25%) "}}
                    />
                </div>
            </div>
        </div>

        {(contractor && !contractor.location) &&  
            <div className='bg-danger text-white text-small p-1 cursor-pointer'
                onClick={()=>dispatch(setDialog('profile'))}
            >
                Please provide your address.
            </div>
        }

        {loading == 1 ?
            <div className=' d-flex flex-column align-items-center '>

                <CircularProgress className=' my-5 text-primary'/>
                <p className='text-secondary '>
                    Loading service requests..
                </p>
            </div>

            :
            <div className='container mt-2 px-1 text-left'>
                {quotes.length?
                    filtered_quotes.map( q =>{
                        const list = q.list.slice().map(q => {
                            const unread_message = getLatestReceivedMessage(q.service,messages,user.id)
                            let updated_at = q.updated_at
                            if(unread_message?.message){
                                updated_at = updated_at > unread_message.created_at ? updated_at : unread_message.created_at
                            } 
                            return {
                                ...q,
                                unread_message,
                                updated_at
                            }
                        })
                        .sort((a,b)=>a.updated_at>b.updated_at?-1:0);
                        if(!list.length){
                            return null
                        }
                        return <React.Fragment key={q.key}>
                            <p className='text-secondary font-weight-500
                                border-bottom text-left p-2 text-capitalize'>
                                {q.key} 
                            </p>
                            
                            {list.map(
                                s => <ServiceTile s={s} key={s.id} onClick={()=>setEdited(s)} />
                            )}

                        </React.Fragment>
                    })
                    :
                    <p className='text-muted my-5 text-center lead'>
                            You don't have any service requests yet.
                    </p>
                }
            </div>
        }


        {edited && 
            <QuoteProvider edited={edited} onClose={handleClose} />
        }

    </div>

}


export function TimeSlotPicker({value,onClose,slot,times,onChange}){
    const littleText = 'text-muted text-medium mb-1'

    let now = new Date()
    const tday = `${now.getFullYear()}-${padZero(now.getMonth()+1)}-${padZero(now.getDate())}`
    const hours = [now.getHours(),now.getMinutes()]
    const notToday = hours[0] >= 21
    console.log(hours)
    const [start,setStart] = useState('')
    const [end,setEnd] = useState('')
    const [date,setDate] = useState('')
    const [touched,setTouched] = useState(0)

    const picked_already = slot>=0 && date && times && times.some((t,i) => slot != i && t.date && t.date == date && t.start == start) 
 
    useEffect(()=>{
        if(value){
            setStart(value.start)
            setEnd(value.end)
            setDate(value.date)
        }
    },[value])

    function setStartTime(t){
        setStart(t)
        console.log(end)
        const [h,m] = t.split(':').map(st=> Number(st))
        if(!(touched&2)){
            const newEnd = Math.min(h+4,21)
            setEnd(`${padZero(newEnd)}:00`)
            console.log(newEnd)
        }
        setTouched(touched|1)
    }
    function setEndTime(t){
        setEnd(t)
        const [h,m] = t.split(':').map(st=> Number(st))
        if(!(touched&1)){
            const newStart = Math.max(h-4,6)
            setStart(`${padZero(newStart)}:00`)
        }
        setTouched(touched|2)
    }

    function setTheDate(d){
        setDate(d)
        console.log(d,tday)
        if(d == tday){  
            let st = hours[0]+2
            let et = Math.min(st+4,24)
  
            setStart(padZero(st)+':00')
            setEnd(padZero(et)+':00')
        }
    }

    const endBeforeStart = (end <= start)
    const sdate = start.split(':').map((s,i) => Number(s)*(1+59*(1-i))).reduce((a,b)=>a+b,0)
    const edate = end.split(':').map((s,i) => Number(s)*(1+59*(1-i))).reduce((a,b)=>a+b,0)
    // console.log(sdate,edate)

    const endMoreThan4 = ((edate-sdate)/60) > 4

    let [cdate,ctime] = getDateTime(new Date())

    // hours,minutes of current time
    const ctdate = hours.map((s,i) => Number(s)*(1+59*(1-i))).reduce((a,b)=>a+b,0)

    const pickedPast =  date && ((date < cdate) || (date == cdate && sdate < ctdate))
    const invalid = !date || endBeforeStart || pickedPast

    let error1,error2;
    if(pickedPast){
        error1 = 'Please pick a time in the future.'
    }
    // if(endMoreThan4){
    //     error2 = 'Please pick a time within 4 hours of the start time.'
    // }
    if(endBeforeStart){
        error2 = `Either choose a start time before the end time, 
        or choose an end time that is after the start time.`
    }

    return <div className='my-dialog p-2 p-sm-3 flex-grow-1 d-flex flex-column pb-3'>
        <h3 className='font-weight-bold '>
            Propose new time
        </h3>
        <p className='text-small text-muted'>
        Pick a start and end time period of the appointment 
        during which you can visit the property, the homeowner
            will choose a time slot from the ones you provide.
        </p>
        <p className={`${littleText} my-1`}>
            Date
        </p>
        <DatePicker value={date} onChange={setTheDate} 
            notToday={notToday}
        />
        <p className={`${littleText} my-1`}>
            Start time
        </p>

        <TimePicker value={start} onChange={setStartTime} />
        {!error1?null:
            <p className='text-danger text-small my-1'>
                {error1}
            </p>
        }


        <p className={`${littleText} my-1`}>
            End time
        </p>
        <TimePicker value={end} onChange={setEndTime}
            className='mb-3'
        />
        {!error2?null:
            <p className='text-danger text-small my-1'>
               {error2}
            </p>
        }
        {picked_already?
            <p className='text-small text-danger my-2 '>
                You have already provided a time slot with {to12hours(start)} as start time,
                choose a different time
            </p>
        :null}
        <ActionButtons 
            onBack={()=>onClose()}
            onNext={()=>onChange({date,start,end})}
            nextText="Submit"
            disabled={invalid||picked_already}
        />
    </div>
}
