import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {logout,getUser,patchUser, setUser} from '../../app/auth/authSlice'
import {useHistory, useLocation, Link, } from 'react-router-dom'
import { Button, Drawer, Popover } from '@material-ui/core'
import { capitalize, $prettify } from '../../utils'
import CloseIcon from '@material-ui/icons/Close'
import {getUserType,userTypes} from '../../app/user/userSlice'

import MenuIcon from '@material-ui/icons/Menu'
import SimpleMenu from '../common/SimpleMenu'
import NotificationPanel from './NotificationPanel'
import { setDialog } from '../../app/ui/uiSlice'
import { $pageView } from '../../utils/http'

export default function TopBar(props) {
    const {landingUserTypes,landingUserType,switchUserType} = props
    const user = useSelector(getUser)
    const dispatch = useDispatch()

    const location = useLocation()
    const showSwitcher = ['/','/home'].includes(location.pathname)
    const invert = landingUserType === 'agents' && showSwitcher
    const showSignIn = !user
    const [openDrawer,setDrawer] = useState(false)

    const handleSignInDialog = props.handleSignInDialog

    const userType = useSelector(getUserType) 
    // const flipLogin = ()=>{
    //     const loginAction = user ? logout : login
    //     dispatch(loginAction({name:'ali baba'}))
    // }
    const history = useHistory()
    function switchUser(to){

        history.push({
            pathname: user?'/home':'/',
            search:'?for='+to
        })
        switchUserType(to)

    }
    

    useEffect(()=>{
        $pageView(location.pathname)
        console.log('visiting page ..  '+location.pathname)

        history.listen((loc)=>{
            $pageView(loc.pathname)

            console.log('visiting page ..  '+loc.pathname)
        })
    },[])
    
    useEffect(()=>{
        window.scrollTo(0,1)

    },[location])

    const {displaySignIn} = props 


    const links = ['home','counter'].map(l => ({name:l,path:'/'+l}))
    links.find(l => l.name === 'home').path = '/'

    
    function handleButtonClick(button){
        console.log(button)
        
        if(button == 'sign_out'){
            dispatch(logout())
            history.replace(location.pathname)
        }
        // if(button == 'payment_details'){
        //     dispatch(setDialog('payment'))
        // }
        else{
            dispatch(setDialog(button))
        }
        setDrawer(false)

    }

    function handleTabSwitch(newTab){
        if(landingUserTypes.includes(newTab)){
            switchUser(newTab)
        }else{
            history.push( '/'+newTab)
        }
        setDrawer(false)
    }

    function TabbedButton({buttons,...props}){
        const cinvert = invert && !props.static
        const urlParts = location.pathname.split('/')
        const uri = urlParts.length && urlParts[1]

        let isActive = (props.isActive || (button => {
            return (uri === button)
        }) )

        return <div className={(props.vertical?' flex-column ':'')+' '+(props.className||'d-flex ')}>
        {
            buttons.map(u => (
                <Button 
                    key={u}
                    variant={isActive(u) ? 'outlined':'text'} 
                    color={isActive(u) ? "primary" :'default'} 
                    className={`
                    ${cinvert?'border-white text-white':'border-primary'} 
                    mx-1 font-weight-bold`}
                    style={{outline:'none',borderWidth:'2px',}}
                    onClick={()=>handleTabSwitch(u)}
                >
                    {u.split('-').map(a => capitalize(a)).join(' ')}
                </Button>
            ))
        }
        </div>
        
    }

    function UserTypeSwitcher(props){
        return showSwitcher?
        <TabbedButton 
            buttons={landingUserTypes} 
            isActive={button=>(landingUserType === button)}
            {...props}    
        />
        :false    
    }

 
    let availablePages = [
    ]
    const isAdmin = user?.is_staff
    if(!isAdmin){
        if(userType==userTypes.agent || (userType==userTypes.home_owner)){availablePages.push(...['dashboard',])}
        if(userType==userTypes.home_owner || userType==userTypes.contractor){availablePages.push(...['services'])}
        if(userType === userTypes.potential_buyer){availablePages.push('dashboard')}
        if(userType != null){
            availablePages.push('messages')
        }
    }

    
    let buttons =  [
       'profile'
    ]

    if(userType==userTypes.home_owner){buttons.push(...['payment_details','my_properties',])}

    if(userType==userTypes.agent || (userType==userTypes.contractor)){buttons.push(...[])}
    
    buttons.push(...['sign_out'])
    // if(userType === userTypes.potential_buyer){buttons.push('dashboard')}
    
    if(isAdmin){
        buttons = ['sign_out']
    }
    let buttonList = buttons.map((button,i) =>
        <Button 
            key={button}
            // color='primary'
            disableElevation
            onClick={()=>handleButtonClick(button)}
            className={'py-2 d-block px-4 font-weight-500 '+(i==0?'border-top':'border-top')}
        >
            {$prettify(button)}
        </Button>
    )

    return <div className={(invert?'bg-pri-light':'')+' '+
        (' pt-3 pb-2  landing-page')
    }>

        <Drawer
            open={openDrawer}
            onClose={()=>{setDrawer(false)}}
            anchor='top'
            PaperProps={{style:{borderBottomLeftRadius:'16px',borderBottomRightRadius:'16px'}}}
        >   
            <div className='mx-0 mt-3 mb-1 d-flex flex-column px-1'  >
                
                <div className='d-flex justify-content-between mb-3 ml-2'>
                    <img src='/images/micasa-logo-text.svg' alt="micasa logo"
                     style={{height:'32px'}} 
                    />
                    <Button onClick={()=>{setDrawer(false)}}>
                        <CloseIcon />
                    </Button>
                </div>
                <UserTypeSwitcher static vertical />
                <TabbedButton buttons={availablePages} vertical  />


                {showSwitcher ? null : buttonList}
                
                {(showSwitcher?
                    [
                        <Button 
                            variant="contained"
                            color='primary'
                            disableElevation
                            onClick={()=>{displaySignIn(2);setDrawer(false);}}
                            className='py-2'
                        >
                            Create Account
                        </Button>,
                        <Button 
                            disableElevation
                            onClick={()=>{handleSignInDialog();setDrawer(false);}}
                            className='py-2 '
                            style={{backgroundColor:'#e3e3e3'}}
                        >
                            Sign In
                        </Button>
                    ].map(
                        (u,i) => (
                            <div key={i} className={'my-1 d-flex flex-column '+(i<2?'pt-2 border-top':'')}>
                                {u}
                            </div>
                        )
                    )
                :false)}
                

                
            </div>


        </Drawer>

    <div 
        className={`d-flex  pr-0 
                ${isAdmin?'container-fluid px-5':'container pr-sm-2'}
        `}
    >
        <Link to={user?'/':'/'} className='mr-4'>
            <img src='/images/micasa-logo-text.svg' 
            alt="micasa logo with text"
            style={{height:'2.2em',
            ...(invert?{filter:'brightness(0) invert(1)'}:{})
            }} />
        </Link>

        <TabbedButton buttons={availablePages} className='d-none d-md-block ml-3'  />

        {
            showSignIn?
            <div className=' flex-grow-1 ml-4 d-none d-md-flex'>
                
                <UserTypeSwitcher />

                <span className=' ml-auto'>

                    <Button
                    variant='contained'
                    disableElevation
                    color='primary'
                    className={`${invert?'bg-white text-primary':''} mr-2`}
                    onClick={()=>displaySignIn(2)}
                    >
                        Create account
                    </Button>

                    <Button
                    variant='contained'
                    disableElevation
                    color={invert?'primary':'default'}
                    className={`${invert?'text-white bg-pri-dark':''} `}
                    onClick={()=>{handleSignInDialog();}}
                    >
                        Sign in
                    </Button>

                 
                </span>
            </div>
            :
            <div className='d-none  d-md-flex ml-auto align-tems-center'>
                {showSwitcher && 
                    <UserTypeSwitcher />
                }
                {
                    user?
                    <div className='p-0 mr-2 d-flex ml-3'>
                       
                        <SimpleMenu
                            onChange={handleButtonClick}
                            options={buttons}
                        >
                            My account
                        </SimpleMenu>
                        
                    </div>
                    :
                    false
                }

            </div>
        }
        <div className=' ml-auto ml-md-0'>
            {user && !isAdmin ?
                <NotificationPanel className='ml-1 ' />
            :null}
        </div>

        <Button
            onClick={()=>setDrawer(true)}
            className=' d-md-none '
        >    

                <MenuIcon/>

        </Button>


       

    </div>

</div>



}