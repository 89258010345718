
import {addToast,setAlert,setConfirm} from '../app/ui/uiSlice'
import * as yup from 'yup'
import { login } from '../app/auth/authSlice'

const version = 0.10

export const $baseUrl = process.env.REACT_APP_BASE_URL
export function $success(res){
    let message = 'Success'
    if(typeof res === 'string'){
        message = res
    }else{
        if(res.data){message = (res.data.message || res.statusText || 'success')}
    }
    if(message === 'OK'){message = "Success"}

    console.log(message,res)
    const store = window.store
    store.dispatch(addToast({message,type:'success'}))
    console.log(message)
}

export function $error(err){
 
    let message = 'Error occured'
    if(typeof err === 'string'){
        message = err
    }else{
        const res = err.response
        const statusText = res?res.statusText:null
        const data = res?res.data:null
        message = (data&&data.message)?(res.data.message):statusText
        const localMode = false
        if(!localMode && !navigator.onLine){
            message = 'You are offline'
        }else if(err.message === 'Network Error'){
            message = 'Could not connect to server'
        }else if(!message){
            message = (err.statusText)||err.message || 'There was an error,Please check Console';
        }
        if(err.stack){
            console.log(err.stack)
        }
    }
    
    const store = window.store
    if(store){
        store.dispatch(addToast({message,type:'error'}))
    }
    console.log(message)
}
export function $warning(message){ 
    const store = window.store
    store.dispatch(addToast({message,type:'warning'}))
}

export function $confirm(payload){
    window.store.dispatch(setConfirm(payload))
    return new Promise((res,rej)=>{
        window.addEventListener('confirm',e=>{
            if(e.detail){
                res()
            }else{
                rej()
            }
        })
    })
}

export function $alert(payload){
    window.store.dispatch(setAlert(payload))
    window.alert_content = ''
}

window.$alert = $alert
window.error = $error
window.warning = $warning
window.success = $success

function fromSamePage(){
    const ref = document.referrer;

    return ref.split('/')[2] === window.location.host
}

export function signInFromCache(initial=false,callback){
    const local_version = localStorage.getItem('version')
    
    if(local_version != version){
        localStorage.setItem('version',version)
        localStorage.setItem('user',null)
    }

    const user = localStorage.getItem('user')
    if(user && user!='' && user!='null'){
        const userData = JSON.parse(user)

        const remember = userData.remember
        userData.cached = true
        let pageReloaded = false

        if(initial === true){
            let lastSeen = localStorage.getItem('lastSeen')
            if(lastSeen){
                if(lastSeen){lastSeen = Number(lastSeen)}
                const recent = (Date.now() - lastSeen) < 120000
                const fromThisPage = fromSamePage()
                // alert(Date.now()-lastSeen)
                if(recent || fromThisPage){
                    pageReloaded = true
                }
            }
        }

        const valid = (!initial && remember) || pageReloaded 
        console.log(pageReloaded,valid,initial)
        if(valid){
            window.store.dispatch(login({remember,...userData}))
            return true
        }
    }
}

export const $validPassword = yup.string().test('password', 'myFailureMessage', function(value) {
    const { path, createError } = this;
    const text = (value || '')
    const messages = []
    const rules = [
        [/^(?=.*[A-Z]).*$/,' uppercase letter'],
        [/^(?=.*[a-z]).*$/,' lowercase letter'],
        [/^(?=.*\d).*$/,' number'],
        [/^(?=.*[@$!%*#?&]).*$/,' special character (@$!%*#?&)'],
        // [/^[A-Za-z\d@$!%*#?&].*$/,'Password can only contain english alphabets,numbers and these special characters (@$!%*#?&)'],
    ]
    
    let notEnough = text.length < 8
    let start = `Password ${notEnough?'must atleast be 8 characters long':''}`
    rules.forEach((rule,i) =>{
        const [p,e] = rule
        if(!text.match(p)){
            let error = e
            if(messages.length == 0){
                error = (notEnough?' and':'')+' must contain atleast one '+e
            }
            messages.push(error)
        }
    })
    let message = start + messages.slice(0,-1).join(', one')
    let last_fix = ''
    if(messages.length >2){
        last_fix = ' and a'
    }else if(messages.length >1){
        last_fix = ' and one'
    }
    message += last_fix+messages.slice(-1)

    if(notEnough || messages.length){
        return createError({ path, message });
    }else{
        return true
    }
})


// yup.string().min(8,'Password must contain atleast 8 characters').
// matches(/^[A-Za-z\d@$!%*#?&]{8,}$/,'Password can only contain english alphabets,numbers and these special characters (@$!%*#?&)').
// matches(/^(?=.*[a-z])[A-Za-z\d@$!%*#?&]{8,}$/,'Password must contain atleast one Lowercase letter').
// matches(/^(?=.*[A-Z])[A-Za-z\d@$!%*#?&]{8,}$/,'Password must contain atleast one Uppercase letter').
// matches(/^(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,'Password must contain atleast one number').
// matches(/^(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,'Password must contain atleast one special character (@$!%*#?&)')
// // .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,'haha!!')

export const $validEmail = (message,required=true) => yup.string().test('babu', 'myFailureMessage2', function(value) {
    const { path, createError } = this;
    const email = (value || '')
    let invalid = true

    // invalid = email.match(/^(?=.*[A-Z]).*$/)
    invalid = !email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    if(!required && !value){
        invalid = false
    }
    message = message || 'Email is invalid'
    if(required && !value){message = 'Email is required'}
    if(invalid){
        return createError({ path, message });
    }else{
        return true
    }
})


export const $required = (field_name="This ") => `${field_name} is required`
export const $phoneValidator = val => {
    const value = val && val.replace(/[-() ]/g,'')
    return !value || (value.length === 10)
}

export function $event () {
    if($baseUrl?.includes('localhost')){return}
    if(window.gtag){
        window.gtag('event',...arguments)
    }
}
export function $pageView(args){
    const [path,name,query] = arguments
    const page_data = {
        page_location: path,//'https://example.com/about',
        page_path: query,
        page_title: name,
    }
    if(window.gtag){
        window.gtag('event', 'page_view', page_data);
    }
}
export function $modalView(name){
    if(window.gtag){
        window.gtag('event', 'screen_view', {screen_name:name});
    }
}
export const $http = {$baseUrl,$success,$error,$event}