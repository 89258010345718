import React, { useEffect, useRef, useState } from 'react'
import { Button } from '@material-ui/core'
import LandingPart from '../components/special/LandingPart'
import styles from '../styles'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { getPricing } from '../app/user/userSlice';
import { setDialog } from '../app/ui/uiSlice';
import { getUser } from '../app/auth/authSlice';

const featureCards = [
    {
        image:'receipts.svg',
        text:'Manage documents & receipts of your Home'
    },
    {
        image:'tools.svg',
        text:'Request Maintenance assistance '
    },
    {
        image:'calendar.svg',
        text:'Track your home for its next checkup'
    },
]

export const HomeOwnerLandingPart = ({small,animated=false,...props}) => <LandingPart
    small={small}
    title = "Your personal property manager"
    description={` micasa makes it simple for you to keep track of the
    many facets of home ownership all in one place.`}
    image='home-thumbs-up.svg'
    animated
    {...props}
>

</LandingPart>
function isScrolledIntoView(container,elem,offset){ 
  const erect = elem.getBoundingClientRect();
  const perect = container.getBoundingClientRect();
  
  var docViewTop = container.scrollTop;
  if(container!=document){docViewTop = perect.top}
  var docViewBottom = docViewTop + perect.height;
  
    var elemTop = erect.top;
    var elemBottom = erect.top + erect.height // - offset * erect.height;

    // console.log(elemTop,docViewBottom)
    const off = offset * erect.height

    return ((elemBottom <= docViewBottom) && (elemBottom >= docViewTop+off)) || 
    ((elemTop <= docViewBottom-off) && (elemTop >= docViewTop))

}
export function Appear({delay=100,animated=true,duration=0.8,
    direction='in',container,offset=0.1,children,component,style,...props}){

    const divRef = useRef()
    const [astyle,setAStyle] = useState({opacity:animated?0:1})

    function setAnim(){
        const tstyle = {}
        tstyle.animation = (`fade-${direction}`)+' '+(duration)+'s'
        
        tstyle.animationFillMode = 'forwards';  
        tstyle.animationDelay = (delay || 100)+'ms'
        tstyle.opacity = 0
        
        setAStyle(tstyle)
    }

    function handleInit(el) {
        if(!animated){return;}
        const root = document.getElementById('main-column')
        let scroll_container  =  container || root

        if(typeof scroll_container == 'string'){
            scroll_container = document.getElementById(scroll_container)|| root
        }
            
        function handler(){
          if(isScrolledIntoView(scroll_container,el,offset||0.2)){
            setAnim()
            scroll_container.removeEventListener('scroll',handler)
          }
    
        }
        
        if(scroll_container){
          try{
            scroll_container.addEventListener('scroll',handler)
            handler()
          }catch{
            setAnim()
          }
        }else{
          setAnim()
        }

        return ()=>{
            scroll_container.removeEventListener('scroll',handler)
        }
    
    }

    useEffect(()=>{

        const cleanUp = handleInit(divRef.current)

        return cleanUp

    },[])

    const fades = ['fade-in','fade-right','fade-left','fade-above','fade-below']

    const Component = component || 'div'
    return <Component {...props} style={{...style,...astyle}}
        ref={divRef}
    >
        {children}
    </Component>

}

export const HomeOwnerFeatures = ({small,animated=false}) => <div className={'container '}>
    <div className='row row-cols-1 row-cols-md-3 '
        style={{
            ...(small?{}:{margin:'8em 0em 6em 0em'})
        }}
    >
        {featureCards.map((f,i) =>
            <Appear key={f.text} 
                className={`col py-2 px-2 d-flex flex-column`} 
                animated
                delay={(100+i*200)}
            >
                <div className={'feature-card d-flex flex-column flex-grow-1 py-4 '+(small?'p-3':'p-5')}>
                    <img src={'/images/landing/'+f.image} 
                    alt='feature-image'
                    style={{maxHeight:'8em'}}
                    />
                    <p className='font-weight-bold text-medium mt-3'>
                        {f.text}
                    </p>
                </div>
            </Appear>
            
        )}
    </div>
</div>
// const PlanTable = 

export const PricingTable = ({small,noHeading,...props}) => {
    const pricing = useSelector(getPricing)
    const user = useSelector(getUser)
    const dispatch = useDispatch()

    function PlanLoadFailed(tprops){
        return <div {...tprops}>
            Failed to load plans.
        </div>
    }
    
    const Yess = <CheckIcon />
    const Nope = <CloseIcon style={{color:'gray'}} />


    const tableBgBlue = '#69AFFF44'
    const tableBorderBlue = '#69AFFF88'
    function displayCell(p,i,key){
        const val = p[key]
        const isString = typeof val === 'string'
        const textStyle = {
            ...styles.tableFeature,
        }
        if(i>0 && isString){textStyle.color = 'var(--text-color)'}
        
        return <td className={i>0?'':'border-left'}
            key={key+i}
            style={{
                borderColor:i>0?tableBorderBlue:'',
                backgroundColor:i>0?tableBgBlue:'white',
                ...(isString?textStyle:{})
            }}
        >
            {
            
                (typeof val === 'number')? 
                (`$${val} a month`):
                (
                    isString?(val):
                    (val?Yess:Nope)
                    
                    
                )
            
            }
        </td>
    }
    const comparePlans = <div className='mt-4 px-1 px-sm-3'>
        {!noHeading && 
            <Appear component={'h4'} delay={200} className='font-weight-bold '>
                Compare plans
            </Appear>
        }

        <Appear component='p' delay={noHeading?0:400}  className='text-muted my-4'>
    
            Whether you’re a homeowner
            tracking your own maintenance - or 
            looking to find a property manager,
            we have the plan for you
    
        </Appear>
    </div>
    
    const  disclaimer = <div>
        <p className='text-muted mt-3 mb-2 px-1 px-sm-3'>
        *Must not exceed $99, and the homeowner 
        is responsible for any additional costs
        </p>
    </div>

    function handlePlanClick(auth_type,plan){
        if(user){
            dispatch(setDialog(['payment_details',{plan}]))
        }else{
            props.displaySignIn(auth_type,plan)
        }
    }

    const [basic,premium] = pricing
    function tablePrettify(str){
        const parts = str.split('_').join(' ')
        return parts[0].toUpperCase()+parts.slice(1)
    }
    const plan1 =  (basic && basic.plan) || ''
    const plan2 = (premium && premium.plan) || ''

    const planButtonLabel = plan => plan?.trial_period ? `Try ${getTrialPeriod(plan)} free`: `Select ${plan?.plan}`

    const tryHandsOn = <Button
        disableElevation
        variant='contained'
        onClick={()=>handlePlanClick(2,0)}
    >
        {planButtonLabel(basic)}
    </Button>

    const tryHandsFree = <Button
        disableElevation
        variant='contained'
        color='primary'
        className='text-white'
        onClick={()=>handlePlanClick(2,1)}
    >
        {planButtonLabel(premium)}
    </Button> 


    // Delete descriptoin key values from plan display table

    const displayPricing = pricing.map(p => {
        const pri = {...p}
        const avoidables = [
            'id',
            'name','features','trial_period',
            'landing_description','setup_description',
            'property_manager',
            'stripe_price_id',
            'quarterly_maintenance',
        ]
        const keys = Object.keys(pri)
        for(let key of keys){
            if(avoidables.includes(key)){
                delete pri[key]
            }
        }
        return pri
    })

    const planCardStyle = {
        padding:'28px',
        borderRadius:'12px',
        margin:small?'12px 4px':'18px 20px',
        textAlign:'left'
    }

 return <div>
    {basic?
        <div className='d-block d-md-none px-2'>
            {comparePlans}
            
            <div style={{
                ...planCardStyle,
                backgroundColor:'#f2f2f2',
            }}>
                <div className='d-flex align-items-center
                justify-content-between'>
                    <p className='font-weight-bold mb-0 px-2 '>{plan1}</p>
                    <Button className='bg-primary text-white'>
                        $ {basic.price} per month
                    </Button>
                </div>
                <p style={{color:'gray'}} className='my-4'>
                    {basic.landing_description}
                </p>
                {tryHandsOn}
            </div>

            <div style={{
                ...planCardStyle,
                backgroundColor:tableBgBlue,
            }}
            >
                <div className='d-flex align-items-center
                justify-content-between'>
                    <p className='font-weight-bold mb-0 px-2 '>{plan2}</p>
                    <Button className='bg-primary text-white'>
                        $ {premium.price} per month
                    </Button>
                </div>
                <p style={{color:'gray'}} className='my-4'>
                    {premium.landing_description}
                </p>
                {tryHandsFree}
            </div>

            {disclaimer}
        </div>
        :
        <PlanLoadFailed  className='d-block d-md-none'/>
        }


        {basic?
        <Appear className='rounded-extra d-none d-md-block
        container border px-0 mt-2 mb-5'
        >
            {comparePlans}

            <Appear component='table' delay={400} className='  w-100 '>
                <tbody>

                {Object.keys(displayPricing[0]).map( key =>
                    <tr key={key}>
                        <td className='text-left pl-5 font-weight-bold'
                            style={styles.tableFeature}
                            >
                            {tablePrettify(key)}
                        </td>

                        {displayPricing.map( (p,i) => 
                            displayCell(p,i,key)
                            )}

                    </tr>
                )}
                <tr>
                    <td className='text-left pl-5'>
                        <div>
                            <p style={styles.tableFeature}>
                                Property manager
                            </p>
                            <p className='text-muted' style={{maxWidth:'16em'}}>
                                Get dedicated personnel assigned to
                                take care of your homes health for you
                            </p>
                        </div>
                    </td>
                  
                    {displayCell(pricing[0],0,'property_manager')}
                  
                    <td className='overflow-hidden position-relative p-5'
                        style={{
                            backgroundColor:tableBgBlue,
                            borderColor:tableBorderBlue,
                        }}
                    >
                        <div className='my-4'> 
                        {pricing[1].property_manager?
                            Yess:
                            Nope
                            }
                        </div>
                        
                        <Appear component="img" direction="right" duration={0.6}
                        delay={600}
                        src='/images/landing/say-hi.svg'
                            alt='Property manager'
                            style={{
                                position:'absolute',
                                right:'-20px',
                                bottom:'-36px'
                            }}
                        />

                    </td>
                </tr>
                <tr>
                    <td className='text-left pl-5'>
                            <p style={styles.tableFeature}>
                                Quarterly maintenance*
                            </p>
                    </td>
                   
                    {displayCell(pricing[0],0,'quarterly_maintenance')}
                    {displayCell(pricing[1],1,'quarterly_maintenance')}                    
                </tr>
                <tr>
                    <td></td>
                    <td style={{paddingTop:'18px'}}>
                        {tryHandsOn}
                    </td>
                    <td  style={{paddingTop:'18px'}}>
                        {tryHandsFree}  
                    </td>

                </tr>

                
                
                </tbody>
            </Appear>
            {disclaimer}
        </Appear>
        :<PlanLoadFailed className='d-none d-md-block'/>
        }

</div>
}


export default function HomeOwnerLandingPage({handleSignInDialog,...props}){
    
    const user = useSelector(getUser)
    // const defPricing = [
    //     {
    //         plan:'Hands on',
    //         price:8.99,
      
    //         home_facts_and_features : true,
    //         maintenace_help : true,
    //         manage_documents : true,
    //         resources : true,

    //         property_manager : false,
    //         quarterly_maintenance : false,
    //     },
    //     {
    //         plan:'Hands free',
    //         price:89,
      
    //         home_facts_and_features : true,
    //         maintenace_help : true,
    //         manage_documents : true,
    //         resources : true,

    //         property_manager : true,
    //         quarterly_maintenance : true,
    //     }
    // ]
    // let {pricing} = props


return  <div className=''>

        <div className='container'>
        <div className='row   mt-5 mb-5'>
            <div className='col-md-7 text-left d-flex flex-column 
            justify-content-end mb-5 mt-5'>
                <Appear delay={200}>
                    <h1 className='font-weight-bold landing-display-1'>
                        Keep your home in shape
                    </h1>
                </Appear>

                <Appear delay={400} component='p' className='text-muted my-2' >
                    micasa helps you throughout the process of buying,
                    selling or keeping your home - wherever you are in that process.
                </Appear>

                {user?null:

                    <div className='d-flex mt-3'>
                        <Appear component={Button}
                            delay={1000}
                            variant='contained'
                            color='primary'
                            className='mr-2 p-2 px-4'
                            onClick={()=>props.displaySignIn(2)}
                        >
                            Create Account
                        </Appear>
                        <Appear component={Button}
                            delay={800}
                            variant='contained'
                            disableElevation
                            className=' p-2 px-4'
                            onClick={handleSignInDialog}
                        >
                            Sign in
                        </Appear>
                    </div>
                }
            </div>
            <div className='col-md-5'>
                <Appear component='img' src='/images/landing/home.svg' alt='' style={{height:'16em'}} 
                    delay={600}
                />
            </div>

        </div>
        </div>

        {HomeOwnerLandingPart({animated:true,delay:0,offset:0.01})}

        {HomeOwnerFeatures({animated:true})}
        
        {PricingTable({displaySignIn:props.displaySignIn})}

</div>

}



export function getTrialPeriod(plan){
    let trial_days = (plan.trial_period)
    let trial_period = trial_days
    const months = Math.floor(trial_days/30)
    let units = 'months'
    if(trial_period < 30){
        units = 'days'
    }else{
        trial_period = months
    }
    trial_period = `${trial_period} ${units}`
    return trial_period
}