// import '@fortawesome/fontawesome-free/css/all.min.css'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { logout } from './app/auth/authSlice';
import store from './app/store';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { $baseUrl, $event, signInFromCache } from './utils/http';


window.baseUrl = process.env.REACT_APP_BASE_URL

Axios.interceptors.response.use(
  res => res,
  error =>  {
    const resp = error.response
    const status = resp && resp.status
    if(status == 500 /*|| status == 400*/ && !$baseUrl.includes('localhost')){
      const {url,method} = (resp && resp.config) || {}
      $event('error',{code:status,url,method})
    }
    if (error.response && error.response.status === 401) 
    {
      store.dispatch(logout())

      const data = error.response.data || {}
      let message = data.message
      if(!message || message !== 'Invalid credentials'){
        message = "User doesn't exist or is deleted"
      }
      error.response.data= {...data,message}
      // location.reload();
      return Promise.reject({...error,status:401});
    }
    return Promise.reject(error);
  } 
)


// window.initGmaps = () => {
//   alert('initialized Gmaps')
// }

// function addGoogleMaps(){
//   let mapScript = document.createElement('script')
//   mapScript.id='google-map-script'
//   const apiKey = 'AIzaSyDQTvm__l-7YqFGH31BqyOgIjEcdVb_bLk'
//   const url = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initGmaps`
//   // script.async = false;                                 // <-- this is important
//   mapScript.setAttribute('src',url )
//   document.head.appendChild(mapScript)

// }
// addGoogleMaps()


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#057AF0',
      main: '#057AF0',
      dark: '#057AF0',
    },
    secondary: {
      light: '#ffffff',
      main: 'rgb(255, 255, 255)',
      dark: '#ffffff',
      contrastText: '#212121',

      // light: '#248965',
      // main: 'rgb(243, 147, 57)',
      // dark: '#ba000d',
      // contrastText: '#212121',
    },
    disabled:{
      main:'red'
    }
    
  },
  typography:{
    button:{
      textTransform:'none',
      fontWeight: 500,
    },
  },
  shape:{
    borderRadius: 8,
  },
  overrides:{
    MuiAccordion:{
      root:{
        '&:before': {
          display: 'none'
        },
      }
    },
    MuiAccordionSummary:{
        root:{
          minHeight:'unset',

          "&$expanded":{
            margin:'0px',
            padding:"8px",
            minHeight:'unset',

          }
        },
        content:{
          display:'flex',
          alignItems:'center',
          margin:'0px',
          "&$expanded":{
            margin:'0px'
          }
        },
    },
    MuiIconButton:{
      root:{
        padding:'6px',
    
      },
  },
    MuiFormControlLabel:{
      label:{
        fontSize:'0.8rem',
        fontWeight:'500',
        color:'var(--black3)'
      }
    },
    MuiOutlinedInput: {
      root:{
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline":{
          borderColor:"var(--pri-light)"
        },
        "&:disabled:not($focused):not($error) $notchedOutline":{
          borderColor:"var(--pri-light)"
        }
      },
      notchedOutline:{
        borderWidth: "2px",
        borderColor: "#eaeaea",
      },
      input: {
        "&::placeholder": {
          color: "#040609"
        },
        fontSize:'0.9em'
       }
    },
    // MuiInputBase:{
    //   root:{
    //     '&$disabled': {
    //       backgroundColor: '#ff0000'
    //     }
    //   }
    // }
    
  }
  
});


window.store = store

// window.addEventListener('beforeunload', ()=>{
//   localStorage.setItem('lastSeen',Date.now())
// })


// const ntype = window.performance && window.performance.navigation.type

// const user = localStorage.getItem('user')
// if(user && user!='' && user!='null'){
//   const userData = JSON.parse(user)
//   store.dispatch(login(userData))
// }
function setLastSeen(){
  if(store.getState().auth.user){
    localStorage.setItem('lastSeen',Date.now())
  }
}
window.setInterval(setLastSeen,60000)
// window.addEventListener('beforeunload',()=>{
//   setLastSeen()
// })

signInFromCache(true)

ReactDOM.render(
  <React.Fragment>
    <ThemeProvider theme={theme}>


      <Provider store={store}>
        <App />
      </Provider>
    
    </ThemeProvider>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// var exampleSocket = new WebSocket("ws://localhost:8765", "echo-protocol");


// exampleSocket.onopen = function (event) {
//   exampleSocket.send("Here's some text that the server is urgently awaiting!"); 
// };


// exampleSocket.onerror = (event) => {
//   console.log('Could not connect to ws')
// }

// exampleSocket.onmessage = 

