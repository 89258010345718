
export function capitalize(str){

    return (str[0].toUpperCase())+str.slice(1)

}


export function $wait(time){

    return new Promise((res) => {
        setTimeout(() => {
            res();
        }, time);
    })

}


export function $prettify(str){
    let new_str = str || ''

    new_str = new_str.replace(/_/g,' ').trim()
    if(new_str){
        new_str = (new_str[0].toUpperCase())+new_str.slice(1)
    }
    
    return new_str
}

export function padZero(num,zeroes=1){
    return (('0'.repeat(zeroes))+num).slice(-1-zeroes)
}