import { Button, CircularProgress } from '@material-ui/core'
import { ChatBubbleOutline, Edit } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import MoreIcon from '@material-ui/icons/MoreHoriz'
import qs from 'querystring'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { getUser } from '../../app/auth/authSlice'
import {
    fetchMessages, fetchSchema, fetchSymptoms,
    quoteStatus, selectFeatures, selectMessages, selectServices,
    selectServiceSchema
} from '../../app/user/userSlice'
import SimpleMenu from '../../components/common/SimpleMenu'
import StatusText from '../../components/special/StatusText'
import { $prettify } from '../../utils'
import { getFeatureIcon, getLatestReceivedMessage } from '../../utils/helpers'
import { getConditionIcon } from '../dashboard/FeatureEditor'
import ServiceAdder from './ServiceAdder'
import ServiceDetail from './ServiceDetail'

export default function Services(props){
    const features = useSelector(selectFeatures)
    const serviceRequests = useSelector(selectServices)
    const messages = useSelector(selectMessages)

    let flatFeautres = [...features]
    features.forEach(rf => {
        flatFeautres = [...flatFeautres,...rf.children]
    });
    const getFeature = (s) => {
        const feat = s.feature
        return feat
    }

    const isMobile = window.outerWidth < 420
    const user = useSelector(getUser)
    const services = serviceRequests.filter(s => !!getFeature(s)) 
    const dispatch = useDispatch()
    const [edited,setEdited] = useState(false)
    const [loading,setLoading] = useState(1)

    const serviceSchema = useSelector(selectServiceSchema)
    const statusChoices = (serviceSchema && serviceSchema.status.choices) || []

    const location = useLocation()
    const history = useHistory()

    const query = qs.parse(location.search.replace('?',''))

    const new_feature_id = query.add_new
    const service = edited && typeof(edited)=='number' && services.find(s=>s.id == edited)

    useEffect(()=>{
        setLoading(1)

        Promise.all([
            ...(!messages.length?[
                dispatch(fetchMessages()),
            ]:[]),
            dispatch(fetchSymptoms()),
            dispatch(fetchSchema({resource:'service'}))
        ]).then(res => {

        }).catch(err => {

        }).finally(()=>{
            setLoading(0)
        })

     
    },[])

    useEffect(()=>{
        const query = qs.parse(location.search.replace('?',''))

        if(services.length){

            const id = query.id
            const qid = query.quote
            if(id){

                const sr = services.find(s => s.id == id )
                if(sr){
                    const acc = sr
                    setEdited(acc)
                }
                // const stage = query.stage
                // if(stage){
                //     setStage(stage)
                // }
            }
            else if(qid){
                const flatQuotes = []
                for(let s of services){
                    const bois = s.quotes.map(q=>({...q,service:s}))
                    flatQuotes.push(...bois)
                }
                const quote = flatQuotes.find(fq=>fq.id == qid)
                if(quote){
                    setEdited({...quote.service,open_quote:Number(qid)})
                }
            }
    
        }

    },[serviceRequests,location])

    useEffect(()=>{
        const ed = edited
        const new_edited = ed && serviceRequests.find(s => s.id == ed.id)
        if(new_edited){
            setEdited(new_edited)
        }
    },[serviceRequests])
    
    const isEdited = edited && typeof(edited) == 'object'

    function handleClose(){

        if(new_feature_id){
            history.push('/dashboard')
        }else{
            history.replace(location.pathname)
        }
        setEdited(false)

    }

    const [stage,setStage] = useState('')
    
    // if(edited && edited != true){
    //     const service = services.find(s=>s.id==edited)
    //     if(service){
    //         const feat = service.feature
    //         featureName = feat && (feat.name)
    //     }
    // }
   
    return <div className='mb-3'>
        <div className=' bg-primary '>
            <div className='container d-flex justify-content-between 
                
            '>
                <div className='py-4 px-0 pr-sm-4 pl-1 text-left '>
                    <h2 className='text-white font-weight-bold mb-2'>
                        Let us take care of your <br/> spring cleaning
                    </h2>
                    <Button color='secondary' className='text-primary bg-white text-left'
                        variant='contained' onClick={()=>setEdited(true)}

                    >
                        Request spring cleaning
                    </Button>
                </div>
                <div>
                    <img src='/images/dashboard/cleaning.svg' 
                    height='140px' style={{transform:"translateY(30%)"}}
                    />
                </div>
            </div>
        </div>
        <div className='container mt-4 pl-1  pr-1  text-left d-flex flex-column'>
            <p className='text-secondary font-weight-500
             border-bottom text-left p-2'>
                My services
            </p>
            <div className='d-flex py-3 px-1  cursor-pointer
             text-primary font-weight-500 '
                onClick={()=>setEdited(true)}
            >
                <AddIcon color='primary' className='mr-1 ' />
                Request new service
            </div>

            {(loading == 1)?
                <CircularProgress color='primary' className='mx-auto my-5' />
            :(services.length?
                services.slice()
                .map(s => {
                    const unread_message = getLatestReceivedMessage(s,messages,user.id)
                    let q_updated_at = s.q_updated_at
                    if(unread_message?.message){
                        q_updated_at = q_updated_at > unread_message.created_at ? q_updated_at : unread_message.created_at
                    } 
                    return {
                        ...s,
                        unread_message,
                        q_updated_at
                    }
                })
                .sort((a,b)=>a.q_updated_at>b.q_updated_at?-1:0)
                .map(sr=>{
                    const feat = getFeature(sr)
                    // const status = statusChoices.find(s=>s.value==sr.status)
                    const accepted_quote = sr.accepted_quote && sr.quotes.find(q=>q.id==sr.accepted_quote) 
                    const given_quotes = sr.quotes.filter(q=>q.status==2)
                    const canceled = sr.status == -1
                    const unread_message = sr.unread_message
                    const {message,...sender} = unread_message ? unread_message : {}
                    const has_accepted_a_quote = (accepted_quote && accepted_quote.status != -1)
                    const is_completed = has_accepted_a_quote && accepted_quote.status >= 100
                    const is_paid = has_accepted_a_quote && accepted_quote.status == 101


                    // const latest_sent = getLatestSentMessage(sr,messages,user)
                    // const my_latest_message = getMyLatestServiceMessage(sr,messages,user.id)
                    const no_need_of_count = canceled || has_accepted_a_quote

                    let quote_count_display = (!no_need_of_count && given_quotes.length) ?
                    <p className=' m-0 text-primary '>
                        <span className='text-primary p-1  px-2  rounded-8 text-medium font-weight-500'
                            style={{backgroundColor:'var(--light)'}}
                        >
                            {given_quotes.length}
                        </span>
                    </p>
                    :
                    null
                    
                    let options = ['cancel_request']
                    // remove cancel_request if work is already in_progress
                    if(accepted_quote && accepted_quote.status >= quoteStatus.dispatched){
                        options = ['view_details']
                    }
                    // if(editable)
                    {
                        if(!accepted_quote){
                            options.unshift('edit_request')
                        }
                        if(given_quotes.length){
                            options.unshift('view_quotes')
                        }else{
                            if(!accepted_quote || accepted_quote.status == -1){
                                options.unshift('get_more_quotes')
                            }
                        }

                    }
                    if(accepted_quote && accepted_quote.status < 9 && accepted_quote.status >= 0){
                        options.unshift('reschedule')
                    }
                    
                    if(canceled || is_completed){
                        options = ['view_details']
                    }
                    if(is_completed && !is_paid){
                        options.unshift('make_payment')
                    }

                    if(message){
                        options.unshift('respond_to_contractor')
                    }


                    function handleContextMenu(e){
                        if(e == 'edit_request'){
                            setEdited(sr.id)
                        }
                        else if(e == 'view_details'){
                            setEdited(sr)
                        }
                        else if(e == 'reschedule'){
                            setEdited({...sr,reschedule:true})
                        }
                        else if(e == 'make_payment'){
                            setEdited({...sr,make_payment:true})
                        }
                        else if(e == 'respond_to_contractor'){
                            history.push(`/messages?to=${sender.id}&service=${sr.id}`)
                        }
                        else if(e == 'cancel_request'){
                            setEdited({...sr,cancel_this:true})
                        }
                        else if(e == 'view_quotes'){
                            setEdited(sr)
                        }
                        else if(e == 'get_more_quotes'){
                            setEdited({...sr,popup:'get_more'})
                        }
                    }
                    const topOption = options[0]

                    return <div className='d-flex2 py-3 pl-1  pr-1  align-items-center
                    border-top cursor-pointer service-tile d-flex '
                        key={sr.id}                    
                    >
                        <div className='d-flex overflow-hidden align-items-center flex-grow-1 
                        '
                            onClick={()=>{setEdited(sr);console.log(sr.id)}}
                        >
                            
                            <img src={feat?getConditionIcon(feat):'/images/features/condition/done.svg'} style={{height:'24px'}} />
                            <img src={getFeatureIcon(feat)} 
                                    style={{width:'36px'}} className='mx-2' />

                            <div className='d-flex flex-column overflow-hidden flex-sm-row flex-grow-1 align-items-sm-center '>
                                <div className='d-flex overflow-hidden align-items-center'>
                                    <p className='font-weight-500 mr-2 text-truncate '>
                                        {feat.name}
                                    </p>
                                </div>

                                <StatusText service={sr}  className='text-medium ml-sm-2'
                                    // latest_message={message}
                                    // latest_message={message || (latest_sent && latest_sent.message)}
                                    fontWeight='400'
                                />

                                {message &&
                                <p className='text-secondary mx-sm-auto text-medium d-flex align-items-center'
                                    onClick={()=>handleContextMenu('respond_to_contractor')}
                                >
                                    <ChatBubbleOutline color='primary' className=' mr-1 text-small' fontSize='small' />
                                    {message}
                                </p>
                                }

                            </div>
                     
                        </div>
                        
                        {/* <div className='d-flex flex-column align-items-start mx-sm-4 mx-2'>
                            <StatusText service={sr} />
                        </div> */}



                        <div className='d-flex justify-content-end align-items-center ml-auto
                            flex-column flex-sm-row
                        '>
                            {quote_count_display}

                            <div className='d-flex'>

                                {
                                    <button className='text-primary font-weight-500 
                                    text-medium bg-white hover-scale d-none d-sm-inline-block'
                                        onClick={e=>{e.stopPropagation();handleContextMenu(topOption,sr)}}
                                    >
                                        {['edit_request'].includes(topOption)?
                                            <Edit className='mr-1' fontSize='small' />
                                        :null}

                                        {$prettify(topOption)}
                                    </button>
                                }

                                <SimpleMenu
                                    options={options}
                                    className=' bg-white ml-1 '
                                    onChange={e=>handleContextMenu(e,sr)}
                                >
                                    <MoreIcon color={'primary'}/>
                                    
                                </SimpleMenu>
                                

                            </div>
                           
                        </div>
                    </div>

                }
                )

                :
                <p className='text-medium my-5 text-center text-muted'>
                    You do not have any service requests yet.
                </p>
            )
            }
        </div>

        {isEdited && 
            <ServiceDetail
                open={!!edited || !!new_feature_id}
                onClose={handleClose}

                source={edited} 
                feature={getFeature(edited)} 
                stage={stage} setStage={setStage}

            />
        }
        {!isEdited && (!!edited || !!new_feature_id) && 
            <ServiceAdder
                onClose={handleClose}

                feature_id={new_feature_id} 
                service_id={edited===true?null:edited} 
            />

        }
    </div>

}