import { Button, Checkbox, CircularProgress, FormControlLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { Close, Search } from '@material-ui/icons'
import Plus from '@material-ui/icons/Add'
import ArrowBack from '@material-ui/icons/ArrowBack'
import EmailIcon from '@material-ui/icons/EmailOutlined'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import PhoneIcon from '@material-ui/icons/PhoneOutlined'
import Axios from 'axios'
import { Form, Formik, useField } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { fetchMessages, fetchResource, selectClients, selectMessages, setClients } from '../../../app/user/userSlice'
import { DocumentViewer } from '../../../components/common/DocumentViewer'
import MDialog from '../../../components/common/MDialog'
import SimpleMenu from '../../../components/common/SimpleMenu'
import AddressField from '../../../components/input/AddressField'
import { NameBadge } from '../../../components/special/HomeDialog'
import { $prettify } from '../../../utils'
// import SendIcon from '@material-ui/icons/SendRounded'
import {
    $baseUrl,
    $confirm, $event, $http,
    $phoneValidator, $required,
    $validEmail
} from '../../../utils/http'
import { MessageBubble } from '../../messages/Messages'
import { ActionButtons } from '../FeatureEditor'
import { VFormField } from '../InitialSetup'

export async function referClient(data){
    try {
        if(data.user_type === 4){
            if(data.name){
                data.first_name = data.name.split(' ')[0]
                data.last_name = data.name.split(' ')[1]
            }
        }
        const res = await Axios.post($baseUrl+'/referral_mail',data)
        $event('referral',{email:data.email})

        $http.$success(res)
    } catch (err) {
        $http.$error(err)
    }
}

export function ClientContractorField({className,newClassName,type,label,...props}){
    const cprops = {...props}

    const [field,meta] = useField(props)
    const errorText = meta.error && meta.touched  ? meta.error : ''
    return <TextField
        inputProps={{style:{padding:'8px 12px',color:'var(--black3)'}}}

        {...field}
        error={!!errorText}
        helperText={errorText}
        size='small'
        variant='outlined'
        label=''
        className={newClassName||('mb-3 '+className)}
        {...props}
        placeholder={props.placeholder||$prettify(props.name)}
    />
}

export function ItemBar({itemName,addNew,onChange,value}){
    const handleSearch = onChange || (()=>{})
    const [showSearch,setShowSearch] = useState(false)
    return <div className=' my-2 py-2 border border-left-0 border-right-0 sticky-top bg-white'
        style={{overflowAnchor:'none'}}
    >
            <div className='container'>
                <div className='row no-gutters'>
                    <div className='col-8 col-md-3 d-flex align-items-center'>
                        <Link to='/dashboard' >
                            <ArrowBack />
                        </Link>

                        <h3 className='mx-sm-3 ml-2 my-0 font-weight-bold'>{itemName}s</h3>
                    </div>

                    <div className={`col-12 col-md-6 order-4 d-sm-flex order-md-2 mt-3 mt-md-0 
                        ${showSearch?' d-flex  ':'d-none'}
                    `}
                       
                    >
                        <TextField 
                            variant='outlined' 
                            size='small'
                            className='flex-grow-1'
                            placeholder='search by name, profile or email'
                            style={{width:'24em',}}
                            value={value}
                            onChange={onChange}
                            InputProps={{
                                style:{paddingRight:'8px'},
                                endAdornment: (
                                        value &&
                                      <Close className='cursor-pointer text-muted'
                                        onClick={()=>onChange({target:{value:''}})}
                                      />
                             
                                ),
                              }}
                        />

                        <Button variant='contained' className='mx-2' disableElevation
                        >
                            Search
                        </Button>
                    </div>

                    <div  className='d-flex col-4 col-md-3 order-3 justify-content-end'>
                        <button 
                            onClick={()=>setShowSearch(!showSearch)}
                            className='d-sm-none mr-1 '
                            style={{filter:showSearch?'brightness(0.8)':''}}
                        >
                            <Search className='mx-sm-1 mx-0'/>
                        </button>
                        <Button 
                            variant='contained' color='primary' 
                            onClick={addNew}
                            disableElevation
                            style={{ minWidth:'32px'}}
                        >
                            <Plus className='mx-sm-1 mx-0'/>
                            <span className='d-none d-md-inline'>
                            Add new {itemName.toLowerCase()}
                            </span>
                        </Button>
                    </div>
                </div>
            
             </div>
        </div>
}

export const ClientEditor = ({item,onTabChange,actionButtons,...props}) => {
    const [tab,setTab] = useState(0)
    const tabs = props.tabs || []
    const name = item.name || item.full_name || 'Edit'
    const activeTab = tabs[tab] || {content:'Haha this is cool'}
    const changeTab = (t,i)=>{
        setTab(i)
        onTabChange(t)
    }
    const eTabList = <React.Fragment>
        {tabs.map((t,i) => 
            <div key={i} className={` px-4 py-3  cursor-pointer font-weight-500 text-medium
            ${tab==i?'bg-primary text-white':'text-primary bg-white'} 
            ${t.disabled?'opacity-50 ':''} 
            `}
            onClick={()=>{if(!t.disabled)changeTab(t,i)}}
        >
                {t.name}
        </div>    
        )}
    </React.Fragment>

    return <div className='flex-grow-1 d-flex flex-column flex-sm-row
        container my-dialog-xl px-1 px-sm-0 overflow-hidden pr-sm-2
    ' >

            <div className='d-none d-sm-flex flex-column border-right' style={{width:'10em'}}
                
            >
                {eTabList}
            </div>

            <div className='d-flex flex-column overflow-hidden flex-grow-1 '
                style={{height:'500px'}}
            >
                <div className='d-flex flex-column flex-grow-1 overflow-auto'>
                    {/* <MExpand summary={<p className='text-primary'>
                        {(activeTab && activeTab.name)||'----'}
                    </p>}
                        className='d-sm-none '   
                    >
                        <div className='d-flex flex-column border rounded border-primary' 
                            
                        >
                            {eTabList}
                        </div>
                    </MExpand> */}
                    <Select
                        value={tab}
                        onChange={({target:{value}})=>changeTab(tabs[value],value)}
                        variant='outlined'
                        color='secondary'
                        className='m-2 d-sm-none font-weight-bold text-primary'
                    >   
                        {tabs.map((t,i)=>
                            <MenuItem value={i} key={i} className='font-weight-500 '>
                                {t.name}
                            </MenuItem>
                        )}
                    </Select>

                    <div className='flex-grow-1 d-flex flex-column p-0 pl-1 py-2  pb-3 overflow-auto'>
                            {activeTab.content}
                    </div>
                </div>

                {activeTab.noActions?null:actionButtons}
    
            </div>
            

    </div>

}  

export const ClientListClass = 'row row-cols-sm-2 row-cols-1'

export function ClientCard({isContractor,...source}){
    const company = source.company_name
    let name =  source.full_name || source.name || company 
    const phone = source.phone || source.phone_number_primary || source.phone_number
    const c_type = (source.provider_type == null ? '' : source.type_string)
    let address = (source.address || '').trim() 
    address = (address && address != ',') && address
    const dispatch = useDispatch()
    const showInvite = false
    if(address == null){
        address = (source.address_1||'') +''+ (source.address_2||'')
    }
    if(company){
        if(name != company){
            address = company
        }
    }
    console.log(address)

    const client = source
    const [sending,setSending] = useState(false)

    async function sendMail(user_type){
        try {
            setSending(true)
            await referClient({...client,user_type})
            dispatch(fetchResource({resource:'clients'}))
        } catch (err) {
            
        }
        finally{
            setSending(false)
        }
    }
    function referClientContractor(client){
        let user_type = 3
        if(client.is_home_owner){user_type = 0}

        sendMail(client.user_type || user_type)
    }

    return <div className='rounded-extra border-thick p-3 d-flex' style={{cursor:'pointer',borderColor:'whitesmoke'}}
        onClick={()=>source.onClick(source)}
    >
        <div className='d-flex flex-column'>

            {isContractor && 
                <NameBadge size={50} source={{full_name: name}} />
            }
        </div>
        <div className='flex-grow-1 overflow-hidden'>
            <div className='text-left mb-0 d-flex overflow-hidden align-items-center'>
                <div className='d-flex flex-column flex-sm-row  overflow-hidden align-items-sm-center
                    flex-grow-1'
                >
                    <div className='flex-grow-1 overflow-hidden d-flex flex-column '
                    style={{minWidth:'120px'}}
                    >
                        <p  className='d-block text-truncate mr-2 font-weight-500 ' 
                        style={{
                            color:'var(--black3)',
                            
                            // whiteSpace: 'nowrap',
                            // overflow: 'hidden',
                            // textOverflow: 'ellipsis',
                        }}
                        >
                            {name}
                        </p>
                    </div>
                    
                    <p className='text-muted d-none d-md-block text-truncate text-medium mx-sm-2 '
                    style={{minWidth:'0px'}}
                    >
                        {address}
                    </p>
                    

                </div>
                {c_type?
                    <p className=' text-success pl-1 text-right text-wrap text-medium'>
                        {c_type}
                    </p>
                :null}
            </div>
            { 
                 <p className='text-muted d-block d-md-none text-truncate text-medium mx-sm-2 '
                 style={{minWidth:'0px'}}
                 >
                     {address}
                 </p>
            }
   
            <div className='d-flex text-primary align-items-center'>
                {!!source.email &&
                    <p className='my-auto mr-auto text-small'>
                            <EmailIcon  className='mr-1' fontSize='small'/>
                            {source.email}
                    </p>
                }
                {!!phone && 
                <p className=' my-auto text-small d-none d-md-block'>
                    <PhoneIcon className='mr-1 ' fontSize='small' />
                    {phone}
                </p>
                }
                {(source.user||!showInvite)?null:
                    
                        <Button onClick={(e)=>{e.stopPropagation();referClientContractor(source)}} 
                        variant='outlined'
                        size='small'
                        className='mr-0 p-1 ml-auto position-relative'>
                            
                            <span className={sending?'invisible':''}>
                                Invite
                            </span>

                            {sending &&
                                <div className='position-absolute d-flex align-items-center 
                                    justify-content-center' 
                                >
                                    <CircularProgress  size={16} 
                                        
                                        style={{color:'gray',left:'50%',top:'50%',}} 
                                    />
                                </div>
                            }

                            {/* <SendIcon className='ml-2' style={{transform:"rotate(-30deg) translateY(-4px)"}} /> */}
                        </Button>
                    
                }
            
            </div>
        </div>

    </div>
}

export function ClientMessageList({client,isContractor,loading}){
    const messages = useSelector(selectMessages)
    const history = useHistory()

    const mclass = `text-muted text-medium my-auto mx-auto`
    const aId = client && client.user 

    function openMessages(){
        history.push(`/messages?to=${aId}`)
    }

    if(!aId){
        return <p className={mclass}>
            The {isContractor?'contractor':'client'} hasn't signed up yet.
        </p>
    }
    
    const imessages = !client? []: messages.filter(m => m.sender == aId || m.user == aId )
    
    if(loading){
        return <CircularProgress
            color='primary'
            className='m-auto'
        />
    }

    return <div className='d-flex flex-column flex-grow-1 overflow-hidden'>

        <div className='d-flex flex-column flex-grow-1 overflow-auto '>
            { imessages.length ? 
                imessages.map(m => 
                    <div className='rounded-8 '
                        key={m.id}
                    >
                        <MessageBubble
                            message={m}
                            isMobile={false}
                            thread={{contact:{name:client.full_name}}}
                        />
                    </div>
                )
                :
                <p className={mclass}>
                    No messages to show.
                </p>
            }
        </div>

        <div className='rounded-8 border-thick p-1 mt-auto 
        d-flex justify-content-center align-items-center cursor-pointer'
            onClick={openMessages}
        >
            <img src='/images/dashboard/send-small.svg' height='24px' />
            <p className='ml-2 text-medium text-primary font-weight-500'>
                Send new message
            </p>
        </div>


    </div>
} 

export function LoadingCard(props){
    return  <div className='p-5 d-flex flex-column align-items-center my-auto'>
        <CircularProgress
            size={64}
            className='mx-4 mb-3'
        />
        <p>Loading...</p>
    </div>
}

export function NothingFound({text,name='client'}){

    return <div className='my-auto mx-auto'>
        <img src={`/images/dashboard/${name}s.svg`}
            style={{filter:'grayscale(0.7)'}}
            height='86px'
            />
        
        <p className='lead mb-2'>
            {text || `No ${name}s yet`}
        </p>
        <p className='text-muted small'>
            Try adding a {name} using the button in the top right
        </p>
    </div>

}

export default function Clients(props){
    let [interests,setinterests] = useState({
        is_corporate_housing_agent:false,
        is_home_owner:false,
        is_potential_buyer:false,
        is_investor:false,
    })
    const clientList = useSelector(selectClients)
    const clients = clientList.map(c => ({...c,type:'client'}))

    let [showAdder,setShowAdder] = useState(false)
    let [loading,setLoading] = useState(true)
    let [saving,setSaving] = useState(false)
    let [query,setQuery] = useState('') 

    const [edited,setEdited] = useState(null)

    const formRef = useRef()

    function hideModal(){
        setEdited(null)
        setShowAdder(false)
    }
    const dispatch = useDispatch()
    const history = useHistory()

    async function fetchClients(){
        // dispatch(fetchResource({resource:'clients'}))
        try {
            setLoading(true)
            await dispatch(fetchResource({resource:'clients'}))

        } catch (err) {
            console.log(err)
        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        fetchClients();

    },[])

    const interestsOptions = Object.keys(interests)
    const onBoarded = !!edited?.user && edited.signed_up

    function ClientField(props){
        return <ClientContractorField disabled={onBoarded} {...props}/>
    }
 
    const clientFields = [
        {
            name:'Name',
            content: <div className='d-flex flex-column mb-2'>
                <div className='d-flex'>
                    <ClientField className='flex-grow-1' name='first_name' />
                    <ClientField className='flex-grow-1 ml-2' name='last_name'/>    
                </div>
            </div>
        },
        {
            name:'Address',
            content: <div className='d-flex flex-column mb-2'>
                <AddressField 
                    variant="outlined"
                    size="small"
                    name="line1"
                    formikRef={formRef}
                    placeholder="Enter the first line of your address"   
                    disabled={onBoarded}
                    inputProps={{style:{padding:'8px 12px',color:'var(--black3)'}}}
                />
                <ClientField placeholder='Address line 2' name='line2' className='mt-2'/>

                <div className='d-flex'>
                    <ClientField className='flex-grow-1' name='city' />
                    <ClientField className='flex-grow-1 mx-2'  name='state'/>
                    <ClientField className='flex-grow-1 ml-211' name='zip_code' />    
                </div>
            </div>
        },
        {
            name:'Phone number',
            content: <VFormField isphone placeholder='Enter phone number' name='phone'
             className='mb-3' disabled={onBoarded} inputProps={{style:{padding:'8px 12px',color:'var(--black3)'}}}

            />
        },
        {
            name:'Email address',
            content: <ClientField placeholder='Enter email address' name='email'/>
        },
        {
            name:'Interests',
            contentDynamic:(values,setFeildValue) => <div>
      
                   {interestsOptions.map((io,i) =>
                    <FormControlLabel
                        key={io}
                        disabled={onBoarded}
                        control={
                        <Checkbox name={io} color="primary"
                            checked={values[io]}
                            onChange={()=>setFeildValue(io,!values[io])}
                        />
                    }
                        label={$prettify(io.replace('is_',''))}

                        style={{color:'var(--black3)',fontSize:'0.4em'}}
                        className='my-0 font-weight-500 '
                    />
                   )}
            </div>
        },
    ]

    const validationSchema = yup.object({
        first_name: yup.string().required($required('First name')),
        last_name: yup.string().required($required('Last name')),
        email: $validEmail("Please provide a valid email"),
        phone: yup.string().test('len', 'Mobile number must have 10 digits', 
        $phoneValidator
        )


    })
    function handleNext(){
        const form = formRef.current
        if(form){
            form.submitForm()
        }
    }
    async function handleSubmit(data){
        const method = edited?'patch':'post'
        const end = edited?'/'+(edited.id):''
        try {
            setSaving(true)

            data['mobile_number'] = data['phone']
            data['address_1'] = data['line1']
            data['address_2'] = data['line2']

            const res = await Axios[method]($http.$baseUrl+'/clients'+end,data)
            $http.$success(res)
            setEdited(null)
            setShowAdder(false)
            fetchClients()
        } catch (err) {

            const res = err.response
            if(res && res.status == 400){
                const errs = res.data
                const form = formRef.current
                if(form){
                    form.setErrors({...form.errors,...errs})
                }
            }else{
                $http.$error(err)
            }
  
        }finally{
            setSaving(false)
        }
    }

    
    const query_text = query.toLowerCase()
    const filteredClients =  clients.map(q => ({...q,address:((q.address_1||'')+' '+(q.address_2||''))}))
    .filter(clientFilterer(query_text))

    const sections = [
        {
            name:'Recent',
            list: clients.sort((a,b)=>a.created_at>b.created_at?-1:1).slice(0,6),   
        },
        {
            name:'Looking for homes',
            list: clients.filter(c => c.is_potential_buyer),   
        },
        {
            name:'Looking for investments',
            list: clients.filter(c => c.is_investor),   
        },
        {
            name:'Home owners',
            list: clients.filter(c => c.is_home_owner),   
        },
        {
            name:'Corporate housing agents',
            list: clients.filter(c => c.is_corporate_housing_agent),   
        },
        {
            name:'Other',
            list: clients.filter(c => !(c.is_home_owner+c.is_investor+c.is_potential_buyer+c.is_corporate_housing_agent))
        }
    ]

    let lists

    if(query_text){
        lists = <React.Fragment>
            {!!filteredClients.length && 
                <p className=' font-weight-500 my-4'>Top matches</p>
            }

            {filteredClients.length>0?

            <div className={ClientListClass}>

                {filteredClients.map((c,i)=>

                    <div className='col px-2 py-2' key={i}>
                        <ClientCard {...c} onClick={()=>setEdited(c)}/>
                    </div>
                                        
                )}

            </div>
            :
            <div className='text-center my-2 py-3 my-sm-5 p-sm-5 col-12 text-secondary font-weight-bold'>   
                
                <NothingFound text="No matches found" />

            </div>
            }

        </React.Fragment>

    }else{
        lists =  <React.Fragment>

            {sections.map(s=>
                s.list && !!s.list.length && 
                    <React.Fragment key={s.name}>    
                        <p className=' font-weight-500 mt-4 mb-2'>{s.name}</p>

                        <div className={ClientListClass}>

                            {s.list.map((c,i)=>

                                <div className='col px-2 py-2' key={i}>
                                    <ClientCard {...c} onClick={()=>setEdited(c)}/>
                                </div>
                                                    
                            )}

                        </div>
                    </React.Fragment>
                
            )}


        </React.Fragment>

    }

    const clientLists = <div className='container text-left'>
        
        {lists}
        
    </div>

    let activeSlide = loading? <LoadingCard /> : (clients.length? clientLists: <NothingFound/>)

    let initialValues = {
        line1:'',
        line2:'',
        city:'',
        state:'',
        zip_code:'',
        
        email:'',
        phone:'',

        first_name:'',
        last_name:'',

        is_corporate_housing_agent:false,
        is_home_owner:false,
        is_potential_buyer:true,
        is_investor:false,
    }

    if(edited){
        for(let key in initialValues){
            initialValues[key] = (edited[key] != null) ? edited[key] :  ''

            
        }
        initialValues['line1'] = edited['address_1']
        initialValues['line2'] = edited['address_2']
        initialValues['phone'] = edited['mobile_number']
    }



    const ClientInfo = <div className='container-fluid '>
        <ReferralMessage source={edited}/>

        <Formik
            innerRef={formRef}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
        >
            { ({errors,touched,values,setFieldValue}) => {
             
                    return <Form  className='row no-gutters'>
                    {
                        clientFields.map(cf => 
                        <React.Fragment key={cf.name}>
                            <div className='col-3 small pl-0 ' 
                                style={{fontWeight:'800',color:'var(--black6)'}}
                            >
                                {cf.name}
                            </div>
                            <div className={`col-9 d-flex flex-column pr-0 ${onBoarded?'opacity-70':''}  `}>
                                {cf.content||(cf.contentDynamic&&cf.contentDynamic(values,setFieldValue))}
                            </div>
                        </React.Fragment>
                        )

                    }
                    </Form>
                }

            }
        </Formik>

        {/* <ActionButtons 
            backText='Discard'
            onBack={hideModal}
            nextText='Save'
            onNext={handleNext}

            loading={saving}
        /> */}
    </div>

    const ClientAdder = <div className='p-4'>
        
        {ClientInfo}

    </div>

    const documentViewer = <div className='d-flex flex-column '>

        <DocumentViewer
            source={{...edited}} 
            // house={{id:source.house}}
            // onAddNew={(ndoc)=>{
            //     const newBoi = {...edited,documents:[...edited.documents,ndoc]}
            //     const new_list = [...clientList.filter(c=>c.id!=edited.id),newBoi]
            //     dispatch(setClients(new_list))
            //     setEdited(newBoi)
            // }}
            onDocChange={(ndoc,deleted)=>{
                const ndocs = edited.documents.filter(d=>d.id!=ndoc.id)
                if(!deleted){
                    ndocs.push(ndoc)
                }
                const newBoi = {...edited,documents:ndocs}
                const new_list = [...clientList.filter(c=>c.id!=edited.id),newBoi]
                dispatch(setClients(new_list))
                setEdited(newBoi)
            }}
            hideBack
        />
    </div>

    const editorTabs = [
        {
            name:'Information',
            content:ClientInfo,
            noActions:onBoarded
        },
        {
            name:'Documents',
            content: documentViewer
        },
        {
            name:'Messages',
            content:ClientMessageList({client:edited}),
            disabled: !(edited && edited.signed_up),
            noActions:true
        },

    ]
    async function fetchTheMessages(){
        try{
            setLoading(1)
            await dispatch(fetchMessages())
        }finally{
            setLoading(0)
        }
    }

    const handleTabChange = (t) => {
        if(t.name == 'Messages'){
            fetchTheMessages()
        }
        // if(edited && t.name == 'Information'){
        //     handleBack()
        // }
    }
    const options = ['contact']
    if(edited && !edited.signed_up){
        options[0] = '_'+options[0]
        options.push('delete')
    }
    const handleContextMenu = (e) => {
        if(e == 'delete'){
            deleteClient()
        }else if(e == 'contact'){
            history.push(`/messages?to=${edited.user}`)
        }
    }
    async function deleteClient(){
        try {
            const okay = await $confirm({question:'Are you sure you want to delete this client?'})
            const res = await Axios.delete($http.$baseUrl+'/clients/'+edited.id)
            $http.$success(res)
            fetchClients()
            setEdited(null)
        } catch (err) {
            if(err){
                $http.$error(err)
            }
        }
    }
    const titleBar = <div className='d-flex align-items-center p-2'>

            <p className=' ml-2 font-weight-500 lead'
                style={{color:'var(--black3)'}}
            >
                    {edited?edited.full_name:'New client'}

            </p>

            <SimpleMenu
                onChange={handleContextMenu}
                options={options}
                className='px-2 bg-white mx-1'
            >
                <MoreHoriz style={{fontSize:"1.5em"}} 
                    className='mx-3 text-primary'
                />
            </SimpleMenu>
    </div>

    const actionButtons = <ActionButtons 
        backText='Discard'
        onBack={hideModal}
        nextText='Save'
        onNext={handleNext}
        className='mb-2 mt-1 mx-2'
        loading={saving}
    />
    
    return <div className='d-flex flex-column flex-grow-1'>
        
        <ItemBar itemName='Client' addNew={()=>setShowAdder(true)}
            value={query}
            onChange={e=>setQuery(e.target.value)}
        />

        <div className='d-flex flex-column flex-grow-1 container'>

            {activeSlide}

        </div>
        
        
        <MDialog
            open={showAdder||!!edited}
            onClose={hideModal}
            titleBar={titleBar}
            paperPadding=' pt-1'
            paperStyle={{maxWidth:'900px'}}
        >
            {
            showAdder?
            <React.Fragment>
                <div className=' my-dialog-xl d-flex flex-column overflow-hidden flex-grow-1'>
                    <div className='d-flex flex-grow-1 overflow-auto '>
                        {ClientAdder}
                    </div>
                    <div className=' '>
                        {actionButtons}
                    </div>
                </div>
            </React.Fragment>
            :
            (!!edited?
            <ClientEditor item={edited} 
                tabs={editorTabs} 
                className='flex-grow-1'
                onTabChange={handleTabChange}
                actionButtons={actionButtons}
            >
            </ClientEditor>
            :null)
            }
            
        </MDialog>


    </div>
} 


export function ReferralMessage({source:client,type='client'}){
    const [sending,setSending] = useState(false)

    async function sendMail(user_type){
        try {
            setSending(true)
            await referClient({...client,user_type})
        } catch (err) {
            
        }
        finally{
            setSending(false)
        }
    }
    function referClientContractor(client){
        let user_type = 3
        if(client.is_home_owner){user_type = 0}

        sendMail(client.user_type || user_type)
    }

    let message = null 

    if(client && !client.signed_up){
        message = <React.Fragment>
            A referral mail has been sent to the {type}, you can re-send the email by clicking 
            <span
                onClick={e=>{e.stopPropagation();referClientContractor(client)}}
                className='text-primary bg-transparent px-1 hover-link'
            >
                {sending? <CircularProgress size='12px' />:'here'}
            </span>
        </React.Fragment>
    }else if(client && client.user && client.signed_up){
        message = `The ${type} is signed up.`
    }
  
    return message && <div className='text-small text-muted p-2 bg-light rounded-8 mb-2'>
        {message}
    </div>
}



export function  clientFilterer(query_text) {
    
    return q => (
               q.email.toLowerCase().includes(query_text)
            || q.full_name?.toLowerCase().includes(query_text)
            || q.name?.toLowerCase().includes(query_text)
            || q.address?.toLowerCase().includes(query_text) 

         )
}