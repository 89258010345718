import { Button, Link, CircularProgress, MenuItem, Select, TextField } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Circle from '@material-ui/icons/RadioButtonUncheckedOutlined';
import Refresh from '@material-ui/icons/Refresh';
import Axios from 'axios';
import { Form, Formik, useField } from 'formik';
import qs from 'querystring';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { fetchUser, getUser, logout, patchUser, setUser } from '../../app/auth/authSlice';
import { fetchFeatureSchema, fetchPlans, getFRE, getPricing, selectFeatureSchema, setFRE, userTypes } from '../../app/user/userSlice';
import MDialog from '../../components/common/MDialog';
import PhoneInput from '../../components/input/PhoneInput';
import { $prettify } from '../../utils';
import { $baseUrl, $error, $event, $required, $success } from '../../utils/http';
import EmailVerifier from '../auth/EmailVerifier';
import AddressField from '../../components/input/AddressField';
import ChoiceCard from '../../components/input/ChoiceCard';
import { setDialog } from '../../app/ui/uiSlice';
import { allowed_icons } from '../../utils/constants';
import PlanCard from '../../components/special/PlanCard';
import { getFeatureIcon } from '../../utils/helpers';
import { getTrialPeriod } from '../HomeOwnerLanding';


export const VFormField = ({className,type,whiteError,ignoreError,label,...props}) => {
    const cprops = {...props}
    const setter = cprops.setter
    delete cprops.setter

    const [field,meta] = useField(props)
    const errorText = meta.error && meta.touched && !ignoreError ? meta.error : ''
    const isPhone = !!props.isphone

    const FieldComponent = isPhone ? PhoneInput : TextField
    return <FieldComponent
        {...field}
        error={!!errorText && !whiteError}
        helperText={errorText}
        size='small'
        variant='outlined'
        // style={{marginTop:'8px'}}
        className={className||' flex-shrink-0'}
        label={''}
        type={type||'text'}
        {...props}
        // inputProps={{autoComplete:"new-password"}}
    />
}

export default function InitialSetup(props){
    const [LOADING,VERIFYING,PATCHING,SETUP_DONE] = [1,2,3,4,5,6,]
    const [HOME_OWNER,AGENT,PROPERTY_MANAGER] = [0,1,2]
    const location = useLocation()
    const dispatch = useDispatch()

    let query = location.search.replace('?','')
    query = qs.parse(query)
    
    let [verify,setVerify] = useState(false)

    async function resend_email(){
        try {
            setLoading(VERIFYING)
            const res = await Axios.post($baseUrl+'/resend_email')

            $success(res)
        } catch (err) {
            $error(err)
        }
        finally{
            setLoading(0)
        }
    }
    const [loading,setLoading] = useState(LOADING)
    let [error,setError] = useState('')
    let [step,setStep] = useState(0)
    let [userType,setUserType] = useState(0)

    const [addressTaken,setAddressTaken] = useState(false)

    const user = useSelector(getUser)
    const plans = useSelector(getPricing)
    const FRE = useSelector(getFRE)
    const featureSchema = useSelector(selectFeatureSchema)
    
    async function handleNoUserNotFound(){
        localStorage.setItem('user',null)

        const newPath = '/'+location.search;
        
        if(!newPath.includes('referral_code')){
            $error('User was not found')
        }

        console.log(newPath)
        window.location.href = newPath 
    }
    let discarded = false
    async function fetchUserData(){
        let setupDone
        try {
            setError(false)
            setLoading(LOADING)
            const userData = await dispatch(fetchUser(user.id))

            if(discarded){
                return -1
            }

            console.log(userData)
            if(userData.user_type === HOME_OWNER && userData.primary_home != null){
                await fetchFeatures(userData.primary_home);     
            }
            if(Array.isArray(plans) && plans.length === 0){
                dispatch(fetchPlans())
            }
            setupDone = handleUserFetch(userData)
            
        } catch (err) {
            const res = err.response
            if(res && res.status === 404){
                handleNoUserNotFound()
            }else{
                $error(err)
            }
            if(!setupDone){
                setError('Could not load user data')
            }
        }finally{
            if(!discarded){
                setLoading(setupDone ? SETUP_DONE : 0)
            }
        }
    }

    useEffect(()=>{
        dispatch(fetchFeatureSchema())
        fetchUserData()

        return () => {
            discarded = true;
        }

    },[])

    useEffect(()=>{
        handleUserFetch({...user})
        
    },[user])

    useEffect(()=>{
        if(FRE){
            setStep(FRE)
            setLoading(0)
        }

    },[FRE])

    const policy = {
        onClick: ()=>  dispatch(setDialog('privacy')),
        type: "button",
        href:'#',
    }
    const terms = {
        ...policy,
        onClick: () =>  dispatch(setDialog('terms')),
        style:{marginLeft:'2px'}
    }
  
    // useEffect(()=>{
    //     window.onfocus = () => {
    //         const setup_not_done = (loading !== SETUP_DONE && step === 0)
    //         if(  user && (setup_not_done || user.new_email)){
    //             console.log('refreshing!')
    //             fetchUserData()
    //         }
    //     }

    //     return ()=>{
    //         window.onfocus = null
    //     }
    // },[loading,step])
    
    async function updateUser(data,suppress=false){
        let setupDone
        try {
            setLoading(PATCHING)
            console.log(data)
            const res = await dispatch(patchUser(data))
            const userData = res.data.data
            if(userData){
                dispatch(setUser(userData))
            }
            console.log(userData)
            handleUserFetch(userData)
            return userData
        } catch (err) {
            if(!suppress){
                $error(err)
            }
            throw err
        }finally{
            setLoading(0)
        }
    }

    const countryOptions = [
        // 'United States of America',
        // 'Candada',
        // 'United Kingdom',
        // 'Australia',
        {value:'US',name:'United States of America'},
        {value:'CA',name:'Canada'},
        // {value:'AU',name:'Australia'},
        // {value:'UK',name:'United Kingdom'},
    ]
    const defaultAddressFields = {
        line1:'',line2:'',
        city:'',
        state:'',
        zip_code:'',
        country: '',
    }
    let [address,setAddress] = useState(defaultAddressFields)
    let [googleAddress,setGoogleAddress] = useState(defaultAddressFields)
    const [placesCount,setPlacesCount] = useState(1)

    
    let [features,setFeatures] = useState({})
    
    function setFeatureList(featureSchema,featureList){
 
        const batch_features = (featureSchema && featureSchema.batch_features)|| []
        const defaults = {

        }
        for(let bf of batch_features){
            defaults[bf] = []
        }

        for(const f of featureList){
            if(!f.category){continue}
            const feat_list = defaults[f.category]
            if(feat_list){feat_list.push(f)}
            else{defaults[f.category] = [f]}

        }

        setFeatures(defaults)
    }

    useEffect(()=>{
        if(!featureSchema){return}
        const keys = Object.keys(features)
        if(keys.length >= featureSchema.batch_features.length){return}
      
        const tfeatures = []
        console.log(features)
        for(let k of keys){
            tfeatures.push(...features[k])
        }
        setFeatureList(featureSchema,tfeatures)
    },
    [featureSchema,features])

    async function fetchFeatures(house_id){
        try {
            setLoading(LOADING)
            const house = house_id || user.primary_home
            const res = await Axios.get($baseUrl+`/houses/${house}/features`)
            
            setFeatureList(featureSchema,res.data.data)
        } catch (err) {
            console.log(err)
            $error(err)
        }finally{
            setLoading(0)
        }
    }

    async function patchFeatures(){
        try {
            const data = {...features}
            const house = user.primary_home
            setLoading(PATCHING)
            const res = await Axios.patch($baseUrl+`/houses/${house}/features`,data)
            setFeatureList(featureSchema,res.data.data)
        } catch (err) {
            console.log(err)
            $error('Failed to patch features')
            throw err
        }finally{
            setLoading(0)
        }
    }
    const addressFieldNames = ['line1','line2','city','state','zip_code','country']

    let user_type = userType
    const isHomeOwner = (user) => {return user.user_type === 0}
    const isAgent = (user) => {return user.user_type === 1}
    const isPropertyManager = user_type === 2

    function handleUserFetch(user){
        let setupDone = false
        let step = 0
        const accountVerified = user && user.is_email_verified
        const [line1,line2] = (user.address||'').split('\n')
        
        let addr = {}
        for(let key of addressFieldNames){
            addr[key] = user[key] || ''
        }
        if(!!user.location){
            addr.line1 = line1 || ''
            addr.line2 = line2 || ''
                
            addr.country = addr.country || address.country
        }
        const possibleCountries = countryOptions.map(c=>c.value)
        if(!possibleCountries.includes(addr.country)){
            addr.country = possibleCountries[0]
        }
        let anomalies = user.address_anomalies || '{}'
        anomalies = JSON.parse(anomalies)
        
        setAddress(addr)
        const gAddress = {...addr} 
        for(let k in anomalies){
            gAddress[k] = anomalies[k]
        }
        delete gAddress.line2
        setGoogleAddress(gAddress)

        const new_email = user.new_email
        if( (!accountVerified || new_email) &&  query.activation_code){
            setVerify(true)
            return false
        }
        
        if(accountVerified){
            const subscribedAlready = !!user.stripe_subscription_id
            const hasType = user.user_type != null
            let hasAddress = user.houses && (user.houses.length || user.primary_home!=null)
            const hasFeatures = true
            const hasPlan = user.current_plan != null

            if(user.primary_home)
            {
                hasAddress = hasAddress && !!user.location
            }
            const non_primary = (user.houses && user.houses.length) && user.primary_home == null
            step = 1
            if(hasPlan){
                setCurrentPlan(user.current_plan)
            }
            if(hasType){
                step = 3
                if(!isHomeOwner(user)){
                   return setupDone = true
                }
                
                // if(subscribedAlready){
                //     setStep(99)
                // }
                setUserType(user.user_type)
                if(hasAddress){
                    step = 4

                    if(!hasFeatures && !non_primary){step = 4;}
                    else if(hasPlan || non_primary){
                        step = 6
                        setupDone = true
                    }
                } 
            }
            
        }
        if(user && user.is_staff){
            setupDone = true
        }

        setStep(step)
        if(!setupDone && loading == 4){
            setLoading(0)
        }
        return setupDone
    }

    
    const userTypeList = [
        {
            type:'home_owner',
            name:'Home owner',
            desc:`I want to use micasa to manage my own
             property and keep it in shape`
        },
        {
            type:'agent',
            name:'Agent',
            desc:`I want to use micasa to help my clients
                 with the process of buying and selling their homes`
        },
        // {
        //     type:'property_manager',
        //     name:'Property manager',
        //     desc:`I want to use micasa to help me manage my clients properties `
        // },
        {
            type:'property_manager',
            name:'Property manager',
            desc:`Coming soon `,
            disabled: true,
        },
    ]

    const verifyAccount = {
        heading:'Verify account',
        caption:`We’ve sent a message to your email address 
        containing a verification link.
        Please click the link in the email to verify your account`
        ,
        nextText:'Resend Link',
    }

    const accountVeriifed = {
        heading:'Account verified',
        caption:`Just a couple more steps to help you get set up!`
        ,
        nextText:'Let’s do it',
        content: null,
    }

    const whoAreYou = {
        heading:'A few more steps',
        caption:`Are you a..`
        ,
        content: <div>
            {userTypeList.map((u,i) => (
                <ChoiceCard 
                alignCenter
                key={u.name}
                active={userType === i}
                onClick={()=>( u.disabled ? null : setUserType(i))}
                style={{height:'90px'}}
                >
            
                    <p style={{fontWeight:'bold',margin:'0'}}>
                        {userTypeList[i].name}
                    </p>
                    <p style={{color:'gray',fontSize:'0.8em',margin:'0'}}>
                        {userTypeList[i].desc}
                    </p>
                
                </ChoiceCard>
            ))}

            {!user.has_password?
            <div>
                <p className='small text-secondary mt-3 mb-1'>
                By clicking next, you’re agreeing to micasa’s 
                <Link {...terms} className=''> Terms of Service </Link> and 
                <Link {...policy} > Policies</Link>.
                </p>
            </div>
            :null}
        </div>,
    }
    const cfprops = {variant:'outlined',size:'small',}

    const formikRef = useRef()

    const formik = formikRef.current

    const googleEmpty = !!(!googleAddress.line1 && placesCount)
    
    const addressFields = [
        {
            label:'Line 1',
            key:'line1',
            content:<AddressField {...cfprops} 
                inputProps={{autoFocus:true}}
                name="line1"
                formikRef={formikRef}
                setGoogleAddress={setGoogleAddress}
                placeholder="Address line 1" 
                setplacescount={setPlacesCount}
                placeTouched={()=>{
                    if(formik && !formik.touched.line1){
                        formik.setTouched({...formik.touched,line1:true})
                    }
                }}
            />,
        },
        {
            label:'Line 2',
            key:'line2',
            content:<VFormField {...cfprops}
                name="line2"
                placeholder="Address line 2" 
                className='w-100'
                disabled={!!(googleEmpty && placesCount)}
            />,
        },
        {
            label:'City',
            key:'city',
            content:<VFormField {...cfprops}
                name="city"
                placeholder="City" 
                className='w-100'
                disabled={googleEmpty}
            />,
        },
        {
            label:'State/Zip',
            key:'zip_code',
            content: <div className='d-flex'>

                <VFormField {...cfprops}
                    placeholder="State"
                    name="state" 
                    className='flex-grow-1 mr-1'
                    disabled={googleEmpty}
                />
                <VFormField {...cfprops}
                    placeholder="Zip code"   
                    className='flex-grow-1'
                    name="zip_code"
                    disabled={googleEmpty}
                />
            </div>,
        },
        {
            label:'Country',
            key:'country',
            dynamic_content: (values,setFieldValue) => <Select 
                value={values.country}
                onChange={(e)=>{
                    setFieldValue('country',e.target.value);
                    // setAddress({...address,country:e.target.value});
                }}
                {...cfprops}
                displayEmpty
                SelectDisplayProps={{style:{...cfprops,padding:'10px',textAlign:'left'}}}
                disabled={googleEmpty}
                className=' w-100'
            >
                {countryOptions.map(c =>
                    <MenuItem value={c.value} key={c.value}>{c.name}</MenuItem>                    
                )}
            </Select>
        },

    ]

    function fillAddressForm(user){
        const form = formikRef.current
        if(form){
            for(let key of addressFieldNames){
                form.setFieldValue(key,user[key])
            }
        }
    }
  
    async function patchAddress(values){
        try {
            let data = {...values}
            setAddress(data)
            
            const anomalies = {}
            for(let key in googleAddress){
                const googleValue = googleAddress[key] 
                if(googleValue != data[key]){
                    anomalies[key] = googleValue
                }
            }
            delete anomalies.line2
            data.address_anomalies = JSON.stringify(anomalies)

            if(!googleAddress.line1){
                return $error('Please select an address from google dropdown in line 1')
            }
            data.address = data.line1+'\n'+data.line2
            delete data.line1
            delete data.line2

            const userData = await updateUser(data,true)

            if(userType === HOME_OWNER && userData.primary_home){
                await fetchFeatures(userData.primary_home)
            }
            
            setAddressTaken(false)
            if(FRE){
                window.location.reload()
            }
            if(user.current_plan != null){
                setStep(6)
                setLoading(SETUP_DONE)
            }else{
                setStep(step+1)
            }

        } catch (err) {
            console.log(err)
            const res = (err.response && err.response) || {}
            if(res.status === 409){
                setAddressTaken(true)
                setStep(step+1)
            }else{
                $error(err)
            }

        }
    }
    const please_choose = 'Please choose an addresses that best matches your address from the list shown.'

    const validationSchema = yup.object({
        line1: yup.string().required('Please select an address')
        .test('google_address',please_choose,
        (value)=>{
            console.log(googleAddress.line1,value)
            return !googleEmpty 
        }),
        ...(!googleEmpty?{
            line2: yup.string(),
            city: yup.string().required($required('City')).max(24),
            state: yup.string().required($required('State')).max(24),
            zip_code: yup.string().required($required('Zip code')),
        }:
        {})
    })

    function cloneAddress(){
        let addr = {}
        for(let key of addressFieldNames){addr[key]=address[key]||''}
        return addr
    }

    const homeAddress = {
        heading:'What’s your address?',
        caption:`It’ll help track your property`,
        content:<div className='mt-4 mb-3'>
            <Formik
                initialValues={cloneAddress()}
                validationSchema={validationSchema}
                // validate={validateForm}
                onSubmit={patchAddress}
                innerRef={formikRef}
             >
            { ({errors,touched,values,setFieldValue}) => 
                <Form>
                    {(errors.line1&&touched.line1)?
                        <p className='text-danger text-medium'>
                            {errors.line1}
                        </p>
                    :null}
                    <table className=''>
                        <tbody>
                    {

                        addressFields.map(af => (
                            <tr className='' 
                            key={af.label}
                            >   
                                <td className=' border-0 p-0'>
                                    <p className='mr-2 m-0 p-0  text-secondary small'>{af.label}</p>
                                </td>
                                
                                <td className='position-relative p-0 border-0 py-1'>
                                {af.content||(af.dynamic_content&&af.dynamic_content(values,setFieldValue))}

                                {(googleAddress[af.key] && googleAddress[af.key]!=values[af.key])?
                                    <p className=' m-0  p-0 text-primary position-absolute'
                                        style={{right:'6px',top:'0px'}}
                                    >
                                        *
                                    </p>
                                :null}

                                </td>
                            </tr>
                        ))
                    }
                        </tbody>
                    </table>
                </Form>
            }

            </Formik>
            <div>
                <p className='small text-secondary mt-3 mb-1'>
                By clicking next, you’re claiming above address 
                as your home, and agreeing to micasa’s 
                <Link {...terms}  className='' > Terms of Service </Link> and 
                <Link {...policy} className='ml-1'> Policies</Link>.
                </p>
            </div>
        </div>
    }



    const other_features = features.other;
    const featureList = Object.keys(features).filter(f => f!='feature').sort()

    // featureList.push('add_other')
    const other_keys = Object.keys(other_features||{})
    for(let otk in other_keys){
        const otf = other_features[otk]
        featureList[otf.name] = ([otf])
    }
    const toggleFeature = (featureKey) => {

        if(featureKey === 'add_other'){
            return;
        }

        const currVal = features[featureKey]
        
    let val = !currVal.length ? [{/*user:user.id*/}] : []
        
        const newFeatures = {...features}
        newFeatures[featureKey] = val
        setFeatures(newFeatures)
    }
    const homeFeatures ={
        heading:'About your home',
        caption:`What features do you have?`,
        content: <div className='container-fluid overflow-auto'
            style={{maxHeight:'19em'}}
        >

            <div className='row row-cols-2'>

                {!featureSchema?
                    <CircularProgress />
                    :
                featureList.map(fkey => {
                    const val = features[fkey]
                    const baby_feature = featureSchema?.baby_features?.find(bf => bf.type == fkey)
                    const category = featureSchema.categories.find(c => c.id == fkey)

                    const active =  val && Array.isArray(val) &&  !!val.length
                    const style = {
                        border: '2px solid '+(active?'var(--pri)':'lightgray'),
                        cursor:'pointer',
                        filter:(active?'':'grayscale(1)')
                    }
                    return <div className='p-1' key={fkey}>
                        <div className='col d-flex  p-2 rounded-extra' 
                            style={style}
                            onClick={()=>toggleFeature(fkey)}
                        >
                            <img src={getFeatureIcon(category,fkey)} width={20} height={20}/>
                            
                            <p className='p-0 m-0 ml-2 text-truncate'
                                style={{fontSize:'0.8em',color:'var(--pri)',fontWeight:'500'
                                    ,maxWidth:'120px'
                                }}
                            >
                                {$prettify(category?.name||'feature')}
                            </p>
                        </div>
                    </div>
                }
                )}

            </div>
        </div>
    }
    let [ifload,setIfload] = useState(0)
    let [currentPlan,setCurrentPlan] = useState(-1)
    useEffect(()=>{
        if(currentPlan === -1 && plans && Array.isArray(plans) && plans.length){
            setCurrentPlan(plans[0].id)
        }
    },[plans])
    const pickPlan = {
        heading:'Select a package',
        caption:`You can change this later`
        ,
        content: <div>
            {plans.map((plan,i) => 
                {
                    let trial_days = (plan.trial_period)
                    const trial_period = getTrialPeriod(plan)

                    return <PlanCard  
                        key={plan.name}
                        onClick={()=>setCurrentPlan(plan.id)}
                        active={currentPlan === plan.id}
                        plan={plan}
                        trialPeriod={trial_days?trial_period:''}
                        isTrailing={true}
                    >
                    </PlanCard>
                }
            )}
        </div>
    }
    const problem = `Home address claim`
    const AddressTaken = {
        heading:'Are you a co-owner?',
        caption:`More than one owner can be added to a home`,
        nextText:'Okay',
        content:<div className='text-left '>
                <p className='text-small text-secondary font-weight-500  mt-3'>
                    The home address you have entered in the previous screen 
                    already has a Primary owner. 
                    <br className='my-1'/>
                    If you would like to be added
                    as a co-owner to manage this home, please contact the home owner
                    directly as primary owner is the only person authorized to add additional owners.
                </p>
                
                <p className='mt-5 text-small text-secondary'>
                    If you believe someone else has claimed your home as the primary owner,
                    let us know right away by contacting us at 
                    <a href={`mailto:support@micasa.ai?subject=${problem}`} 
                        className='font-weight-500 mx-1'
                    >
                        micasa support 
                    </a> 
                    with supporting documents. We will be taking actions as
                    soon as we hear from you. 
                </p>
        </div>
    }
    
    const FieldName = (props) => (
        <p className='text-secondary text-left small mt-3 mb-2'>
            {props.children}
        </p>
    )

    let steps = [
        verifyAccount,
        accountVeriifed,
        whoAreYou, // 1
        homeAddress, // 2
        homeFeatures, // 3
        pickPlan, // 4
        // paymentScreen, // 5
    ]
    
    let disableNext = false

    if(googleEmpty && step === 3){
        disableNext = true;
    }
        
    if(userType === userTypes.agent){
        steps = steps.slice(0,3)
    }

    const totalSteps = steps.length - 2
    const currentStep = step - 1

    // console.log(step,steps.length)
    async function takeStep(inc=1){
        try {
            if(inc<0){

            }
            else if(step === 0){
                return resend_email()
            }
            else if (step === 2){ // set user_type
                await updateUser({user_type:userType})

                let user_type = Object.keys(userTypes).find(k => userTypes[k] == user_type)
                if(user_type){
                    $event('user_type',{value:user_type})
                }
            }
            else if (step === 3){ // set user_address
                if(formikRef.current){
                    formikRef.current.submitForm()
                }
                throw "manual step"
            }
            else if(step === 4){
                if(addressTaken){
                    // alert('closing')
                    dispatch(setUser(null))
                    return
                }
                await patchFeatures()
            }else if(step === 5){
                await updateUser({current_plan:currentPlan})
            }
            

            let nstep = (step+inc)
            nstep = Math.max(nstep,0)
            // nstep = Math.min(nstep,steps.length-1)
            if(nstep === 6){
                setIfload(0)
            }

            setStep(nstep)

        } catch (err) {
        }
        
    }
    const settingUp = (step < steps.length)
    let currentSlide = step < steps.length? steps[step] :{}
    if(addressTaken && currentSlide == homeFeatures){
        currentSlide = AddressTaken
    }

    const hideSteps = currentSlide === AddressTaken || currentStep < 1

    let {heading,caption,content,nextText} = currentSlide
    if(step === steps.length-1){
        nextText = 'Finish'
    }

    function showVerifier(){
        setVerify(true)
    }
    function hideVerifier(success){
        setVerify(false)
        if(success==true){
            setStep(1)

            if(user.user_type !=null && user.user_type != userTypes.home_owner){
                setLoading(SETUP_DONE)
            }
        }
    }
    let showOverlay = loading != SETUP_DONE && (loading > 0 || error || settingUp)
    showOverlay = showOverlay || (FRE != null)
    const closable = (FRE != null)

    function closeFRE(){
        if(FRE){
            dispatch(setFRE(null));
            setLoading(SETUP_DONE)
        }
    }
    function goBack(){
        if(FRE){
            closeFRE()
        }else{
            takeStep(-1)
        }
    }

    const setupDialog = <div className='px-sm-5 px-2 py-3 d-flex flex-column 
    position-relative text-center flex-grow-1 mx-auto setup-dialog ' 
        
    >

        <div className='mt-auto py-3'>
            <img src={step==1?'/images/dashboard/email-verified.svg':'/images/micasa-logo.svg'} 
            alt='' style={{height:step==1?'6em':'4em'}} 
            />

            <h4 className='mt-3 mb-2 font-weight-bold'>
                {heading}
            </h4>
            <p style={{color:'gray',maxWidth:'24em',marginLeft:'auto',marginRight:'auto'}}>
                {caption}
            </p>
             
            {content}
            
                
        </div>

        <div className='mt-auto mb-3 d-flex'>
            {step>1?
            <Button
                variant="contained"
                disableElevation
                className='flex-grow-1 mr-2'
                onClick={goBack}
                >
                {closable?'Cancel':'Back'}
            </Button>
            :null}
            
            <Button
                variant="contained"
                color='primary'
                disableElevation
                className='flex-grow-1'
                onClick={()=>takeStep(1)}
                disabled={disableNext}
                >
                
                {(loading > 0)?
                <CircularProgress
                    size={18}
                    disableShrink
                    style={{color:'white'}}
                    className='mx-3'
                />
                :null}

                {nextText||'Next'}
            </Button>
        </div>
        
        {!hideSteps?
            <p className='small text-muted mb-1'>
                Step {currentStep} of {totalSteps}
            </p>
        :null}
    </div>

    let activeSlide = setupDialog
    const isLoading = loading === LOADING || loading === SETUP_DONE

    if(verify){
        activeSlide = <div className=''>
            <EmailVerifier showMe={showVerifier} hideMe={hideVerifier} />
        </div>
    }
    else if(error){
        activeSlide = <div className='d-flex flex-column align-items-center justify-content-center
          mb-3  flex-grow-1 '>
            <Button onClick={()=>window.location.reload()}
                 className='mt-5'
            >
                <Refresh  style={{fontSize:'3em',color:'gray',margin:'8px',}} />
            </Button>
            <p className='mx-5 mb-5'>
                Error: {error}
            </p>
        </div>
    }else if(isLoading){
        activeSlide = <div className='p-5 d-flex flex-column align-items-center my-auto
            text-center
        '>
            <div className='my-auto'>
                <CircularProgress 
                size={64}
                className='mx-4 mb-3 '
                />
                <p>Loading...</p>
            </div>
        </div>
    }

    return <MDialog
        open={!!showOverlay}
        unClosable={!closable}
        onClose={closeFRE}
    >
        {activeSlide}

    </MDialog>
}

