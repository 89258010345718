import { default as React, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { setDialog } from './app/ui/uiSlice';
import { fetchFeatures, fetchHouse, fetchServices, getHouse, userTypes } from './app/user/userSlice';
import './App.css';
import { getUser } from './app/auth/authSlice';
import { selectBlur, selectUIMessages } from './app/ui/uiSlice';
import ProRoute from './components/routing/ProtectedRoute';
import Authenticator from './components/special/Authenticator';
import DialogViewer from './components/special/DialogViewer';
import Footer from './components/special/Footer';
import TopBar from './components/special/TopBar';
import { signInFromCache } from './utils/http';
import InitialSetup from './views/dashboard/InitialSetup';
import ErrorPage from './views/ErrorPages';
import LandingPage from './views/LandingPage';
import Legal from './views/legal/Legal';
import Messages from './views/messages/Messages';
import { CircularProgress } from '@material-ui/core';

import HomeOwnerDashboard from './views/dashboard/HomeOwnerDashboard';
import Services from './views/services/Services'
import ContractorDashboard from './views/dashboard/ContractorDashboard';
import AgentDashboard from './views/dashboard/agent/AgentDashboard';
import PotentialBuyerDashboard from './views/dashboard/PotentialBuyerDashboard';
import PropertyManagerDashboard from './views/dashboard/PropertyManagerDashboard';

// const HomeOwnerDashboard = React.lazy(() => import('./views/dashboard/HomeOwnerDashboard'))
// const Services = React.lazy(() => import('./views/services/Services'))
// const ContractorDashboard = React.lazy(() => import('./views/dashboard/ContractorDashboard'))
// const AgentDashboard =  React.lazy(()=> import('./views/dashboard/agent/AgentDashboard'))
// const PotentialBuyerDashboard =  React.lazy(()=> import('./views/dashboard/PotentialBuyerDashboard'))
// const PropertyManagerDashboard =  React.lazy(()=> import('./views/dashboard/PropertyManagerDashboard'))

const AdminDashboard =  React.lazy(()=> import('./views/dashboard/admin/AdminDashboard'))

function App() {

  const homeOwner = 'home-owners'
  const agent = 'agents'

  let defaultUserType = homeOwner 
  
  const user_types = [homeOwner,agent]
  let [userType,setUserType] = useState(defaultUserType)
  const [showSignIn,displaySignIn] = useState(0) 

  const user = useSelector(getUser)
  const blurScreen = useSelector(selectBlur)
 
  function switchUserType(type){
    const to = type||homeOwner
    setUserType(to)
  }
  const messages = useSelector(selectUIMessages)

  function handleSignInDialog(){
      if(!signInFromCache()){
          displaySignIn(1)
      }
  }
  let is_trailing =  user &&  user.user_type == userTypes.home_owner && user.plan_status === 0
  const isExpired = is_trailing && user.user_type == userTypes.home_owner && user.days_left <= 0

  const [LOADING,SAVING]  = [1,2]
  const [status,setStatus] = useState(0)

  const loading = () => <div>
    LOADING...
  </div>

  let dashboard  = loading
  let services = loading
  let adminPanel = Redirect

  const isAdmin = user?.is_staff 

  if(isAdmin){
    adminPanel = AdminDashboard
    dashboard = Redirect
    services = Redirect
  }else if (user){
      switch (user.user_type) {
        case userTypes.home_owner:
            if(isExpired){
                dashboard = SubscriptionExpired
            }else{
              dashboard = HomeOwnerDashboard
              services = Services
            }
            break;
        case userTypes.agent:
            dashboard = AgentDashboard
            break;
        case userTypes.property_manager:
            dashboard = PropertyManagerDashboard
            break;
        case userTypes.potential_buyer:
            dashboard = PotentialBuyerDashboard
            break;      
        case userTypes.contractor:
            dashboard = Redirect 
            services = ContractorDashboard
            // ContractorDashboard
            break;
        default:
            services = Redirect
            // dashboard = <HomeOwnerDashboard/>
            break;
    }
  }

  const dispatch = useDispatch()
  const userRef = useRef()
  const houseRef = useRef()
  let discarded = false
  const house = useSelector(getHouse)

  async function load(user_changed){
      try {
          if(!user.primary_home && (!user.houses || !user.houses.length)){
              return
          }
          setStatus(LOADING)

          const refresh_house = user_changed || (!house)
          let active_home = (!refresh_house && house && house.id) || user.primary_home || user.houses[0]

          if(!refresh_house){active_home = house.id}
          const requests = [dispatch(fetchFeatures(active_home))]

          if(refresh_house){
              requests.push(
                  dispatch(fetchHouse(active_home))
              )
          }

          await Promise.all(requests)
          
          if(discarded){
              throw "discarded!!"
          }
          
      } catch (err) {
          console.log(err)
      } finally {
          setStatus(0)
      }
      
  }

  useEffect(()=>{
      if(user && !user.is_staff){
  
          const user_type = user.user_type
          if(user_type == userTypes.home_owner){
              dispatch(fetchServices())

              if(userRef.current != user){
                  userRef.current = user
                  load(true)
              }else if (houseRef.current!=house){
                  // avoid unnecessary loading just after first load
                  if(houseRef.current){load()}
                  houseRef.current = house
              }
              return () => {
                  discarded = true
              }
          }
      }

  },[user,house])

  let default_path = '/'
  if(user){
    default_path = '/dashboard'
    if(user.user_type == userTypes.contractor){
      default_path = '/services'
    } 
    if(isAdmin){
      default_path = '/admin/users'
    }
  }

  return (
    <div className="App vh-100 d-flex flex-column"
    
    >
      <Router>
  <div 
    
    className='flex-grow-1 d-flex flex-column overflow-hidden ' 
    style={{ ...(blurScreen>0?{filter:'blur(8px)'}:{/*transition:'filter 0.5s'*/}),}} 
          >

          <TopBar 
            switchUserType={switchUserType}
            landingUserTypes={user_types}
            landingUserType={userType}
            displaySignIn={displaySignIn}
            handleSignInDialog={handleSignInDialog}
            >

          </TopBar>

          <div className='flex-grow-1 landing-page overflow-auto d-flex flex-column'
          
          >
            <div className='d-flex flex-column overflow-auto'  id="main-column"
              style={{flexGrow:'1',}}
            >
            {user && !user.is_staff &&
              <ExpiryWarning/>
            }

            <div className='d-flex flex-column flex-grow-1'>
            <React.Suspense fallback={
              <div className='lead'>
                  Loading
                  <CircularProgress size={14} className='ml-3' />
              </div>}>
            <Switch>
              {/* <ProRoute path='/admin' component={Redirect} to={'/'} exact /> */}
              <ProRoute path='/admin' component={adminPanel} to={'/'} />
              
              <ProRoute path='/dashboard' component={dashboard} status={status}  to={'/'} />
              <ProRoute path='/messages' component={Messages} to={'/'} />
              <ProRoute path='/services' component={services} to={'/'} />

              <Route path='/legal' component={Legal} />
              <Route path='/home'            
                render={
                  props => (
                    <LandingPage {...props} 
                      userType={userType}
                      userTypes={user_types}
                      switchUserType={switchUserType}
                      showSignIn={showSignIn}
                      displaySignIn={displaySignIn}
                      handleSignInDialog={handleSignInDialog}
                      
                    />
                    
                    )
                }
              />

              <Route path='/' exact
                
                render={
                  props => (
                    user?
                    <Redirect to={{...props.location,pathname: default_path}}/>
                    :
                    <LandingPage {...props} 
                      userType={userType}
                      userTypes={user_types}
                      switchUserType={switchUserType}
                      showSignIn={showSignIn}
                      displaySignIn={displaySignIn}
                      handleSignInDialog={handleSignInDialog}
                      
                    />
                    
                    )
                  }
              />
              <Route path='/' component={ErrorPage} />

            </Switch>
            </React.Suspense>
            </div>
              <Footer  userType={userType} />
            </div>


          </div>
        </div>
        
        {user?
        <InitialSetup user={user} />
        :null}
          
          <Authenticator
            userType={userType}
            showSignIn={showSignIn}
            displaySignIn={displaySignIn}

          />

          <DialogViewer />

        <div className='notification-container position-fixed d-flex flex-column '
          style={{
            right:'16px',
            zIndex:'1320'
            // right:'50%',
            // transform:'translateX(50%)'
        }}
        >
          {messages.map( (m,i) => 
              <div
                // open={true}
                // anchorOrigin={{vertical:'top',horizontal:'right'}}
                // message= {m.message}
                key={m.time}
                className='notification-card position-absolute2'
                style={{
                  borderColor: getToastColor(m.type),
                  // transform:`translateY(${i*110}%)`,
                  right:'16px',
                  bottom:'4px'
                }}
                
              >
                {m.message}
              </div>
          )}
        </div>
      

      </Router>
    </div>
  );
}

export default App;


function ExpiryWarning(){
  const user = useSelector(getUser)
  const is_home_owner = user.user_type === userTypes.home_owner
  let is_trailing = is_home_owner && user.plan_status === 0 // || true
  is_trailing = is_trailing && (user.days_left <= 15)
  const trial_expired = is_trailing && user.days_left < 1
  const dispatch = useDispatch()
  const history = useHistory()

  return <div className='mt-2'>
      {is_trailing &&
          <div className={`bg-${trial_expired?'red':'warning'} 
           text-white p-3 font-weight-500 `}
          >
              <div className='d-flex container px-0 px-sm-2 
              flex-column flex-sm-row align-items-sm-center
              '>
                  <p className='text-left mr-2'>
                      {trial_expired?
                      <span>
                          {`${'Your trial has expired!'}`}
                      </span>
                      :
                      <span>
                         { ` Only ${user.days_left} days remaining in your trail subscription`}
  
                         <span className=' '>
                          {`, to continue the services offered by micasa, sign up for a subscription.`}
                         </span>
                         <br className='d-sm-none'/>
                          <span className='text-white font-weight-500 cursor-pointer ml-2'
                              onClick={()=>history.push('/home?for=home-owners')}
                          >
                              <u className='text-nowrap'>
                              Learn more
                              </u>
                          </span>
                      </span>
                      }
  
                  </p>
                  <button className='ml-auto font-weight-500 text-white
                      bg-transparent border-thick border-white text-nowrap'
  
                      onClick={()=>dispatch(setDialog('payment_details'))}
                  >
                      Subscribe now
                  </button>
              </div>
          </div>
      }

  </div>
}


function SubscriptionExpired(){
  const dispatch = useDispatch()
  return <div className='d-flex flex-column flex-grow-1 
      align-items-center justify-content-center
  '>

      <p className='text-muted '>
          Please 
          <span className=' mx-1 hover-light cursor-pointer'
              onClick={()=>dispatch(setDialog('payment_details'))}
          >
              <u>
              subscribe
              </u>
          </span>
           to continue the services.
      </p>
   
  </div>
}



function getToastColor(type){
  let color = 'red'

  switch(type){
    case 'error':
      color = '#ff2211'
      break;
    case 'success':
      // color = '#6eff00'
      color = '#258af3'
      // color = 'var(--pri)'

      break;
    case 'warning':
      color = '#ecc819'
      break;
  }

  return color
}