import { Button, CircularProgress, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import EmailIcon from '@material-ui/icons/EmailOutlined'
import PhoneIcon from '@material-ui/icons/PhoneOutlined'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import { VFormField } from '../../views/dashboard/InitialSetup'
import { $alert, $baseUrl, $confirm, $error, $success, $warning } from '../../utils/http'
import SubmitButton from '../common/SubmitButton'
import { $wait } from '../../utils'
import ChoiceCard from '../input/ChoiceCard'
import { useDispatch, useSelector } from 'react-redux'
import { fetchHouse, fetchSchema, getHouse,getHouseSchema,setFRE,setHouse } from '../../app/user/userSlice'
import { getUser } from '../../app/auth/authSlice'
import Axios from 'axios'
import { ActionButtons, AutoForm } from '../../views/dashboard/FeatureEditor'
import MDialog from '../common/MDialog'
import { DocumentUploader } from '../common/DocumentUploader'
import { DocumentViewer } from '../common/DocumentViewer'

export function NameBadge({source,size}){
    const o = source
    const isize = (size||48)+'px'
    return <div className=' d-flex  text-small mr-2 my-auto font-weight-500
    text-dark
    align-items-center justify-content-center text-lowercase text-uppercase'
        style={{backgroundColor:'#e3e3e3',
        borderRadius:'12px',height:isize,width:isize}}
    >
        {(o.full_name||o.email.split('@').join(' ')).split(' ').map(s => s.slice(0,1))}
    </div>
}
function ActionRow({goBack,loading,onClick,submitText='Add',...props}){
    return <div className='d-flex ' {...props}>
        <Button 
            variant="contained"
            className=' px-4 my-2 flex-grow-1 '
            disableElevation
            onClick={goBack}
        >
            Cancel
        </Button>

        <SubmitButton loading={loading} 
            className='my-2 pr-3 flex-grow-1 ml-2'
            onClick={onClick||(()=>{})} 
        >
            <span className='mr-3'>
            {submitText}
            </span>
        </SubmitButton>

    </div>
}
export default function HomeDialog({type,goBack,payload,...props}){
    const dispatch = useDispatch()
    const house = useSelector(getHouse)
    const user = useSelector(getUser)
    const [LOADING,SAVING] = [1,2]

    async function patchHouse(data,fields){
        try {
            let fdata = {}
            if(fields){
                fields.forEach(fkey=>{fdata[fkey] = data[fkey]})
            }else{
                fdata = {...data} 
            }

            for(let f of fields){
                const field = houseSchema[f]
                if(field.choices){
                    const active_choice =  field.choices.find(c=>c.value == data[f])
                    if(!active_choice){
                        fdata[f] = undefined
                    }
                }
            }

            const res = await Axios.patch($baseUrl+`/houses/${house.id}`,fdata)
            $success(res)
            return res
        } catch (err) {
            $error(err)
        }
        
    }
    async function makeUserPrimary(o){
        const res = await $confirm({
            // question:`Are you sure you want to make ${o.full_name||'this user'} the primary owner ?`,
            question:`Would you like to proceed with making your co-owner as primary to manage this property?`,
            detail:'You can always ask your co-owner to make you primary if you change your mind.',
            no:'Cancel'
        })

        handleAddOwner({primary_owner_id:o.id},{setSubmitting:(val)=>{setMakingPrimary(val)}})
        
    }

    async function handleAddOwner({email,primary_owner_id},{setSubmitting}){
        setSubmitting(true)
        try {
            if(!house){
                throw("Please select a property first")
            }
            const data = {}

            if(email){data.new_owner = email}
            else if (primary_owner_id){data.primary_owner = primary_owner_id}

            await patchHouse(data)

            dispatch(fetchHouse(house.id))
        } catch (err) {
            $error(err)
        }
        finally{
            setSubmitting(false)
            goBack()
        }
    }
    const validationSchema=yup.object({
        email:yup.string().required('Please provide the email address of the owner')
        .email('Please provide a valid email address')
    })

    let add_owner = !house ? null : <div className='d-flex flex-column text-center my-auto
     px-3 px-sm-4 mx-sm-5 flex-grow-1 
     justify-content-center
    '
    >
        <p className='text-black font-weight-bold mb-1 mt-4' style={{fontSize:'1.8em'}}>
            Add additional owner
        </p>
        <p className='tex-medium text-secondary mb-2'>
            What is the email address of the owner?
        </p>

        <Formik
            initialValues={
                {
                    email: payload.email||''
                }
            }
            onSubmit={handleAddOwner}
            validationSchema={validationSchema}
        >
            {({errors,isSubmitting}) => 
                <Form className='d-flex flex-column my-3 '>
                    <VFormField name="email" placeholder='Email address'>
                    </VFormField>

                    <ActionRow 
                        goBack={goBack} 
                        loading={isSubmitting}
                        className='d-flex '
                    />

                </Form>
            }
        </Formik>

    </div>
    const [makingPirmary,setMakingPrimary] = useState(false)

    let owners = 
    house? house.owner_list :
    [
        {full_name:'Caleb Whitmore',email:'caleb_w89@gmail.com',id:1},
        {full_name:'Joe Smith',email:'joe_smith896@gmail.com',id:2},
    ]
    
    const primary_owner = house?house.primary_owner: null
    owners = owners.map(o=> ({...o,is_primary:Number(o.id == primary_owner)}))
                .sort((a,b) => b.is_primary-a.is_primary)

                
    const is_user_capable = user.id == primary_owner

    let show_owners = !house ? null : <div className=' p-2 pl-sm-3 my-dialog-lg '>
        {owners.map( (o,i) =>
            <div className='d-flex my-2' key={i}>
                
                
                <NameBadge 
                    source={o}
                />

                <div className=' py-2 flex-grow-1 d-flex flex-column flex-grow-1'>
                    <div className='mb-1 rounded text-medium d-flex '>
                        <div className='mr-2 mr-sm-5 d-flex align-items-sm-end
                        flex-sm-row flex-column'>
                            <p className='m-0'>
                                {o.full_name || o.email} 
                            </p>
                        </div>

                    
                    </div>
                    <div className='d-flex text-small text-primary flex-sm-row flex-column'>
                        <p className=' m-0 text-truncate'>
                                <EmailIcon 
                                    style={{fontSize:'0.96em'}}
                                    className='mr-1'
                                />
                                {o.email}
                        </p>
                        {o.mobile_number?
                            <p className=' m-0 ml-0 ml-sm-3'>
                                <PhoneIcon 
                                    style={{fontSize:'0.96em'}}
                                    className='mr-1 '
                                />
                                {o.mobile_number}
                            </p>
                        :null}

                    </div>
                </div>

                <div className='ml-auto my-auto' >
                    {o.is_primary?
                        <p className='pl-2 m-0 text-small ' 
                        style={{color:'green',fontWeight:'500'}}>
                            Primary
                        </p>
                        :
                        (is_user_capable && (o.is_active) && (o.primary_home == null) ?
                            makingPirmary?
                            <CircularProgress size={20} />
                            :
                            <p
                                color='primary'
                                className='text-small text-primary bg-muted text-right
                                hover-scale 
                                m-0 font-weight-500 cursor-pointer rounded border-primary'
                                onClick={()=>makeUserPrimary(o)}
                            >
                                Make user primary
                            </p>
                        :
                            <p className='small text-gray text-right' style={{}}>
                                {
                                    is_user_capable?
                                    (o.is_active?'Primary on another property':"Pending sign up")
                                    :''
                                }
                            </p>
                        )
                    }
                </div>

            </div>
        )}
    </div>
    // const fake_homes = [
    //     {address:"8621 Crested Cove Ct"},
    //     {address:"24011 NE 14th St"},
    //     {address:"2724 Cascade Cove Dr"},

    // ]
    const [homes,setHomes] = useState(null)

    function setHome(h){
        dispatch(setHouse(h))
    }
    const [hi,setHi] = useState(homes?homes.findIndex(h=>(house && h.id === house.id)):0)
    async function fetchHomes(){
        const ids = [...user.houses,]
        const ph = user.primary_home
        if(ph != null && !ids.includes(ph)){
            ids.push(ph)
            console.log(
                'fetching homes'
            )
        }

        const requests = []
        console.log('houses =======   ')
        console.log(ids)

        for(const id of ids){
            requests.push(Axios.get($baseUrl+`/houses/${id}`))
        }

        try {

            let results = await Promise.all(requests)
            
            results = results.map(r => r.data)
            setHomes(results)
            setHi(results.findIndex(h=>(house && h.id === house.id))||0)
        } catch (err) {
            $error(err)
        }
    }
    async function fetchHouseSchema(){
        try {
            setLoading(LOADING)
            await dispatch(fetchSchema({resource:'house'}))
        } catch (err) {
            $error(err)
        }finally{
            setLoading(0)
        }

    }
    const houseSchema = useSelector(getHouseSchema)
    const [loading,setLoading] = useState(0)
    const [babyHouse,setBabyHouse] = useState({})
    useEffect(()=>{
        // setHome(homes[0])
        if(type == 3){
            // fetch all houses
            fetchHomes()
        }
        
        if(!houseSchema){
            fetchHouseSchema()
        }
    },
    [])

    useEffect(()=>{
        if(type==4){
            setBabyHouse({...house})
        }
    },[house])

    const my_properties = !homes? null :<div className='d-flex flex-column 
        text-center p-4 px-sm-4 mx-sm-4 flex-grow-1 justify-content-center'
    >
        <img src="images/micasa-logo.svg" style={{height:'4em'}} />

        <p className='text-black font-weight-bold mb-1' style={{fontSize:'1.8em'}}>
            My properties
        </p>
        <p className='tex-medium text-secondary mb-3'>
            Select an address to view & manage it
        </p>
        
        <div className='d-flex flex-column mb-3'>
            {!user.primary_home?
                <div className='d-flex align-items-center p-3 cursor-pointer hover-scale'
                    style={{borderRadius:'8px',border:'2px solid lightgray'}}
                    onClick={()=>dispatch(setFRE(3))}
                >
                    <img src='/images/dashboard/add-property.svg' height='32px' />
                    <p className='font-weight-500 m-0 text-primary ml-3'>
                        Add a property
                    </p>
                </div>
            :null}
            {homes.map((h,i) => 
                <ChoiceCard key={h.address}
                    onClick={()=>setHi(i)}
                    active={hi === i}
                    alignCenter
                >   
                
                    <div className='my-2 ml-2'>
                        <p className='font-weight-500 m-0'>
                            {h.address}
                        </p>
                        <p className='small text-muted m-0'> 
                            {/* {[h.city,h.state,h.country].filter(i=>!!i).join(',')} */}
                            {h.plan_name||'No plan picked yet'}
                        </p>
                    </div>

                </ChoiceCard>
            )}
        
        </div>


        <ActionRow 
            goBack={goBack}
            loading={false}
            submitText='Select' 
            onClick={()=>{setHome(homes[hi]);goBack()}}
        />

    </div>

    let houseInfo = <div>
            no schema!!!!
    </div>
    const [showUploader,setShowUploader] = useState(false)
    const [showDocuments,setShowDocuments] = useState(false)

    function handleFieldEvent(event){
        if(event=='view_documents'){
            setShowDocuments(true)
        }
        else if(event=='upload_document'){
            setShowUploader(true)
        }else{
            $alert('Feature not implemented yet!')
        }
    }
    if(loading==LOADING){
        houseInfo =  <p className='text-secondary py-5 p-4 text-center my-auto' >
            loading...    
        </p>
    }else if(houseSchema){
        const addr_fields = ['address','city','zip_code','state','country']
        const excludedFields = ['id','owners','primary_owner','notes','documents','google_place_id','location',
        'plan_name','owner_list',...addr_fields]
        const fields = Object.keys(houseSchema).filter(f => !excludedFields.includes(f))
        if(houseSchema.documents){fields.push('documents')}
        if(houseSchema.notes){fields.push('notes')}


        houseInfo = <div className='p-1 '>
            {showDocuments?
                <DocumentViewer 
                    source={house} 
                    onClose={()=>setShowDocuments(false)} 
                    house={house}
                    openUpload={()=>setShowUploader(true)}
                />
            :
                <div className='p-3 '>
                    <AutoForm 
                        schema={houseSchema} 
                        source={babyHouse}
                        setter={setBabyHouse}
                        onEvent={handleFieldEvent}

                        fields={fields}
                    />

                    <ActionButtons 
                        onNext={async()=>{await patchHouse({...babyHouse},fields);goBack();}} 
                        onBack={goBack} 
                        nextText='Save' 
                    />
                </div>
            }

            <MDialog open={showUploader}
                onClose={()=>setShowUploader(false)}
            >
                <DocumentUploader
                    onClose={()=>setShowUploader(false)} 
                    house={house}
                />

            </MDialog>
            

        </div>
    }
    
    let general_info = <div className='my-dialog-lg d-flex flex-column '>
            {houseInfo}
        </div>

    const dialogs = [
        add_owner,
        show_owners,
        my_properties,
        general_info
    ]
    let dialog = dialogs[(type-1)]

    const loadingIndicator = <div className='mx-auto my-auto p-5 d-flex '>

        <CircularProgress className='mx-auto my-auto' />

    </div>

    return <div className='flex-grow-1 d-flex flex-column'  >

        {dialog||loadingIndicator}

    </div>

}