
import { Button, Checkbox, CircularProgress, Dialog, TextField } from '@material-ui/core'
import { ArrowBack, Check, MoreHoriz } from '@material-ui/icons'
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { getUser } from '../../../app/auth/authSlice'
import { setChecklists } from '../../../app/user/userSlice'
import { DocumentViewer } from '../../../components/common/DocumentViewer'
import MDialog from '../../../components/common/MDialog'
import SimpleMenu from '../../../components/common/SimpleMenu'
import { $baseUrl, $error } from '../../../utils/http'
import qs from 'querystring'

export default function Documents(props){
    const user = useSelector(getUser)
    const u_client = user.linked_data.client
    const history = useHistory()
    const location = useLocation()

    const [client,setClient] = useState(null)
    const [filterText,setFilterText] = useState('')
    const [checklist,setChecklist] = useState(null)

    function displayChecklist(checklists){
        let query = location.search.replace('?','')
        query = qs.parse(query)

        if(query.checklist){
            const c_list = checklists.find(c=>c.template==query.checklist)
            if(c_list){
                setChecklist(c_list)
            }
        }
    }

    async function fetchClient(){
        try {
            const res = await Axios.get(`${$baseUrl}/clients/${u_client.id}`)
            setClient(res.data)

            displayChecklist(res.data.checklists)
        } catch (error) {
            $error(error)
        }
    }
    const [IDLE,SAVING_TASK,LOADING] = ',saving,loading'.split(',')
    const [loading,setLoading] = useState(IDLE)

    useEffect(()=>{
        fetchClient()
        
        if(client){
            displayChecklist(client.checklists)
        }
    },[])

    async function patchTask(item){
        try {
            setLoading(item.id)
            const data = {status:item.status}
            const res = await Axios.patch(`${$baseUrl}/actionitems/${item.id}`,data)

            const shouldDelete = false
            const new_tasks = checklist.tasks.slice().filter(tt=>tt.id != item.id)
            if(!shouldDelete){
                const updated_one = res.data 
                new_tasks.push(updated_one)
            }
            const new_edited = {...checklist,tasks:new_tasks}
            setChecklist(new_edited)
            
            const new_boi = {
                ...client,
                checklists: [...checklists.slice().filter(c=>c.id!=checklist.id),new_edited]
            } 
            setClient(new_boi)
        } catch (error) {
            $error(error)
        }finally{
            setLoading(IDLE)
        }
    }

    const cdocuments = client ? client.documents : []
    const checklists = client ? client.checklists.slice().sort((a,b)=>a.id-b.id) : []
    const searchText= filterText.toLowerCase()

    return <div className='flex-grow-1  '>

        <div className='border border-right-0 border-left-0 py-sm-3 py-2 mb-3 sticky-top bg-white'>

            <div className='d-flex align-items-sm-center  
                flex-column flex-sm-row container
            '>
                <div className='d-flex mb-2 align-items-center'>
                    <ArrowBack fontSize='large' className='cursor-pointer text-primary'
                        onClick={()=>history.push('/dashboard')} 
                    />
                    <h4 className=' mx-2 my-0 font-weight-bold ml-3'>
                        Documents
                    </h4>
                </div>
                <div className='d-flex align-items-center'>
                    <TextField 
                        variant='outlined'
                        size='small'
                        placeholder='Search by name '
                        className='mx-2'
                        style={{minWidth:'50vw'}}
                        value={filterText}
                        onChange={e=> setFilterText(e.target.value)}
                    />
                    <button className='p-2 px-4'>
                        search
                    </button>
                </div>
            </div>
        </div>

        <div className='container'>

        {client ?
            <DocumentViewer
                source={{...client,type:'client'}}
                hideBack
                gridView
                label={'Upload a document'}
                onAddNew={newDoc => 
                    setClient({...client,documents:[...client.documents,newDoc]})
                }
                onDocChange={(doc,deleted)=>{
                    const filtered_out = client.documents.slice().filter(d=>d.id!=doc.id)
                    if(deleted){
                        setClient({...client,documents:filtered_out})
                    }else{
                        setClient({...client,documents:[...filtered_out,doc].sort((a,b)=>a.id-b.id)})
                    }

                }}
                filterer={d => ['',' '].includes(searchText) || d.filename.toLowerCase().includes(searchText)}
            />
            :
            <CircularProgress className='m-5' />
        }

        {client && !!checklists?.length && 
            <div>
                <p className='text-left font-weight-500 lead'>
                    Checklists
                </p>

                <div className='row'>
                    {checklists.filter(c=>c.name.toLowerCase().includes(searchText)).map(c=>
                        // <div className='col-6 p-1'>

                        //     <div className='d-flex border p-3 rounded'>
                        //         <Check  />
                        //         <p className='ml-2'>
                        //             {c.name}
                        //         </p>
                        //     </div>

                        // </div>

                        <div className='col-12 col-sm-6 p-2 cursor-pointer ' key={c.id}
                            onClick={()=>setChecklist(c)}
                        >
                        <div className=' d-flex align-items-center border-thick 
                        border-top-01 border-right-01 border-left-01 rounded-extra p-3 '
                            style={{borderColor:'whitesmoke'}}
                        >
                            <Check fontSize='large' htmlColor='#aabbcc' />
                            <p className='ml-2 flex-grow-1 text-left text-truncate' 
                            style={{fontSize:'14px'}}
                            >
                                {c.name} 
                            </p>
                        </div>
                        </div>
                    )}
                </div>

            </div>
        }


        <MDialog
            open={!!checklist}
            onClose={()=>setChecklist(null)}

            titleBar={!!checklist && <div className={`d-flex justify-content-between font-weight-500 ml-3 lead
             align-items-center m-0 my-auto`}>
                {checklist.name || 'Checklist'}
            </div>}
            // paperPadding=' p-2 p-sm-4 '
            // fixedClose
            // height={isMobile?'':'45vh'}

        >
            {checklist ? 
                <div className='bg-muted pr-3 py-2 my-dialog-lg'>
                        {/* <p>{checklist.name}</p> */}
                        
                        {
                            checklist.tasks.slice().sort((a,b)=>a.id-b.id)
                            .map(t => <div key={t.id} className='d-flex align-items-start '>

                                    {loading == t.id? <CircularProgress size={18} 
                                        style={{margin:'12px 12px'}}
                                    /> :
                                        <Checkbox 
                                            color='primary'
                                            checked={t.status}
                                            onClick={()=>patchTask({...t,status:!t.status})}
                                        />
                                    }

                                    <p style={{wordWrap:'break-word',marginTop:'10px'}} 
                                    className='flex-grow-1 overflow-hidden text-left '
                                    
                                    >
                                        {t.name}
                                    </p>
                                </div>    
                            )
                        }
                </div>
                :
                ''
            }
    
        </MDialog>


        </div>
        
    </div>
}