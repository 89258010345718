import React, { useState, useRef } from 'react'

import {logout,getUser,patchUser, setUser} from '../../app/auth/authSlice'
import { useSelector, useDispatch } from 'react-redux'
import * as yup from 'yup'
import {VFormField} from '../../views/dashboard/InitialSetup'
import { MenuItem, Select, Checkbox, Button, CircularProgress, TextField, Paper, Tabs, Tab, FormControlLabel } from '@material-ui/core'
import { Formik, Form } from 'formik'
import { getUserType,selectFeatureSchema,userTypes } from '../../app/user/userSlice'
import { $baseUrl, $error, $event, $phoneValidator, $required, $success, $validEmail } from '../../utils/http'
import SubmitButton from '../common/SubmitButton'
import { setDialog } from '../../app/ui/uiSlice'
import AddressField from '../input/AddressField'
import { ExpertisePicker, providerTypes } from '../../views/dashboard/agent/Contractors'
import { ActionButtons } from '../../views/dashboard/FeatureEditor'
import Axios from 'axios'
import FieldWithLabel  from '../common/FieldWithLabel'

export default function ProfileDialog({onClose,profileTab, ...props}){
     
    const user = useSelector(getUser)
    const userType = useSelector(getUserType)
    const featureSchema = useSelector(selectFeatureSchema)

    const contractor = user.user_type == userTypes.contractor &&  user?.linked_data?.contractor

    const isAgent = userType === userTypes.agent
    const isHomeOwner = userType === userTypes.home_owner

    const priGuys = isAgent?'clients':'agents'
    const secGuys = isAgent?'potential clients':'contractors'

    const homely = isHomeOwner
    const dispatch = useDispatch()
    const isContractor = user.user_type == userTypes.contractor

    const is_social_boi = user && !user.has_password //(user.facebook_uid || user.google_uid)
    const [allow_mobile,setAllowMobile] = useState(!!(user && user.allow_mobile))
    const [myErrors,setMyErrors] = useState({})
    // const [currentTab,setCurrentTab] = useState(0)
    const currentTab = profileTab

    const initialContractorValues = {
        provider_type: contractor?.provider_type,
        company_name: contractor?.company_name,
    }
    const expertise = contractor?.expertise
    const [specialities,setSpecialities] = useState(expertise)


    const [loading,setLoading] = useState(0)
    const [formData,setFormData] = useState(initialContractorValues)

    async function patchContractor(patchData){
        try {
            setLoading(1)
            const fdata =  patchData || {
                ...formData,
                categories:`,${specialities.join(',')},`,
                expertise: specialities,

            }
            const res = await Axios.patch(`${$baseUrl}/contractors/${contractor.id}`,fdata)
            const newUserObj = {...user,linked_data:{...user.linked_data,contractor:res.data}}
            
            dispatch(setUser(newUserObj))

            if(!patchData){
                $success(res)
            }
        } catch (error) {
            $error(error)
        } finally {
            setLoading(0)
        }
    }

    function ChangePassword(){
        dispatch(setDialog('change_password'))
    }

    const defaultAddressFields = {
    
        line1:'',
        line2:'',
        city:'',
        state:'',
        zip_code:'',
        // country: '',
    }

    const defaultUserFields ={
        first_name:'',
        last_name:'',
        email:'',
        mobile_number:'',

        allow_email:'',
        allow_mobile:'',


        ...defaultAddressFields
    }

    async function handleSave(fdata,{setSubmitting}){
        const data = {...fdata}
        try {
            setSubmitting(true)
            const anomalies = {}
            for(let key in googleAddress){
                const googleValue = googleAddress[key] 
                if(googleValue != data[key]){
                    anomalies[key] = googleValue
                }
            }
            delete anomalies.line2
            // Set email to null for contractor
            const email = data.email || null
            data.address_anomalies = JSON.stringify(anomalies)
            data.allow_mobile = allow_mobile
            const location = fdata.location

            data.address = data.line1+'\n'+data.line2
            if(user.user_type == 0){
                delete data.address
                delete data.city
                delete data.zip_code
            }
            const isContractor = user.user_type == userTypes.contractor
            if(isContractor){
                if(!data.email){delete data.email}
            }

            const requests = [dispatch(patchUser(data))]
            const contractor_fields = ['company_name','email','location','line1']
            const something_in_contractor_changed = contractor_fields.some(field => fdata[field] != contractor[field])
            if(isContractor && something_in_contractor_changed){
                const cdata = {company_name:fdata.company_name,email}
                if(location){
                    cdata.location = location;
                    cdata.city_state_zip = [data.city,data.state,data.zip_code].join(',')
                    cdata.address = data.address
                }
                requests.push(patchContractor(cdata))
            }
            const [res,cres] = await Promise.all(requests)
            $success(res)
            $event('profile_update',{})
            onClose()
        } catch (err) {
            const res = err.response
            const code = res && err.response.status
            console.log(code)
            if(code == 409){
                const error = res.data.error
                if(error == 'email_taken'){
                    setMyErrors({...myErrors,email:true,})
                }
            }else{
                $error(err)
            }
        }
    }

    let gAddress = {}

    function populateFields(){
        if(!user){return}
        for(let k in defaultUserFields){
            const val = user[k]
            defaultUserFields[k] = val != null ? val : ''
        }
        const address = (isContractor && contractor ? contractor.address : user.address) || ''
        const [line1,line2] = address.split('\n')
        defaultUserFields.line1 = line1 || ''
        defaultUserFields.line2 = line2 || ''

        if(isContractor && contractor){
            const parts =  (contractor.city_state_zip || '').split(',')
            Object.keys(defaultAddressFields).slice(2).forEach((tf,i) => defaultUserFields[tf] = parts[i])
        }

        for(let k in defaultAddressFields){
            const val = defaultUserFields[k]
            defaultAddressFields[k] = val != null ? val : ''
        }

        let anomalies = user.address_anomalies || '{}'
        anomalies = JSON.parse(anomalies)
        
        gAddress = {...defaultAddressFields} 
        for(let k in anomalies){
            gAddress[k] = anomalies[k]
        }
        delete gAddress.line2
    }

    
    populateFields();

    let [userData,setUserData] = useState(defaultUserFields)

    let [address,setAddress] = useState(defaultAddressFields)
    let [googleAddress,setGoogleAddress] = useState(gAddress)


    console.log(user)

    const formikRef = useRef()
    const cfprops = {variant:'outlined',size:'small',}
    const countryOptions = [
        // 'United States of America',
        // 'Candada',
        // 'United Kingdom',
        // 'Australia',
        {value:'US',name:'United States of America'},
        {value:'CA',name:'Canada'},
        // {value:'AU',name:'Australia'},
        // {value:'UK',name:'United Kingdom'},
    ]
    const first_two = [
        {
            label:'Line 1',
            key:'line1',
            content:<AddressField {...cfprops} 
                name="line1"
                formikRef={formikRef}
                setGoogleAddress={setGoogleAddress}
                placeholder="Enter the first line of your address" 
                disabled={isHomeOwner}
            />,
        },
        {
            label:homely?'Line 2':'Company name',
            key:'line2',
            content:<VFormField {...cfprops}
                name={isContractor?"company_name":"line2"}
                disabled={isHomeOwner}
                placeholder={homely?'Address line 2' :'Company name'}
            />,
        },
    ]
    if(!homely){
        first_two.reverse()
    }
    const addressFields = [
        ...first_two
        ,
        {
            label:'State/Zip',
            key:'zip_code',
            content: <div className='container-fluid'>
                <div className='row'>
                    <div   className='pr-2 pl-0 col-5 d-flex flex-column'>
                        <VFormField {...cfprops}
                            name="city"
                            placeholder="City" 
                            disabled={isHomeOwner}
                        />
                    </div>
                    <div className='p-0 col-3 d-flex flex-column'>
                        <VFormField {...cfprops}
                            placeholder="state"
                            name="state" 
                            disabled={isHomeOwner}
                            
                        />
                    </div>
                    <div  className='pl-2 pr-0 col-4 d-flex flex-column'>
                        <VFormField {...cfprops}
                            name="zip_code"
                            label='zip code'
                            placeholder="zip code"
                            disabled={isHomeOwner}
                        />
                    </div>
                    
                </div>
            </div>,
        },
        // {
        //     label:'Country',
        //     key:'country',
        //     dynamic_content: (values,setFieldValue) => <Select
        //         value={values.country}
        //         onChange={(e)=>{
        //             setFieldValue('country',e.target.value);
        //             // setAddress({...address,country:e.target.value});
        //         }}
        //         {...cfprops}
        //         displayEmpty
        //         SelectDisplayProps={{style:{...cfprops,padding:'10px',textAlign:'left'}}}
        //     >
        //         {countryOptions.map(c =>
        //             <MenuItem value={c.value} key={c.value}>{c.name}</MenuItem>                    
        //         )}
        //     </Select>
        // },

    ]

    const addressFieldNames = ['line1','line2','city','state','zip_code','country']

    const phoneRegExp = /^[0-9 ()-]*$/

    // const allow_mobile = formikRef && formikRef.current && formikRef.current.values.allow_mobile
    console.log('allow mobile = '+allow_mobile)
    console.log(formikRef && formikRef.current && formikRef.current.values.mobile_number)
    const mobile_valid = allow_mobile ? yup.string().required('Please provide a mobile number') : yup.string()

    const please_choose = 'Please choose an addresses that best matches your address from the list shown.'

    const validationSchema = yup.object({
        // first_name: yup.string().required($required('First name')),
        // last_name: yup.string().required($required('Last name')),
        // company_name: yup.string().required($required('Company name')),
        email: $validEmail('',true),
        line1: yup.string().required('Please pick an address'),
        mobile_number: mobile_valid.test('len', 'Mobile number must have 10 digits', 
        $phoneValidator
        ),

    })

    function cloneUserData(){
        let initialUserData = {}
        for(let key of Object.keys(defaultUserFields)){initialUserData[key]=userData[key]}
        initialUserData.company_name = contractor?.company_name
        if(isContractor){
            initialUserData.email = contractor?.email || ''
            if(contractor && !contractor.location){
               addressFieldNames.forEach(af_name => initialUserData[af_name] = '')
            }
        }
        return initialUserData
    }
    const micasaMail = 'support@micasa.ai'
    const subject = 'Request to change home address'
    const body = ''
    const emailLink = `mailto:${micasaMail}?subject=${subject}&body=${body}`

    const labelClass = 'small text-muted p-0 m-0 font-weight-500'

    const OR = <p className='text-muted font-weight-500 text-center m-0 text-medium'>
        OR
    </p>

    function validateForm(values) {
        const {email,first_name,last_name,mobile_number,company_name} = values
        const errors = {}

        // if(!email && !mobile_number){
        //     errors.email = 'One of email or phone number is required'
        // }
        
        if(!company_name){
            if(!first_name && !last_name){
                errors.company_name = 'Either your full name or company name is required'
            }else
            if(!first_name){
                errors.first_name = 'First name is required'
            }else
            if (!last_name){
                errors.last_name = 'Last name is required'
            }
          
        }
        

        return errors
    }

    const spacer = <div className='my-2' />

    const userInformation = <Formik
        initialValues={cloneUserData()}
        validationSchema={validationSchema}
        validate={validateForm}
        onSubmit={handleSave}
        innerRef={formikRef}
    >
    { ({values,setFieldValue,isSubmitting}) => 
        <Form className='grow-column overflow-hidden'>

    
        {/* <div className='border-bottom-3'></div> */}
        {/* <div className='grow-column bg-success '>
            {(new Array(50).fill(0).map((z,i) => <p>pig {i}</p>))}
        </div> */}
  
        <div className='px-2 py-1 grow-column overflow-auto '>
            <div className='d-flex pt-2'>
                <div className='flex-grow-1  pr-2 d-flex flex-column'>
                        <p className={labelClass+' mt-sm-0'} >First name</p>
                        <VFormField  {...cfprops} name="first_name"  inputProps={{autoFocus:true}}/>
                </div>
                <div className='flex-grow-1 d-flex flex-column'>
                        <p className={labelClass+' mt-sm-0'} >Last name</p>
                        <VFormField  {...cfprops} name="last_name"/>
                </div>
            </div>
            {spacer}
            {/* {isContractor &&
                <div className='d-flex flex-column'>
                    {OR}
                    <div className='flex-grow-1 d-flex flex-column'>
                            <p className={labelClass+' mt-sm-0'} >Company name</p>
                            <VFormField  {...cfprops} name="company_name"/>
                    </div>
                    {spacer}
                </div>
            } */}

            <p className={labelClass}>Email address</p>
            <VFormField  {...cfprops} name="email" placeholder="Email address"/>

            {(user && user.new_email)?
            <p className='text-small text-secondary my-1' style={{color:'#e3e3e3'}}>
                Please note that you have requested to change your email to <span className='text-info'> {user.new_email} </span>
                <br/>
                You can continue by verifying it.
            </p>
            :null}

            {(user && myErrors.email)?
            <p className='text-small my-1' style={{color:'#ff4422'}}>
                Email address provided belongs to a primary owner of another home,instead of adding 
                this person  as primary owner,you can add as co-owner.
                <span className='text-primary cursor-pointer mx-1 font-weight-500' 
                onClick={()=>dispatch(setDialog(['add_a_co-owner',{email:values.email}]))}> 
                Add as co-owner 
                </span>
                <span className='text-muted'>
                now
                </span>
                
            </p>
            :null}

            <div className='d-flex my-2 align-items-center'>
                <Checkbox
                    size='medium'
                    className='p-0 mr-2'
                    checked={!!values.allow_email}
                    color='primary'
                    onClick={() => {setFieldValue('allow_email',!values.allow_email)}}
                ></Checkbox>
                <p className='m-0 small'>
                Allow {priGuys} and {secGuys} to reach me via email
                </p>

            </div>

            {/* {isContractor && 
                OR
            } */}

            <p className={labelClass}>Phone number</p>

            <VFormField  {...cfprops} name="mobile_number" isphone placeholder='Phone number'/>

            <div className='d-flex my-2 align-items-center'>
                <Checkbox
                    size='medium'
                    className='p-0 mr-2'
                    // checked={!!values.allow_mobile}
                    checked={!!allow_mobile}
                    color='primary'
                    // onClick={() => {setFieldValue('allow_mobile',!values.allow_mobile)}}
                    onClick={()=>setAllowMobile(!allow_mobile)}
                ></Checkbox>
                <p  className='m-0 small'>
                    Allow {priGuys} and {secGuys} to contact me through phone/text
                </p>
            </div>

            {!is_social_boi?
            <React.Fragment>
                <div className='d-flex justify-content-between align-items-center'>
                    <p className={labelClass} >Password</p>
                    <Button size='small' 
                    className='mb-0 text-primary'
                        onClick={ChangePassword}
                    >
                        Change password
                    </Button>
                </div>
                {/* <TextField type='password' value='0123456789' className='my-2 mb-3' 
                    {...cfprops}
                    style={{pointerEvents:'none',}}
                    inputProps={{style:{color:'gray'}}}
                /> */}
                <div className='fake-password-input flex-shrink-0'>
                    •••••••••
                </div>
            </React.Fragment>
            :null}
            
            {spacer}
            <p className={labelClass}>
                <span className='mr-1'>
                {homely?'Home':'Company name &'} address
                </span>
                {homely?
                    <span >
                    (
                        <span 
                            className='mr-1 text-primary font-weight-500 cursor-pointer'
                            onClick={()=>window.location.href=emailLink}
                        > 
                        Contact support 
                        </span>
                        to change your home address)
                    </span>
                :null}
            </p>
        

            {
                addressFields.map(af => (
                    <div className='d-flex flex-column mt-2 align-items-stretch position-relative' 
                    key={af.label}
                    >
                        {af.content || (af.dynamic_content && af.dynamic_content(values,setFieldValue))}

                        {(googleAddress[af.key] && googleAddress[af.key]!=values[af.key])?
                            <p className=' m-0  p-0 text-primary position-absolute'
                                style={{right:'6px',top:'0px'}}
                            >
                                *
                            </p>
                        :null}

                    </div>
                ))
            }

            
        </div>
        <div className='d-flex mt-1'>
            <Button variant='contained' 
            className='flex-grow-1 mr-2' 
            onClick={onClose}
            disableElevation>
                Discard
            </Button>
            <SubmitButton
                loading={isSubmitting}
            >
                Save
            </SubmitButton>
        </div>

        </Form>
    }

    </Formik>

    const expertiseChooser = <div className='container-fluid pl-1 pr-0 grow-column pt-sm-3'>


        {/* <FieldWithLabel label="Company name">
            <TextField 
                variant='outlined'
                size='small'
                value={formData.company_name}
                onChange={e=>setFormData({...formData,company_name:e.target.value})}
            />
        </FieldWithLabel> */}
        <div id="scrollable-area" className='overflow-auto flex-grow-1 pt-2 pr-1'>

            <FieldWithLabel label="Provider type">
                <div className='d-flex'>
                    {providerTypes.map((p,i) =>
                        <FormControlLabel
                            key={p.value}
                            control={
                                <Checkbox color="primary"
                                    checked={formData.provider_type == p.value}
                                    onClick={e=>(setFormData({...formData,provider_type:formData.provider_type==p.value?null:p.value}))}
                                    className='py-0 px-2'
                                />
                            }
                            label={p.display_name}
                            style={{color:'var(--black3)',fontSize:'0.4em'}}
                            className='my-0 font-weight-500 mb-2'
                        />
                    )}
                </div>
            </FieldWithLabel>

            <FieldWithLabel label='Areas of expertise'>
                <ExpertisePicker 
                    {...{featureSchema,value:specialities,onChange:setSpecialities}}
                />
            </FieldWithLabel>
        </div>


        <ActionButtons 
            nextText="Save"
            backText='Discard'
            onBack={()=>onClose()}
            onNext={patchContractor}
            className='mt-auto'
        />
    </div>

    let activeArea = userInformation

    if(user.user_type == userTypes.contractor){
        
        activeArea = <div className='grow-column '>
            <div className='grow-column '>
                {currentTab == 0 ?
                    userInformation
                    :
                    expertiseChooser
                }
            </div>
        </div>
    }


    return <div className='pl-sm-1 pb-2 grow-column my-dialog-md'
    >

        {activeArea}

            {/* <MDialog open={resetPass} onClose={()=>setResetPass(false)}>
                <div className=' bg-white'>
                    <ResetPassword changePassword goBack={()=>setResetPass(false)} />
                </div>

            </MDialog> */}


    </div>
}