import { Checkbox, CircularProgress, ClickAwayListener } from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import MoreIcon from '@material-ui/icons/MoreHoriz'
import Axios from 'axios'
import { default as React, useState } from 'react'
import SimpleMenu from '../../components/common/SimpleMenu'
import { $baseUrl, $error, $success, $confirm } from '../../utils/http'
import { DocumentUploader } from './DocumentUploader'

export function DocumentViewer({source,readOnly,filterer,hideBack,listOnly,onClose,house,
    selectable,selected,handleChecked,viewing_what,nonDeletable,onAddNew,onDocChange,
    gridView,className,label,disableUpload,...props
}){
    const[newName,setNewName] = useState('')
    const[renamed,setRenamed] = useState(null)

    async function renameDocument(doc){
        try {
            const url = `/documents/${doc.id}`
            
            const res = await Axios.patch($baseUrl+url,{filename:newName})
            setRenamed(null)
            $success(res)
            if(onDocChange){
                onDocChange(res.data)
            }
        } catch (err) {
            if(err!=false){
                $error(err)
            }
            throw err;
        }
    }

    async function handleEvent(event,doc){
        if(event=='document_download'){
            const url = doc.file
            downloadResource(url,doc.fname)
        }else if(event=='document_delete'){
            try {
                await deleteDocument(doc)
                if(onDocChange){
                    onDocChange(doc,true)
                }
            } catch (err) {
                console.log(err)
            }
        }else if(event=='document_open'){
            const url = doc.file
            window.open(url)
        }
        else if(event=='document_rename'){
            setNewName(doc.filename)
            setRenamed(doc.id)
            console.log(doc)
        }
    }

    const docOptions = ['open','download',]
    if(!readOnly){
        docOptions.push('rename',)
        if(!nonDeletable){
            docOptions.push('delete')
        }
    }
    const documents = source.documents
    const iconSize='20px'

    const noScroll = gridView
    const filtered_documents = filterer ? documents.filter(filterer) : documents
    const docList = filtered_documents.length ? 
    <div className={`${noScroll?'':'overflow-auto'} ${gridView?'row':''} mt-2`} 
        style={{maxHeight:noScroll?'':'360px'}} 
    >

        {filtered_documents.map(d =>
        {
            const fname = (d.file.split('/').slice(-1)[0])
            const name = d.filename||fname
            const doc = {...d,fname}
            const checked = (selectable)?selected.includes(d.id):false

            const renamer = <Renamer 
                name={name}
                value={newName} 
                onChange={e=>setNewName(e.target.value)}
                renaming={renamed==d.id}
                onRename={()=>renameDocument(doc)}
                onClickAway={()=>setRenamed(null)}
                onClick={()=>handleEvent('document_open',d)}
            />

            const menu = <SimpleMenu 
                options={docOptions}
                onChange={e=>handleEvent('document_'+e,doc)}
                className='py-1 bg-white pr-0'
            >
                <MoreIcon color='primary'/>
            </SimpleMenu>

            const extension = (d.file.split('.').reverse()?.[0] || '').toLowerCase()
            const fileTypes = [
                {
                    extensions:['jpg','jpeg','png'],
                    type:'image'
                },
                {
                    extensions:['docx','txt','word'],
                    type:'word'
                },
                {
                    extensions:['xls','xlsx','csv','xlsm', 'xltx', 'xltm',],
                    type:'excel'
                },
                {
                    extensions:['pdf'],
                    type:'pdf',
                },
                {
                    extensions:['ppt','pptx'],
                    type:'ppt',
                },
                {
                    extensions:['mp4','avi','mov'],
                    type:'video',
                },
                {
                    extensions:['mp3','wav',],
                    type:'audio',
                }
            ]

            let icon = 'document.svg'

            const file_type = fileTypes.find(ftype => ftype.extensions.includes(extension))
            if(file_type){
                icon = `file_types/${file_type.type}.png`
            }

            if(gridView){
                return <div className='col-12 col-sm-6 p-2 cursor-pointer ' key={d.id}>
                    <div className=' d-flex align-items-center border-thick 
                    border-top-0 border-right-0 border-left-0 p-2 pb-3'
                        style={{borderColor:'whitesmoke',}}
                    >
                        <img src={`/images/dashboard/${icon}`} width='36px' />
                        {renamer}
                        {menu}
                    </div>
                </div>
            }

            return  <div className={`d-flex align-items-center p-1
                cursor-pointer hover-light ${gridView?'col-12 col-sm-6':''} `}
                    key={d.id}
                    onClick={()=>{if(selectable)handleChecked(d)}}
                >   
                    {selectable?
                        <Checkbox
                            color='primary'
                            size="medium"
                            checked={checked}
                        />
                    :null}

                    <img src={`/images/dashboard/${icon}`} 
                        className=''
                        width={iconSize}
                    />
                    {renamer}

                    {/* <button
                        className=' bg-white'
                        onClick={()=>handleEvent('document_download',doc)}
                    >
                        <DownladIcon color='primary' />
                    </button> */}
                    {menu}
                </div>
            }
        )}
    </div>
    :
    <p className='my-4 text-medium text-muted text-center '>
            No documents yet   
    </p>


    if(listOnly){
        return docList;
    }
    

    return <div className={`d-flex flex-column flex-grow-1 ${className}`}>
        {hideBack?null:
            <div className='d-flex border-bottom2 align-items-center my-2'>
                <button className=' bg-white pl-0 pr-2' 
                onClick={()=>onClose()}>
                    <ArrowBack />
                </button>
                <p className='mx-0 my-0 text-secondary text-medium font-weight-500'>
                {viewing_what || 'Viewing documents'}
                </p>
                {/* <button className='bg-white ml-auto'>
                <MoreIcon className='' />
                </button> */}
            </div>
        }
        <div className='d-flex flex-column pb-3'>
            {/* <div className='text-primary cursor-pointer p-1 py-2
            d-flex align-items-center font-weight-500 text-medium'
                onClick={()=>openUpload()}
            >
                <img src='/images/dashboard/upload.svg' 
                    className='mr-2'
                    width={iconSize}
                />
                Upload new attachment
            </div> */}
            {readOnly?null:
                <DocumentUploader
                    isButton
                    source={source}
                    house_id={source.house}
                    className=''
                    icon='add'
                    // noBorder
                    noPadding
                    gridView={gridView}
                    label={label}
                    onAddNew={onAddNew||onDocChange}
                    disabled={disableUpload}
                />
            }
            {docList}
            
        </div>
    </div>
}

export function DocumentCount({count,className}){

    return <div className={`position-relative d-flex flex-column
        text-primary ${className||'mr-2 '}
        rounded text-small bg-light
    `}
    >
        <img src='/images/dashboard/document.svg' height='22px' />
        <p className='m-0 position-absolute text-small'
            style={{top:'50%',left:'50%',transform:'translate(-50%,-50%)',fontSize:'0.8rem'}}
        >
            {count}
        </p>
    </div>

}


export function Renamer({name,renaming,onRename,onClickAway,maxNameWidth,onClick,...props}){
    const [LOADING,SAVING] = '123456789'.split().map(n=>Number(n))
    const [loading,setLoading] = useState(0)

    async function handleRename(e){
        try {
            e.stopPropagation()
            e.preventDefault()
            setLoading(SAVING)
            await onRename()
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(0)
        }
    }
    const handleClickAway = (e) => {
        const targ = e.target
        if(!targ || targ.id != 'save-button'){
            onClickAway()
        }
    }

    return <form className={`d-flex overflow-hidden align-items-center flex-grow-1 ml-1
    ${renaming?' border bg-white  border-primary ml-2 ':''} `}
    style={{borderRadius:'6px'}}
        onSubmit={handleRename}
    >
       {renaming?
       <ClickAwayListener onClickAway={handleClickAway}>

        <div className='d-flex flex-grow-1 mw-100 p-0'>
                <input 
                    variant="outlined"
                    color='secondary'
                    size='small'
                    className='bg-white p-1 border-0 pl-2 flex-grow-1 d-flex'
                    style={{outline:'none',minWidth:'32px',fontSize:'14px'}}
                    {...props}
                    autoFocus
                />
                <div className='text-small bg-white px-2 d-flex align-items-center
                text-primary p-1 border-left cursor-pointer'
                    onClick={handleRename}
                    id="save-button"
                >    
                    {loading==SAVING?
                        <CircularProgress size={12} color='primary' className='mx-2' />
                    :'Save'}
                </div>
        </div>
       </ClickAwayListener>

       :      
       <p className='m-0 ml-1 pr-1 pl-2 mr-auto text-truncate' onClick={onClick||(()=>{})}
            style={{fontSize:'14px'}}
       >
           {name}
       </p>
       }

    </form>
}


export async function deleteDocument(doc){
    try {
        // setLoading(SAVING)
        const url = `/documents/${doc.id}`
        
        try{
            await $confirm({question:`Are you sure you want to delete this document?`, no:'Cancel'})
        }catch{
            throw false;
        }
        const res = await Axios.delete($baseUrl+url)
        $success(res)
        return res.data
    } catch (err) {
        if(err!=false){
            $error(err)
        }
        throw err
    }
}


function forceDownload(blob, filename) {
    var a = document.createElement('a');
    a.download = filename;
    a.href = blob;
    // For Firefox https://stackoverflow.com/a/32226068
    document.body.appendChild(a);
    a.click();
    a.remove();
}

// Current blob size limit is around 500MB for browsers
function downloadResource(url, filename) {
    if (!filename) filename = url.split('\\').pop().split('/').pop();
    fetch(url)
    .then(response => response.blob())
    .then(blob => {
    let blobUrl = window.URL.createObjectURL(blob);
    forceDownload(blobUrl, filename);
    })
    .catch(e => console.error(e));
}