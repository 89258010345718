
import { Button, CircularProgress } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Axios from 'axios'
import { default as React, useRef, useState } from 'react'
import { $baseUrl, $error, $success } from '../../utils/http'
import { ActionButtons } from '../../views/dashboard/FeatureEditor'


export function DocumentUploader({label,source,house,onClose,
    onFilePicked,onAddNew,buttonOnly,gridView,disabled,
    icon='upload',noBorder,noPadding,isButton,...props}){
    const [uploading,setUploading] = useState(false)
    const src = {...(source||house)}
    if(house){src.type='house';}
    const filePicker = useRef()
    const [newDocument,setNewDocument] = useState('')

    const allowed_formats = [
    '.gif', '.jpg', '.png','.jpeg',
        
    '.doc', '.docx', '.docm', '.dotx', '.dotm', 
    ,'.xls', '.xlsx', '.xlsm', '.xltx', '.xltm',
    ,'.ppt', '.pptx', '.pps', '.ppsx', '.pptm', '.potx', '.potm',
    ,'.pdf','.htm ', '.html','.txt',

    ,'.mp4','.avi', '.mov',

    ]

    const getErrors = (file) => {
        const errors = []
  
        let pretty_file = file.split('\\').slice(-1)[0]
        const extension = '.'+pretty_file.split('.').slice(-1)[0]
        const invalid_format = file && !allowed_formats.includes(extension.toLowerCase())
        const filePicked = filePicker.current && filePicker.current.files[0]
        const fileTooLarge = filePicked && (filePicked.size  > 1000* 1000 * 10)
        const emptyFile = filePicked && (filePicked.size  == 0)
    
        if(invalid_format){
            errors.push('This type of file is not allowed')
        }
        if(fileTooLarge){
            errors.push('This File is too large (more than 10 MB)')
        }
        else if(emptyFile){
            errors.push('The file is empty')
        }
        
        return errors
    }

    
    const file = newDocument
    const errors = getErrors(file)
    const clearFile = () => {
        if(filePicker.current){
            filePicker.current.value = ''
        }
        if(onFilePicked){
            onFilePicked()
        }
        setNewDocument('')
    }
    let pretty_file = file.split('\\').slice(-1)[0]
    const extension = '.'+pretty_file.split('.').slice(-1)[0]
    const max_len = 18
    let pretty_short_file = pretty_file.slice(0,max_len)+(pretty_file.length>max_len?'...':'')

    async function uploadDocument(filename) {
        try {
            setUploading(true)
            const filePicked = filePicker.current && filePicker.current.files[0]
 
            const fdata = new FormData()
            fdata.append('file',filePicked)
            fdata.append('filename',filename||pretty_short_file)

            const config = {headers: {'Content-Type': 'multipart/form-data'}}

            const res = await Axios.post($baseUrl+`/documents/${(src.type||'house')}/${src.id}`,fdata,config)
            if(onAddNew){
                const newDoc = res.data.data 
                if(newDoc){
                    onAddNew(newDoc)
                }
            }
            $success(res)
            if(onClose){onClose()}
            clearFile()
        } catch (err) {
            $error(err)
        }
        finally{
            setUploading(false)
        }
    }
    const iconDict = {
        'upload':'dashboard/upload',
        'add':'features/add',
    }
    let fileIcon = iconDict[icon]

    const inputField = <input type="file" 
        ref={filePicker}
        className='ml-auto2 d-none'
        id='contained-button-file'
        disabled={disabled}
        onChange={e=>{
            const file = e.target.value
            const errors =  getErrors(file)
            setNewDocument(file)
            if(onFilePicked){
                onFilePicked(file,filePicker.current && filePicker.current.files[0],errors)
            }
            if(!buttonOnly){
                
                let pretty_file = file.split('\\').slice(-1)[0]
                const extension = '.'+(file.split('\\').slice(-1)[0].split('.').slice(-1)[0])
                const invalid_format = file && !allowed_formats.includes(extension.toLowerCase())
                const filePicked = filePicker.current && filePicker.current.files[0]
                const fileTooLarge = filePicked && (filePicked.size  > 1000* 1000 * 10)
                const emptyFile = filePicked && (filePicked.size  == 0)

                // const extension = '.'+pretty_file.split('.').slice(-1)[0]
                const max_len = 18
                let pretty_short_file = pretty_file.slice(0,max_len)+(pretty_file.length>max_len?'...':'')
            
            
                const file_rejected = fileTooLarge || invalid_format || emptyFile
                
                if(!file_rejected){
                    uploadDocument(pretty_short_file)
                }
            }
        }}
    />
    const btnClass = `bg-white ml-1 rounded-8 text-primary border-thick d-flex 
    align-items-center justify-content-center px-2 `

    const invalid_format = file && !allowed_formats.includes(extension.toLowerCase())
    const filePicked = filePicker.current && filePicker.current.files[0]
    const fileTooLarge = filePicked && (filePicked.size  > 1000* 1000 * 10)
    const emptyFile = filePicked && (filePicked.size  == 0)

    const file_rejected = fileTooLarge || invalid_format || emptyFile

    const uploadButton = <div {...props}>
        {inputField}
        <div  className='d-flex flex-grow-1 '>

            <div
            className={` d-flex align-items-center flex-grow-1
                 text-small py-0 rounded-8 bg-white my-0  ${noBorder?'':'border-thick'} 
                    ${gridView?'mb-2':''}
                 `}
                 style={{borderColor:gridView?'whitesmoke':'whitesmoke'}}
            >
                <label  htmlFor="contained-button-file"
                    className={`d-flex align-items-center mb-0 flex-grow-1 cursor-pointer
                        ${disabled?' filter-grayscale opacity-50':''}
                    `}
                 style={{padding:'6px 6px'}}

                >

                    {file?null:
                    <img src={`/images/${fileIcon}.svg`} height={gridView?'32px':"24px"}  className='mr-1 ml-0' />
                    }
                    <span className={`ml-1 font-weight-500 ${gridView?'text-medium':"text-small"} text-primary text-left`}
                    >
                        {file?pretty_short_file:(label||'Upload a file or a picture')}
                    </span>
                </label>

                {file?
                    <div className='d-flex ml-auto'>
                        {file_rejected?null:
                            (uploading?
                                <div className={` py-2 pr-2 mr-2 `}>
                                    <CircularProgress size={18} color='primary' />
                                </div>
                                :
                                <button className='bg-white px-2'
                                onClick={uploadDocument}
                                title="Upload the file"
                                >
                                    {/* <UploadIcon  size={18} /> */}
                                    <img src={`/images/dashboard/upload.svg`} height="24px" />
                                </button>
                            )
                        }
                    </div>
                :null}

            </div>
            {file?
                <div className='d-flex'>
                    {
                    (file_rejected||'THIS LINE ACTS AS A COMMENT')?null:
                        (uploading?
                        <div className={` ${btnClass} `}>
                            <CircularProgress size={18} color='primary' />
                        </div>
                        :
                            <button 
                            onClick={uploadDocument} className={`${btnClass} text-primary`}
                            title="Upload the file"
                            >
                                {/* <UploadIcon  size={18} /> */}
                                <img src={`/images/dashboard/upload.svg`} height="24px" />
                            </button>
                        )
                    }

                    <button onClick={clearFile} className={`${btnClass} text-secondary`}
                        title="Clear file"
                    >
                        <CloseIcon/>
                    </button>
                </div>
            :null}
        </div>
            {!errors.length ? null :
                (errors.map((e,i) => 
                    <p className='text-small text-danger ' key={i}>
                        {e}
                    </p>  
                ))
            }
          
    </div>
    
    if(isButton){
        return uploadButton
    }

    return <div className='p-3 text-center my-dialog flex-grow-1 d-flex flex-column'>
        <p className='lead font-weight-bold mb-2'>
            Upload a document
        </p>
        <p className='text-medium text-muted '>
            Please pick a file to upload.
        </p>

        {inputField}
        
        <label htmlFor="contained-button-file">
            <div className='d-flex justify-content-between align-items-center  rounded border p-2 '>
                <p className='m-0'>
                    {file?file:'No file picked'}
                </p>

                <Button variant="outlined" className='ml-2'
                color="primary" component="span"
                >
                    Choose {file?'file':'file'}
                </Button>

            </div>
        </label>

        <ActionButtons
            onBack={()=>onClose()}
            onNext={()=>uploadDocument()}
            nextText='Upload'
            disabled={!file}
            loading={uploading}
        />

    </div>
}